<template>
  <div class="photocontestEdit">

    <form method="POST" :action="actionurl" class="register_form pure-form pure-form-stacked" enctype="multipart/form-data" @submit.prevent>

        <fieldset class="">

          <div class="form-group required">
            <div class="form_header">
              <span class="label">コンテストのタイトル</span>
            </div>

            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1" v-bind:class="{ 'is-invalid':errors.title }" name="title" v-model="node.title" required autocomplete="title" autofocus>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.title"></div>
                <div class="note">コンテストのタイトル。</div>
              </div>
            </div>
          </div>

          <div class="form-group required">
            <div class="form_header">
              <span class="label">URL</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                /photocontest/ <input type="text" class="pure-input" v-bind:class="{ 'is-invalid':errors.slug }" name="slug" v-model="node.slug" required autocomplete="slug">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.slug"></div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">バナー画像</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <image-uploader-with-photo v-show="node.image.flag.uploaderShowFlag && !node.image.flag.nowRenderingFlag"
                name="image"
                tmpFormatName="photocontest_image"
                :image-que="null"
                :multiple="false"
                :disablePG="true"
                @startUpload="startUploadPhotocontestImage"
                @setFile="setFile"
                @setProcessing="setProcessing"
                @addQue="addQue"
                >
                </image-uploader-with-photo>
                <image-preview v-show="!node.image.flag.uploaderShowFlag"
                :previewFile="node.image.preview"
                :rotatable="false"
                @remove="removeImage"
                @setImageRotate="setImageRotate"
                >
                </image-preview>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group" v-show="!node.body_include">
            <div class="form_header">
              <span class="label">本文HTML</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <textarea
                v-model="node.body_html"
                rows="5"
                >
                </textarea>
                <!-- <textarea-auto-adjust
                  :class="{ 'is-invalid':errors.body_html }"
                  name="body_html"
                  v-model="node.body_html"
                ></textarea-auto-adjust> -->
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.body_html"></div>
                <div class="note">
                  ここにはHTMLのみ書いてください。CSSは「/public/css/photocontest.css」へ
                </div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group required">
            <div class="form_header">
              <span class="label">募集期間</span>
            </div>
            <div class="form_body">
              <div class="form_input_row">
                <div class="form_input">
                  <input type="text" class="pure-input" v-bind:class="{ 'is-invalid':errors.schedule_start }" name="schedule_start" v-model="node.schedule_start" required autocomplete="schedule_start"> ～ <input type="text" class="pure-input" v-bind:class="{ 'is-invalid':errors.schedule_end }" name="schedule_end" v-model="node.schedule_end" autocomplete="schedule_end">
                </div>
              </div>

              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.schedule_start"></div>
                <div class="invalid-feedback" role="alert" v-html="errors.schedule_end"></div>
                <div class="note">
                  募集期間スタート日時と締切日時を "Y-m-d H:i:s" 形式で入力してください。
                </div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">結果発表日時</span>
            </div>
            <div class="form_body">
              <div class="form_input_row">
                <div class="form_input">
                  <input type="text" class="pure-input" v-bind:class="{ 'is-invalid':errors.results_announcement_date }" name="results_announcement_date" v-model="node.results_announcement_date" autocomplete="results_announcement_date">
                </div>
              </div>

              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.results_announcement_date"></div>
                <div class="note">
                  結果発表の日時を "Y-m-d H:i:s" 形式で入力してください。
                </div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="" v-if="isStaff">
          <div class="form-group">
            <div class="form_header">
              <span class="label">公開状態</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <status-checker :config_status="config_status" :enable_values="enable_status" :currentStatus="node.status ? node.status : 40" @setStatus="setStatus"></status-checker>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.categories"></div>
                <div class="note">公開状態を選択してください</div>
              </div>
            </div>
          </div>
        </fieldset>

        <div class="form_buttons">
          <a v-if="nodeprop" class="btn cancelBtn" v-bind:href="'/photocontest/' + nodeprop.slug">戻る</a>
          <button v-if="nodeprop" v-bind:disabled="isProcessing" type="button" class="btn btn-primary" @click.prevent.stop="onSubmit()">更新</button>
          <button v-else type="button" v-bind:disabled="isProcessing" class="btn btn-primary" @click.prevent.stop="onSubmit()">投稿</button>
          <button v-if="nodeprop && user.roles && user.roles.some(role => role.role <= 20)" type="button" class="btn btn-warning" @click="deleteNode">削除</button>
        </div>

        <div class="dialog dialog-t" v-if="showProgress">
          <div class="inner">
            <div class="dialog-header">
              投稿処理
            </div>
            <div class="dialog-content">
              <div v-if="progress > 0 && progress < 100">
                画像ファイルアップロード中...
                <progress ref="progress" value="0" max="100"></progress><br>{{progress}}%
              </div>
              <div v-if="!progress || progress <= 0 && progress >= 100" class="icon-inline">
                <span class="loader icon">Loading...</span>
                <span>ページ生成中...　処理完了後は自動的にページに移動します。</span>
              </div>
            </div>
          </div>
        </div>

        <div class="dialog dialog-t" v-if="showValidationErrorDialog">
          <div class="inner">
            <div class="dialog-header">
              入力内容をご確認ください
            </div>
            <div class="dialog-content">
              <ul v-for="error in validationErrors" :key="error.id">
                <li v-for="message in error.messages" :key="message.id">{{ message.message }}</li>
              </ul>
              <div class="buttons">
                <button type="button" class="btn btn-primary" @click="showValidationErrorDialog = false">OK</button>
              </div>
            </div>
          </div>
        </div>

        <div class="dialog dialog-t" v-if="showErrorDialog">
          <div class="inner">
            <div class="dialog-header">
              {{error.status}} {{error.statusText}}
            </div>
            <div class="dialog-content">
              <div>{{error.data.message}}</div>
              <div class="buttons">
                <button type="button" class="btn btn-primary" @click="showErrorDialog = false">OK</button>
              </div>
            </div>
          </div>
        </div>

    </form>
  </div>

</template>

<script>
    import ImageUploaderWithPhoto from './form_element/ImageUploaderWithPhoto';
    import ImagePreview from './ImagePreview'
    // import draggable from 'vuedraggable';
    // import TextareaAutoAdjust from './form_element/TextareaAutoAdjust';
    import PhotoReference from './form_element/PhotoReference';
    // import ImagePreviewMultiple from './ImagePreviewMultiple'
    import MordalConfirm from './MordalConfirm'
    import StatusChecker from './form_element/StatusChecker';
    export default {
        components: {
            ImageUploaderWithPhoto,
            ImagePreview,
            // draggable,
            // TextareaAutoAdjust,
            PhotoReference,
            // ImagePreviewMultiple,
            MordalConfirm,
            StatusChecker,
        },
        props: {
          // nodeプロパティ
            nodeprop: {
                type: Object,
                default: null,
                required: false,
            },
            // フォームの送信先
            actionurl: {
                type: String,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
            config_status: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            isAdmin: false,
            isStaff: false,
            enable_status: [ // {1: "公開", 5: "限定公開", 10: "予約", 20: "下書き", 40: "承認申請中", 45: "承認却下", 60: "公開制限中", 90: "非公開"}
              // 1, 5, 10, 20, 40, 45, 60, 90,
              1, 90,
            ],
            // post
            node: {
              id: null,
              title: '',
              slug: '',
              image: {
                  file_name: null,
                  preview: {
                    src: null,
                  },
                  flag: {
                    uploaderShowFlag: true,
                    nowRenderingFlag: false,
                    nowChangeFlag: false,
                    isDragging: false,
                    // ドラッグ中かどうかの判定用
                    dragCount: 0,
                  },
                  new: true,
                  rotate: null,
              },
              body_html: '',
              schedule_start: new Date().toLocaleDateString('sv') + ' 00:00:00', // sv（スウェーデン）ロケールはYYYY-MM-DD形式の日付文字列を戻す
              schedule_end: '',
              results_announcement_date: '',
              status: 1,
              reserved: null,
          },

            // エラーダイアログ表示フラグ
            showErrorDialog: false,
            // 返ってきたerror.responceが入る
            error: {},
            // バリデーションエラーの内容が入る
            errors: {},
            // プログレスダイアログ用の進捗状況（%）
            progress: 0,
            // プログレスダイアログ表示フラグ
            showProgress: false,
            // areas: [],
            // areaChecked: [],
            // バリデーションエラーダイアログ表示フラグ
            showValidationErrorDialog: false,
            // バリデーションエラーダイアログ表示フラグ
            validationErrors: [],
            // プレイスでマッチしたプレイスデータを入れる
            findPlaceResults: [],
            // 処理中はtrueにするとボタンが無効になる
            isProcessing: false,
        }),
        created() {
            // console.log('this.nodeprop');
            // console.log(this.nodeprop);
            // console.log(this.node.image);
            // console.log(this.node.image.flag);
            this.isAdmin = this.user.roles.some(user => user.role === 1);
            this.isStaff = this.user.roles.some(user => user.role === 1 || user.role === 10 || user.role === 20);
            if(this.nodeprop) {
              // ノードプロパティをdataにセット
              // this.node = this.nodeprop;
              this.node.id = this.nodeprop.id;
              this.node.title = this.nodeprop.title;
              this.node.slug = this.nodeprop.slug;
              if(this.nodeprop.image) {
                this.node.image = {
                  file_name: this.nodeprop.image,
                  preview: {
                    src: '/storage/images/photocontest_image/single/' + this.nodeprop.image,
                  },
                  flag: {
                    uploaderShowFlag: false,
                    nowRenderingFlag: false,
                    nowChangeFlag: false,
                    isDragging: false,
                    // ドラッグ中かどうかの判定用
                    dragCount: 0,
                  },
                  new: false,
                  rotate: null,
                }
              }
              this.node.body_html = this.nodeprop.body_html;
              this.node.schedule_start = this.nodeprop.schedule_start;
              this.node.schedule_end = this.nodeprop.schedule_end;
              this.node.results_announcement_date = this.nodeprop.results_announcement_date;
              this.node.status = this.nodeprop.status;
            }
        },
        computed: {
        },
        methods: {
            setStatus(status) {
              this.node.status = status;
              // console.log(this.node.status);
            },
            setProcessing(status) {
              this.isProcessing = status;
              // console.log(this.isProcessing);
            },
            setImageRotate(imageRotate) {
                this.node.image.rotate = imageRotate.rotate;
            },
            setSingleImageRotate(imageRotate) {
                this.node.single_image.rotate = imageRotate.rotate;
            },
            startUploadPhotocontestImage() {
                this.node.image.flag.uploaderShowFlag = false;
                this.node.image.flag.nowRenderingFlag = true;
            },
            startUploadSingleImage() {
                this.node.single_image.flag.uploaderShowFlag = false;
                this.node.single_image.flag.nowRenderingFlag = true;
            },
            addQue() {
              // this.planImageQue++;
              // this.planImages.push({
              //   order: this.planImages.length + 1,
              //   type: 'que',
              //   queIndex: this.planImageQue,
              //   preview: {},
              // });
            },
            addSingleImageQue() {
              // this.planImageQue++;
              // this.planImages.push({
              //   order: this.planImages.length + 1,
              //   type: 'que',
              //   queIndex: this.planImageQue,
              //   preview: {},
              // });
            },
            // from: ImageUploaderMultipleWithPhoto ->
            // ファイルを追加（アップローダーコンポーネントで画像追加したらこれが発火 ※引数にファイルが入ってくる）
            // または ファイルを削除（アップローダーコンポーネントで画像削除したらこれが発火 ※引数はnull）
            // setFile(file, type, queIndex = null) {
            setFile(e) {
              var file = e.file;
              var type = e.type;
              if(type == 'upload') {
                if(file) { // ファイルが送られてきたら（=画像が追加された）
                  this.node.image = {
                    type: 'photocontest_image',
                    file_name: file.tmpFile,
                    preview: {
                      src: file.src,
                      file: file.file,
                    },
                    flag: {
                      uploaderShowFlag: false,
                      nowRenderingFlag: false,
                      nowChangeFlag: false,
                      isDragging: false,
                      // ドラッグ中かどうかの判定用
                      dragCount: 0,
                    },
                    file: file,
                    new: true,
                    rotatable: true,
                    rotate: 0,
                    imageRotate: 0,
                    imageRotateClass: 'rotate-init',
                  };
                  // this.node.image = newValue;
                } else { // 引数がnullだったら（=画像が削除された）
                  // 登録用画像を削除
                  this.file = null;
                }
                if(this.node.file_name) {
                  // nodeのfileを削除
                  this.node.file_name = null;
                  // 古いの消したよフラグ立てる
                  this.remove_file = true;
                }
              } else if(type == 'pg') {
                if(file) { // ファイルが送られてきたら（=画像が追加された）
                  // 画像データを登録
                  this.node.image = {
                    type: 'pg',
                    file_name: file.file_name,
                    preview: {
                      src: '/storage/images/pg/sample/' + file.file_name,
                    },
                    flag: {
                      uploaderShowFlag: false,
                      nowRenderingFlag: false,
                      nowChangeFlag: false,
                      isDragging: false,
                      // ドラッグ中かどうかの判定用
                      dragCount: 0,
                    },
                    node: file,
                    new: true,
                    user_id: file.user.id,
                    user_name: file.user.name,
                  };
                } else { // 引数がnullだったら（=画像が削除された）
                  // 登録用画像を削除
                  this.file = null;
                }
                // if(this.node.file_name) {
                //   // nodeのfileを削除
                //   this.node.file_name = null;
                //   // 古いの消したよフラグ立てる
                //   this.remove_file = true;
                // }
              }
              this.isProcessing = false;
              // console.log(this.node.image);
            },
            setSingleImageFile(e) {
              var file = e.file;
              var type = e.type;
              // console.log('e');
              // console.log(e);
              if(type == 'upload') {
                if(file) { // ファイルが送られてきたら（=画像が追加された）
                  this.node.single_image = {
                    type: 'photocontest_image',
                    file_name: file.tmpFile,
                    preview: {
                      src: file.src,
                      file: file.file,
                    },
                    flag: {
                      uploaderShowFlag: false,
                      nowRenderingFlag: false,
                      nowChangeFlag: false,
                      isDragging: false,
                      // ドラッグ中かどうかの判定用
                      dragCount: 0,
                    },
                    file: file,
                    new: true,
                    rotatable: true,
                    rotate: 0,
                    imageRotate: 0,
                    imageRotateClass: 'rotate-init',
                  };
                  // this.node.image = newValue;
                } else { // 引数がnullだったら（=画像が削除された）
                  // 登録用画像を削除
                  this.file = null;
                }
                if(this.node.file_name) {
                  // nodeのfileを削除
                  this.node.file_name = null;
                  // 古いの消したよフラグ立てる
                  this.remove_file = true;
                }
              } else if(type == 'pg') {
                if(file) { // ファイルが送られてきたら（=画像が追加された）
                  // 画像データを登録
                  this.node.single_image = {
                    // order: this.planImages.length + 1,
                    type: 'pg',
                    file_name: file.file_name,
                    preview: {
                      src: '/storage/images/pg/sample/' + file.file_name,
                    },
                    flag: {
                      uploaderShowFlag: false,
                      nowRenderingFlag: false,
                      nowChangeFlag: false,
                      isDragging: false,
                      // ドラッグ中かどうかの判定用
                      dragCount: 0,
                    },
                    node: file,
                    new: true,
                    user_id: file.user.id,
                    user_name: file.user.name,
                  };
                } else { // 引数がnullだったら（=画像が削除された）
                  // 登録用画像を削除
                  this.file = null;
                }
                // if(this.node.file_name) {
                //   // nodeのfileを削除
                //   this.node.file_name = null;
                //   // 古いの消したよフラグ立てる
                //   this.remove_file = true;
                // }
              }
              this.isProcessing = false;
              // console.log(this.node.single_image);
            },
            // from: ImageUploaderMultipleWithPhoto -> メイン画像アップロードコンポーネントから、画像が削除された時の処理
            removeImage() {
              // console.log('removeSpotImage');
              // console.log(index);
              if(this.node.image.type == 'photocontest_image' && this.node.image.new === true ) {
                // その編集で追加した画像の場合はtmp画像を消す
                axios.post('/tmp-remove',{
                  // field: 'multiple_image',
                  images: [this.node.image.file_name],
                })
                .then((res)=>{
                  // console.log(res);
                  this.node.image = {
                    file_name: null,
                    preview: {
                      src: null,
                    },
                    flag: {
                      uploaderShowFlag: true,
                      nowRenderingFlag: false,
                      nowChangeFlag: false,
                      isDragging: false,
                      // ドラッグ中かどうかの判定用
                      dragCount: 0,
                    },
                    new: true,
                    rotate: null,
                  }
                })
                .catch((error) => {
                  // console.log(error);
                  // console.log(error.response);
                  alert('サーバーエラー: 画像を削除できませんでした');
                  // // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                  // if(error.response.data.errors) {
                  //   var errors = {};
                  //   for(var key in error.response.data.errors) {
                  //     errors[key] = error.response.data.errors[key].join('<br>');
                  //   }
                  //   this.errors = errors;
                  // } else {
                  //   // バリデーションエラーの場合以外はエラーダイアログを表示
                  //   // エラーデータをほりこむ
                  //   this.error = error.response;
                  //   this.showErrorDialog = true;
                  // }
                })
              } else {
                // // もともとある画像の場合は削除フラグをセットしてデータから消す
                // this.removedSpecialImages.push({
                //   plan_image_id: this.planImages[index].plan_image_id,
                //   file: {
                //     type: this.planImages[index].type,
                //     file_id: this.planImages[index].file_id,
                //     file_name: this.planImages[index].file_name,
                //   }
                // }),
                // 指定の画像を消す
                this.node.image = {
                  file_name: null,
                  preview: {
                    src: null,
                  },
                  flag: {
                    uploaderShowFlag: true,
                    nowRenderingFlag: false,
                    nowChangeFlag: false,
                    isDragging: false,
                    // ドラッグ中かどうかの判定用
                    dragCount: 0,
                  },
                  new: true,
                  rotate: null,
                }
              }
              this.isProcessing = false;
            },
            removeSingleImage() {
              // console.log('removeSpotImage');
              // console.log(this.node.single_image);
              if(this.node.single_image.type == 'photocontest_image' && this.node.single_image.new === true ) {
                // その編集で追加した画像の場合はtmp画像を消す
                axios.post('/tmp-remove',{
                  // field: 'multiple_image',
                  images: [this.node.image.file_name],
                })
                .then((res)=>{
                  // console.log(res);
                  this.node.single_image = {
                    file_name: null,
                    preview: {
                      src: null,
                    },
                    flag: {
                      uploaderShowFlag: true,
                      nowRenderingFlag: false,
                      nowChangeFlag: false,
                      isDragging: false,
                      // ドラッグ中かどうかの判定用
                      dragCount: 0,
                    },
                    new: true,
                    rotate: null,
                  }
                })
                .catch((error) => {
                  // console.log(error);
                  // console.log(error.response);
                  alert('サーバーエラー: 画像を削除できませんでした');
                  // // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                  // if(error.response.data.errors) {
                  //   var errors = {};
                  //   for(var key in error.response.data.errors) {
                  //     errors[key] = error.response.data.errors[key].join('<br>');
                  //   }
                  //   this.errors = errors;
                  // } else {
                  //   // バリデーションエラーの場合以外はエラーダイアログを表示
                  //   // エラーデータをほりこむ
                  //   this.error = error.response;
                  //   this.showErrorDialog = true;
                  // }
                })
              } else {
                // // もともとある画像の場合は削除フラグをセットしてデータから消す
                // this.removedSpecialImages.push({
                //   plan_image_id: this.planImages[index].plan_image_id,
                //   file: {
                //     type: this.planImages[index].type,
                //     file_id: this.planImages[index].file_id,
                //     file_name: this.planImages[index].file_name,
                //   }
                // }),
                // 指定の画像を消す
                this.node.single_image = {
                  file_name: null,
                  preview: {
                    src: null,
                  },
                  flag: {
                    uploaderShowFlag: true,
                    nowRenderingFlag: false,
                    nowChangeFlag: false,
                    isDragging: false,
                    // ドラッグ中かどうかの判定用
                    dragCount: 0,
                  },
                  new: true,
                  rotate: null,
                }
              }
              this.isProcessing = false;
            },


            /**
             * 送信処理
             */
            // 画像アップロード中プログレスバーを出す
            onUpload(e) {
              if(this.showProgress == true) {
                // console.log(e.loaded +'/'+ e.total);
                if(this.$refs.progress) {
                  // プログレスバーを計算して変更
                  this.$refs.progress.value = Math.floor((e.loaded * 100) / e.total);
                  this.progress = Math.floor((e.loaded * 100) / e.total);
                }
              }
            },
            deleteNode() {
              window.confirm('本当に削除しますか?');
              this.isProcessing = true;
              const formData = new FormData();
              // 削除リクエスト投げる
              axios.delete('/photocontest/' + this.node.id).then((res)=>{
                if(res.data) {
                this.isProcessing = false;
                  //
                  // this.findPlaceResults = res.data;
                  // console.log('res.data');
                  // console.log(res.data);
                }
                // console.log(this.node.places);
                // console.log(this.matchPlaceResults);
              })
              // ToDo: エラー処理書く
              .catch(error => console.log(error))
            },
            // サブミット！
            onSubmit() {
                this.isProcessing = true;
                // フォームで送信するFormDataインスタンスつくる
                const formData = new FormData();
                // 送信するフィールドのデータを登録する
                // propで投稿情報が来てたら（updateだったら）
                if(this.nodeprop) {
                  // PUTで送ってるように偽装する（普通にPUTで送れないため）
                  formData.append('_method', 'PUT');
                }
                // 登録する写真を登録
                if(this.node.image) {
                  formData.append('image', this.node.image.file_name || '');
                }
                // その他のデータを登録
                formData.append('title', this.node.title);
                formData.append('slug', this.node.slug);
                formData.append('body_html', this.node.body_html || '');
                formData.append('schedule_start', this.node.schedule_start || '');
                formData.append('schedule_end', this.node.schedule_end || '');
                formData.append('results_announcement_date', this.node.results_announcement_date || '');
                formData.append('status', this.node.status || '');

                //axiosでPOST送信
                axios.post(this.actionurl, formData, {
                  onUploadProgress: this.onUpload,
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then( response => {
                  // プログレスダイアログを消す
                  this.showProgress = false;
                  // プログレスの値をリセット
                  this.progress = 0;
                  // console.log(response);
                  // リダイレクトURLが返ってきたら（=正常に登録できた）ページ移動する（シングルページ���リダイレクト）
                  if(response.data.redirect_path) {
                    document.location.href = response.data.redirect_path;
                    // if(confirm(response.data.message + 'ページに移動しますか？')) document.location.href = response.data.redirect_path;
                  } else {
                    console.log(response);
                    alert('例外が発生しました。Error: PhotocontestEdit001');
                  }
                  this.isProcessing = false;
                }).catch(
                  (error) => {
                    // プログレスダイアログを消す
                    this.showProgress = false;
                    // プログレスの値をリセット
                    this.progress = 0;
                    // 例外処理
                    // console.log(error);
                    // console.log(error.response.status);
                    // console.log(error.response);
                    // this.success = false;
                    // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                    if(error.response.data.errors) {
                      var errors = {};
                      for(var key in error.response.data.errors) {
                        errors[key] = error.response.data.errors[key].join('<br>');
                      }
                      this.errors = errors;
                      // console.log(error.response.data.errors);

                      var validationErrors = [];
                      for(var key in error.response.data.errors) {
                        validationErrors.push({
                          id: key,
                          messages: error.response.data.errors[key].map((message, key) => {
                            return {
                              id: key,
                              message: message,
                            };
                          }),
                        });
                        this.validationErrors = validationErrors;
                      }
                      // console.log(this.validtionErrors);
                      // this.echoErrors();
                      this.showValidationErrorDialog = true;
                      this.isProcessing = false;
                    } else {
                      // バリデーションエラーの場合以外はエラーダイアログを表示
                      // エラーデータをほりこむ
                      this.error = error.response;
                      this.showErrorDialog = true;
                      this.isProcessing = false;
                    }
                  }
                );
            },

        }
    }
</script>

<style lang="scss" scoped>
.specialBodyEditor > .form_body {
  padding: 9px;
  margin: 0.25em 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  vertical-align: middle;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  .sortableWrap {
    .special_child {
      width: calc(100% + 9px);
      padding-right: 30px;
      margin: 5px 0;
      position: relative;
      .draggable-handle {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 100%;
        text-align: center;
        cursor: pointer;
        // background-color: #fafafa;
        // background-color: #ccc;
        span {
          width: 20px;
          height: 2px;
          background-color: #ccc;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -1px);
          text-indent: -9999em;
          &::before {
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #ccc;
            transform: translate(0, -8px);
          }
          &::after {
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #ccc;
            transform: translate(0, 8px);
          }
        }
      }
    }
  }
  .special_child_block {
    // padding-right: 30px;
    padding: 0 14px;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    margin: 5px 0;
    position: relative;
  }
}
.controll_panel {
  width: 100%;
  margin: 0;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 9px 4px;
  display: flex;
  .addChildBtns {
    .btns {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin: 0 5px;
      .btn {
        margin: 2px;
        border: 1px solid #ccc;
      }
    }
  }
}
.forTranslate {
  display: none;
}
</style>
