<template>
  <div class="specialEdit">

    <section class="forTranslate">

      <button v-if="translateMode != 'ja'" type="button" name="modeTranslate" @click="confirmSaveTranslate('ja')">日本語ページを編集</button>
      <button v-if="!node.translate.en" type="button" name="addTranslate" @click="addTranslate('en')">英語ページを追加</button>
      <button v-else type="button" name="modeTranslate" @click="confirmSaveTranslate('en')">英語ページを編集</button>
      <button v-if="!node.translate.Hant" type="button" name="addTranslate" @click="addTranslate('Hant')">簡体中文ページを追加</button>
      <button v-else type="button" name="modeTranslate" @click="confirmSaveTranslate('Hant')">簡体ページを編集</button>
      <button v-if="!node.translate.Hans" type="button" name="addTranslate" @click="addTranslate('Hans')">繁体中文ページを追加</button>
      <button v-else type="button" name="modeTranslate" @click="confirmSaveTranslate('Hans')">繁体ページを編集</button>
      <div>
        translateMode: {{translateMode}} <br>
        translateModeNext: {{translateModeNext}}
      </div>
      <div style="display:flex;">
        <div>▼言語別データマスタ<br><textarea style="max-width:100%;min-height:150px;">{{node.translate}}</textarea></div>
        <div>▼英語<br><textarea style="max-width:100%;min-height:150px;">{{node.translate.en}}</textarea></div>
        <div>▼簡体<br><textarea style="max-width:100%;min-height:150px;">{{node.translate.Hant}}</textarea></div>
        <div>▼繁体<br><textarea style="max-width:100%;min-height:150px;">{{node.translate.Hans}}</textarea></div>
      </div>
      <div style="display:flex;">
        <div>▼編集中の言語データ<br><textarea style="max-width:100%;min-height:150px;">{{currentTranslate}}</textarea></div>
        <div>▼編集中の言語が保存済みか<br>{{currentTranslateEditing}}</div>
      </div>
      <div>
        ▼この特集の日本語タイトル<br>{{node.title}}
      </div>

      <div>
        ▼node<br><textarea style="max-width:100%;min-height:150px;">{{node}}</textarea>
      </div>

    </section>

    <form method="POST" :action="actionurl" class="register_form pure-form pure-form-stacked" enctype="multipart/form-data" @submit.prevent>

        <fieldset class="">

          <div v-show="translateMode == 'ja'" class="form-group required">
            <div class="form_header">
              <span class="label">タイトル</span>
            </div>


            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1" v-bind:class="{ 'is-invalid':errors.title }" name="title" v-model="node.title" required autocomplete="title" autofocus>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.title"></div>
                <div class="note">特集記事のタイトル。</div>
              </div>
            </div>
          </div>
          <div v-if="translateMode != 'ja'" class="form-group required translate">
            <div class="form_header">
              <span class="label">タイトル（{{translateMode}}）</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1" v-bind:class="{ 'is-invalid':errors.title }" name="title" v-model="currentTranslate.title" required autocomplete="title" autofocus>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.title"></div>
                <div class="note">特集記事のタイトル（{{translateMode}}）。</div>
              </div>
            </div>
          </div>

          <div v-show="translateMode == 'ja'" class="form-group required">
            <div class="form_header">
              <span class="label">URL</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                /spcial/ <input type="text" class="pure-input" v-bind:class="{ 'is-invalid':errors.slug }" name="slug" v-model="node.slug" required autocomplete="slug">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.slug"></div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset v-show="translateMode == 'ja'" class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">バナー画像</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <image-uploader-with-photo v-show="node.image.flag.uploaderShowFlag && !node.image.flag.nowRenderingFlag"
                name="image"
                tmpFormatName="special_image"
                :image-que="null"
                :multiple="false"
                :disablePG="true"
                @startUpload="startUploadSpecialImage"
                @setFile="setFile"
                @setProcessing="setProcessing"
                @addQue="addQue"
                >
                </image-uploader-with-photo>
                <image-preview v-show="!node.image.flag.uploaderShowFlag"
                :previewFile="node.image.preview"
                :rotatable="false"
                @remove="removeImage"
                @setImageRotate="setImageRotate"
                >
                </image-preview>
              </div>
              <div class="form_inputs">
                <label for="image_to_single" class="single_checkbox">
                  <input id="image_to_single" type="checkbox" class="" name="image_to_single" value="1" autocomplete="model_release" true-value="1" false-value="0" v-model="node.image_to_single"> バナー画像をシングルページのヘッダー画像としても使用する
                </label>
              </div>
            </div>
            <!-- <textarea style="max-width:100%;min-height:400px;">{{ planImages }}</textarea> -->
          </div>

          <div class="form-group" v-if="node.image_to_single == 0">
            <div class="form_header">
              <span class="label">シングルページヘッダー画像</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <image-uploader-with-photo v-show="node.single_image.flag.uploaderShowFlag && !node.single_image.flag.nowRenderingFlag"
                name="single_image"
                tmpFormatName="special_image"
                :image-que="null"
                :multiple="false"
                :disablePG="true"
                @startUpload="startUploadSingleImage"
                @setFile="setSingleImageFile"
                @addQue="addSingleImageQue"
                @setProcessing="setProcessing"
                >
                </image-uploader-with-photo>
                <image-preview v-show="!node.single_image.flag.uploaderShowFlag"
                :previewFile="node.single_image.preview"
                :rotatable="false"
                @remove="removeSingleImage"
                @setImageRotate="setSingleImageRotate"
                >
                </image-preview>
              </div>
            </div>
            <!-- <textarea style="max-width:100%;min-height:400px;">{{ planImages }}</textarea> -->
          </div>

        </fieldset>
        <fieldset v-if="translateMode != 'ja'" class="translate">

          <div class="form-group">
            <div class="form_header">
              <span class="label">バナー画像（{{translateMode}}）</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <image-uploader-with-photo v-show="currentTranslate.image.flag.uploaderShowFlag && !currentTranslate.image.flag.nowRenderingFlag"
                name="image"
                tmpFormatName="special_image"
                :image-que="null"
                :multiple="false"
                :disablePG="true"
                @startUpload="startUploadSpecialImage"
                @setFile="setFile"
                @addQue="addQue"
                @setProcessing="setProcessing"
                >
                </image-uploader-with-photo>
                <image-preview v-show="!currentTranslate.image.flag.uploaderShowFlag"
                :previewFile="currentTranslate.image.preview"
                :rotatable="false"
                @remove="removeImage"
                @setImageRotate="setImageRotate"
                >
                </image-preview>
              </div>
              <div class="form_inputs">
                <label for="image_to_single" class="single_checkbox">
                  <input id="image_to_single" type="checkbox" class="" name="image_to_single" value="1" autocomplete="model_release" true-value="1" false-value="0" v-model="currentTranslate.image_to_single"> バナー画像をシングルページのヘッダー画像としても使用する（{{translateMode}}）
                </label>
              </div>
            </div>
            <!-- <textarea style="max-width:100%;min-height:400px;">{{ planImages }}</textarea> -->
          </div>

          <div class="form-group" v-if="currentTranslate.image_to_single == 0">
            <div class="form_header">
              <span class="label">シングルページヘッダー画像（{{translateMode}}）</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <image-uploader-with-photo v-show="currentTranslate.single_image.flag.uploaderShowFlag && !currentTranslate.single_image.flag.nowRenderingFlag"
                name="single_image"
                tmpFormatName="special_image"
                :image-que="null"
                :multiple="false"
                :disablePG="true"
                @startUpload="startUploadSingleImage"
                @setFile="setSingleImageFile"
                @addQue="addSingleImageQue"
                @setProcessing="setProcessing"
                >
                </image-uploader-with-photo>
                <image-preview v-show="!currentTranslate.single_image.flag.uploaderShowFlag"
                :previewFile="currentTranslate.single_image.preview"
                :rotatable="false"
                @remove="removeSingleImage"
                @setImageRotate="setSingleImageRotate"
                >
                </image-preview>
              </div>
            </div>
            <!-- <textarea style="max-width:100%;min-height:400px;">{{ planImages }}</textarea> -->
          </div>

        </fieldset>

        <fieldset class="">

          <div v-show="translateMode == 'ja'" class="form-group">
            <div class="form_header">
              <span class="label">CSS（外部CSS）</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                /public/asets/special/{{ node.slug || '{URL}' }}/ <input type="text" class="pure-input" v-bind:class="{ 'is-invalid':errors.css }" name="css" v-model="node.css"> .css
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.css"></div>
              </div>
            </div>
          </div>

          <div v-show="translateMode == 'ja'" class="form-group">
            <div class="form_header">
              <span class="label">JS（外部JS）</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                /public/asets/special/{{ node.slug || '{URL}' }}/ <input type="text" class="pure-input" v-bind:class="{ 'is-invalid':errors.js }" name="js" v-model="node.js"> .js
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.js"></div>
              </div>
            </div>
          </div>

          <div v-show="translateMode == 'ja'">

            <div class="form-group">
              <div class="form_header">
                <span class="label">共通HTML（ヘッダー、ナビゲーションなど）（外部PHP）</span>
              </div>
              <div class="form_body">
                <div class="form_input">
                  /resources/includes/specia/{{ node.slug || '{URL}' }}/ <input type="text" class="pure-input" v-bind:class="{ 'is-invalid':errors.parent_include }" name="parent_include" v-model="node.parent_include"> .php
                </div>
                <div class="form_note">
                  <div class="invalid-feedback" role="alert" v-html="errors.parent_include"></div>
                </div>
              </div>
            </div>

            <div class="form-group" v-show="!node.parent_include">
              <div class="form_header">
                <span class="label">共通HTML（ヘッダー、ナビゲーションなど）</span>
              </div>
              <div class="form_body">
                <div class="form_input">
                  <textarea
                  v-model="node.parent_html"
                  rows="5"
                  >
                  </textarea>
                  <!-- <textarea-auto-adjust
                    :class="{ 'is-invalid':errors.parent_html }"
                    name="parent_html"
                    v-model="node.parent_html"
                  ></textarea-auto-adjust> -->
                </div>
                <div class="form_note">
                  <div class="invalid-feedback" role="alert" v-html="errors.parent_html"></div>
                </div>
              </div>
            </div>

          </div>
          <div v-if="node.translate.en">

            <div class="form-group">
              <div class="form_header">
                <span class="label">共通HTML（ヘッダー、ナビゲーションなど）（外部PHP）（英語）</span>
              </div>
              <div class="form_body">
                <div class="form_input">
                  /resources/includes/speci/{{ node.slug || '{URL}' }}/ <input type="text" class="pure-input" v-bind:class="{ 'is-invalid':errors.parent_include }" name="parent_include" v-model="currentTranslate.parent_include"> .php
                </div>
                <div class="form_note">
                  <div class="invalid-feedback" role="alert" v-html="errors.parent_include"></div>
                </div>
              </div>
            </div>

            <div class="form-group" v-show="!node.parent_include">
              <div class="form_header">
                <span class="label">共通HTML（ヘッダー、ナビゲーションなど）</span>
              </div>
              <div class="form_body">
                <div class="form_input">
                  <textarea
                  v-model="node.parent_html"
                  rows="5"
                  >
                  </textarea>
                  <!-- <textarea-auto-adjust
                    :class="{ 'is-invalid':errors.parent_html }"
                    name="parent_html"
                    v-model="node.parent_html"
                  ></textarea-auto-adjust> -->
                </div>
                <div class="form_note">
                  <div class="invalid-feedback" role="alert" v-html="errors.parent_html"></div>
                </div>
              </div>
            </div>

          </div>

        </fieldset>

        <fieldset class="special_children">

          <div v-show="translateMode == 'ja'" class="form-group required specialBodyEditor">

            <div class="form_header">
              <span class="label">本文</span>
            </div>

            <div class="form_body">

              <draggable v-if="node.bodies" v-model="node.bodies" tag="div" :class="bodySortable" class=""
              animation="200"
              handle=".draggable-handle"
              delay="30"
              delayOnTouchOnly="true"
              touchStartThreshold="5"
              @move="onmove"
              @start="onstart"
              @add="onadd"
              @remove="onremove"
              @update="onupdate"
              @end="onend"
              @choose="onchoose"
              @sort="onsort"
              @filter="onfilter"
              @clone="onclone">

                <div class="special_child" v-for="body in node.bodies" :key="body.key">
                  <div class="draggable-handle"><span></span></div>

                  <div class="special_child_block">

                    <div class="form-group required" v-if="body.order >= 2">
                      <div class="form_header">
                        <span class="label">子ページタイトル</span>
                      </div>
                      <div class="form_body">
                        <div class="form_input">
                          {{ node.title || '{親タイトル}'  }} | <input type="text" class="pure-input" v-bind:class="{ 'is-invalid':errors.bodies }" name="body[key][title]" v-model="body.title" required>
                        </div>
                        <!-- <div class="form_input" v-else>
                          {{ node.title || '{親タイトル}'  }}
                        </div> -->
                        <div class="form_note">
                          <div class="invalid-feedback" role="alert" v-html="errors.bodies"></div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group required" v-if="body.order >= 2">
                      <div class="form_header">
                        <span class="label">子ページURL</span>
                      </div>
                      <div class="form_body">
                        <div class="form_input">
                          /spcial/{{ node.slug || '{親URL}' }}/ <input type="text" class="pure-input" v-bind:class="{ 'is-invalid':errors.bodies }" name="body[key][slug]" v-model="body.slug" required>
                        </div>
                        <!-- <div class="form_input" v-else>
                          /spcial/{{ node.slug || '{親URL}' }}
                        </div> -->
                        <div class="form_note">
                          <div class="invalid-feedback" role="alert" v-html="errors.bodies"></div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">

                      <div class="form_header">
                        <span class="label">本文HTML（外部PHP）</span>
                      </div>
                      <div class="form_body">
                        <div class="form_input">
                          /resources/includes/speci/{{ node.slug || '{親URL}' }}/ <input type="text" class="pure-input" v-bind:class="{ 'is-invalid':errors.bodies }" name="body[key][include]" v-model="body.include"> .php
                        </div>
                        <div class="form_note">
                          <div class="invalid-feedback" role="alert" v-html="errors.bodies"></div>
                        </div>
                      </div>

                    </div>

                    <div class="form-group" v-if="!body.include">
                      <div class="form_header">
                        <span class="label">本文HTML</span>
                      </div>
                      <div class="form_body">
                        <div class="form_input">
                          <textarea
                          v-model="body.inline"
                          name="body[key][inline]"
                          rows="5"
                          >
                          </textarea>
                          <!-- <textarea-auto-adjust
                            :class="{ 'is-invalid':errors.bodies }"
                            name="parent_html"
                            v-model="body.inline"
                          ></textarea-auto-adjust> -->
                        </div>
                        <div class="form_note">
                          <div class="invalid-feedback" role="alert" v-html="errors.bodies"></div>
                          <div class="note">本文のHTMLソースをそのまま記入。</div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group" v-if="body.order >= 1">
                      <span class="btn button-small" @click="removeChild(body.key)">ページを削除</span>
                    </div>

                  </div>

                </div>

              </draggable>

              <div class="controll_panel">
                <div class="addChildBtns">
                  <div class="btns">
                    <span class="btn button-small" @click="addChild">子ページを追加する</span>
                  </div>
                </div>
              </div>

            </div>

            <!-- <textarea>{{ node.bodies }}</textarea> -->

          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">使用した写真</span>
            </div>
            <div class="form_body">
              <div class="form_input relative">
                <image-preview-multiple
                :images="node.photo_reference"
                @remove="index => removePhotoReference(index)"
                @onsort="images => sortPhotoReference(images)"
                @setImageRotate="(imageRotate, index) => setPhotoReferenceRotate(imageRotate, index)"
                ></image-preview-multiple>
                <photo-reference
                name="photo_reference"
                :multiple="true"
                @setPG="setPG"
                >
                </photo-reference>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.image"></div>
              <div class="note">この記事内で使用した写真ギャラリー内の写真を選んでください。</div>
              </div>
            </div>
          </div>
          <!-- <textarea>{{ node.photo_reference }}</textarea> -->

        </fieldset>

        <fieldset v-show="translateMode == 'ja'" class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">プレイス（スポット）</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <tag-editor :tagColumn="'place'" :class="{ 'is-invalid':errors.places }" :currentTags="node.places" @setTags="setPlaces"></tag-editor>
              </div>
              <div v-if="node.places">
                <div v-for="result in matchPlaceResults" :key="result.key">
                  <div v-if="result.spots">
                    <div class="place">「{{ result.place }}」で
                      <a v-for="spot in result.spots" :key="spot.id" :href="'/spot/' + spot.id" target="_blank">{{ spot.name }}</a> と関連付けられます。
                    </div>
                  </div>
                  <div v-else>
                    <div class="place">「{{ result.place }}」でマッチするスポットが今の所登録されていません。</div>
                  </div>
                </div>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.places"></div>
                <div class="note">訪問する場所や施設の名前を全て入力してください。行程には入っていなくても必要な場合は入れてください。ここに入力された名前は当サイトに掲載のスポットと照合され、自動的に関連づけられます（なるべく一般的と思われる名前を推奨します。）。複数ある場合はカンマ区切りで入力してください。</div>
                <div class="note">例: <br>金閣寺の場合→「金閣寺, 鹿苑寺, 鹿苑寺金閣」<br>金戒光明寺の場合→「金戒光明寺, 黒谷さん, 黒谷, くろ谷さん, くろ谷」など</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">タグ</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <tag-editor :tagColumn="'tag'" :currentTags="node.tags" @setTags="setTags"></tag-editor>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.tags"></div>
                <div class="note">このモデルコースに関連するワードをタグとして入力します。同じタグがついたスポットやその他のページと関連づけられます。カンマ区切りで入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group required">
            <div class="form_header">
              <span class="label">カテゴリー</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <category-checker entity="special" :currentCategories="node.categories" @setCategories="setCategories"></category-checker>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.categories"></div>
                <div class="note">該当するカテゴリーを全てチェックしてください。最低でも1つチェックが必要です。</div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset v-show="translateMode == 'ja'" class="">

          <div class="form-group required">
            <div class="form_header">
              <span class="label">情報の有効範囲</span>
            </div>
            <div class="form_body">
              <div class="form_input_row">
                <div class="form_input">
                  <date-field
                  title="開始日"
                  name="schedule_start"
                  v-model="node.schedule_start"
                  class=""
                  :errors="errors"
                  minDate="2000-01-01"
                  maxDate="2100-12-31"
                  @setDate="value => node.schedule_start = value"
                  required="required"
                  >
                  </date-field>
                </div>
                <div class="form_input">
                  <date-field
                  title="終了日"
                  name="schedule_end"
                  v-model="node.schedule_end"
                  class=""
                  :errors="errors"
                  minDate="2000-01-01"
                  maxDate="2100-12-31"
                  @setDate="value => node.schedule_end = value"
                  >
                  </date-field>
                </div>
              </div>
              <!-- <textarea>{{ node.schedule_start }}</textarea> -->
              <!-- <textarea>{{ node.schedule_end }}</textarea> -->
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.schedule_start"></div>
                <div class="invalid-feedback" role="alert" v-html="errors.schedule_end"></div>
                <div class="note">
                  一般のユーザーに見てほしい期間のはじめと終わりを入力してください（記事自体の公開期間ではありません）。<br>
                  ※特集トップページの中での並び順は、この項目の数字で決まります。<br>
                  ※今のところ年は並び順には影響しません。基本的に投稿した年を入れてください。年またぎの場合は必ず終了日の年を後にしてください<br>
                  →1) 期間があるもので、期間中の投稿が最上位に並びます<br>
                  　　　その中では期間が短いものほど上位に表示されます<br>
                  →2) 通年のものはスタート日のみ入れてください。<br>
                  　　　通年の中で、スタート日が上半期のものは2番目に上位に並びます<br>
                  　　　通年の中で、スタート日が下半期のものは最下位に並びます<br>
                  　　　　通年の中での並び順はスタート日の若い順となります<br>
                  →3) 期間外のものは3番目に並びます。<br>
                  　　　その中では期間が短いものほど上位に表示されます<br>
                  →1～3の中での並びはスタート日が今日に近い順に並びます。（この並び順は暫定で後日調整する場合があります）
                </div>
              </div>
            </div>
          </div>

        </fieldset>

        <div class="forTranslate">
          <div v-if="node.translate.en"><label><input type="checkbox" v-model="node.translate.en.active" @change="checkTranslateEn" /> 英語版を公開</label></div>
          <div v-if="node.translate.Hant"><label><input type="checkbox" v-model="node.translate.Hant.active" @change="checkTranslateHant" /> 簡体字版を公開</label></div>
          <div v-if="node.translate.Hans"><label><input type="checkbox" v-model="node.translate.Hans.active" @change="checkTranslateHans" /> 繁体字版を公開</label></div>
        </div>

        <fieldset class="" v-if="isStaff">
          <div class="form-group">
            <div class="form_header">
              <span class="label">公開状態</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <status-checker :config_status="config_status" :enable_values="enable_status" :currentStatus="node.status ? node.status : 40" @setStatus="setStatus"></status-checker>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.categories"></div>
                <div class="note">公開状態を選択してください</div>
              </div>
            </div>
          </div>
        </fieldset>

        <div class="form_buttons">
          <a v-if="nodeprop" class="btn cancelBtn" v-bind:href="'/special/' + nodeprop.slug">戻る</a>
          <button v-if="nodeprop" v-bind:disabled="isProcessing" type="button" class="btn btn-primary" @click.prevent.stop="onSubmit()">更新</button>
          <button v-else type="button" v-bind:disabled="isProcessing" class="btn btn-primary" @click.prevent.stop="onSubmit()">投稿</button>
          <button v-if="nodeprop && user.roles && user.roles.some(role => role.role <= 20)" type="button" class="btn btn-warning" @click="deleteNode">削除</button>
        </div>

        <div class="dialog dialog-t" v-if="showProgress">
          <div class="inner">
            <div class="dialog-header">
              投稿処理
            </div>
            <div class="dialog-content">
              <div v-if="progress > 0 && progress < 100">
                画像ファイルアップロード中...
                <progress ref="progress" value="0" max="100"></progress><br>{{progress}}%
              </div>
              <div v-if="!progress || progress <= 0 && progress >= 100" class="icon-inline">
                <span class="loader icon">Loading...</span>
                <span>ページ生成中...　処理完了後は自動的にページに移動します。</span>
              </div>
            </div>
          </div>
        </div>

        <div class="dialog dialog-t" v-if="showValidationErrorDialog">
          <div class="inner">
            <div class="dialog-header">
              入力内容をご確認ください
            </div>
            <div class="dialog-content">
              <ul v-for="error in validationErrors" :key="error.id">
                <li v-for="message in error.messages" :key="message.id">{{ message.message }}</li>
              </ul>
              <div class="buttons">
                <button type="button" class="btn btn-primary" @click="showValidationErrorDialog = false">OK</button>
              </div>
            </div>
          </div>
        </div>

        <div class="dialog dialog-t" v-if="showErrorDialog">
          <div class="inner">
            <div class="dialog-header">
              {{error.status}} {{error.statusText}}
            </div>
            <div class="dialog-content">
              <div>{{error.data.message}}</div>
              <div class="buttons">
                <button type="button" class="btn btn-primary" @click="showErrorDialog = false">OK</button>
              </div>
            </div>
          </div>
        </div>

        <mordal-confirm
        slag="translateSaveConfirm"
        :title="translateSaveConfirm.title"
        body="変集内容を保存しますか?（記事を保存するまでページには反映されません）"
        okText="保存する"
        cancelText="破棄する"
        :isShow="translateSaveConfirm.isShow"
        @confirm="translateSaveConfirmOk"
        @cancel="translateSaveConfirmCancel"></mordal-confirm>

    </form>
  </div>

</template>

<script>
    import ImageUploaderWithPhoto from './form_element/ImageUploaderWithPhoto';
    import ImagePreview from './ImagePreview'
    import TagEditor from './TagEditor';
    import CategoryChecker from './CategoryChecker';
    import draggable from 'vuedraggable';
    import TextareaAutoAdjust from './form_element/TextareaAutoAdjust';
    import DateField from './form_element/DateField';
    import PhotoReference from './form_element/PhotoReference';
    import ImagePreviewMultiple from './ImagePreviewMultiple'
    import MordalConfirm from './MordalConfirm'
    import StatusChecker from './form_element/StatusChecker';
    export default {
        components: {
            ImageUploaderWithPhoto,
            ImagePreview,
            TagEditor,
            CategoryChecker,
            draggable,
            TextareaAutoAdjust,
            DateField,
            PhotoReference,
            ImagePreviewMultiple,
            MordalConfirm,
            StatusChecker,
        },
        props: {
          // nodeプロパティ
            nodeprop: {
                type: Object,
                default: null,
                required: false,
            },
            // フォームの送信先
            actionurl: {
                type: String,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
            config_status: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            isAdmin: false,
            isStaff: false,
            enable_status: [ // {1: "公開", 5: "限定公開", 10: "予約", 20: "下書き", 40: "承認申請中", 45: "承認却下", 60: "公開制限中", 90: "非公開"}
              // 1, 5, 10, 20, 40, 45, 60, 90,
              1, 90,
            ],
            // post
            node: {
              id: null,
              title: '',
              slug: '',
              image: {
                  file_name: null,
                  preview: {
                    src: null,
                  },
                  flag: {
                    uploaderShowFlag: true,
                    nowRenderingFlag: false,
                    nowChangeFlag: false,
                    isDragging: false,
                    // ドラッグ中かどうかの判定用
                    dragCount: 0,
                  },
                  new: true,
                  rotate: null,
              },
              image_to_single: '1',
              single_image: {
                  file_name: null,
                  preview: {
                    src: null,
                  },
                  flag: {
                    uploaderShowFlag: true,
                    nowRenderingFlag: false,
                    nowChangeFlag: false,
                    isDragging: false,
                    // ドラッグ中かどうかの判定用
                    dragCount: 0,
                  },
                  new: true,
                  rotate: null,
              },
              parent_html: '',
              parent_include: '',
              css: '',
              js: '',
              schedule_start: {
                date: null,
                year: null,
                month: null,
                day: null,
              },
              schedule_end: {
                date: null,
                year: null,
                month: null,
                day: null,
              },
              status: 1,
              reserved: null,
              user_id: '',
              bodies: [
                {
                  key: 0,
                  order: 1,
                  id: null,
                  title: '',
                  slug: '',
                  inline: '',
                  include: '',
                },
              ],
              places: [],
              categories: [],
              tags: [],
              photo_reference: [],
              translate: {
                en: null,
                Hans: null, // 中国語（簡体）
                Hant: null, // 中国語（繁体）
              },
            },
            translateMode: 'ja',
            translateModeNext: '',
            // translates: [
            //   {
            //     prefix: 'en',
            //     ja: '英語',
            //     en: 'English',
            //     Hans: '簡体中文',
            //     Hans: '繁体中文',
            //   },
            // ],

            translateDataDefault: {
              id: null,
              title: '',
              slug: '',
              image: {
                  file_name: null,
                  preview: {
                    src: null,
                  },
                  flag: {
                    uploaderShowFlag: true,
                    nowRenderingFlag: false,
                    nowChangeFlag: false,
                    isDragging: false,
                    // ドラッグ中かどうかの判定用
                    dragCount: 0,
                  },
                  new: true,
                  rotate: null,
              },
              image_to_single: '1',
              single_image: {
                  file_name: null,
                  preview: {
                    src: null,
                  },
                  flag: {
                    uploaderShowFlag: true,
                    nowRenderingFlag: false,
                    nowChangeFlag: false,
                    isDragging: false,
                    // ドラッグ中かどうかの判定用
                    dragCount: 0,
                  },
                  new: true,
                  rotate: null,
              },
              parent_html: '',
              parent_include: '',
              css: '',
              js: '',
              schedule_start: {
                date: null,
                year: null,
                month: null,
                day: null,
              },
              schedule_end: {
                date: null,
                year: null,
                month: null,
                day: null,
              },
              status: 1,
              reserved: null,
              user_id: '',
              bodies: [
                {
                  key: 0,
                  order: 1,
                  id: null,
                  title: '',
                  slug: '',
                  inline: '',
                  include: '',
                },
              ],
              places: [],
              categories: [],
              tags: [],
              photo_reference: [],
            },

            // 現在編集中の言語データ
            currentTranslate: {},

            // 現在編集中の言語データに未保存（DBにではなくvueオブジェクトに）の変更が加わっているか
            currentTranslateEditing: false,

            // 現在編集中の言語データへの変更を監視するウォッチャー（の戻り値でそのウォッチャーを破棄する関数）
            // ※何も変更がないまま他の言語の編集に切り替えるような場合に切り替え時のデータ入れ替えも変更とみなされるためこれで監視を破棄する必要がある。
            currentTranslateEditingWatcher: null,

            translateSaveConfirm: {
              title: '日本語ページの内容が変更されています',
              isShow: false,
            },

            removedBodyId: [],
            // エラーダイアログ表示フラグ
            showErrorDialog: false,
            // 返ってきたerror.responceが入る
            error: {},
            // バリデーションエラーの内容が入る
            errors: {},
            // プログレスダイアログ用の進捗状況（%）
            progress: 0,
            // プログレスダイアログ表示フラグ
            showProgress: false,
            // areas: [],
            // areaChecked: [],
            // バリデーションエラーダイアログ表示フラグ
            showValidationErrorDialog: false,
            // バリデーションエラーダイアログ表示フラグ
            validationErrors: [],
            // プレイスでマッチしたプレイスデータを入れる
            findPlaceResults: [],
            // 処理中はtrueにするとボタンが無効になる
            isProcessing: false,
        }),
        created() {
            // console.log('this.nodeprop');
            // console.log(this.nodeprop);
            // console.log(this.node.image);
            // console.log(this.node.image.flag);
            this.isAdmin = this.user.roles.some(user => user.role === 1);
            this.isStaff = this.user.roles.some(user => user.role === 1 || user.role === 10 || user.role === 20);
            if(this.nodeprop) {
              // ノードプロパティをdataにセット
              // this.node = this.nodeprop;
              this.node.id = this.nodeprop.id;
              this.node.title = this.nodeprop.title;
              this.node.slug = this.nodeprop.slug;
              if(this.nodeprop.image) {
                this.node.image = {
                  file_name: this.nodeprop.image,
                  preview: {
                    src: '/storage/images/special_image/single/' + this.nodeprop.image,
                  },
                  flag: {
                    uploaderShowFlag: false,
                    nowRenderingFlag: false,
                    nowChangeFlag: false,
                    isDragging: false,
                    // ドラッグ中かどうかの判定用
                    dragCount: 0,
                  },
                  new: false,
                  rotate: null,
                }
              }
              this.node.image_to_single = this.nodeprop.image_to_single;
              if(this.nodeprop.single_image) {
                this.node.single_image = {
                  file_name: this.nodeprop.single_image,
                  preview: {
                    src: '/storage/images/special_image/single/' + this.nodeprop.single_image,
                  },
                  flag: {
                    uploaderShowFlag: false,
                    nowRenderingFlag: false,
                    nowChangeFlag: false,
                    isDragging: false,
                    // ドラッグ中かどうかの判定用
                    dragCount: 0,
                  },
                  new: false,
                  rotate: null,
                }
              }
              this.node.parent_html = this.nodeprop.parent_html;
              this.node.parent_include = this.nodeprop.parent_include;
              this.node.css = this.nodeprop.css;
              this.node.js = this.nodeprop.js;

              if(this.nodeprop.schedule_start) {
                let split_date = this.nodeprop.schedule_start.split('-');
                this.node.schedule_start = {
                  date: this.nodeprop.schedule_start,
                  year: split_date[0],
                  month: split_date[1],
                  day: split_date[2],
                }
              }
              if(this.nodeprop.schedule_end) {
                let split_date = this.nodeprop.schedule_end.split('-');
                this.node.schedule_end = {
                  date: this.nodeprop.schedule_end,
                  year: split_date[0],
                  month: split_date[1],
                  day: split_date[2],
                }
              }
              // this.node.schedule_start = this.nodeprop.schedule_start;
              // this.node.schedule_end = this.nodeprop.schedule_end;
              this.node.status = this.nodeprop.status;
              this.node.reserved = this.nodeprop.reserved;
              this.node.user_id = this.nodeprop.user_id;
              if(this.nodeprop.bodies.length >= 1) {
                var currentBodies = [];
                this.nodeprop.bodies.map((body, index) => {
                  let theBody = {
                    key: index,
                    order: body.order,
                    id: body.id,
                    title: body.title,
                    slug: body.slug,
                    inline: body.inline,
                    include: body.include,
                  };
                  currentBodies.push(theBody);
                });
              }
              this.node.bodies = currentBodies;

              if(this.nodeprop.photos.length >= 1) {
                this.node.photo_reference = [];
                this.nodeprop.photos.forEach((photo, index) => {
                  const photo_ref = {
                    order: index + 1,
                    type: 'pg',
                    file_name: photo.file_name,
                    preview: {
                      src: '/storage/images/pg/thumb/' + photo.file_name,
                    },
                    node: photo,
                    new: false,
                    user_id: photo.user.id,
                    user_name: photo.user.name,
                  }
                  this.node.photo_reference.push(photo_ref);
                });
              }

              this.node.places = this.nodeprop.places;
              this.node.categories = this.nodeprop.categories;
              this.node.tags = this.nodeprop.tags;
            }
            // 翻訳データが空の場合は初期値をほりこむ
              // console.log(this.node.translate);
            if(!this.node.translate.en) {
              // console.log(this.node.translate.en);
              this.node.translate.en = this.translateDataDefault;
            }
            if(!this.node.translate.Hans) {
              this.node.translate.Hans = this.translateDataDefault;
            }
            if(!this.node.translate.Hant) {
              this.node.translate.Hant = this.translateDataDefault;
            }
        },
        computed: {
          // プレイスの入力値にマッチするスポットの情報を返す
          matchPlaceResults() {
            if(this.node.places) {
              // console.log('プレイス検索中');
              return this.node.places.map((val, key) => {
                var match = null;
                if(this.findPlaceResults.length >= 1) {
                  match = this.findPlaceResults.find(v => v.place == val.place);
                }
                if(match && match.spots.length >= 1) {
                  return {key: key, place: val.place, spots: match.spots}
                } else {
                  return {key: key, place: val.place, spots: null}
                }
              });
            }
          },
          bodySortable: function() {
            if(this.node.bodies.length >=2) {
              return 'sortableWrap';
            } else {
              return;
            }
          },
        },
        methods: {
            // 指定言語の翻訳投稿を追加
            addTranslate(lang) {
              this.node.translate[lang] = {
                // empty: true,
                // active: false,
                // title: '',
                // parent_html: '',
                // parent_include: '',
                // status: 20,
                // reserved: null,
                // bodies: [
                //   {
                //     key: 0,
                //     order: 1,
                //     id: null,
                //     title: '',
                //     slug: '',
                //     inline: '',
                //     include: '',
                //   },
                // ],
              };
              if(this.node.translate[lang].title === '' && this.node.translate[lang].parent_html === '' && this.node.translate[lang].parent_include === '') {
                this.node.translate[lang].empty = true;
              };
              // console.log(this.node.translate);
              this.confirmSaveTranslate(lang);
            },
            confirmSaveTranslate(lang) {
              // 切り替え先の言語を一旦保存しておく
              this.translateModeNext = lang;
              // 切り替え元が変更されていたら
              if(this.currentTranslateEditing) {
                // 保存しますか？のconfirmを出す
                this.translateConfirmFire();
              } else { // 切り替え元が変更されてなければ
                if(this.currentTranslateEditingWatcher) {
                  // 変更を監視するウォッチャーを破棄
                  this.currentTranslateEditingWatcher();
                }
                // 言語モード切替
                this.modeTranslate(lang);
              }
            },
            setStatus(status) {
              this.node.status = status;
              // console.log(this.node.status);
            },
            setProcessing(status) {
              this.isProcessing = status;
              // console.log(this.isProcessing);
            },
            // confirmを表示
            translateConfirmFire() {
              // confirmのタイトル
              this.translateSaveConfirm.title = '変更の保存）';
              // confirmを表示
              this.translateSaveConfirm.isShow = true;
            },
            // confirmでOK（変更を保存）押したら発火
            translateSaveConfirmOk() {
              // confirmを非表示
              this.translateSaveConfirm.isShow = false;
              // 現在編集中の言語のデータを本チャンデータにコピー
              this.node.translate[this.translateMode] = Vue.util.extend({}, this.currentTranslate);
              // 言語切り替え
              this.modeTranslate(this.translateModeNext);
            },
            // confirmでCancel（変更を破棄）押したら発火
            translateSaveConfirmCancel() {
              // confirmを非表示
              this.translateSaveConfirm.isShow = false;
              // 言語切り替え
              this.modeTranslate(this.translateModeNext);
            },
            // 言語切り替え
            modeTranslate(lang) {
              // 切り替え先の言語
              this.translateMode = lang ? lang : 'ja';
              // 切り替え先が日本語なら
              if(this.translateMode == 'ja') {
                // 編集する翻訳のオブジェクトを空に（日本語は翻訳データと違いnodeの中のフィールドなので）
                this.currentTranslate = {};
              } else { // 切り替え先が日本語以外なら
                // 本チャンデータから編集用オブジェクトに各値をコピーする
                // this.currentTranslate = this.node.translate[lang]; // 参照渡しになるので↓に変更
                this.currentTranslate = Vue.util.extend({}, this.node.translate[lang]); // 値渡し
              }
              // 編集中オブジェクトの変更を監視するウォッチャーを作る
              this.currentTranslateEditingWatcher = this.$watch('currentTranslate', function(val) {
                // 変更があったらこの変数をTrueにする
                this.currentTranslateEditing = true;
                // 一度変更があればあとは監視する必要が無いのでこのウォッチャーを破棄する。
                this.currentTranslateEditingWatcher();
              }, {
                deep: true, // ネストされた階層のデータの変更も監視する
              });
              // 変更があったらTrueにする変数に初期値のFalseを入れる
              this.currentTranslateEditing = false;
            },
            translateIsEmpty(lang) {
              if(!this.node.translate[lang]) return true;
              return true;
            },
            checkTranslateEn(event) {
              // console.log(this.node.translate.en.active);
            },
            checkTranslateHant(event) {
              // console.log(this.node.translate.Hant.active);
            },
            checkTranslateHans(event) {
              // console.log(this.node.translate.Hans.active);
            },
            setPG(file) {
                if(file) { // ファイルが送られてきたら（=画像が追加された）
                  if(this.node.photo_reference) {
                    const currentIndex = this.node.photo_reference.findIndex(photo => photo.file_name === file.file_name);
                    // console.log(currentIndex);
                    if(currentIndex >= 0) {
                      this.node.photo_reference.splice( currentIndex, 1 );
                    }
                  }
                  // 画像データを登録
                  this.node.photo_reference.push({
                    order: this.node.photo_reference.length + 1,
                    type: 'pg',
                    file_name: file.file_name,
                    preview: {
                      src: '/storage/images/pg/thumb/' + file.file_name,
                    },
                    node: file,
                    new: true,
                    user_id: file.user.id,
                    user_name: file.user.name,
                  });
                }
            },
            sortPhotoReference(pg) {
              this.node.photo_reference = pg;
            },
            removePhotoReference(index) {
              // 指定の画像を消す
              this.node.photo_reference.splice( index, 1 );
              // orderを書き換える（key + 1）
              this.node.photo_reference.forEach((val, index) => val.order = index + 1);
            },
            setImageRotate(imageRotate) {
                this.node.image.rotate = imageRotate.rotate;
            },
            setSingleImageRotate(imageRotate) {
                this.node.single_image.rotate = imageRotate.rotate;
            },
            startUploadSpecialImage() {
                this.node.image.flag.uploaderShowFlag = false;
                this.node.image.flag.nowRenderingFlag = true;
            },
            startUploadSingleImage() {
                this.node.single_image.flag.uploaderShowFlag = false;
                this.node.single_image.flag.nowRenderingFlag = true;
            },
            addQue() {
              // this.planImageQue++;
              // this.planImages.push({
              //   order: this.planImages.length + 1,
              //   type: 'que',
              //   queIndex: this.planImageQue,
              //   preview: {},
              // });
            },
            addSingleImageQue() {
              // this.planImageQue++;
              // this.planImages.push({
              //   order: this.planImages.length + 1,
              //   type: 'que',
              //   queIndex: this.planImageQue,
              //   preview: {},
              // });
            },
            // from: ImageUploaderMultipleWithPhoto ->
            // ファイルを追加（アップローダーコンポーネントで画像追加したらこれが発火 ※引数にファイルが入ってくる）
            // または ファイルを削除（アップローダーコンポーネントで画像削除したらこれが発火 ※引数はnull）
            // setFile(file, type, queIndex = null) {
            setFile(e) {
              var file = e.file;
              var type = e.type;
              if(type == 'upload') {
                if(file) { // ファイルが送られてきたら（=画像が追加された）
                  this.node.image = {
                    type: 'special_image',
                    file_name: file.tmpFile,
                    preview: {
                      src: file.src,
                      file: file.file,
                    },
                    flag: {
                      uploaderShowFlag: false,
                      nowRenderingFlag: false,
                      nowChangeFlag: false,
                      isDragging: false,
                      // ドラッグ中かどうかの判定用
                      dragCount: 0,
                    },
                    file: file,
                    new: true,
                    rotatable: true,
                    rotate: 0,
                    imageRotate: 0,
                    imageRotateClass: 'rotate-init',
                  };
                  // this.node.image = newValue;
                } else { // 引数がnullだったら（=画像が削除された）
                  // 登録用画像を削除
                  this.file = null;
                }
                if(this.node.file_name) {
                  // nodeのfileを削除
                  this.node.file_name = null;
                  // 古いの消したよフラグ立てる
                  this.remove_file = true;
                }
              } else if(type == 'pg') {
                if(file) { // ファイルが送られてきたら（=画像が追加された）
                  // 画像データを登録
                  this.node.image = {
                    type: 'pg',
                    file_name: file.file_name,
                    preview: {
                      src: '/storage/images/pg/sample/' + file.file_name,
                    },
                    flag: {
                      uploaderShowFlag: false,
                      nowRenderingFlag: false,
                      nowChangeFlag: false,
                      isDragging: false,
                      // ドラッグ中かどうかの判定用
                      dragCount: 0,
                    },
                    node: file,
                    new: true,
                    user_id: file.user.id,
                    user_name: file.user.name,
                  };
                } else { // 引数がnullだったら（=画像が削除された）
                  // 登録用画像を削除
                  this.file = null;
                }
                // if(this.node.file_name) {
                //   // nodeのfileを削除
                //   this.node.file_name = null;
                //   // 古いの消したよフラグ立てる
                //   this.remove_file = true;
                // }
              }
              this.isProcessing = false;
              // console.log(this.node.image);
            },
            setSingleImageFile(e) {
              var file = e.file;
              var type = e.type;
              // console.log('e');
              // console.log(e);
              if(type == 'upload') {
                if(file) { // ファイルが送られてきたら（=画像が追加された）
                  this.node.single_image = {
                    type: 'special_image',
                    file_name: file.tmpFile,
                    preview: {
                      src: file.src,
                      file: file.file,
                    },
                    flag: {
                      uploaderShowFlag: false,
                      nowRenderingFlag: false,
                      nowChangeFlag: false,
                      isDragging: false,
                      // ドラッグ中かどうかの判定用
                      dragCount: 0,
                    },
                    file: file,
                    new: true,
                    rotatable: true,
                    rotate: 0,
                    imageRotate: 0,
                    imageRotateClass: 'rotate-init',
                  };
                  // this.node.image = newValue;
                } else { // 引数がnullだったら（=画像が削除された）
                  // 登録用画像を削除
                  this.file = null;
                }
                if(this.node.file_name) {
                  // nodeのfileを削除
                  this.node.file_name = null;
                  // 古いの消したよフラグ立てる
                  this.remove_file = true;
                }
              } else if(type == 'pg') {
                if(file) { // ファイルが送られてきたら（=画像が追加された）
                  // 画像データを登録
                  this.node.single_image = {
                    // order: this.planImages.length + 1,
                    type: 'pg',
                    file_name: file.file_name,
                    preview: {
                      src: '/storage/images/pg/sample/' + file.file_name,
                    },
                    flag: {
                      uploaderShowFlag: false,
                      nowRenderingFlag: false,
                      nowChangeFlag: false,
                      isDragging: false,
                      // ドラッグ中かどうかの判定用
                      dragCount: 0,
                    },
                    node: file,
                    new: true,
                    user_id: file.user.id,
                    user_name: file.user.name,
                  };
                } else { // 引数がnullだったら（=画像が削除された）
                  // 登録用画像を削除
                  this.file = null;
                }
                // if(this.node.file_name) {
                //   // nodeのfileを削除
                //   this.node.file_name = null;
                //   // 古いの消したよフラグ立てる
                //   this.remove_file = true;
                // }
              }
              this.isProcessing = false;
              // console.log(this.node.single_image);
            },
            // from: ImageUploaderMultipleWithPhoto -> メイン画像アップロードコンポーネントから、画像が削除された時の処理
            removeImage() {
              // console.log('removeSpotImage');
              // console.log(index);
              if(this.node.image.type == 'special_image' && this.node.image.new === true ) {
                // その編集で追加した画像の場合はtmp画像を消す
                axios.post('/tmp-remove',{
                  // field: 'multiple_image',
                  images: [this.node.image.file_name],
                })
                .then((res)=>{
                  // console.log(res);
                  this.node.image = {
                    file_name: null,
                    preview: {
                      src: null,
                    },
                    flag: {
                      uploaderShowFlag: true,
                      nowRenderingFlag: false,
                      nowChangeFlag: false,
                      isDragging: false,
                      // ドラッグ中かどうかの判定用
                      dragCount: 0,
                    },
                    new: true,
                    rotate: null,
                  }
                })
                .catch((error) => {
                  // console.log(error);
                  // console.log(error.response);
                  alert('サーバーエラー: 画像を削除できませんでした');
                  // // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                  // if(error.response.data.errors) {
                  //   var errors = {};
                  //   for(var key in error.response.data.errors) {
                  //     errors[key] = error.response.data.errors[key].join('<br>');
                  //   }
                  //   this.errors = errors;
                  // } else {
                  //   // バリデーションエラーの場合以外はエラーダイアログを表示
                  //   // エラーデータをほりこむ
                  //   this.error = error.response;
                  //   this.showErrorDialog = true;
                  // }
                })
              } else {
                // // もともとある画像の場合は削除フラグをセットしてデータから消す
                // this.removedSpecialImages.push({
                //   plan_image_id: this.planImages[index].plan_image_id,
                //   file: {
                //     type: this.planImages[index].type,
                //     file_id: this.planImages[index].file_id,
                //     file_name: this.planImages[index].file_name,
                //   }
                // }),
                // 指定の画像を消す
                this.node.image = {
                  file_name: null,
                  preview: {
                    src: null,
                  },
                  flag: {
                    uploaderShowFlag: true,
                    nowRenderingFlag: false,
                    nowChangeFlag: false,
                    isDragging: false,
                    // ドラッグ中かどうかの判定用
                    dragCount: 0,
                  },
                  new: true,
                  rotate: null,
                }
              }
              this.isProcessing = false;
            },
            removeSingleImage() {
              // console.log('removeSpotImage');
              // console.log(this.node.single_image);
              if(this.node.single_image.type == 'special_image' && this.node.single_image.new === true ) {
                // その編集で追加した画像の場合はtmp画像を消す
                axios.post('/tmp-remove',{
                  // field: 'multiple_image',
                  images: [this.node.image.file_name],
                })
                .then((res)=>{
                  // console.log(res);
                  this.node.single_image = {
                    file_name: null,
                    preview: {
                      src: null,
                    },
                    flag: {
                      uploaderShowFlag: true,
                      nowRenderingFlag: false,
                      nowChangeFlag: false,
                      isDragging: false,
                      // ドラッグ中かどうかの判定用
                      dragCount: 0,
                    },
                    new: true,
                    rotate: null,
                  }
                })
                .catch((error) => {
                  // console.log(error);
                  // console.log(error.response);
                  alert('サーバーエラー: 画像を削除できませんでした');
                  // // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                  // if(error.response.data.errors) {
                  //   var errors = {};
                  //   for(var key in error.response.data.errors) {
                  //     errors[key] = error.response.data.errors[key].join('<br>');
                  //   }
                  //   this.errors = errors;
                  // } else {
                  //   // バリデーションエラーの場合以外はエラーダイアログを表示
                  //   // エラーデータをほりこむ
                  //   this.error = error.response;
                  //   this.showErrorDialog = true;
                  // }
                })
              } else {
                // // もともとある画像の場合は削除フラグをセットしてデータから消す
                // this.removedSpecialImages.push({
                //   plan_image_id: this.planImages[index].plan_image_id,
                //   file: {
                //     type: this.planImages[index].type,
                //     file_id: this.planImages[index].file_id,
                //     file_name: this.planImages[index].file_name,
                //   }
                // }),
                // 指定の画像を消す
                this.node.single_image = {
                  file_name: null,
                  preview: {
                    src: null,
                  },
                  flag: {
                    uploaderShowFlag: true,
                    nowRenderingFlag: false,
                    nowChangeFlag: false,
                    isDragging: false,
                    // ドラッグ中かどうかの判定用
                    dragCount: 0,
                  },
                  new: true,
                  rotate: null,
                }
              }
              this.isProcessing = false;
            },

            /**
             * bodyの処理
             */
            // from: MultiElementEditor -> 本文コンポーネントから本文のデータをセット
            setBody(val) {
                this.node.body = val;
            },
            // from: MultiElementEditor -> 本文コンポーネントから、カレント（今回の編集で新規に追加したものでない）エレメントを削除した場合に削除リストに入れる
            removeCurrentElement(elm) {
                var removedCurrentBodyElement = {
                  order: elm.order,
                  element_id: elm.plan_body_element_id,
                  element_type: elm.elementType,
                };
                if(elm.elementType == 'header' || elm.elementType == 'paragraph' || elm.elementType == 'list') {
                  removedCurrentBodyElement.type = elm.type;
                  removedCurrentBodyElement.text = elm.text;
                } else if(elm.elementType == 'image') {
                  removedCurrentBodyElement.file = {
                    type: elm.type,
                    file_name: elm.image.fileName,
                    file_id: elm.image.nodeId,
                  };
                }
                this.removedCurrentBodyElements.push(removedCurrentBodyElement);
            },
            // タグをセット
            setTags(tagsArr) {
              // console.log(tagsArr);
              var newTags = [];
              tagsArr.forEach(tag =>{
                newTags.push({
                  tag: tag,
                });
              });
              this.node.tags = newTags;
            },
            // プレイスをセット
            setPlaces(placesArr) {
              // その都度やってたら1文字ごとにクエリが飛ぶので、0.5秒待ってから処理。その間に次のやつ来たら前のをキャンセルする。
              if(this.setPlacesQue) {
                clearTimeout(this.setPlacesQue);
              }
              this.setPlacesQue = setTimeout(this.executeSetPlaces, 500, placesArr);
            },
            executeSetPlaces(placesArr) {
              var newPlaces = [];
              placesArr.forEach(place =>{
                if(place) {
                  newPlaces.push({
                    place: place,
                  });
                }
              });
              if(JSON.stringify(this.node.places) != JSON.stringify(newPlaces)) { //前後の変数を比較して異なっていた時だけ処理する
                this.node.places = newPlaces;
                this.findPlaces();
              }
            },
            findPlaces() {
              // プレイス名の配列を作る
              var placeNames = this.node.places.map(v => { return v.place });
              // マッチするスポットを探す
              axios.post('/place/find_spot', { places: placeNames }).then((res)=>{
                if(res.data) {
                  // プレイスがあったらデータに反映
                  this.findPlaceResults = res.data;
                  // console.log('res.data');
                  // console.log(res.data);
                }
                // console.log(this.node.places);
                // console.log(this.matchPlaceResults);
              })
              // ToDo: エラー処理書く
              .catch(error => console.log(error))
            },
            // カテゴリーをセット
            setCategories(categoriesArr) {
              // var newCategories = [];
              var newCategories = categoriesArr.map(category =>{
                return {id: category};
              });
              this.node.categories = newCategories;
              //     console.log('-+++--');
              // console.log(this.node.categories);
            },
            // // エリアをセット
            // setAreas() {
            //   // console.log(e.target);
            //   // var area_id = e.target.value;
            //   // var newCategories = [];
            //   var newAreas = this.areaChecked.map(area =>{
            //     return {id: area};
            //   });
            //   this.node.areas = newAreas;
            //   // console.log('-+++--');
            //   // console.log(this.node.areas);
            // },

            addChild() {
              // console.log('addChild!');
              let key = this.node.bodies.length;
              // console.log(key);
              let order = this.node.bodies.length + 1;
              this.node.bodies.push(
                {
                  key: key,
                  order: order,
                  id: null,
                  title: '',
                  slug: '',
                  inline: '',
                  include: '',
                },
              );
            },
            removeChild(key) {
              // console.log('removeChild!');
              const removeBody = this.node.bodies.find(body => body.key === key);
              if(removeBody.id) {
                this.removedBodyId.push(removeBody.id);
              }
              const removed = this.node.bodies.filter(body => body.key !== key);
              removed.forEach((body, index) => {
                body.key = index;
                body.order = index + 1;
              });
              this.node.bodies = removed;
              // console.log('this.removedBodyId');
              // console.log(this.removedBodyId);
              // console.log('this.node.bodies');
              // console.log(this.node.bodies);
            },

            // draggable のメソッドたち
            onstart: function (index) {
                // console.log("onstart")
            },
            onadd: function (index) {
                // console.log("onadd")
            },
            onremove: function (index) {
                // console.log("onremove")
            },
            onupdate: function (index) {
                // console.log("onupdate")
            },
            onend: function (index) {
                // console.log("onend")
            },
            onchoose: function (index) {
                // console.log("onchoose")
            },
            onsort: function (index) {
                // 画面上のソートが終わったらorderを書き換える（key + 1）
                this.node.bodies.forEach((val,key) => val.order = key + 1);
            },
            onfilter: function (index) {
                // console.log("onfilter")
            },
            onclone: function (index) {
                // console.log("onclone")
            },
            onmove: function (index) {
                // console.log("onmove")
                return true
            },


            /**
             * 送信処理
             */
            // 画像アップロード中プログレスバーを出す
            onUpload(e) {
              if(this.showProgress == true) {
                // console.log(e.loaded +'/'+ e.total);
                if(this.$refs.progress) {
                  // プログレスバーを計算して変更
                  this.$refs.progress.value = Math.floor((e.loaded * 100) / e.total);
                  this.progress = Math.floor((e.loaded * 100) / e.total);
                }
              }
            },
            deleteNode() {
              window.confirm('本当に削除しますか?');
              this.isProcessing = true;
              const formData = new FormData();
              // 削除リクエスト投げる
              axios.delete('/special/' + this.node.id).then((res)=>{
                if(res.data) {
                this.isProcessing = false;
                  //
                  // this.findPlaceResults = res.data;
                  // console.log('res.data');
                  // console.log(res.data);
                }
                // console.log(this.node.places);
                // console.log(this.matchPlaceResults);
              })
              // ToDo: エラー処理書く
              .catch(error => console.log(error))
            },
            // サブミット！
            onSubmit() {
                this.isProcessing = true;
                // フォームで送信するFormDataインスタンスつくる
                const formData = new FormData();
                // 送信するフィールドのデータを登録する
                // propで投稿情報が来てたら（updateだったら）
                if(this.nodeprop) {
                  // PUTで送ってるように偽装する（普通にPUTで送れないため）
                  formData.append('_method', 'PUT');
                }
                // 元の子ページを削除した場合に消した子ページのIDを送る（コントローラーでレコードを消すため）
                if(this.removedBodyId) {
                  this.removedBodyId.forEach((val, key) => {
                    formData.append('removedBodies[' + key + ']', val);
                  });
                }
                if(this.node.bodies) {
                  this.node.bodies.forEach((body, index) => {
                    formData.append('bodies[' + index + '][order]', body.order);
                    formData.append('bodies[' + index + '][id]', body.id || '');
                    formData.append('bodies[' + index + '][title]', body.title || '');
                    formData.append('bodies[' + index + '][slug]', body.slug || '');
                    formData.append('bodies[' + index + '][inline]', body.inline || '');
                    formData.append('bodies[' + index + '][include]', body.include || '');
                  });
                }
                // 登録する写真を登録
                if(this.node.image) {
                  formData.append('image', this.node.image.file_name || '');
                }
                if(this.node.single_image) {
                  formData.append('single_image', this.node.single_image.file_name || '');
                }
                // その他のデータを登録
                formData.append('title', this.node.title);
                formData.append('slug', this.node.slug);
                formData.append('image_to_single', this.node.image_to_single);
                formData.append('parent_html', this.node.parent_html || '');
                formData.append('parent_include', this.node.parent_include || '');
                formData.append('css', this.node.css || '');
                formData.append('js', this.node.js || '');
                if(this.node.schedule_start.year && this.node.schedule_start.month && this.node.schedule_start.day) {
                  formData.append('schedule_start', [this.node.schedule_start.year, this.node.schedule_start.month, this.node.schedule_start.day].join('-'));
                  formData.append('schedule_start_year', this.node.schedule_start.year);
                  formData.append('schedule_start_month', this.node.schedule_start.month);
                  formData.append('schedule_start_day', this.node.schedule_start.day);
                }
                if(this.node.schedule_end.year && this.node.schedule_end.month && this.node.schedule_end.day) {
                  formData.append('schedule_end', [this.node.schedule_end.year, this.node.schedule_end.month, this.node.schedule_end.day].join('-'));
                  formData.append('schedule_end_year', this.node.schedule_end.year);
                  formData.append('schedule_end_month', this.node.schedule_end.month);
                  formData.append('schedule_end_day', this.node.schedule_end.day);
                } else {
                  formData.append('schedule_end', '');
                }
                formData.append('status', this.node.status || '');
                formData.append('reserved', this.node.reserved || '');
                formData.append('user_id', this.node.user_id || '');
                // 使用した写真
                if(this.node.photo_reference) {
                  this.node.photo_reference.forEach((pg, index) => {
                    formData.append('photo_reference[' + index + ']', pg.node.id);
                  });
                }
                // タグをセット
                if(this.node.tags) {
                  this.node.tags.forEach(tag => {
                    formData.append('tags[]', tag.tag);
                  });
                }
                // プレイスをセット
                if(this.node.places) {
                  this.node.places.forEach(place => {
                    formData.append('places[]', place.place);
                  });
                }
                // カテゴリーをセット
                if(this.node.categories) {
                  this.node.categories.forEach(category => {
                    formData.append('categories[]', category.id);
                  });
                }

                //axiosでPOST送信
                axios.post(this.actionurl, formData, {
                  onUploadProgress: this.onUpload,
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then( response => {
                  // プログレスダイアログを消す
                  this.showProgress = false;
                  // プログレスの値をリセット
                  this.progress = 0;
                  // console.log(response);
                  // リダイレクトURLが返ってきたら（=正常に登録できた）ページ移動する（シングルページ���リダイレクト）
                  if(response.data.redirect_path) {
                    document.location.href = response.data.redirect_path;
                    // if(confirm(response.data.message + 'ページに移動しますか？')) document.location.href = response.data.redirect_path;
                  } else {
                    console.log(response);
                    alert('例外が発生しました。Error: SpecialEdit001');
                  }
                  this.isProcessing = false;
                }).catch(
                  (error) => {
                    // プログレスダイアログを消す
                    this.showProgress = false;
                    // プログレスの値をリセット
                    this.progress = 0;
                    // 例外処理
                    // console.log(error);
                    // console.log(error.response.status);
                    // console.log(error.response);
                    // this.success = false;
                    // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                    if(error.response.data.errors) {
                      var errors = {};
                      for(var key in error.response.data.errors) {
                        errors[key] = error.response.data.errors[key].join('<br>');
                      }
                      this.errors = errors;
                      // console.log(error.response.data.errors);

                      var validationErrors = [];
                      for(var key in error.response.data.errors) {
                        validationErrors.push({
                          id: key,
                          messages: error.response.data.errors[key].map((message, key) => {
                            return {
                              id: key,
                              message: message,
                            };
                          }),
                        });
                        this.validationErrors = validationErrors;
                      }
                      // console.log(this.validtionErrors);
                      // this.echoErrors();
                      this.showValidationErrorDialog = true;
                      this.isProcessing = false;
                    } else {
                      // バリデーションエラーの場合以外はエラーダイアログを表示
                      // エラーデータをほりこむ
                      this.error = error.response;
                      this.showErrorDialog = true;
                      this.isProcessing = false;
                    }
                  }
                );
            },

        }
    }
</script>

<style lang="scss" scoped>
.specialBodyEditor > .form_body {
  padding: 9px;
  margin: 0.25em 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  vertical-align: middle;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  .sortableWrap {
    .special_child {
      width: calc(100% + 9px);
      padding-right: 30px;
      margin: 5px 0;
      position: relative;
      .draggable-handle {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 100%;
        text-align: center;
        cursor: pointer;
        // background-color: #fafafa;
        // background-color: #ccc;
        span {
          width: 20px;
          height: 2px;
          background-color: #ccc;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -1px);
          text-indent: -9999em;
          &::before {
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #ccc;
            transform: translate(0, -8px);
          }
          &::after {
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #ccc;
            transform: translate(0, 8px);
          }
        }
      }
    }
  }
  .special_child_block {
    // padding-right: 30px;
    padding: 0 14px;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    margin: 5px 0;
    position: relative;
  }
}
.controll_panel {
  width: 100%;
  margin: 0;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 9px 4px;
  display: flex;
  .addChildBtns {
    .btns {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin: 0 5px;
      .btn {
        margin: 2px;
        border: 1px solid #ccc;
      }
    }
  }
}
.forTranslate {
  display: none;
}
</style>
