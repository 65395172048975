<template>
  <div class="photoReference">
    <div class="photoReferenceBtn">
      <span @click="showPG()" class="btn btn-primary">京都写真ギャラリーから選ぶ</span>
    </div>
    <photo-select
    v-if="showByPhotoGallery"
    id="imageFieldPG"
    :multiple="multiple"
    :isShow="showByPhotoGallery"
    @setPG="setPG"
    @cancelPG="cancelPG">
    </photo-select>
  </div>
</template>

<script>
  import PhotoSelect from '../PhotoSelect'
  export default {
    components: {
      PhotoSelect,
    },
    props: {
      // フィールドのname
      name: {
        type: String,
        required: true,
      },
      // 複数選択の可否
      multiple: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    data: () => ({
      // アップロードファイルオブジェクト
      files: [],
      que: 0,
      showByPhotoGallery: false,
      byPhotoGalleryStr: null,
      byPhotoGallery: [],
      // 写真ギャラリーから選ぶのモーダルが表示されてる間後ろのスクロールを止めるためbodyのスクロール量をバックアップ
      bodyScrollTop: 0,
    }),
    created() {
      //
    },
    methods: {
      /**
       * PGフィールド関係
       */
      // 写真ギャラリーから選ぶダイアログを表示
      showPG() {
        this.showByPhotoGallery = true;
        // this.bodyFix();
      },
      // 写真ギャラリーから選ぶダイアログでキャンセル押したとき（非表示にする）
      cancelPG() {
        this.showByPhotoGallery = false;
        // this.bodyFix();
      },
      // 写真ギャラリーから写真を選んで決定押したとき
      setPG(selected) {
        // いっこ上のコンポーネントに選択済みの写真のIDの配列をほりなげる
        selected.forEach(pgID => this.$emit('setPG', pgID));
        // 写真ギャラリーから選ぶダイアログを消す
        this.showByPhotoGallery = false;
      },
    }
  }
</script>

<style lang="scss" scoped>
//
</style>
