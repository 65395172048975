<docs>
参考
https://www.youtube.com/watch?v=YmyAEyGL33U
※↑これはmultiple対応
</docs>

<template>
    <div class="imageFieldComponent">
        <div :class="name + '_edit'">
          <span class="btn" v-show="!image && !currentFile" v-on:click="uploaderShow()">{{ title }}を新規登録</span>
          <span class="btn" v-show="image || currentFile" v-on:click="uploaderShow()">{{ title }}を変更</span>
          <span class="btn" v-show="image || currentFile" v-on:click="removeCurrent()">{{ title }}を削除</span>
        </div>
        <mordal-window
        :slag="name"
        :title="title"
        :enableConfirm="enableConfirm"
        :isShow="isShow"
        @cancel="cancelPG"
        @confirm="confirmPG">
                <image-uploader :title="title" :name="name" :current="current" :prvpath="'/storage/images/cover_image/m/'"
                @setFile="setFile"
                @setProcessing="setProcessing"
                @setImageRotate="setImageRotate">
                </image-uploader>
        </mordal-window>
    </div>
</template>

<script>
    export default {
        // フィールド名
        props: {
            // 日本語のフィールド名
            title: {
                type: String,
                default: null,
                required: false,
            },
            // フィールドのname
            name: {
                type: String,
                required: true,
            },
            // 現在の画像
            current: {
                type: String,
                default: null,
                required: false,
            }
        },
        data: () => ({
            // アップローダー表示フラグ
            uploaderShowFlag: false,
            // ドラッグ中かどうか
            isDragging: false,
            // ドラッグ中かどうかの判定用
            dragCount: 0,
            // ファイルの情報を入れる配列
            file: null,
            // ファイル自体を入れる配列
            file_preview: null,
            // ファイル自体を入れる配列
            image: null,
            // ファイルの情報を入れる配列
            image_preview: null,
            // すでに登録済みの画像
            currentFile: null,
            // すでに登録済みの画像を消す時に入れるゴミ箱
            currentFileRemoved: null,
            // 処理中はtrueにするとボタンが無効になる
            isProcessing: false,
        }),
        created() {
            // もともとファイルが登録されている場合は変数に入れておく
            if(this.current) {
              this.currentFile = {
                // 現在の画像のファイル名
                name: this.current,
                // 現在の画像のパス。正式なパスに書き換える。
                path: '/storage/images/300/' + this.current
              }
            }
        },
        methods: {
            // アップローダーを表示
            uploaderShow() {
              this.uploaderShowFlag = true;
            },
            // ドラッグがdrop領域に入った時
            onDragEnter(e) {
              e.preventDefault();
              this.dragCount++;
              this.isDragging = true;
            },
            // ドラッグがdrop領域を出た時
            onDragLeave(e) {
              e.preventDefault();
              this.dragCount--;
              // console.log(this.dragCount);
              if(this.dragCount <= 0) {
                  this.isDragging = false;
              }
            },
            // ファイルフィールドのchangeイベント
            onInputChange(e) {
              const file = e.target.files[0];
              this.addImage(file);
            },
            // drop領域内でdropした時
            onDrop(e) {
              e.preventDefault();
              e.stopPropagation();
              this.dragCount = 0;
              this.isDragging = false;
              const file = e.dataTransfer.files[0];
              this.addImage(file);
            },
            // this.fileにファイルの実態をほりこむ
            addImage(file) {
              // ドロップされたものが画像かどうかチェック
              if(!file.type.match('image.[jpeg|png|gif]')) {
                alert(`${file.name} は非対応のファイル形式です`);
                return;
              }
              if(file.size > 30000000) {
                alert(`${file.name} は容量が大きすぎるためアップロードできません。ファイル容量の上限は30MBです。`);
                return;
              }
              // プレビュー用の画像を用意
              this.file_preview = file;
              // 以下おまじない
              const reader = new FileReader();
              reader.onload = (e) => this.image_preview = e.target.result;
              reader.readAsDataURL(file);
            },
            cancel() {
              this.remove();
              // アップローダーを隠す
              this.uploaderShowFlag = false;
            },
            // ドロップしたファイルの決定ボタンを押したとき
            confirm() {
              // アップロード用の画像を用意
              this.file = this.file_preview;
              // 以下おまじない
              const reader = new FileReader();
              reader.onload = (e) => this.image = e.target.result;
              reader.readAsDataURL(this.file);
              // いっこ上の階層のVueコンポーネントのメソッド（ファイルをセット）にファイルを投げる
              this.$emit('setFile',this.file);
              // コントローラーで元の画像を削除するため、消した元画像のファイル名を入れておく
              if(!this.currentFileRemoved) this.currentFileRemoved = this.currentFile;
              // 元画像の情報を消す
              this.currentFile = null;
              // アップローダーを隠す
              this.uploaderShowFlag = false;
              // プレビューを隠す
              this.image_preview = null;
              // プレビュー用の画像を消す
              this.file_preview = null;
            },
            // いっこ消す
            remove() {
              // 画像を消す
              this.image = null;
              // 登録用のファイル実体を消す
              this.file = null;
              // プレビュー用の画像を消す
              this.image_preview = null;
              // プレビュー用のファイル実体を消す
              this.file_preview = null;
              // // コントローラーで元の画像を削除するため、消した元画像のファイル名を入れておく
              // if(!this.currentFileRemoved) this.currentFileRemoved = this.currentFile;
              // // 元画像の情報を消す
              // this.currentFile = null;
              // // いっこ上の階層のVueコンポーネントのメソッド（ファイルをセット）にnullを投げる（そっちの方でも削除の処理する）
              // this.$emit('setFile',null);
              // アップローダーを隠す
              this.uploaderShowFlag = false;
            },
            // いっこ消す
            removeCurrent() {
              // 画像を消す
              this.image = null;
              // 登録用のファイル実体を消す
              this.file = null;
              // プレビュー用の画像を消す
              this.image_preview = null;
              // プレビュー用のファイル実体を消す
              this.file_preview = null;
              // コントローラーで元の画像を削除するため、消した元画像のファイル名を入れておく
              if(!this.currentFileRemoved) this.currentFileRemoved = this.currentFile;
              // 元画像の情報を消す
              this.currentFile = null;
              // いっこ上の階層のVueコンポーネントのメソッド（ファイルをセット）にnullを投げる（そっちの方でも削除の処理する）
              this.$emit('setFile',null);
              // アップローダーを隠す
              this.uploaderShowFlag = false;
            },
        }
    }
</script>

<style lang="scss" scoped>
  .uploader {
    width: calc(100% - 40px);
    height: 100vh;
    margin: 20px;
    padding: 2rem 1.5rem;
    text-align: center;
    border: 3px dashed #CCC;
    position: relative;
    background-color: #eee;
    &.dragging {
      background-color: rgba(0,120,230,.5);
      * {
        opacity: .5;
      }
    }
    &> .inner {
      position: absolute;
      margin: 0 auto;
      top: 45%;
      left: 50%;
      transform: translateY(-45%) translateX(-50%);
      -webkit-transform: translateY(-45%) translateX(-50%);
    }
    .message {
      font-size: 1.7rem;
      font-weight: bold;
      margin: 0;
      line-height: 1;
      span {
        display: block;
        margin-top: -.3rem;
        font-size: 1.3rem;
        font-weight: normal;
      }
    }
    .file-input {
      width: 100%;
      text-align: center;
      margin: auto;
      position: relative;
      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        opacity: 0;
      }
    }
  }
  .images-preview {
    width: 100%;
    height: 100vh;
    max-width: 1000px;
    max-height: 90%;
    margin: 0 auto;
    text-align: center;
    .img-wrapper {
      text-align: center;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      border: 1px solid #fff;
    }
    .uploadImagePreview {
      width: auto;
      height: auto;
      max-width: 90%;
      max-height: 90%;
      margin: 0 auto;
      object-fit: contain;
      border: 1px solid #fff;
    }
    .details {
      margin-top: .7rem;
      span {
        margin: 0 .7rem;
      }
    }
  }
</style>
