<template>

  <div>

    <form class="model_selector pure-form pure-form-stacked">
      <div class="model_radio"><label class="label"><input type="radio" name="tab" v-model="currentModel" v-on:change="onTabChange" v-bind:disabled="loading" value="special"><span class="labelText">特集</span></label></div>
      <div class="model_radio"><label class="label"><input type="radio" name="tab" v-model="currentModel" v-on:change="onTabChange" v-bind:disabled="loading" value="article"><span class="labelText">レポート</span></label></div>
      <div class="model_radio"><label class="label"><input type="radio" name="tab" v-model="currentModel" v-on:change="onTabChange" v-bind:disabled="loading" value="event"><span class="labelText">イベント</span></label></div>
      <div class="model_radio"><label class="label"><input type="radio" name="tab" v-model="currentModel" v-on:change="onTabChange" v-bind:disabled="loading" value="spot"><span class="labelText">スポット</span></label></div>
      <div class="model_radio"><label class="label"><input type="radio" name="tab" v-model="currentModel" v-on:change="onTabChange" v-bind:disabled="loading" value="plan"><span class="labelText">コース</span></label></div>
      <div class="model_radio"><label class="label"><input type="radio" name="tab" v-model="currentModel" v-on:change="onTabChange" v-bind:disabled="loading" value="photo"><span class="labelText">写真ギャラリー</span></label></div>
      <div class="model_radio"><label class="label"><input type="radio" name="tab" v-model="currentModel" v-on:change="onTabChange" v-bind:disabled="loading" value="user"><span class="labelText">ユーザー</span></label></div>
    </form>


    <div v-if="loading" class="loader_box">
      <div class="loader_dot"></div>
    </div>

    <div v-if="!nodes" class="loader_box">
      <div class="no_posts">読み込めませんでした</div>
    </div>

    <div v-else-if="currentModel == 'special'" class="special">
      <section class="section-w node-list">
          <div class="node-list-item" v-for="node in nodes" :key="node.order">
            <a :href="'/special/' + node.slug">
              <div class="txt">
                <h2 class="title">{{ node.title }}</h2>
              </div>
              <div class="img">
                <div class="image-wrap">
                  <img v-if="node.image" :src="'/storage/images/special_image/thumb/' + node.image" :alt="node.name">
                </div>
              </div>
            </a>
            <recommend-button
              :model="node.recommend.recommendable_type"
              :id="node.id"
              :recommendobj="node.recommend"
            ></recommend-button>
            <div class="author-link">
              <a class="link link-edit" :href="'/special/' + node.id + '/edit'" >編集</a>
            </div>
          </div>
      </section>
    </div>
    
    <div v-else-if="currentModel == 'article'" class="article">
      <section class="section-w node-list">
        <div :class="'node-list-item status-' + node.status" v-for="node in nodes" :key="node.order">
          <a class="link" :href="'/article/' + node.id">
            <div class="txt">
              <h2 class="title">{{ node.title }}</h2>
              <div class="excerpt">
                {{node.bodytext_forindex}}
              </div>
            </div>
            <div class="img">
              <div class="image-wrap">
                <img v-if="node.thumb" :src="node.thumb" :alt="node.name">
              </div>
            </div>
          </a>
          <recommend-button
            :model="node.recommend.recommendable_type"
            :id="node.id"
            :recommendobj="node.recommend"
          ></recommend-button>
          <div class="author-link">
            <a class="link link-edit" :href="'/article/' + node.id + '/edit'" >編集</a>
          </div>
        </div>
      </section>
    </div>
    
    <div v-else-if="currentModel == 'event'" class="article">
      <section class="section-w node-list">
        <div class="event-list-item" :class="'status-' + node.status" v-for="node in nodes" :key="node.order">
          <a class="link" :href="'/event/' + node.id">
            <div class="txt">
              <h2 class="title">{{ node.name }}</h2>
              <div class="event_date_wrap">
                <event-status v-if="node.date_end.time"
                  :start="node.date_start.time"
                  :end="node.date_end.time"
                ></event-status>
                <event-status v-else
                  :start="node.date_start.time"
                ></event-status>
                <div class="date">
                  <span v-if="node.date_display" class="date_display">{{ node.date_display }}</span>
                  <span v-if="!node.date_display" class="start">
                    <span class="year"><span>{{ node.date_start.Y }}</span>年</span>
                    <span class="month"><span>{{ node.date_start.n }}</span>月</span>
                    <span class="day"><span>{{ node.date_start.j }}</span>日</span>
                    <span class="week">（<span :class="'wd' + node.date_start.dow">{{ node.date_start.week }}</span>）</span>
                  </span>
                  <span v-if="!node.date_display" class="end">～
                    <span v-if="node.date_start.Y !== node.date_end.Y" class="year"><span>{{ node.date_end.Y }}</span>年</span>
                    <span v-if="node.date_start.Y !== node.date_end.Y || node.date_start.month !== node.date_end.month" class="month"><span>{{ node.date_end.n }}</span>月</span>
                    <span class="day"><span>{{ node.date_end.j }}</span>日</span>
                    <span class="week">（<span :class="'wd' + node.date_end.dow">{{ node.date_end.week }}</span>）</span>
                  </span>
                </div>
                <span v-if="node.date_uncertain" class="date_uncertain">※未確定</span>
              </div>
              <div class="venue"><span class="at">at</span> {{ node.venue }}</div>
            </div>
            <div class="img">
              <div class="image-wrap">
                <img v-if="node.thumb" :src="node.thumb" :alt="node.name">
              </div>
            </div>
          </a>
          <recommend-button
            :model="node.recommend.recommendable_type"
            :id="node.id"
            :recommendobj="node.recommend"
          ></recommend-button>
          <div class="author-link">
            <a class="link link-edit" :href="'/event/' + node.id + '/edit'" >編集</a>
          </div>
        </div>
      </section>
    </div>

    <div v-else-if="currentModel == 'spot'" class="article">
      <section class="section-w node-list">
        <div :class="'node-list-item status-' + node.status" v-for="node in nodes" :key="node.order">
          <a class="link" :href="'/spot/' + node.id">
            <div class="txt">
              <h2 class="title">{{ node.name }}</h2>
              <div class="excerpt">{{ node.excerpt_forindex }}</div>
            </div>
            <div class="img">
              <div class="image-wrap">
                  <img v-if="node.thumb" :src="node.thumb" :alt="node.name">
              </div>
            </div>
          </a>
          <recommend-button
            :model="node.recommend.recommendable_type"
            :id="node.id"
            :recommendobj="node.recommend"
          ></recommend-button>
          <div class="author-link">
            <a class="link link-edit" :href="'/spot/' + node.id + '/edit'" >編集</a>
          </div>
        </div>
      </section>
    </div>
    
    <div v-else-if="currentModel == 'plan'" class="plan">
      <section class="section-w node-list plan-node-list">
        <div :class="'node-list-item status-' + node.status" v-for="node in nodes" :key="node.order">
          <a class="link" :href="'plan/' + node.id">
            <div class="txt">
              <h2 class="title">{{ node.title }}</h2>
              <div class="excerpt">{{ node.excerpt_forindex }}</div>
              <div class="itinerary_index">
                <ol>
                  <li v-for="itinerary in node.itineraries" :key="itinerary.order"><span class="name">{{ itinerary.name }}</span></li>
                </ol>
              </div>
            </div>
            <div class="img">
              <div class="image-wrap">
                  <img :src="node.thumb" :alt="node.name">
              </div>
            </div>
          </a>
          <recommend-button
            :model="node.recommend.recommendable_type"
            :id="node.id"
            :recommendobj="node.recommend"
          ></recommend-button>
          <div class="author-link">
            <a class="link link-edit" :href="'/plan/' + node.id + '/edit'" >編集</a>
          </div>
        </div>
      </section>
    </div>

    <div v-else-if="currentModel == 'photo'" class="photo">
      <section class="section-w">
        <div class="photo_thumbs">
          <div :class="'item status-' + node.status" v-for="node in nodes" :key="node.order">
            <a class="link" :href="'/photo/' + node.id">
              <div class="title">{{ node.title }}</div>
              <img :class="'img ' + node.landscape_class" :src="'/storage/images/pg/thumb/' + node.file_name" :alt="node.title">
            </a>
            <recommend-button
              :model="node.recommend.recommendable_type"
              :id="node.id"
              :recommendobj="node.recommend"
            ></recommend-button>
            <div class="author-link">
              <a class="link link-edit" :href="'/photo/' + node.id + '/edit'" >編集</a>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div v-else-if="currentModel == 'user'" class="user" v-for="node in nodes" :key="node.order">
      {{node.id}}: {{node.name}}
            <recommend-button
            :model="node.recommend.recommendable_type"
            :id="node.id"
            :recommendobj="node.recommend"
            ></recommend-button>
            <div class="author-link">
              <a class="link link-edit" :href="'/special/' + node.id + '/edit'" >編集</a>
            </div>
    </div>

  </div>

</template>

<script>
    import RecommendButton from './standalone_element/RecommendButton';
    import EventStatus from './view_element/EventStatus';
    export default {
      components: {
        RecommendButton,
        EventStatus,
      },
      props: {
        // ユーザーオブジェクト
        auth: {
          type: Object,
          required: true,
        },
      },
      data: () => ({
        special: [],
        article: [],
        event: [],
        spot: [],
        plan: [],
        photo: [],
        user: [],
        nodes: [],
        currentModel: 'special',
        loading: false,
        currentSearch: false,
        page: 1,
        loading: false,
        load: true,
        currentTab: 'keyword',
      }),
      created() {
        this.getRecommendedNodes()
      },
      methods: {
        onTabChange() {
          console.log(this.currentModel)
          currentSearch: false,
              this.nodes = []; // 検索結果を一消す
              this.page = 1
          this.getRecommendedNodes()
        },
        async getRecommendedNodes() {
          if (!this.loading) { //読み込み中は読み込めないようにする
            if(!this.currentSearch) { // 検索条件に変更があれば
              this.nodes = []; // 検索結果を一消す
              this.page = 1 // 現在のページ数をリセット
            }
            this.currentSearch = true; // 現在検索結果表示中
            // this.currentSearchFormData = this.searchFormData; // 現在検索結果表示中の検索条件を保存
            // フォームで送信するFormDataインスタンスつくる
            const formData = new FormData();
            // その他のデータを登録
            formData.append('model', this.currentModel);
            this.loading = true
            try {
              const response = await axios.post('/kdadmin/getrecommendednodes?page=' + this.page, formData)
              if (response.data.nodes.last_page == this.page) this.load = false
              if (response.data.nodes.data) {
                await response.data.nodes.data.forEach((n,i) => {
                  n.order = i;
                  if(this.currentModel == 'photo') {
                    n.landscape_class = n.landscape ? 'landscape' : 'vertical';
                  }
                  if(this.currentModel == 'event') {
                    console.log(n.thumb);
                    console.log(n);
                  }
                  this.nodes.push(n)
                  console.log(n)
                })
              } else {
                this.nodes = [];
              }
              this.page += 1
            } catch (e) {
              // console.log(e.response)
              this.load = false
              this.loading = false
            } finally {
              this.loading = false
            }
          }
        },
      }
    }
</script>

<style lang="scss" scoped>
  .model_selector {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .model_radio {
      margin: 0 1rem 0;
    }
  }
  .loader_box {
    width: 100%;
    height: 100vh;
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .loader_dot {
      width: 40px;
      aspect-ratio: 4;
      --_g: no-repeat radial-gradient(circle closest-side,#000 90%,#0000);
      background: 
        var(--_g) 0%   50%,
        var(--_g) 50%  50%,
        var(--_g) 100% 50%;
      background-size: calc(100%/3) 100%;
      animation: l7 1s infinite linear;
    }
    @keyframes l7 {
        33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
        50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
        66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
    }
  }
</style>
