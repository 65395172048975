<template>
  <div class="multiple_images_preview">
    <draggable v-if="images" v-model="images" tag="div" class="photo_thumbs"
    animation="200"
    handle=".draggable-handle"
    delay="30"
    delayOnTouchOnly="true"
    touchStartThreshold="5"
    @move="onmove"
    @start="onstart"
    @add="onadd"
    @remove="onremove"
    @update="onupdate"
    @end="onend"
    @choose="onchoose"
    @sort="onsort"
    @filter="onfilter"
    @clone="onclone">
      <div class="item" v-for="(image, index) in images" :key="image.viewId"><image-preview v-show="image"
        :previewFile="image.preview"
        :rotatable="image.type != 'pg' && image.type != 'current_pg' && image.type != 'current_deleted'"
        :multiple="true"
        @setImageRotate="imageRotate => setImageRotate(imageRotate, index)"
        @remove="remove(index)"
        >
        <div class="draggable-handle"></div>
        <div v-if="image.new" class="icon_new">NEW</div>
        <div v-if="image.user_name" class="author">by {{ image.user_name }}</div>
      </image-preview>
      </div>
    </draggable>
  </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import ImagePreview from './ImagePreview'
    export default {
        components: {
            draggable,
            ImagePreview,
        },
        props: {
          images: {
            type: Array,
            default: null,
            required: false,
          },
        },
        data: () => ({
          imagesdata: [],
        }),
        created() {
          if (this.images) {
              // 画像プロパティをdataにセット
              this.imagesdata = this.images;
          }
          // console.log('images!');
          // console.log(this.images);
        },
        methods: {
          setImageRotate(imageRotate, index) {
            // console.log(imageRotate);
            this.imageRotate = imageRotate;
            this.$emit('setImageRotate', imageRotate, index);
          },
          remove(index) {
            this.$emit('remove',index);
          },
          // draggable のメソッドたち
          onstart: function (index) {
              // console.log("onstart")
          },
          onadd: function (index) {
              // console.log("onadd")
          },
          onremove: function (index) {
              // console.log("onremove")
          },
          onupdate: function (index) {
              // console.log("onupdate")
          },
          onend: function (index) {
              // console.log("onend")
          },
          onchoose: function (index) {
              // console.log("onchoose")
          },
          onsort: function (index) {
              // 画面上のソートが終わったらorderを書き換える（key + 1）
              this.images.forEach((val,key) => val.order = key + 1);
              // 親のデータを更新
              this.$emit('onsort', this.images);
          },
          onend: function (index) {
            // console.log('this.images');
            // console.log(this.images);
              // 画面上のソートが終わったらorderを書き換える（key + 1）
              // this.images.forEach((val,key) => val.order = key + 1);
              // setTimeout(() => {

                // this.$emit('onsort', this.images);
              // }, 5000);
              // // 画面上のソートが終わったらorderを書き換える（key + 1）
              // this.spotImages.forEach((val,key) => val.order = key + 1);
          },
          onfilter: function (index) {
              // console.log("onfilter")
          },
          onclone: function (index) {
              // console.log("onclone")
          },
          onmove: function (index) {
              // console.log("onmove")
              return true
          },
        }
    }
</script>
