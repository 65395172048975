import { render, staticRenderFns } from "./TagEditor.vue?vue&type=template&id=655862e4&scoped=true"
import script from "./TagEditor.vue?vue&type=script&lang=js"
export * from "./TagEditor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "655862e4",
  null
  
)

/* custom blocks */
import block0 from "./TagEditor.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

export default component.exports