<template>
    <div class="itineraryEditor">

            <!-- <textarea>{{ itineraries }}</textarea> -->

        <draggable v-if="itineraries" v-model="iti" tag="div" :class="sortable" class=""
        animation="200"
        handle=".draggable-handle"
        delay="30"
        delayOnTouchOnly="true"
        touchStartThreshold="5"
        @move="onmove"
        @start="onstart"
        @add="onadd"
        @remove="onremove"
        @update="onupdate"
        @end="onend"
        @choose="onchoose"
        @sort="onsort"
        @filter="onfilter"
        @clone="onclone">
          <div class="itinerary" v-for="itinerary in iti" :key="itinerary.order">
            <div class="draggable-handle"><span></span></div>
            <fieldset class="itineraryFields">
              <div class="blockLabel"><span>行程{{ itinerary.order }}</span>
                <span class="btn remove-btn" @click="removeItinerary(itinerary)">削除</span>
              </div>

              <div class="form-group required">
                <div class="form_header">
                  <span class="label">行程名（スポット名）</span>
                </div>
                <div class="form_body">
                  <div class="form_input">
                    <input type="text" class="pure-input-1" v-bind:class="{ 'is-invalid':itinerary.error }" :name="'itinerary_name['+itinerary.order+']'" v-model="itinerary.name" required autocomplete="title" @input="updateItineraries">
                  </div>
                  <div class="form_note">
                    <div class="invalid-feedback" role="alert" v-html="itinerary.error"></div>
                    <div class="note">行程のタイトル（スポット名）。</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="form_header">
                  <span class="label">スポットID</span>
                </div>
                <div class="form_body">
                  <div class="form_input">
                    <input type="text" class="" v-bind:class="{ 'is-invalid':itinerary.error }" :name="'itinerary_spot_id['+itinerary.order+']'" v-model="itinerary.spot_id" autocomplete="spot_id" @input="updateItineraries">
                  </div>
                  <div class="form_note">
                    <div class="invalid-feedback" role="alert" v-html="itinerary.error"></div>
                    <div class="note">スポットID</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="form_header">
                  <span class="label">この行程の画像</span>
                </div>
                    <!-- <textarea>{{ itinerary }}</textarea> -->
                <div class="form_body">
                  <div class="form_input">
                    <!-- :setFile="setItineraryFile(itinerary)" -->
                    <!-- @setFile="image => setItineraryFile(image, itinerary)" -->
                    <image-uploader-with-photo
                    v-show="itinerary.image.flag.uploaderShowFlag"
                    :name="'itinerary_image_'+itinerary.order"
                    tmpFormatName="itinerary_image"
                    :image-que="null"
                    :multiple="false"
                    :itinerary="itinerary"
                    @setFile="image => setItineraryFile(image, itinerary)"
                    @addQue="addQue(itinerary)"
                    @setProcessing="setProcessing"
                    >
                    </image-uploader-with-photo>
                    <image-preview
                    v-show="!itinerary.image.flag.uploaderShowFlag"
                    :previewFile="itinerary.image.preview"
                    :rotatable="itinerary.image.type != 'pg'"
                    @remove="index => removeImage(index,itinerary)"
                    @setImageRotate="imageRotate => setImageRotate(imageRotate, itinerary)"
                    >
                    </image-preview>
                  </div>
                </div>
              </div>
              <!-- <textarea>{{ itinerary }}</textarea> -->

              <div class="form-group">
                <div class="form_header">
                  <span class="label">この行程の説明</span>
                </div>
                <div class="form_body">
                  <div class="form_input">
                    <!-- <textarea v-bind:class="{ 'is-invalid':errors.note }" name="note" rows="2" v-model="node.note"></textarea> -->
                    <textarea-auto-adjust
                      :errors="itinerary.error"
                      :name="'itinerary_description'+itinerary.order"
                      v-model="itinerary.description"
                      @input="updateItineraries"
                    ></textarea-auto-adjust>
                  </div>
                  <div class="form_note">
                    <div class="invalid-feedback" role="alert" v-html="itinerary.error"></div>
                    <div class="note">この行程の説明を入力してください。</div>
                  </div>
                </div>
              </div>

              <!-- <div class="form-group">
                <div class="form_header">
                  <span class="label">前の行程からの移動手段</span>
                </div>
                <div class="form_body">
                  <div class="form_input">
                    <select name="itinerary_traffic_type" :class="{ 'is-invalid':itinerary.error }" v-model="itinerary.traffic_type">
                      <option value="">選択してください</option>
                      <option value="1">徒歩</option>
                      <option value="2">バス</option>
                      <option value="3">電車</option>
                      <option value="4">タクシー（車）</option>
                    </select>
                  </div>
                  <div class="form_note">
                    <div class="invalid-feedback" role="alert" v-html="itinerary.traffic_type"></div>
                  </div>
                </div>
              </div> -->

              <div class="form-group">
                <div class="form_header">
                  <span class="label">次の行程への移動情報</span>
                </div>
                <div class="form_body">
                  <div class="itineraryTrafficEditor">
                    <draggable v-if="itinerary.traffics" v-model="itinerary.traffics" tag="div" :class="sortable_traffics" class=""
                    animation="200"
                    handle=".draggable-handle"
                    delay="30"
                    delayOnTouchOnly="true"
                    touchStartThreshold="5"
                    @move="onmove_t"
                    @start="onstart_t"
                    @add="onadd_t"
                    @remove="onremove_t"
                    @update="onupdate_t"
                    @end="onend_t"
                    @choose="onchoose_t"
                    @sort="onsort_t"
                    @filter="onfilter_t"
                    @clone="onclone_t">
                      <div class="traffic" v-for="(traffic, idx) in itinerary.traffics" :key="traffic.order">
                        <div class="draggable-handle"><span>[移動]</span></div>
                        <div>移動情報{{ idx + 1 }}<span class="btn button-small" @click="removeTraffic(itinerary.traffics, idx)">この移動手段を削除</span></div>
                        <div class="form-group">
                          <div class="form_header">
                            <span class="label">交通手段タイプ</span>
                          </div>
                          <div class="form_body">
                            <div class="form_input">
                              <select type="text" name="tt" v-model="traffic.type">
                                <option v-for="traffic_type in itinerary_traffic_types" :value="traffic_type.id" :key="traffic_type.id">
                                  {{ traffic_type.type }}
                                </option>
                              </select>
                            </div>
                            <div class="form_note">
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form_header">
                            <span class="label">交通機関名</span>
                          </div>
                          <div class="form_body">
                            <div class="form_input">
                              <input type="text" class="pure-input-1-2" v-bind:class="{ 'is-invalid':itinerary.error }" :name="'itinerary[traffics]['+traffic.order+']'" v-model="traffic.title" @input="updateTraffics">
                            </div>
                            <div class="form_note">
                              <div class="invalid-feedback" role="alert" v-html="itinerary.error"></div>
                              <div class="note"></div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="form_header">
                            <span class="label">移動説明</span>
                          </div>
                          <div class="form_body">
                            <div class="form_input">
                              <!-- <textarea v-bind:class="{ 'is-invalid':errors.note }" name="note" rows="2" v-model="node.note"></textarea> -->
                              <textarea-auto-adjust
                                :errors="traffic.error"
                                name="traffic_text"
                                v-model="traffic.text"
                                @input="updateTraffics"
                              ></textarea-auto-adjust>
                            </div>
                            <div class="form_note">
                              <div class="invalid-feedback" role="alert" v-html="traffic.error"></div>
                              <div class="note">次の行程への所要時間など、移動情報を入力してください。</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </draggable>
                    <div><span class="btn button-small" @click="addTraffic(itinerary.traffics)">移動手段を追加</span></div>
                  </div>
                </div>
              </div>

            </fieldset>
          </div>

        </draggable>

        <div><span class="btn button-small" @click="addItinerary">行程を追加</span></div>

        <!-- <photo-select
        v-if="showByPhotoGallery"
        :isShow="showByPhotoGallery"
        id="bodyPG"
        :multiple="false"
        @setPG="setPG"
        @cancelPG="cancelPG">
        </photo-select> -->

    </div>
</template>

<script>
    import PhotoSelect from '../PhotoSelect';
    import ImagePreview from '../ImagePreview';
    // import ImageUploaderMultipleWithPhoto from '../ImageUploaderMultipleWithPhoto';
    import ImageUploaderWithPhoto from './ImageUploaderWithPhoto';
    import TextareaAutoAdjust from './TextareaAutoAdjust';
    export default {
        components: {
            PhotoSelect,
            ImagePreview,
            // ImageUploaderMultipleWithPhoto,
            ImageUploaderWithPhoto,
            TextareaAutoAdjust,
        },
        // フィールド名
        props: {
            itineraries: {
              type: Array,
              default: null,
              required: false,
            },
            // 移動手段タイプの設定
            itinerary_traffic_types: {
              type: Array,
              required: true,
            },
        },
        data: () => ({
          iti: [
            {
            //   new: true,
            //   spot_id: null,
            //   name: null,
            //   image: {
            //     file_name: null,
            //     preview: {
            //       src: null,
            //     },
            //     flag: {
            //       uploaderShowFlag: true,
            //       nowRenderingFlag: false,
            //       nowChangeFlag: false,
            //       isDragging: false,
            //       // ドラッグ中かどうかの判定用
            //       dragCount: 0,
            //     },
            //   },
            //   photo_id: null,
            //   description: null,
            //   traffic_type: null,
            //   order: 1,
            },
          ],
          // アップローダー表示フラグ
          uploaderShowFlag: true,
          // ドラッグ中かどうか
          isDragging: false,
          // ドラッグ中かどうかの判定用
          dragCount: 0,
          // 削除した画像のファイル名が入る
          removedItineraryImages: [],
          // 処理中はtrueにするとボタンが無効になる
          isProcessing: false,
        }),
        created() {
          if(this.itineraries) {
            // console.log('this.itineraries');
            // console.log(this.itineraries);
            this.iti = this.itineraries;
            // this.iti = [];
            // this.itineraries.forEach(itinerary => {
            //   this.iti.push({
            //     new: false,
            //     spot_id: itinerary.spot_id ? itinerary.spot_id : null,
            //     name: itinerary.name,
            //     image: {
            //       file_name: itinerary.image ? itinerary.image : null,
            //       preview: {
            //         src: itinerary.image ? '/storage/images/itinerary_image/m/'+itinerary.image : null,
            //       },
            //       flag: {
            //         uploaderShowFlag: true,
            //         nowRenderingFlag: false,
            //         nowChangeFlag: false,
            //         isDragging: false,
            //         // ドラッグ中かどうかの判定用
            //         dragCount: 0,
            //       },
            //     },
            //     photo_id: itinerary.photo_id ? itinerary.photo_id : null,
            //     description: itinerary.description ? itinerary.description : null,
            //     traffic_type: itinerary.traffic_type ? itinerary.traffic_type : null,
            //     order: itinerary.order,
            //   });
            // });
          } else {
            this.addItinerary();
          }
            // this.elements = this.body;
            // console.log(this.body);
            // // 新規の場合はあらかじめブロックを表示しておく
            // if(this.elements.length == 0) {
            //   this.addElm('header');
            //   this.addElm('paragraph');
            // }
            // this.initNewHeader();
            // this.initNewText();
            // this.title = this.titleprop;
          // console.log('this.itinerary_traffic_types');
          // console.log(this.itinerary_traffic_types);
          // this.config_traffic_type = this.itinerary_traffic_types.map((type, id) => {
          //   return {
          //     id: id,
          //     type: type,
          //   };
          // });
          // console.log('this.config_traffic_type');
          // console.log(this.config_traffic_type);
        },
        computed: {
        },
        methods: {
          sortable() {
            if(this.iti.length >=2) {
              return 'sortableWrap';
            } else {
              return;
            }
          },
          sortable_traffics() {
            if(this.itinerary.traffics.length >=2) {
              return 'sortableWrap';
            } else {
              return;
            }
          },
          addItinerary() {
            // console.log('addItinerary');
            this.iti.push({
              new: true,
              spot_id: null,
              name: null,
              image: {
                file_name: null,
                preview: {
                  src: null,
                },
                flag: {
                  uploaderShowFlag: true,
                  nowRenderingFlag: false,
                  nowChangeFlag: false,
                  isDragging: false,
                  // ドラッグ中かどうかの判定用
                  dragCount: 0,
                },
              },
              description: null,
              traffics: [
                {
                  id: null,
                  itinerary_id: null,
                  type: null,
                  title: '',
                  text: '',
                  order: 1,
                },
              ],
              order: this.iti.length + 1,
            });
          },
          removeItinerary(itinerary) {
              var confirmed = window.confirm('削除すると復元できません。本当に削除しますか？');
              if(confirmed) {
                if(!itinerary.new) {
                  this.$emit('removeCurrentItinerary', itinerary.itinerary_id);
                }
                if(itinerary.image) {
                  this.removeImage(null, itinerary);
                }
                const newItinerary = this.iti.filter(val => val.order !== itinerary.order);
                this.iti = newItinerary;
                // ソート順(order)を振り直す
                this.iti.forEach((val,key) => val.order = key + 1);
                this.$emit('setIteneraries', this.iti);
              }
          },
          updateItineraries() {
            this.$emit('setIteneraries', this.iti);
          },
          addTraffic(traffics) {
            // console.log(traffics);
            traffics.push(
              {
                id: null,
                plan_itinerary_id: null,
                type: null,
                title: "",
                text: "",
                order: traffics.length + 1,
              }
            );
            // console.log(traffics);
            this.updateItineraries();
          },
          setTraffic() {

          },
          removeTraffic(traffics, index) {
            // console.log('++++++++++++removeTraffic');
            let targetIndex = index + 1;
            // console.log(targetIndex);
            traffics.splice(index, 1);
          },

          // setItineraryFile: itinerary => (file, type, queIndex = null) => {
          setItineraryFile(e, itinerary) {
            var file = e.file;
            var type = e.type;
            // var queIndex = e.queIndex;
            // var itinerary = e.itinerary;
            // console.log('++++++++++++++++++++++++');
            // console.log(itinerary);
            // console.log(file);
            // console.log(type);
            // console.log(queIndex);
            if(type == 'upload') {
              if(file) { // ファイルが送られてきたら（=画像が追加された）
                itinerary.image = {
                  type: 'upload',
                  file_name: file.tmpFile,
                  file: file,
                  new: true,
                  rotatable: true,
                  rotate: 0,
                  imageRotateClass: 'rotate-init',
                  preview: {
                    src: file.src,
                    file: file.file,
                  },
                  flag: {
                    uploaderShowFlag: false,
                    nowRenderingFlag: false,
                    nowChangeFlag: false,
                    isDragging: false,
                    // ドラッグ中かどうかの判定用
                    dragCount: 0,
                  },
                };
              }
              //  else { // 引数がnullだったら（=画像が削除された）
              //   // 登録用画像を削除
              //   itinerary.image = null;
              // }
              // if(this.node.file_name) {
              //   // nodeのfileを削除
              //   this.node.file_name = null;
              //   // 古いの消したよフラグ立てる
              //   this.remove_file = true;
              // }
            } else if(type == 'pg') {
              if(file) { // ファイルが送られてきたら（=画像が追加された）
                // 画像データを登録
                itinerary.image = {
                  type: 'pg',
                  file_name: file.file_name,
                  node: file,
                  new: true,
                  rotatable: false,
                  photo_id: file.id,
                  user_id: file.user.id,
                  user_name: file.user.name,
                  preview: {
                    src: '/storage/images/pg/thumb/' + file.file_name,
                  },
                  flag: {
                    uploaderShowFlag: false,
                    nowRenderingFlag: false,
                    nowChangeFlag: false,
                    isDragging: false,
                    // ドラッグ中かどうかの判定用
                    dragCount: 0,
                  },
                };
              }
              //  else { // 引数がnullだったら（=画像が削除された）
              //   // 登録用画像を削除
              //   this.file = null;
              // }
              // if(this.node.file_name) {
              //   // nodeのfileを削除
              //   this.node.file_name = null;
              //   // 古いの消したよフラグ立てる
              //   this.remove_file = true;
              // }
            }
            this.updateItineraries();
          },

          setImageRotate(imageRotate, itinerary) {
              // console.log(imageRotate);
              itinerary.image.rotate = imageRotate.rotate;
              // this.imageRotate = imageRotate;
              // console.log(itinerary.image.rotate);
              this.updateItineraries();
          },
          removeImage(index, itinerary) {
            // console.log('removeSpotImage');
            // console.log(index);
            if(itinerary.image.type == 'upload' && itinerary.image.new === true ) {
              // その編集で追加した画像の場合はtmp画像を消す
              axios.post('/tmp-remove',{
                field: 'itinerary_image',
                images: [itinerary.image.file_name],
              })
              .then((res) => {
                // console.log(res);
                // 指定の画像を消す
                itinerary.image = {
                  file_name: null,
                  preview: {
                    src: null,
                  },
                  flag: {
                    uploaderShowFlag: true,
                    nowRenderingFlag: false,
                    nowChangeFlag: false,
                    isDragging: false,
                    // ドラッグ中かどうかの判定用
                    dragCount: 0,
                  },
                };
                // orderを書き換える（key + 1）
                // itinerary.image.forEach((val, index) => val.order = index + 1);
              })
              .catch((error) => {
                // console.log(error.response);
                alert('サーバーエラー: 画像を削除できませんでした');
                // // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                // if(error.response.data.errors) {
                //   var errors = {};
                //   for(var key in error.response.data.errors) {
                //     errors[key] = error.response.data.errors[key].join('<br>');
                //   }
                //   this.errors = errors;
                // } else {
                //   // バリデーションエラーの場合以外はエラーダイアログを表示
                //   // エラーデータをほりこむ
                //   this.error = error.response;
                //   this.showErrorDialog = true;
                // }
              })
            } else {
              // もともとある画像かフォトギャラリー画像の場合は削除フラグをセットしてデータから消す
              this.removedItineraryImages.push({
                type: itinerary.image.type,
                file_name: itinerary.image.file_name,
                photo_id: itinerary.image.photo_id ? itinerary.image.photo_id : '',
              }),
              this.$emit('removeItineraryImage', this.removedItineraryImages);
              // 指定の画像を消す
              itinerary.image = {
                file_name: null,
                preview: {
                  src: null,
                },
                flag: {
                  uploaderShowFlag: true,
                  nowRenderingFlag: false,
                  nowChangeFlag: false,
                  isDragging: false,
                  // ドラッグ中かどうかの判定用
                  dragCount: 0,
                },
              };
              // orderを書き換える（key + 1）
              // this.planImages.forEach((val, index) => val.order = index + 1);
            }
            this.updateItineraries();
          },

          addQue(itinerary) {
            // console.log('----------------------');
            // console.log(itinerary);
            // console.log(itinerary.image.flag);
            itinerary.image.flag.uploaderShowFlag = false,
            itinerary.image.flag.nowRenderingFlag = true;
          },
          setProcessing(status) {
              this.isProcessing = status;
              // console.log(this.isProcessing);
            },

          // draggable のメソッドたち
          onstart: function (index) {
              // console.log("onstart")
          },
          onadd: function (index) {
              // console.log("onadd")
          },
          onremove: function (index) {
              // console.log("onremove")
          },
          onupdate: function (index) {
              // console.log("onupdate")
          },
          onend: function (index) {
              // console.log("onend")
          },
          onchoose: function (index) {
              // console.log("onchoose")
          },
          onsort() {
            this.iti.forEach((itinerary, index) => itinerary.order = index + 1);
            this.updateItineraries();
          },
          onfilter: function (index) {
              // console.log("onfilter")
          },
          onclone: function (index) {
              // console.log("onclone")
          },
          onmove: function (index) {
              // console.log("onmove")
              return true
          },

          // draggable のメソッドたち
          onstart_t: function (index) {
              // console.log("onstart")
          },
          onadd_t: function (index) {
              // console.log("onadd")
          },
          onremove_t: function (index) {
              // console.log("onremove")
          },
          onupdate_t: function (index) {
              // console.log("onupdate")
          },
          onend_t: function (index) {
              // console.log("onend")
          },
          onchoose_t: function (index) {
              // console.log("onchoose")
          },
          onsort_t() {
            // this.iti.forEach((itinerary, index) => itinerary.order = index + 1);
            // this.updateItineraries();
          },
          onfilter_t: function (index) {
              // console.log("onfilter")
          },
          onclone_t: function (index) {
              // console.log("onclone")
          },
          onmove_t: function (index) {
              // console.log("onmove")
              return true
          },

        updateTraffics() {
          //
        }




        },
    }
</script>

<style lang="scss" scoped>
  .itineraryEditor {
    padding: 9px;
    margin: 0.25em 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    vertical-align: middle;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    .itinerary {
      width: calc(100% + 9px);
      padding-right: 30px;
      margin: 5px 0;
      position: relative;
      .itineraryFields {
        border: 1px solid #ccc;
        background-color: #f0f0f0;
        padding: 14px;
      }
      .draggable-handle {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 100%;
        text-align: center;
        cursor: pointer;
        // background-color: #fafafa;
        // background-color: #ccc;
        span {
          width: 20px;
          height: 2px;
          background-color: #ccc;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -1px);
          text-indent: -9999em;
          &::before {
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #ccc;
            transform: translate(0, -8px);
          }
          &::after {
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #ccc;
            transform: translate(0, 8px);
          }
        }
      }
      .itineraryTrafficEditor {
        width: calc(100% + 9px);
        padding: 9px;
        padding-right: 30px;
        border: 1px solid #ccc;
        background-color: #fff;
        border-radius: 4px;
        .traffic {
          border: 1px solid #ccc;
          background-color: #f0f0f0;
          padding: 14px;
          margin: 5px 0;
          position: relative;
          .draggable-handle {
            right: -30px;
          }
        }
      }
    }
  }
</style>
