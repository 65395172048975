<template>
  <div class="mordal" v-show="isShow">
    <div :class="[slag, type]" class="inner" :style="{maxHeight: mordalHeight + 'px'}">
      <div class="mordal-header">
        <div class="cancelBtn" @click="cancel">戻る</div>
        <div class="mordal-title">
          <h1 class="title">{{ title }}</h1>
          <div :class="confirmBtnDisabledClass" class="confirmBtn btn" @click="confirm">OK</div>
        </div>
      </div>
      <div class="mordal-content">
        <div class="inner">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
        type: {
            type: String,
            required: false,
        },
        slag: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        enableConfirm: {
            type: Boolean,
            default: false,
            required: false,
        },
        isShow: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data: () => ({
      mordalHeight: window.innerHeight,
    }),
    // created() {
    // },
    watch: {
      isShow: function() {
        if(this.isShow) {
          this.bodyScrollTop = window.scrollY;
          // alert(this.bodyScrollTop);
          document.body.style.height = '100vh';
          // document.body.style.overflow = 'hidden';
          document.body.style.width = '100%';
          document.body.style.position = 'fixed';
          document.body.style.top = '-' + this.bodyScrollTop + 'px';
        } else {
          document.body.style.height = '100%';
          // document.body.style.overflow = 'auto';
          document.body.style.position = '';
          document.body.style.top = '';
          // document.body.style.top = '-${this.bodyScrollTop}px';
          // alert(parseInt(this.bodyScrollTop || '0') * -1);
          window.scrollTo(0, parseInt(this.bodyScrollTop || '0'));
        }
      }
    },
    mounted: function () {
      window.addEventListener('resize', this.windowResize);
    },
    beforeDestroy: function () {
      window.removeEventListener('resize', this.windowResize);
    },
    computed: {
      confirmBtnDisabledClass() {
        if(this.enableConfirm) {
          return 'btn-primary';
        } else {
          return 'pure-button-disabled';
        }
      },
    },
    methods: {
      windowResize: function() {
        // resizeのたびに発火
        this.mordalHeight = window.innerHeight;
      },
      // キャンセル（ダイアログを消す）
      cancel() {
        this.$emit('cancel');
      },
      // 決定（選んだのをいっこ上のコンポーネントにほりあげる）
      confirm() {
        this.$emit('confirm');
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
