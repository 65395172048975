<docs>
参考
https://www.youtube.com/watch?v=YmyAEyGL33U
※↑これはmultiple対応
</docs>

<template>
    <div class="multiElementEditor">

      <div class="preview" :class="[previewMinimum ? 'previewMinimum' : '']">

        <draggable v-if="elements" v-model="elements" tag="div" :class="sortable" class=""
        animation="200"
        handle=".draggable-handle"
        delay="30"
        delayOnTouchOnly="true"
        touchStartThreshold="5"
        @move="onmove"
        @start="onstart"
        @add="onadd"
        @remove="onremove"
        @update="onupdate"
        @end="onend"
        @choose="onchoose"
        @sort="onsort"
        @filter="onfilter"
        @clone="onclone">
          <div class="element" v-for="elm in elements" :key="elm.order">

            <div v-if="elm.elementType == 'header'" class="elmHeader elmBlock">
              <div class="draggable-handle"><span></span></div>
              <div v-show="elm.status == 'preview'" class="elmInner previewElm" @click="editElm(elm)">
                <h2 v-if="elm.type == 2" v-text="elm.text"></h2>
                <h3 v-if="elm.type == 3" v-text="elm.text"></h3>
              </div>
              <div v-show="elm.status == 'edit'" class="elmInner elementEditor headerEditor">
                <div class="form_input">
                  <div class="blockLabel"><span>見出し</span>
                    <select class="headerLevelSelector" :value="elm.type" @input="elm.type = $event.target.value" @forcus="headerElmForcus(elm)">
                      <option v-for="option in headerTypeOption" :value="option.value" :key="option.value">{{ option.label }}</option>
                    </select>
                    <span class="btn remove-btn" @click="removeElm(elm)">削除</span>
                  </div>
                  <input type="text" :ref="'elm_input_' + elm.order" name="header_text" :value="elm.text"
                  @input="elm.text = $event.target.value"
                  @keypress.enter.prevent.stop="setCanEnter"
                  @keyup.enter.prevent.stop="updateInputElmWithEnterKey(elm)"
                  required autocomplete="name">
                  <span class="btn confirm-btn btn-primary" @click="updateElm(elm)">OK</span>
                </div>
              </div>
            </div>

            <div v-else-if="elm.elementType == 'paragraph'" class="elmParagraph elmBlock">
              <div class="draggable-handle"><span></span></div>
              <div v-show="elm.status == 'preview'" class="elmInner previewElm" v-html="previewParagraph(elm.text)" @click="editElm(elm)"></div>
              <div v-show="elm.status == 'edit'" class="elmInner elementEditor paragraphEditor">
                <div class="form_input">
                  <div class="blockLabel"><span>文章</span><span class="btn remove-btn" @click="removeElm(elm)">削除</span></div>
                  <!--
                  <textarea
                    :ref="'elm_input_' + elm.order"
                    name="text_text"
                    :value="elm.text"
                    @input="elm.text = $event.target.value;textAreaHeightSet($event,this);"
                    @change="textAreaHeightSet($event,this)"
                    @focus="textAreaHeightSet($event,this)"
                    @keydown.enter="updateTextareaElmWithEnterKey($event,elm)"
                    required autocomplete="name">
                  </textarea>
                  -->
                  <textarea-auto-adjust
                    :ref="'elm_input_' + elm.order"
                    :refVal="'elm_input_' + elm.order"
                    :isShow="elm.status == 'edit' ? true : false"
                    name="text_text"
                    v-model="elm.text"
                    required="required"
                    autocomplete="name"
                    @onEnter="updateElm(elm)"
                  ></textarea-auto-adjust>
                  <span class="btn confirm-btn btn-primary" @click="updateElm(elm)">OK</span>
                </div>
              </div>
            </div>

            <div v-else-if="elm.elementType == 'list'" class="elmList elmBlock">
              <div class="draggable-handle"><span></span></div>
              <div v-show="elm.status == 'preview'" class="elmInner previewElm" @click="editElm(elm)">
                <ul>
                  <li v-for="li in previewList(elm.text)" :key="li.key" v-text="li.text"></li>
                </ul>
              </div>
              <div v-show="elm.status == 'edit'" class="elmInner elementEditor listEditor">
                <div class="form_input">
                  <div class="blockLabel"><span>リスト</span><span class="btn remove-btn" @click="removeElm(elm)">削除</span></div>
                  <!-- <textarea class="textarea" :ref="'elm_input_' + elm.order" name="text_text" :value="elm.text"
                  @input="elm.text = $event.target.value;textAreaHeightSet($event,this);"
                  @change="textAreaHeightSet($event,this)"
                  @focus="textAreaHeightSet($event,this)"
                  @keydown.enter="updateTextareaElmWithEnterKey($event,elm)"
                  required autocomplete="name"></textarea> -->
                  <textarea-auto-adjust
                    :refVal="'elm_input_' + elm.order"
                    :isShow="elm.status == 'edit' ? true : false"
                    name="text_text"
                    v-model="elm.text"
                    required="required"
                    autocomplete="name"
                    @onEnter="updateElm(elm)"
                  ></textarea-auto-adjust>
                  <span class="btn confirm-btn btn-primary" @click="updateElm(elm)">OK</span>
                </div>
              </div>
            </div>

            <div v-else-if="elm.elementType == 'image'" class="elmList elmBlock">
              <div class="draggable-handle"><span></span></div>
              <div v-show="elm.status == 'preview'" class="elmInner previewElm" @click="editElm(elm)">
                <div class="images-preview" :class="'rotate-' + elm.image.rotate" v-if="elm.image.file_preview_src">
                  <img class="uploadImagePreview" :src="elm.image.file_preview_src" :style="elm.image.prevStyle" :alt="elm.image.fileName">
                </div>
                <div class="images-preview" v-if="elm.image.currentFile">
                  <img class="uploadImagePreview" :src="elm.image.currentFile.path" alt="現在の写真">
                </div>
              </div>
              <div v-show="elm.status == 'edit'" class="elmInner elementEditor imageEditor">
                <div class="form_input">
                  <div class="blockLabel"><span>画像</span>
                    <!-- <span class="btn" v-show="(elm.image.file_preview_src || elm.image.currentFile) && !elm.flag.nowChangeFlag" @click="doChange(elm)">変更</span> -->
                    <!-- <span class="btn" v-show="(elm.image.file_preview_src || elm.image.currentFile) && elm.flag.nowChangeFlag" @click="cancelChange(elm)">変更をキャンセル</span> -->
                    <span class="btn remove-btn" @click="removeElm(elm)">削除</span>
                  </div>
                  <div class="imageFieldComponent">
                    <div class="">
                      <div class="inner">
                        <div class="uploader" v-show="elm.flag.uploaderShowFlag && !elm.flag.nowRenderingFlag"
                        @dragenter="onDragEnter($event, elm)"
                        @dragleave="onDragLeave($event, elm)"
                        @dragover.prevent
                        @drop="onDrop($event, elm)"
                        :class="{ dragging: elm.flag.isDragging }">

                          <div class="inner">
                            <p class="message">ここにファイルをドロップ<span>または</span></p>
                            <div class="file-input">
                              <label :for="'elm_input_' + elm.order" class="btn btn-primary">ファイルを選択</label>
                              <input type="file" :id="'elm_input_' + elm.order" :name="'body_image['+elm.order+'][image]'" :ref="'elm_input_' + elm.order" @change="onInputChange($event, elm)">
                            </div>
                            <p class="message"><span>または</span></p>
                            <div class="file-input">
                              <span @click="showPG(elm)" class="btn btn-primary">京都写真ギャラリーから選択</span>
                            </div>
                          </div>

                        </div>
                        <div class="inner" v-show="elm.flag.nowRenderingFlag">
                          <span class="loader icon">Loading...</span>
                          <span>プレビュー生成中。<br>しばらくお待ちください…</span>
                        </div>
                        <image-preview v-show="elm.image.preview && !elm.flag.uploaderShowFlag && !elm.flag.nowRenderingFlag"
                        :previewFile="elm.image.preview"
                        :rotatable="elm.type != 'pg'"
                        @setImageRotate="setRotateElmImage($event, elm)"
                        @remove="removeElmImage($event, elm)"
                        >
                        </image-preview>
                        <!-- <div class="images-preview" v-if="elm.image.file_preview_src && !elm.flag.uploaderShowFlag && !elm.flag.nowRenderingFlag">
                          <img class="uploadImagePreview" :src="elm.image.file_preview_src" :alt="elm.image.fileName">
                          <div class="details" v-if="elm.image.localFileName">
                            <span class="name" v-text="elm.image.localFileName"></span>
                          </div> -->
                          <div>
                            <div class="blockLabel"><span>画像のキャプション</span></div>
                            <input type="text" :ref="'elm_input_' + elm.order" name="element_image_caption" :value="elm.caption"
                            @input="elm.caption = $event.target.value"
                            @keypress.enter.prevent.stop="setCanEnter"
                            @keyup.enter.prevent.stop="updateInputElmWithEnterKey(elm)"
                            autocomplete="name">
                          </div>
                        <!-- </div>
                        <div class="dialog-content images-preview" v-if="elm.image.currentFile && !elm.flag.uploaderShowFlag">
                          <img class="uploadImagePreview" :src="elm.image.currentFile.path" alt="現在の写真">
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <button v-bind:disabled="isProcessing" type="button" class="btn confirm-btn btn-primary" @click="updateElm(elm)">OK</button>
                </div>
              </div>
            </div>

          </div>

        </draggable>

        <photo-select
        v-show="showByPhotoGallery"
        :isShow="showByPhotoGallery"
        id="bodyPG"
        :multiple="false"
        @setPG="setPG"
        @cancelPG="cancelPG">
        </photo-select>

        <div v-if="elements.length == 0" class="emptyMessage">
          <p>下のボタンをクリックして必要なブロックを追加してください。</p>
        </div>

        <div class="controll_panel">
          <div class="addElementsBtns">
            <div class="btns">
              <span class="btn button-small" @click="addElm('header')">見出し</span>
              <span class="btn button-small" @click="addElm('paragraph')">文章</span>



              <span class="btn button-small" @click="addElm('list')">リスト</span>
              <span class="btn button-small" @click="addElm('image')">画像</span>
            </div>
          </div>
          <div class="addElementsBtns" v-if="nowEdit.length >= 1">
            <div class="btns">
              <button v-bind:disabled="isProcessing" type="button" class="btn button-small" @click="allConfirm()">全てOKする</button>
            </div>
          </div>
          <div class="addElementsBtns">
            <div class="btns">
              <span v-if="!previewMinimum && elements.length >=2" class="btn button-small" @click="previewMinimum = true">縮小表示</span>
              <span v-if="previewMinimum && elements.length >=2" class="btn button-small" @click="previewMinimum = false">通常表示</span>
            </div>
          </div>
          <!--
          <div class="addElementsBtns">
            <div class="btns">
              <span v-if="!sortMode && elements.length >=2" class="btn" @click="sortModeChange(true)">ブロックを並べ替える</span>
              <span v-if="sortMode && elements.length >=2" class="btn" @click="sortModeChange(false)">並べ替えを終了</span>
            </div>
          </div>
          -->
        </div>

      </div>

    </div>
</template>

<script>
    import PhotoSelect from './PhotoSelect';
    import ImagePreview from './ImagePreview';
    import TextareaAutoAdjust from './form_element/TextareaAutoAdjust';
    export default {
        components: {
            PhotoSelect,
            ImagePreview,
            TextareaAutoAdjust,
        },
        // フィールド名
        props: {
            body: {
              type: Array,
              default: null,
              required: false,
            }
        },
        data: () => ({
            elements: [],
            elementFiles: {},
            elementFilesCount: 1,
            headerTypeOption: [
              {
                value: 2,
                label: 'レベル1',
              },
              {
                value: 3,
                label: 'レベル2',
              },
            ],
            sortMode: false,
            // 写真のデータ
            photo_images: [],
            // 画像を追加してプレビューが描画されるまでのキュー数
            showPreviewQue: 0,
            // 元の写真を削除したらtrue
            remove_file: false,
            // 画像アップローダー関係
            // アップロードファイルオブジェクト
            files: [],
            showByPhotoGallery: false,
            snowPgSelectElm: null,
            byPhotoGalleryStr: null,
            byPhotoGallery: [],
            maxWidth: 200,
            // 並べ替えしやすいようにエレメントを小さくする
            previewMinimum: false,
            // 写真ギャラリーから選ぶのモーダルが表示されてる間後ろのスクロールを止めるためbodyのスクロール量をバックアップ
            bodyScrollTop: 0,
            // 処理中はtrueにするとボタンが無効になる
            isProcessing: false,
        }),
        created() {
            this.elements = this.body;
            // console.log(this.body);
            // // 新規の場合はあらかじめブロックを表示しておく
            // if(this.elements.length == 0) {
            //   this.addElm('header');
            //   this.addElm('paragraph');
            // }
            // this.initNewHeader();
            // this.initNewText();
            // this.title = this.titleprop;
        },
        computed: {
            sortable: function() {
              // if(this.sortMode) {
              //   return 'sortable';
              // } else {
              //   return '';
              // }
              if(this.elements.length >=2) {
                return 'sortableWrap';
              } else {
                return;
              }
            },
            nowEdit() {
              let edits = this.elements.filter(elm => {
                return elm.status === 'edit';
              });
              return edits;
            }
        },
        // events: {
        // },
        // directives: {
        //   clickOutside: {
        //     bind: function (el, binding, vnode) {
        //       console.log('hehehe');
        //       console.log(el);
        //       console.log(binding);
        //       console.log(vnode);
        //       el.clickOutsideEvent = function (event) {
        //         // here I check that click was outside the el and his childrens
        //         if (!(el == event.target || el.contains(event.target))) {
        //           // and if it did, call method provided in attribute value
        //           vnode.context[binding.expression](event);
        //         }
        //       };
        //       document.body.addEventListener('click', el.clickOutsideEvent)
        //     },
        //     unbind: function (el) {
        //       document.body.removeEventListener('click', el.clickOutsideEvent)
        //     },
        //   },
        // },
        methods: {
          test() {
            // console.log('hahahahahaahahhaha');
          },
            //
            setRotateElmImage(imageRotate, elm) {
              // console.log(imageRotate);
              elm.image.rotate = imageRotate.rotate;
              // console.log(imageRotate);
              var style = {};
              if(imageRotate.rotate == 1) {
                style = {
                  transform: 'rotate(90deg) scale(' + imageRotate.ratio + ')',
                  transformOrigin: 'left top',
                  marginLeft: 100 + '%',
                  marginBottom: ((imageRotate.ratio * imageRotate.ratio  - 1) / imageRotate.ratio) * 100 + '%',
                };
              } else if(imageRotate.rotate == 2) {
                style = {
                  transform: 'rotate(180deg)',
                };
              } else if(imageRotate.rotate == 3) {
                style = {
                  transform: 'rotate(270deg) scale(' + imageRotate.ratio + ')',
                  transformOrigin: 'right top',
                  marginLeft: - 200 + '%',
                  marginBottom: ((imageRotate.ratio * imageRotate.ratio  - 1) / imageRotate.ratio) * 100 + '%',
                };
              }
              elm.image.prevStyle = style;
            },
            removeElmImage(imageRotate, elm) {
              // console.log(imageRotate);
              // console.log(elm);
              // console.log(this.elements);
              elm.image = {};
              elm.flag.uploaderShowFlag = true;
              elm.type = null;
              // this.imageRotate = imageRotate;
              // this.$emit('setImageRotate', imageRotate);
            },




            // 新規エレメントを追加
            addElm(type, order = null) {
                if(order === null) order = this.elements.length + 1;
                if(type == 'header') {
                  this.elements.push({
                    elementType: 'header',
                    new: true,
                    type: 2,
                    text: '',
                    order: order,
                    status: 'edit',
                  });
                } else if(type == 'paragraph') {
                  this.elements.push({
                    elementType: 'paragraph',
                    new: true,
                    type: 'p',
                    text: '',
                    order: order,
                    status: 'edit',
                  });
                } else if(type == 'list') {
                  this.elements.push({
                    elementType: 'list',
                    new: true,
                    type: 'ul',
                    text: '',
                    order: order,
                    status: 'edit',
                  });
                } else if(type == 'image') {
                  this.elements.push({
                    elementType: 'image',
                    new: true,
                    image: {},
                    caption: '',
                    flag: {
                      uploaderShowFlag: true,
                      nowRenderingFlag: false,
                      nowChangeFlag: false,
                      isDragging: false,
                      // ドラッグ中かどうかの判定用
                      dragCount: 0,
                    },
                    order: order,
                    status: 'edit',
                  });
              }
              // 追加したフィールドにフォカスを当てる
              this.$nextTick(function () {
                var ref = 'elm_input_' + order;
                // console.log(ref);
                // console.log(this.$refs[ref]);
                if(this.$refs[ref][0].nodeName == 'INPUT' || this.$refs[ref][0].nodeName == 'TEXTAREA') {
                  // console.log('INPUT!');
                  // console.log(this.$refs[ref][0].nodeName);
                  this.$refs[ref][0].focus();
                } else {
                  // console.log('NOT INPUT!');
                  // console.log(this.$refs[ref][0].$refs[ref]);
                  this.$refs[ref][0].$refs[ref].focus();
                }
                // this.$refs[ref][0].focus();
              });
            },
            // エレメントを編集モードにする
            editElm(elm) {
                elm.status = 'edit';
                this.$nextTick(function () {
                  var elmOrder = elm.order;
                  var ref = 'elm_input_' + elm.order;
                  // console.log('ref');
                  // console.log(ref);
                  // console.log(this.$refs);
                  // console.log(this.$refs[ref]);
                  // console.log(this.$refs[ref]);
                  if(this.$refs[ref][0].nodeName == 'INPUT' || this.$refs[ref][0].nodeName == 'TEXTAREA') {
                    // console.log('INPUT!');
                    // console.log(this.$refs[ref][0].nodeName);
                    this.$refs[ref][0].focus();
                  } else {
                    // console.log('NOT INPUT!');
                    // console.log(this.$refs[ref][0].$refs[ref]);
                    this.$refs[ref][0].$refs[ref].focus();
                  }
                  // this.$refs[ref][0].focus();
                });
            },
            // 並べ替えモードオンオフ（現在常に並べ替えモードのため使っていない）
            sortModeChange(bool) {
                this.sortMode = bool;
            },
            headerElmFocus(elm) {
                elm.forcus = true;
                elm.status = 'edit';
            },
            headerElmBlur(elm) {
                elm.forcus = false;
                elm.status = 'preview';
            },
            // 段落エレメントのプレビュー様にフォーマットしたものを返す
            previewParagraph(val) {
                if(val) {
                    var previewText = val.trim();
                    previewText = (previewText + '').replace(/&/g,'&amp;').replace(/"/g,'&quot;').replace(/'/g,'&#039;').replace(/</g,'&lt;').replace(/>/g,'&gt;');
                    previewText = previewText.replace(/[\r\n|\r|\n]{2,}/g, '</p><p>');
                    previewText = previewText.replace(/\r\n|\r|\n/g, '<br />');
                    return '<p>' + previewText + '</p>';
                } else {
                  return '';
                }
            },
            // リストエレメントのプレビュー様にフォーマットしたものを返す
            previewList(val) {
                if(val) {
                  var previewText = val.trim();
                  var textArray = previewText.split(/\r\n|\r|\n/);
                  var previewText = [];
                  textArray.map((val, index) => {
                    var trimVal = val.trim();
                    if(trimVal) {
                      previewText.push({
                        key: index,
                        text: trimVal,
                      });
                    }
                  });
                  return previewText;
                } else {
                  return '';
                }
            },
            // 変換確定時のEnterでもEnter押下が発火してしまうのでEnterのkeypressイベント（変換中は発火しない）で一旦フラグを立ててkeyupで発火させるようにフラグを立てる
            setCanEnter(e) {
                this.canEnter = true;
            },
            // インプットフォーカス中にenterで確定
            updateInputElmWithEnterKey(elm) {
                // 変換確定時のEnterでもEnter押下が発火してしまうのでEnterのkeypressイベント（変換中は発火しない）でtrueになるフラグが立ってる場合のみ発火させる
                if(this.canEnter) {
                  this.updateElm(elm);
                }
            },
            // テキストエリアフォーカス中にctrl+enterで確定
            updateTextareaElmWithEnterKey(e,elm) {
                if(e.ctrlKey || e.metaKey) {
                  e.preventDefault(); // デフォルトの動作をキャンセル
                  e.stopPropagation(); // 現在のイベントの親要素への伝播
                  this.updateElm(elm);
                }
            },
            // 全ての編集中エレメントを確定
            allConfirm() {
                this.nowEdit.forEach(elm => this.updateElm(elm));
            },
            // エレメントの内容を確定
            updateElm(elm) {
                this.canEnter = false;
                if(elm.elementType == 'header') {
                  var elmText = elm.text.trim();
                  if(elmText) {
                    elm.status = 'preview';
                  } else {
                    if(!elm.new) this.$emit('removeCurrentElement', elm);
                    const newElements = this.elements.filter(val => val.order !== elm.order);
                    this.elements = newElements;
                    // ソート順(order)を振り直す
                    this.elements.forEach((val,key) => val.order = key + 1);
                  }
                } else if(elm.elementType == 'paragraph') {
                  var elmText = elm.text.trim();
                  if(elmText) {
                    elm.status = 'preview';
                  } else {
                    if(!elm.new) this.$emit('removeCurrentElement', elm);
                    const newElements = this.elements.filter(val => val.order !== elm.order);
                    this.elements = newElements;
                    // ソート順(order)を振り直す
                    this.elements.forEach((val,key) => val.order = key + 1);
                  }
                } else if(elm.elementType == 'list') {
                  var elmText = elm.text.trim();
                  if(elmText) {
                    elm.status = 'preview';
                  } else {
                    if(!elm.new) this.$emit('removeCurrentElement', elm);
                    const newElements = this.elements.filter(val => val.order !== elm.order);
                    this.elements = newElements;
                    // ソート順(order)を振り直す
                    this.elements.forEach((val,key) => val.order = key + 1);
                  }
                } else if(elm.elementType == 'image') {
                  if(elm.image.file_preview_src) {
                    elm.status = 'preview';
                  } else {
                    if(!elm.new) this.$emit('removeCurrentElement', elm);
                    const newElements = this.elements.filter(val => val.order !== elm.order);
                    this.elements = newElements;
                    // ソート順(order)を振り直す
                    this.elements.forEach((val,key) => val.order = key + 1);
                  }
                }
                this.$emit('setBody', this.elements);
            },
            // エレメントを削除
            removeElm(elm) {
                var confirmed = window.confirm('削除すると復元できません。本当に削除しますか？');
                if(confirmed) {
                  if(!elm.new) this.$emit('removeCurrentElement', elm);
                  const newElements = this.elements.filter(val => val.order !== elm.order);
                  this.elements = newElements;
                  // ソート順(order)を振り直す
                  this.elements.forEach((val,key) => val.order = key + 1);
                  this.$emit('setBody', this.elements);
                }
            },
            resetElementsOrder() {
            },
            // テキストエリアの高さセット
            textAreaHeightSet: function(e, t){
                var argObj = e.target
                // 一旦テキストエリアを小さくしてスクロールバー（縦の長さ)を取得
                argObj.style.height = "1.4em";
                var wSclollHeight = parseInt(argObj.scrollHeight);
                // 最低50pxにする
                if(wSclollHeight < 50){
                  wSclollHeight = 50;
                }
                // テキストエリアの高さを設定する
                argObj.style.height = wSclollHeight + 2 + "px";
            },
            // 画像エレメントで画像の変更ボタンを押したとき
            doChange(elm) {
                // 変更中フラグtrue
                elm.flag.nowChangeFlag = true;
                // アップローダー表示
                elm.flag.uploaderShowFlag = true;
            },
            // 画像エレメントで画像の変更キャンセルボタンを押したとき
            cancelChange(elm) {
                // 変更中フラグfalse
                elm.flag.nowChangeFlag = false;
                // アップローダーを非表示
                elm.flag.uploaderShowFlag = false;
            },
            // // 写真ギャラリーから選ぶのモーダルが表示されてる間後ろのスクロールを止める
            // bodyFix() {
            //   if(this.showByPhotoGallery) {
            //     this.bodyScrollTop = window.scrollY;
            //     // alert(this.bodyScrollTop);
            //     document.body.style.height = '100vh';
            //     // document.body.style.overflow = 'hidden';
            //     document.body.style.width = '100%';
            //     document.body.style.position = 'fixed';
            //     document.body.style.top = '-' + this.bodyScrollTop + 'px';
            //   } else {
            //     document.body.style.height = '100%';
            //     // document.body.style.overflow = 'auto';
            //     document.body.style.position = '';
            //     document.body.style.top = '';
            //     // document.body.style.top = '-${this.bodyScrollTop}px';
            //     // alert(parseInt(this.bodyScrollTop || '0') * -1);
            //     window.scrollTo(0, parseInt(this.bodyScrollTop || '0'));
            //   }
            // },
            // 画像エレメントで写真ギャラリーから選ぶダイアログを表示
            showPG(elm) {
                this.showByPhotoGallery = true;
                this.nowPgSelectElm = elm;
                // this.bodyFix();
            },
            // 画像エレメントで写真ギャラリーから選ぶダイアログでキャンセル押したとき（非表示にする）
            cancelPG() {
                this.showByPhotoGallery = false;
                this.nowPgSelectElm = null;
                // this.bodyFix();
            },
            // 画像エレメントで写真ギャラリーから写真を選んで決定押したとき
            setPG(selected) {
                // いっこ上のコンポーネントに選択済みの写真のIDの配列をほりなげる
                // this.$emit('setFile',selected,'pg');
                // ファイルをセットする
                selected.forEach(file=> this.setFile(this.nowPgSelectElm, file, 'pg'));
                // 写真ギャラリーから選ぶダイアログを消す
                this.showByPhotoGallery = false;
                this.nowPgSelectElm = null;
                // this.bodyFix();
            },
            // 画像エレメントでドラッグがdrop領域に入った時
            onDragEnter(e, elm) {
                e.preventDefault();
                elm.flag.dragCount++;
                elm.flag.isDragging = true;
            },
            // 画像エレメントでドラッグがdrop領域を出た時
            onDragLeave(e, elm) {
                e.preventDefault();
                elm.flag.dragCount--;
                // console.log(elm.flag.dragCount);
                if(elm.flag.dragCount <= 0) {
                  elm.flag.isDragging = false;
                }
            },
            // 画像エレメントでファイルフィールドのchangeイベント
            onInputChange(e, elm) {
                const files = e.target.files;
                Array.from(files).forEach(file => this.addImage(file, elm));
            },
            // 画像エレメントでdrop領域内でdropした時
            onDrop(e, elm) {
                e.preventDefault(); // デフォルトの動作（この場合はファイルをブラウザで開く）をキャンセル
                e.stopPropagation(); // 現在のイベントの親要素への伝播（propagation）止める
                elm.flag.dragCount = 0;
                elm.flag.isDragging = false;
                // ドロップされたファイルたちを定数に入れる
                const files = e.dataTransfer.files;
                // ドロップされたファイルたちを順番に処理する（下の関数内の処理）
                Array.from(files).forEach(file => this.addImage(file, elm, e));
            },
            // ファイルのデータを準備したりプレビューを作ったりする
            addImage(file, elm, e) {
                this.isProcessing = true;
                this.$emit('setProcessing',true);
                console.log('addImage');
                // ドロップされたものが画像かどうかチェック
                if(!file.type.match('image.[jpeg|png|gif]')) {
                  alert(`${file.name} は非対応のファイル形式です`);
                  this.isProcessing = false;
                  this.$emit('setProcessing',false);
                  return;
                }
                // ファイルサイズをチェック
                if(file.size > 30000000) {
                  alert(`${file.name} は容量が大きすぎるためアップロードできません。ファイル容量の上限は30MBです。`);
                  this.isProcessing = false;
                  this.$emit('setProcessing',false);
                  return;
                }
                // ファイルをセットする
                this.setFile(elm, file, 'upload', e);
            },
            // ファイルを追加（アップローダーコンポーネントで画像追加したらこれが発火 ※引数にファイルが入ってくる）
            // または ファイルを削除（アップローダーコンポーネントで画像削除したらこれが発火 ※引数はnull）
            setFile(elm, file, type, e) {
                // this.isProcessing = true;
                // this.$emit('setFile',true);
                console.log('addImage');
                // プレビュー表示処理中フラグをfalse
                elm.flag.nowRenderingFlag = true;
                // アップローダー非表示
                elm.flag.uploaderShowFlag = false;
                if(type == 'upload') {
                  if(file) { // ファイルが送られてきたら（=画像が追加された）
                    // キューのカウントをいっこ増やす
                    this.showPreviewQue++;
                    // プレビュー画像アップしてゲットしてセットする
                    this.uploadTmp(file, elm);
                  }
                } else if(type == 'pg') {
                  if(file) { // ファイルが送られてきたら（=画像が追加された）
                    // 画像データを登録
                    elm.type = 'pg';
                    elm.image = {
                      url: '/storage/images/pg/sample/'+file.file_name,
                      // node: file,
                      nodeId: file.id,
                      file: {
                        name: file.title,
                      },
                      file_preview_src: '/storage/images/pg/sample/'+file.file_name,
                      preview: {
                        src: '/storage/images/pg/sample/'+file.file_name,
                      },
                      author: {
                        name: file.user.name,
                        // user_name: file.user.user_name,
                        // icon_image: file.user.icon_image,
                      },
                    };
                    // データの変更後に Vue.js の DOM 更新の完了を待つには、データが変更された直後に Vue.nextTick(callback) を使用することができます。そのコールバックは DOM が更新された後に呼ばれます。
                    this.$nextTick(function () {
                      // DOMの更新が終わったら
                      // キューのカウントをいっこ減らす
                      this.showPreviewQue--;
                      // DOMの更新が終わったら
                      // プレビュー表示処理中フラグをfalse
                      elm.flag.nowRenderingFlag = false;
                      // アップローダー非表示
                      elm.flag.uploaderShowFlag = false;
                      // 変更中フラグfalse
                      elm.flag.nowChangeFlag = false;
                    });
                  }
                }
                // this.isProcessing = false;
                // this.$emit('setProcessing',false);
                // console.log(this.elements)
            },
            // プレビュー用に画像をアップロード
            uploadTmp(file, elm) {
                // フォームで送信するFormDataインスタンスつくる
                const formData = new FormData();
                // 送信するフィールドのデータを登録する
                formData.append('field', 'element_image');
                // 元の写真を削除した場合にtrueを送る（コントローラーでファイルを消すため）
                formData.append('images[0]', file, file.name);
                // アップロード
                axios.post('/tmp-upload',formData)
                .then((res)=>{
                  var tmpFile = res.data.uploadFiless[0];
                  elm.type = 'uploadImage';
                  elm.image = {
                    uploadFile: tmpFile.filename,
                    file_preview_src: '/storage/images/tmp/prv/'+tmpFile.filename,
                    preview: {
                      src: '/storage/images/tmp/prv/'+tmpFile.filename,
                      file: {
                        name: file.name,
                        size: file.size,
                      }
                    },
                    localFileName: file.name,
                  };
                  // キューのカウントをいっこ減らす
                  this.showPreviewQue--;
                  // プレビュー表示処理中フラグをfalse
                  elm.flag.nowRenderingFlag = false;
                  // 変更中フラグfalse
                  elm.flag.nowChangeFlag = false;
                  this.isProcessing = false;
                  this.$emit('setProcessing',false);
                })
                .catch((error) => {
                  // console.log(error.response);
                  // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                  if(error.response.data.errors) {
                    var errors = {};
                    for(var key in error.response.data.errors) {
                      errors[key] = error.response.data.errors[key].join('<br>');
                    }
                    this.errors = errors;
                  } else {
                    // バリデーションエラーの場合以外はエラーダイアログを表示
                    // エラーデータをほりこむ
                    this.error = error.response;
                    this.showErrorDialog = true;
                  }
                  this.isProcessing = false;
                  this.$emit('setProcessing',false);
                })
            },
            // draggable のメソッドたち
            onstart: function (index) {
                // console.log("onstart")
            },
            onadd: function (index) {
                // console.log("onadd")
            },
            onremove: function (index) {
                // console.log("onremove")
            },
            onupdate: function (index) {
                // console.log("onupdate")
            },
            onend: function (index) {
                // console.log("onend")
            },
            onchoose: function (index) {
                // console.log("onchoose")
            },
            onsort: function (index) {
                // 画面上のソートが終わったらorderを書き換える（key + 1）
                this.elements.forEach((val,key) => val.order = key + 1);
                this.$emit('setBody', this.elements);
            },
            onfilter: function (index) {
                // console.log("onfilter")
            },
            onclone: function (index) {
                // console.log("onclone")
            },
            onmove: function (index) {
                // console.log("onmove")
                return true
            },

        }
    }
</script>

<style lang="scss" scoped>
.multiElementEditor{
  .preview {
    padding: 9px;
    margin: 0.25em 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    vertical-align: middle;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    .emptyMessage {
      padding: 20px 0;
      p {
        text-align: center;
      }
    }
    .controll_panel {
      width: 100%;
      margin: 0;
      background-color: #fafafa;
      border: 1px solid #ccc;
      border-radius: 3px;
      padding: 9px 4px;
      display: flex;
      .addElementsBtns {
        .btns {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          margin: 0 5px;
          .btn {
            margin: 2px;
            border: 1px solid #ccc;
          }
        }
      }
    }
    .element {
      .elmBlock {
        // padding-left: 50px;
        // margin: 5px 0;
        // position: relative;
        margin: 7px 0;
        .draggable-handle {
          display: none;
        }
        .elementEditor {
          border: 1px solid #ccc;
          background-color: #f0f0f0;
          // box-shadow:0px 0px 15px -8px rgba(0,0,0,0.5);
          padding: 14px;
          // width: calc(100% + 16px);
          // margin: 0 0 0 -8px;
          // input {
          //   // margin: 0;
          // }
        }
        .form_input {
          .blockLabel {
            margin-bottom: 3px;
            display: flex;
            align-items: center;
            .btn {
              border: 1px solid #ccc;
              padding: .2em .8em;
              margin: 0 8px;
            }
          }
          .confirm-btn {
            margin-top: 3px;
          }
        }
        .headerEditor .form_input {
          .headerLevelSelector {
            width: 7em;
            margin: 0 8px;
          }
          input {
            width: 100%;
            max-width: 100%;
          }
        }
        .previewElm {
          padding: 0 14px;
          background-color: #fafafa;
          border: 1px solid #f0f0f0;
        }
      }
    }
    .sortableWrap {
      .elmBlock {
        width: calc(100% + 9px);
        padding-right: 30px;
        margin: 5px 0;
        position: relative;
        .draggable-handle {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 30px;
          height: 100%;
          text-align: center;
          cursor: pointer;
          // background-color: #fafafa;
          // background-color: #ccc;
          span {
            width: 20px;
            height: 2px;
            background-color: #ccc;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -1px);
            text-indent: -9999em;
            &::before {
              content: '';
              display: block;
              width: 20px;
              height: 2px;
              position: absolute;
              top: 0;
              left: 0;
              background-color: #ccc;
              transform: translate(0, -8px);
            }
            &::after {
              content: '';
              display: block;
              width: 20px;
              height: 2px;
              position: absolute;
              top: 0;
              left: 0;
              background-color: #ccc;
              transform: translate(0, 8px);
            }
          }
        }
      }
      // .sortable-chosen {
      //   // border: 5px solid #900;
      // }
      .sortable-ghost .elmInner {
        // opacity: .4;
        border: 3px solid #900;
        box-shadow:0px 0px 15px -8px rgba(0,0,0,0.5);
        opacity: 0.8;
      }
      .sortable-drag .elmInner {
        opacity: 0.4;
      }
    }

    .images-preview {
      background-color: #222;
      text-align: center;
      .uploadImagePreview {
        max-height: 75vh;
        margin: 0 auto;
      }
    }
  }
  // .sortable {
  //   //
  // }
  .previewMinimum .elmBlock{
    textarea {
      overflow: hidden;
      max-height: 180px;
    }
    .previewElm {
      overflow: hidden;
      max-height: 180px;
    }
    img {
      overflow: hidden;
      // max-height: 100px;
      width: auto;
      max-height: 180px!important;
      // max-width: 300px;
      // height: auto;
    }
    // transform: scale(0.5);
  }
  .imageEditor {
    .uploader {
      width: 100%;
      height: 200px;
      margin: 0;
      padding: 2rem 1.5rem;
      text-align: center;
      border: 3px dashed #CCC;
      position: relative;
      background-color: #eee;
      &.dragging {
        background-color: rgba(0,120,230,.5);
        * {
          opacity: .5;
        }
      }
      &> .inner {
        position: absolute;
        margin: 0 auto;
        top: 45%;
        left: 50%;
        transform: translateY(-45%) translateX(-50%);
        -webkit-transform: translateY(-45%) translateX(-50%);
      }
      .message {
        font-size: 1.7rem;
        font-weight: bold;
        margin: 0;
        line-height: 1;
        span {
          display: block;
          margin-top: -.3rem;
          font-size: 1.3rem;
          font-weight: normal;
        }
      }
      .file-input {
        width: 100%;
        text-align: center;
        margin: auto;
        position: relative;
        input {
          position: absolute;
          left: 0;
          top: 0;
          width: 0;
          height: 0;
          opacity: 0;
        }
      }
    }
    .images-preview {
      width: auto;
      height: auto;
      max-width: 300px;
      max-height: 90%;
      // margin: 0 auto;
      text-align: left;
      .img-wrapper {
        text-align: center;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        border: 1px solid #fff;
      }
      .uploadImagePreview {
        width: auto;
        height: auto;
        max-width: 90%;
        max-height: 90%;
        margin: 0 auto;
        object-fit: contain;
        border: 1px solid #fff;
      }
      .details {
        margin-top: .7rem;
        span {
          margin: 0 .7rem;
        }
      }
    }
  }
}
</style>
