<template>
  <div class="dialog dialog-t" v-show="isShow">
    <div :class="[slag, type]" class="inner" :style="{maxHeight: mordalHeight + 'px'}">
      <div class="dialog-header">
        {{ title }}
      </div>
      <div class="dialog-content">
        <div class="inner">
          <div>{{ body }}</div>
          <div class="buttons">
            <div class="confirmBtn btn" @click="cancel">{{ cancelText }}</div>
            <div class="confirmBtn btn" @click="confirm">{{ okText }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
        type: {
            type: String,
            required: false,
        },
        slag: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        body: {
            type: String,
            required: true,
        },
        okText: {
            type: String,
            required: false,
        },
        cancelText: {
            type: String,
            required: false,
        },
        enableConfirm: {
            type: Boolean,
            default: false,
            required: false,
        },
        isShow: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data: () => ({
      mordalHeight: window.innerHeight,
    }),
    // created() {
    // },
    watch: {
      isShow: function() {
        if(this.isShow) {
          this.bodyScrollTop = window.scrollY;
          // alert(this.bodyScrollTop);
          document.body.style.height = '100vh';
          // document.body.style.overflow = 'hidden';
          document.body.style.width = '100%';
          document.body.style.position = 'fixed';
          document.body.style.top = '-' + this.bodyScrollTop + 'px';
        } else {
          document.body.style.height = '100%';
          // document.body.style.overflow = 'auto';
          document.body.style.position = '';
          document.body.style.top = '';
          // document.body.style.top = '-${this.bodyScrollTop}px';
          // alert(parseInt(this.bodyScrollTop || '0') * -1);
          window.scrollTo(0, parseInt(this.bodyScrollTop || '0'));
        }
      }
    },
    mounted: function () {
      window.addEventListener('resize', this.windowResize);
    },
    beforeDestroy: function () {
      window.removeEventListener('resize', this.windowResize);
    },
    // computed: {
    //   confirmBtnDisabledClass() {
    //     if(this.enableConfirm) {
    //       return 'btn-primary';
    //     } else {
    //       return 'pure-button-disabled';
    //     }
    //   },
    // },
    methods: {
      windowResize: function() {
        // resizeのたびに発火
        this.mordalHeight = window.innerHeight;
      },
      // キャンセル（ダイアログを消す）
      cancel() {
        this.$emit('cancel');
      },
      // 決定（選んだのをいっこ上のコンポーネントにほりあげる）
      confirm() {
        this.$emit('confirm');
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
