<docs>
</docs>

<template>
    <div class="imageFieldComponent">
      <input type="text" class="pure-input-1" name="tag" :value="tagsString"
      @input="onInput"
      @keypress.enter.prevent.stop="onEnter"
      @focus="onFocus"
      @blur="onBlur">



      <!-- <ul v-show="tags">
        <li v-for="tagObject in tagObjects" :key="tagObject.key" v-text="'#' + tagObject.tag"></li>
      </ul> -->

    </div>
</template>

<script>
    export default {
        // フィールド名
        props: {
            // 現在のタグ
            currentTags: {
                type: Array,
                default: null,
                required: false,
            },
            // タグテーブルのカラム名
            tagColumn: {
                type: String,
                default: 'tag',
                required: false,
            },
        },
        data: () => ({
            tags: [],
            inputArr: [],
        }),
        created() {
          if(this.currentTags) {
            this.currentTags.forEach(tag => this.tags.push(tag[this.tagColumn]));
            this.$emit('setTags', this.tags);
          }
        },
        computed: {
          tagsString() {
            return this.tags.join(',');
          },
          tagObjects() {
            return this.tags.map((tag, key) => {
              return {
                key: key,
                tag: tag,
              };
            });
          }
        },
        methods: {
          onInput(e) {
            // console.log('input');
            var inputString = e.target.value;
            var inputStringClean = inputString;
            // console.log(inputStringClean);
            this.toArray(inputStringClean);
          },
          onEnter(e) {
            // console.log('enter');
            if(e.target.value) {
              var inputString = e.target.value;
              var inputStringClean = inputString.replace(/[,\s]+$/g,'');
              this.toArray(inputStringClean + ',');
            }
          },
          onFocus(e) {
            // console.log('focus');
            if(e.target.value) {
              var inputString = e.target.value;
              var inputStringClean = inputString.replace(/[,\s]+$/g,'');
              this.toArray(inputStringClean + ',');
            }
          },
          onBlur(e) {
            // console.log('blur');
            var inputString = e.target.value;
            var inputStringClean = inputString.replace(/[,\s]+$/g,'');
            this.toArray(inputStringClean);
          },
          toArray(inputString) {
            // console.log(inputString);
            if(inputString) {
              this.tags = inputString.split(/[,|、|\.|。|\/|・|／|\s|\t|\n]+/g);
            } else {
              this.tags = [];
            }
            this.$emit('setTags', this.tags);
            // console.log(this.tags);
          },
        },
    }
</script>

<style lang="scss" scoped>
</style>
