<template>
  <div class="status_checker">
    <label v-for="theStatus in theStatusOptions" :class="'label'+theStatus.value" class="label"><input type="radio" name="status" :value="theStatus.value" v-model="currentValue" @change="onChange"><span class="labelText">{{theStatus.label}}</span></label>
  </div>
</template>

<script>
    export default {
        props: {
          currentStatus: {
            type: Number,
            default: 1,
            required: false,
          },
          enable_values: {
            type: Array,
            default: [],
            required: false,
          },
          config_status: {
            type: Object,
            required: true,
          }
        },
        data: () => ({
          theStatusOptions: [],
          currentValue: 1,
          min: [],
          max: [],
        }),
        created() {
          // console.log('this.currentStatus');
          // console.log(this.currentStatus);
          // console.log('this.enable_values');
          // console.log(this.enable_values);
          // console.log('this.config_status');
          // console.log(this.config_status);
          if(this.enable_values.length >= 1) {
            this.enable_values.forEach(eVal => {
              this.theStatusOptions.push({
                value: eVal,
                label: this.config_status[eVal],
              });
            });
          } else {
            for(var key in this.config_status) {
              this.theStatusOptions.push({
                value: key,
                label: this.config_status[key],
              });
            }
          }
          // console.log('this.theStatusOptions');
          // console.log(this.theStatusOptions);
          this.currentValue = this.currentStatus;
          // this.theStatusOptions
        },
        // mounted () {
        // },
        // computed: {
        // },
        watch: {
          currentStatus: {
            handler: function(newStatus, old) {
              this.currentValue = newStatus;
            }
          }
        },
        methods: {
          onChange() {
            // console.log(this.currentValue);
            this.$emit('setStatus', this.currentValue);
          }
        }
    }
</script>

<style lang="scss" scoped>
    .status_checker {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      .label {
        margin-right: 1rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: baseline;
        input {
          margin-right: .3rem;
        }
      }
    }
</style>

