<template>


  <form method="POST" :action="actionurl" class="pure-form" @submit.prevent>

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">権限</span>
            </div>
            <div class="form_body">
              <div class="form_input">
              <ul>
                <li v-for="role in roleconfig" :key="role.key">
                  <label class="checkbox">
                    <input type="checkbox" class="" :value="role.role" v-model="checked"> {{ role.name }}
                  </label>
                </li>
              </ul>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.checked"></div>
              </div>
            </div>
          </div>

        </fieldset>

        <div class="form_buttons">
          <button type="button" class="btn cancelBtn" onclick="location.href=document.referrer;" value="">戻る</button>
          <button type="submit" class="btn btn-primary" @click.prevent.stop="onSubmit()">保存</button>
        </div>

  </form>

</template>

<script>
    export default {
        props: {
          // ユーザーオブジェクト
          userobj: {
              type: Object,
              default: null,
              required: true,
          },
          // ロールオブジェクト
          rolesobj: {
              type: Array,
              default: null,
              required: true,
          },
          // ロール設定値
          roleconfigobj: {
              type: Object,
              default: null,
              required: true,
          },
          // フォームの送信先
          actionurl: {
              type: String,
              default: null,
              required: false,
          }
        },
        data: () => ({
            // user: [],
            // roles: [],
            roleconfig: [],
            errors: {},
            checked: [],
        }),
        created() {
            for( var key in this.rolesobj ) {
              if( this.rolesobj.hasOwnProperty(key) ) {
                this.checked.push(String(this.rolesobj[key].role));
              }
            }
            let count = 1;
            for( var key in this.roleconfigobj ) {
              if( this.roleconfigobj.hasOwnProperty(key) ) {
                this.roleconfig.push({
                  key: count,
                  role: key,
                  name: this.roleconfigobj[key],
                });
                count++;
              }
            }
        },
        methods: {
            // サブミット！
            onSubmit() {
              // console.log(this.checked);
              // フォームで送信するFormDataインスタンスつくる
              const formData = new FormData();
              // 送信するフィールドのデータを登録する
              // PUTで送ってるように偽装する（普通にPUTで送れないため）
              formData.append('_method', 'PUT');
              // formData.append('check', this.checked);
              this.checked.forEach((v, i) => {
                formData.append('check' + '[]', v)  // arrayデータを分割して入れ直す
              })

              axios.post('/user/' + this.userobj.id + '/role', formData)
              .then( response => {
                // console.log(response);
                // 登録できたらページ移動するか聞いてOKならユーザーページにリダイレクト
                if(response.data.redirect_path) {
                  document.location.href = response.data.redirect_path;
                  // if(confirm(response.data.message + 'ページに移動しますか？')) document.location.href = response.data.redirect_path;
                } else {
                  alert('更新完了');
                  // console.log(response);
                }
              }).catch(
                (error) => {
                  //例外処理を行う
                  // console.log(error);
                                    // this.allerros = error.response.data.errors;
                                    // console.log(this.allerros);
                  this.success = false;



                            var errors = {};

                            for(var key in error.response.data.errors) {

                                errors[key] = error.response.data.errors[key].join('<br>');

                            }

                            this.errors = errors;
                  // console.log(this.errors);
                }
              );
            },
        }
    }
</script>
