<template>
  <div class="date_input">
    <span class="inputPre" v-if="title">{{ title }}: </span>
    <!-- <label class="date_year" @click="$event.target.firstElementChild.select()"> エラー出る（これで何をしようとしてたのか不明） -->
    <!-- <label class="date_year" @click="test($event)"> -->
    <label class="date_year">
      <input
      type="number"
      :name="name + '_year'"
      pattern="\d*"
      class="year"
      autocomplete="off"
      maxlength="4"
      :min="minY"
      :max="maxY"
      :value="value.year"
      @input="updateYear"
      :required="required"
      >年
    </label>
    <!-- :class="{ 'is-invalid':errors[name].year }" -->
    <!-- <label class="date_month" @click="$event.target.firstElementChild.focus()"> -->
    <!-- <label class="date_month" @click="test($event)"> -->
    <label class="date_month">
      <select
      type="text"
      :name="name + '_month'"
      class="month"
      :value="value.month"
      @input="updateMonth"
      :required="required"
      >
        <option value="">月</option>
        <option value="01">1 月</option>
        <option value="02">2 月</option>
        <option value="03">3 月</option>
        <option value="04">4 月</option>
        <option value="05">5 月</option>
        <option value="06">6 月</option>
        <option value="07">7 月</option>
        <option value="08">8 月</option>
        <option value="09">9 月</option>
        <option value="10">10 月</option>
        <option value="11">11 月</option>
        <option value="12">12 月</option>
      </select>
    </label>
    <!-- :class="{ 'is-invalid':errors[name].month }" -->
    <!-- <label class="date_day" @click="$event.target.firstElementChild.select()"> エラー出る（これで何をしようとしてたのか不明） -->
    <!-- <label class="date_day" @click="test($event)"> -->
    <label class="date_day">
      <!-- <input type="number" pattern="\d*" class="day" autocomplete="off" maxlength="2" min="1" max="31" :class="{ 'is-invalid':errors.date_start }" :value="parseInt(node.date_start.day)" @input="test">日 -->
      <input
      type="number"
      :name="name + '_day'"
      pattern="\d*"
      class="day"
      autocomplete="off"
      maxlength="2"
      min="1"
      max="31"
      :value="value.day ? parseInt(value.day) : ''"
      @input="updateDay"
      :required="required"
      >日
    </label>
      <!-- :class="{ 'is-invalid':errors[name].day }"
      @input="value.day = $event.target.value ? ( '0' + $event.target.value ).slice( -2 ) : ''" -->
  </div>
</template>

<script>
    export default {
        props: {
          title: {
            type: String,
            required: false,
          },
          name: {
            type: String,
            required: true,
          },
          value: {
            type: Object,
            default: {
              date: null,
              year: null,
              month: null,
              day: null,
            },
            required: false,
          },
          minDate: {
            type: String,
            default: '1900-01-01',
            required: false,
          },
          maxDate: {
            type: String,
            default: '2100-12-24',
            required: false,
          },
          id: {
            type: String,
            required: false,
          },
          classStr: {
            type: String,
            required: false,
          },
          refVal: {
            type: String,
            default: 'date',
            required: false,
          },
          required: {
            type: Boolean,
            default: false,
            required: false,
          },
          autocomplete: {
            type: String,
            default: '',
            required: false,
          },
          autofocus: {
            type: String,
            required: false,
          },
          // autofocus属性
          // 自動フォーカスを指定する（autofocus）
          // cols属性（初期値は20）
          // 一行当たりの最大文字数の目安を指定する
          // disabled属性
          // 操作を無効にする（disabled）
          // form属性
          // どのフォームと関連付けるかを<form>のid名で指定
          // maxlength属性
          // 入力可能な最大文字数を指定
          // name属性
          // 入力フィールドの名前を指定
          // placeholder属性
          // ユーザー入力に関するヒントを短めのテキストで指定
          // readonly属性
          // ユーザーによるテキスト編集を不可にして読み取り専用にする（readonly）
          // required属性
          // 入力必須を指定
          // rows属性（初期値は2）
          // 表示行数を指定する
          // wrap属性
          // 入力テキストの改行ルールを指定
        },
        data: () => ({
          date: {
            date: null,
            year: null,
            month: null,
            day: null,
          },
          min: [],
          max: [],
        }),
        created() {
          // console.log('this.maxDate');
          // console.log(this.maxDate);
          if(this.value) {
            this.date = this.value;
          }
          this.min = this.minDate ? this.minDate.split('-') : null;
          this.max = this.maxDate ? this.maxDate.split('-') : null;
        },
        // mounted () {
        // },
        computed: {
          minY() {
            return this.min ? this.min[0] : '1900';
          },
          // minM() {
          //   return this.min[1];
          // },
          // minD() {
          //   return this.min[2];
          // },
          maxY() {
            return this.max ? this.max[0] : '2100';
          },
          // maxM() {
          //   return this.max[1];
          // },
          // maxD() {
          //   return this.max[2];
          // },
          classVal() {
            return this.classStr;
          }
        },
        methods: {
          updateYear(e) {
            // console.log(e.target.value);
            this.date.year = e.target.value;
            this.updateValue();
          },
          updateMonth(e) {
            this.date.month = e.target.value;
            this.updateValue();
          },
          updateDay(e) {
            this.date.day = e.target.value ? ( '0' + e.target.value ).slice( -2 ) : '';
            this.updateValue();
          },
          updateValue() {
            // console.log('this.date');
            // console.log(this.date);
            this.date.date = [this.date.year, this.date.month, this.date.day].join('-');
            this.$emit('setDate', this.date);
          },
          test(ev) { // フォームクリック時の謎イベント（何をしようとしてたか不明）のチェック用
            console.log(ev.target);
            console.log(ev.target.firstElementChild);
          }
        }
    }
</script>
