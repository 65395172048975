<template>
  <mordal-window
  :slag="'photoSelect'"
  :title="'京都写真ギャラリーから選ぶ'"
  :enableConfirm="enableConfirm"
  :isShow="isShow"
  @cancel="cancelPG"
  @confirm="confirmPG">
    <section class="section-w">
      

      <div class="search_form">
        <!-- <h2 class="search_form_title">検索</h2> -->
        <div class="content_search_form photo_search_form">
          <form method="post" action="" class="register_form pure-form pure-form-stacked">
            <div class="form_body">
              <div class="form_input tab">
                <label class="label"><input type="radio" name="tab" v-model="currentTab" value="keyword"><span class="labelText">キーワード検索</span></label>
                <label class="label"><input type="radio" name="tab" v-model="currentTab" value="pid"><span class="labelText">作品ID検索</span></label>
              </div>
            </div>
            <fieldset>
              <section v-if="currentTab == 'pid'" class="search_form_keyword">
                <h3 class="search_form_keyword_title">作品ID</h3>
                <input type="text" name="pid" v-model="searchFormData.pid" />
                <div class="form_note">
                  <div class="note">一度に複数検索する場合はスペースで区切ってください</div>
                </div>
              </section>
              <div v-if="currentTab == 'keyword'">
                <section class="search_form_keyword">
                  <h3 class="search_form_keyword_title">キーワード</h3>
                  <input type="text" name="s" v-model="searchFormData.s" />
                </section>
                <input type="checkbox" id="acd-check" class="acd-check" v-model="searchFormData.withCat">
                <label for="acd-check" class="acd-label">カテゴリーで絞り込む</label>
                <section class="search_form_detail acd-content"><div>
                  <div class="form-group">
                    <div class="form_header">
                      <span class="label">カテゴリー</span>
                    </div>
                    <div class="form_body">
                      <div class="form_input">
                        <category-checker :entity="'photo'" :currentCategories="searchFormData.currentCat" @setCategories="setCategories"></category-checker>
                      </div>
                    </div>
                  </div>
                </div></section>
              </div>
            </fieldset>
            <fieldset>
              <button type="text" class="btn" @click.prevent.stop="onSearch();">検索</button>
            </fieldset>
          </form>
        </div>
      </div>




      <div class="photo_thumbs" v-if="nodes">
        <div class="item" v-for="node in nodes" :key="node.id">
          <input class="selectableCheck" v-if="multiple" type="checkbox" :id="'check_node_' + id + '_' + node.id" name="select_photo[]" v-model="selected" :value="node.id">
          <input class="selectableRadio" v-else type="radio" :id="'check_node_' + id + '_' + node.id" name="select_photo[]" v-model="selected" :value="node.id">
          <label :for="'check_node_' + id + '_' + node.id" @dblclick="onDblclick(node.id)" class="link">
            <span class="box"></span>
            <span class="check"></span>
            <img class="img" :class="node.landscape_class" :src="'/storage/images/pg/thumb/'+node.file_name" :alt="node.title">
          </label>
        </div>
      </div>

      <div class="photo_thumbs_status" v-if="!nodes && !loading">
        見つかりませんでした
      </div>

      <div class="photo_thumbs_status" v-if="loading">
        読込中...
      </div>


      <div class="photo_thumbs_status">
        <button v-if="nodes && load && currentSearch && !loading" type="text" class="btn" @click.prevent.stop="moreLoad();">さらに表示する</button>
      </div>


    </section>
  </mordal-window>
</template>

<script>
  import MordalWindow from './MordalWindow';
  import CategoryChecker from './CategoryChecker';
  export default {
    components: {
        MordalWindow,
        CategoryChecker,
    },
    props: {
        id: {
            type: String,
            default: 'pg_select',
            required: false,
        },
        multiple: {
            type: Boolean,
            default: false,
            required: false,
        },
        isShow: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data: () => ({
      nodes: [],
      selected: [],
      photoCatTree: [],
      searchFormData: {
        pid: '',
        s: '',
        withCat: false,
        cat: [],
        currentCat: [],
      },
      currentSearchFormData: {
        pid: '',
        s: '',
        withCat: false,
        cat: [],
        currentCat: [],
      },
      currentSearch: false,
      page: 1,
      loading: false,
      load: true,
      currentTab: 'keyword',
    }),
    created() {
      // // 写真のカテゴリーツリーを取ってくる
      // axios.post('/photo/getcategories').then((res)=>{
      //   // console.log('res.data');
      //   // console.log(res.data);
      //   if(res.data) {
      //     this.photoCatTree = res.data;
      //     console.log('this.photoCatTree');
      //     console.log(this.photoCatTree);
      //   }
      // })
      // ToDo: エラー処理書く
      // .catch(error => console.log(error))
      //           this.loading = true;
      // // 写真を取ってくる
      // axios.post('/photo/select').then((res)=>{
      //   console.log(res.data);
      //   if(res.data.nodes.data) {
      //     // 写真があったらいっこずつ回しながら縦長かどうかのクラス名のプロパティ追加してデータに入れる
      //     this.nodes = res.data.nodes.data.map((value, key) => {
      //       value.landscape_class = value.landscape ? 'landscape' : 'vertical';
      //       return value;
      //     });
      //   }
      //           this.loading = false;
      // })
      // // ToDo: エラー処理書く
      // .catch(error => console.log(error))
      // // console.log(this.node);

    },
    computed: {
      enableConfirm() {
        if(Object.prototype.toString.call(this.selected) === '[object Array]' && this.selected.length >= 1) {
          return true;
        } else if(Object.prototype.toString.call(this.selected) !== '[object Array]' && this.selected) {
          return true;
        } else {
          return false;
        }
      }
    },
    mounted(){
      this.clearVar()
      // window.addEventListener('scroll', () => {
      //   let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight == document.documentElement.offsetHeight;
      //   if (bottomOfWindow) this.getItems();
      // };
      // this.onSearch()
    },
    // watch: {
    //   searchFormData: {
    //     handler: function(newVal, oldVal) {
    //       this.currentSearch = false;
    //     },
    //     deep : true,
    //   }
    // },
    methods: {
      clearVar() {
        this.loading = false
        this.load = true
        this.page = 1
        this.nodes = []
      },
      // カテゴリーをセット
      setCategories(categoriesArr) {
        var newCategories = categoriesArr.map(category =>{
          return {id: category};
        });
        this.searchFormData.cat = newCategories;
        // console.log('this.searchFormData');
        // console.log(this.searchFormData);
      },
      moreLoad() {
        this.currentSearch = true; // 検索条件の変更があった場合でもなかったことにする
        this.searchFormData = this.currentSearchFormData; // 検索フォームの値を現在検索結果表示中の検索条件に戻す
        this.getNodes()
      },
      onSearch() {
        this.currentSearch = false; // 検索条件の変更があった場合でもなかったことにする
        this.getNodes()
      },
      async getNodes() {
        // if (this.load) { //全体の読み込み
          if (!this.loading) { //読み込み中は読み込めないようにする
            if(!this.currentSearch) { // 検索条件に変更があれば
              this.nodes = []; // 検索結果を一消す
              this.page = 1 // 現在のページ数をリセット
            }
            this.currentSearch = true; // 現在検索結果表示中
            this.currentSearchFormData = this.searchFormData; // 現在検索結果表示中の検索条件を保存
            // フォームで送信するFormDataインスタンスつくる
            const formData = new FormData();
            // ID検索の場合は
            if(this.currentTab == 'pid') {
              // IDだけを登録
              formData.append('pid', this.searchFormData.pid);
            } else { // ID検索じゃない場合は
              // カテゴリーをセット
              if(this.searchFormData.cat) {
                this.searchFormData.cat.forEach(category => {
                  formData.append('cat[]', category.id);
                });
              }
              // その他のデータを登録
              formData.append('s', this.searchFormData.s);
              formData.append('withCat', this.searchFormData.withCat);
            }
            this.loading = true
            try {
              const response = await axios.post('/photo/select?page=' + this.page, formData)
              // const response = await axios.post('/photo/select', formData)
              // console.log(response)
              if (response.data.nodes.last_page == this.page) this.load = false
              if (response.data.nodes.data) {
                await response.data.nodes.data.forEach((n,i) => {
                  n.landscape_class = n.landscape ? 'landscape' : 'vertical';
                  this.nodes.push(n)
                })
              } else {
                this.nodes = [];
              }
              // console.log(this.nodes);
              this.page += 1
            } catch (e) {
              // console.log(e.response)
              this.load = false
              this.loading = false
            } finally {
              this.loading = false
            }
          }
        // }
      },
                                                  // onSearch0() {
                                                  //   // フォームで送信するFormDataインスタンスつくる
                                                  //   const formData = new FormData();
                                                  //   // カテゴリーをセット
                                                  //   if(this.searchFormData.cat) {
                                                  //     this.searchFormData.cat.forEach(category => {
                                                  //       formData.append('cat[]', category.id);
                                                  //     });
                                                  //   }
                                                  //   // その他のデータを登録
                                                  //   formData.append('s', this.searchFormData.s);
                                                  //   formData.append('withCat', this.searchFormData.withCat);
                                                  //   this.loading = true;
                                                  //   axios.post('/photo/select', formData).then((res)=>{
                                                  //     console.log(res.data);
                                                  //     if(res.data.nodes.data) {
                                                  //       // 写真があったらいっこずつ回しながら縦長かどうかのクラス名のプロパティ追加してデータに入れる
                                                  //       this.nodes = res.data.nodes.data.map((value, key) => {
                                                  //         value.landscape_class = value.landscape ? 'landscape' : 'vertical';
                                                  //         return value;
                                                  //       });
                                                  //       this.loading = false;
                                                  //     } else {
                                                  //       this.loading = false;
                                                  //     }
                                                  //   })
                                                  //   // ToDo: エラー処理書く
                                                  //   .catch(error => console.log(error))
                                                  // },
      // キャンセル（ダイアログを消す）
      cancelPG() {
        this.$emit('cancelPG');
      },
      // 決定（選んだのをいっこ上のコンポーネントにほりあげる）
      confirmPG() {

        // ほり投げる用にノードのリスト作る
        if(this.selected instanceof Array) {
          var selected_nodes = this.selected.map(value => {
            // this.nodeの中からidで検索する
            return this.nodes.find((node) => {
              return (node.id === value);
            });
          });
        } else {
          var value = this.selected;
          var selected_node = this.nodes.find((node) => {
              return (node.id === value);
          });
          var selected_nodes = [selected_node];
        }
        // console.log(selected_nodes);
        // ほりなげる
        this.$emit('setPG', selected_nodes);
        // 選択済み情報けす
        this.selected = [];
      },
      onDblclick(id) {
        this.selected = [id];
        this.confirmPG();
      },
    }
  }
</script>

<style lang="scss" scoped>
  .section-w {
    margin-top: 0!important;
    .search_form {
      border: none;
      background-color: transparent;
    }
  }
  .photo_thumbs_status {
    margin: 3rem auto;
    text-align: center;
  }
  .selectableCheck,
  .selectableRadio {
    display: none;
  }
  .selectableCheck + label,
  .selectableRadio + label {
    .box {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      width: 20px;
      height: 20px;
      border-radius: 12px;
      border: 2px solid #fff;
      z-index: 1000000;
      background-color: rgba(255,255,255,.7);
      opacity: .3;
    }
  }
  .selectableCheck:checked + label,
  .selectableRadio:checked + label {
    border: solid 8px #0078e7;
    overflow: visible;
    .box {
      top: -5px;
      left: -5px;
      width: 24px;
      height: 24px;
      background-color: rgba(255,255,255,1);
      opacity: 1;
    }
    .check {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: 16px;
      height: 12px;
      border-left: 5px solid #0078e7;
      border-bottom: 5px solid #0078e7;
      transform: rotate(-45deg);
      z-index: 1000001;
    }
  }
  .photo_search_form .tab label {
    margin-right: 1rem;
  }
</style>
