import { render, staticRenderFns } from "./EventEdit.vue?vue&type=template&id=356e620d&scoped=true"
import script from "./EventEdit.vue?vue&type=script&lang=js"
export * from "./EventEdit.vue?vue&type=script&lang=js"
import style0 from "./EventEdit.vue?vue&type=style&index=0&id=356e620d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "356e620d",
  null
  
)

export default component.exports