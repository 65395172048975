<template>
  <div class="imageUploader imageUploaderWithPhoto">
    <div class="uploader"
    @dragenter="onDragEnter"
    @dragleave="onDragLeave"
    @dragover.prevent
    @drop="onDrop"
    :class="{ dragging: isDragging }">
      <div class="inner">
        <p class="message">ここにファイルをドロップ<span>または</span></p>
        <div class="file-input">
          <label :for="name + '_input_field'" class="btn btn-primary">ファイルを選択</label>
          <input type="file" :id="name + '_input_field'" @change="onInputChange" :multiple="multiple">
        </div>
        <div class="pgBtn" v-if="!disablePG">
          <p class="message"><span>または</span></p>
          <div class="file-input">
            <span @click="showPG()" class="btn btn-primary">京都写真ギャラリーから選ぶ</span>
          </div>
        </div>
      </div>
    </div>
    <photo-select
    v-if="showByPhotoGallery"
    id="imageFieldPG"
    :multiple="multiple"
    :isShow="showByPhotoGallery"
    @setPG="setPG"
    @cancelPG="cancelPG">
    </photo-select>
  </div>
</template>

<script>
  import PhotoSelect from '../PhotoSelect'
  export default {
    components: {
      PhotoSelect,
    },
    props: {
      // フィールドのname
      name: {
        type: String,
        required: true,
      },
      // フィールドのタイプ
      tmpFormatName: {
        type: String,
        default: null,
        required: false,
      },
      // アップロードキューのインデックス
      imageQue: {
        type: Number,
        default: 0,
        required: false,
      },
      // 複数アップロードの可否
      multiple: {
        type: Boolean,
        default: false,
        required: false,
      },
      disablePG: {
          type: Boolean,
          default: false,
          required: false,
      },
      // 複数アップロードの可否
      itinerary: {
        type: Object,
        default: null,
        required: false,
      },
            // setFile: {
            //   type: Function,
            //   // default: null,
            //   required: true,
            // },
    },
    data: () => ({
      // ドラッグ中かどうか
      isDragging: false,
      // ドラッグ中かどうかの判定用
      dragCount: 0,
      // アップロードファイルオブジェクト
      files: [],
      que: 0,
      showByPhotoGallery: false,
      byPhotoGalleryStr: null,
      byPhotoGallery: [],
      // 写真ギャラリーから選ぶのモーダルが表示されてる間後ろのスクロールを止めるためbodyのスクロール量をバックアップ
      bodyScrollTop: 0,
    }),
    // computed: {
    //   tmpDir() {
    //     if(this.multiple) {
    //       return 'multiple_image';
    //     } else {
    //       if(this.tmpFormatName) {
    //         return this.type;
    //       } else {
    //         return 'single_image';
    //       }
    //     }
    //   },
    // },
    created() {
      // console.log('this.itinerary');
      // console.log(this.itinerary);
    },
    methods: {
      /**
       * PGフィールド関係
       */
      // 写真ギャラリーから選ぶダイアログを表示
      showPG() {
        this.showByPhotoGallery = true;
        // this.bodyFix();
      },
      // 写真ギャラリーから選ぶダイアログでキャンセル押したとき（非表示にする）
      cancelPG() {
        this.showByPhotoGallery = false;
        // this.bodyFix();
      },
      // 写真ギャラリーから写真を選んで決定押したとき
      setPG(selected) {

        // console.log('selected');
        // console.log(selected);
        // いっこ上のコンポーネントに選択済みの写真のIDの配列をほりなげる
        selected.forEach(pgID => this.$emit('setFile', {file: pgID, type: 'pg',}));
        // selected.forEach(function(pgID) {
        //   this.$emit('setFile', {file: pgID, type: 'pg',})
        // });
          // this.$emit('setFile', {
          //   file: null,
          //   type: null,
          //   // queIndex: queIndex,
          // });
        // selected.forEach(pgID => this.$emit('setFile',pgID,'pg'));
        // selected.forEach(pgID => this.setFile(pgID,'pg'));
        // 写真ギャラリーから選ぶダイアログを消す
        this.showByPhotoGallery = false;
        // this.bodyFix();
      },
      /**
       * D&Dとファイル選択制御
       */
      // ドラッグがdrop領域に入った時
      onDragEnter(e) {
        e.preventDefault();
        this.dragCount++;
        this.isDragging = true;
      },
      // ドラッグがdrop領域を出た時
      onDragLeave(e) {
        e.preventDefault();
        this.dragCount--;
        // console.log(this.dragCount);
        if(this.dragCount <= 0) {
          this.isDragging = false;
        }
      },
      // drop領域内でdropした時
      onDrop(e) {
        e.preventDefault(); // デフォルトの動作（この場合はファイルをブラウザで開く）をキャンセル
        e.stopPropagation(); // 現在のイベントの親要素への伝播（propagation）止める
        this.dragCount = 0;
        this.isDragging = false;
        if(this.multiple) {
          // ドロップされたファイルたちを順番に処理する（下の関数内の処理）
          Array.from(e.dataTransfer.files).forEach(file => {
            this.que++;
            var queIndex = this.que + this.imageQue;
            this.$emit('addQue');
            this.addImage(file, queIndex);
          });
        } else {
          // ドロップされたファイル（複数ドロップされた場合はいっこめだけ）を処理する（下の関数内の処理）
          let file = e.dataTransfer.files[0];
          this.que++;
          var queIndex = this.que + this.imageQue;
          this.$emit('addQue');
          this.addImage(file, queIndex);
        }
        this.$nextTick(function () {
          this.que = 0;
        });
      },
      // ファイルフィールドのchangeイベント
      onInputChange(e) {
        // ファイルたちを順番に処理する（下の関数内の処理）
        Array.from(e.target.files).forEach(file => {
          this.que++;
          var queIndex = this.que + this.imageQue;
          this.$emit('addQue');
          this.addImage(file, queIndex);
        });
        this.$nextTick(function () {
          this.que = 0;
        });
      },
      // ファイルのデータを確認してアップロードメソッドに送る
      addImage(file, queIndex) {
        this.$emit('setProcessing',true);
        console.log('addImage');
        // ドロップされたものが画像かどうかチェック
        if(!file.type.match('image.[jpeg|png|gif]')) {
          alert(`${file.name} は非対応のファイル形式です`);
          this.$emit('setProcessing',false);
          return;
        }
        // ファイルサイズをチェック
        if(file.size > 30000000) {
          alert(`${file.name} は容量が大きすぎるためアップロードできません。ファイル容量の上限は30MBです。`);
          this.$emit('setProcessing',false);
          return;
        }
        // プレビュー画像アップしてゲットしてセットする
        this.uploadTmp(file, queIndex);
      },
      // プレビュー用に画像をアップロード
      uploadTmp(file, queIndex) {
        this.$emit('startUpload');
        // フォームで送信するFormDataインスタンスつくる
        const formData = new FormData();
        // 送信するフィールドのデータを登録する
        // console.log(this.tmpFormatName);
        formData.append('field', this.tmpFormatName);
        // アップロードするファイルを登録する
        formData.append('image', file, file.name);
        // アップロード
        axios.post('/tmp-upload',formData)
        .then((res)=>{
          var tmpFile = res.data.uploadFile;
          // プレビュー用のURLをデータにほりこむ
          var theFile = {
            tmpFile: tmpFile,
            src:  '/storage/images/tmp/prv/' + tmpFile,
            file: {
              name: file.name,
              size: file.size,
            }
          }
          // いっこ上の階層のVueコンポーネントのメソッド（ファイルをセット）にファイルを投げる
          // this.setFile(theFile, 'upload', queIndex);
          // this.$emit('setFile', {
          //   file: null,
          //   type: null,
          //   // queIndex: queIndex,
          // });
          this.$emit('setFile', {
            file: theFile,
            type: 'upload',
            queIndex: queIndex,
            itinerary: this.itinerary,
          });
          // this.emitSetFile({
          //   file: theFile,
          //   type: 'upload',
          //   // queIndex: queIndex,
          // });
        })
        .catch((error) => {
          // console.log(error);
          // console.log(error.response);
          // // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
          // if(error.response.data.errors) {
          //   var errors = {};
          //   for(var key in error.response.data.errors) {
          //     errors[key] = error.response.data.errors[key].join('<br>');
          //   }
          //   this.errors = errors;
          // } else {
          //   // バリデーションエラーの場合以外はエラーダイアログを表示
          //   // エラーデータをほりこむ
          //   this.error = error.response;
          //   this.showErrorDialog = true;
          // }
        })
      },
      // emitSetFile(file) {
      //   console.log('file');
      //   console.log(file);
      //     this.$emit('setFile', file);
      // },
    }
  }
</script>

<style lang="scss" scoped>
  .uploader {
    width: 100%;
    height: 200px;
    margin: 0;
    padding: 2rem 1.5rem;
    text-align: center;
    border: 3px dashed #CCC;
    position: relative;
    background-color: #eee;
    &.dragging {
      background-color: rgba(0,120,230,.5);
      * {
        opacity: .5;
      }
    }
    &> .inner {
      position: absolute;
      margin: 0 auto;
      top: 45%;
      left: 50%;
      transform: translateY(-45%) translateX(-50%);
      -webkit-transform: translateY(-45%) translateX(-50%);
    }
    .message {
      font-size: 1.7rem;
      font-weight: bold;
      margin: 0;
      line-height: 1;
      span {
        display: block;
        margin-top: -.3rem;
        font-size: 1.3rem;
        font-weight: normal;
      }
    }
    .file-input {
      width: 100%;
      text-align: center;
      margin: auto;
      position: relative;
      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        opacity: 0;
      }
    }
  }
  .images-preview {
    width: 100%;
    height: auto;
    max-width: 1000px;
    max-height: 90%;
    margin: 0 auto;
    text-align: center;
    .img-wrapper {
      text-align: center;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      border: 1px solid #fff;
    }
    .uploadImagePreview {
      width: auto;
      height: auto;
      max-width: 90%;
      max-height: 90%;
      margin: 0 auto;
      object-fit: contain;
      border: 1px solid #fff;
    }
    .details {
      margin-top: .7rem;
      span {
        margin: 0 .7rem;
      }
    }
  }
</style>
