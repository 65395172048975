<docs>
参考
https://www.youtube.com/watch?v=YmyAEyGL33U
※↑これはmultiple対応
</docs>

<template>
  <!-- <div class="dialog-content imagePreview" :class="wrapperClass" :style="wrapperStyle">
    <div class="prvWrap">
      <div class="prvInner" v-if="previewFile">
        <span class="removeBtn overlayBtn" @click="$emit('remove')"><i class="icon-remove">削除</i></span>
        <span v-if="rotatable && previewFile.src" class="rotateBtn overlayBtn" @click="rotate"><i class="icon-rotate">時計回りに90°回転する</i></span>
        <div class="prvImage rotatable" :class="imageRotateClass">
          <img v-if="previewFile.src" class="uploadImagePreview" :src="previewSrc">
          <div v-else><span class="loader icon">Loading...</span></div>
        </div>
        <div class="details" v-if="previewFile.file">
          <span class="name" v-text="previewFile.file.name"></span>
          <span class="size" v-text="Math.round(previewFile.file.size / 1000000 * 10) / 10 + 'MB'"></span>
        </div>
      </div>
    </div>
  </div> -->
  <div class="imagePreview" :class="wrapperClass" :style="wrapperStyle">
    <div class="prvWrap" v-if="previewFile">
      <slot></slot>
      <span class="removeBtn overlayBtn" @click="$emit('remove')"><i class="icon-remove">削除</i></span>
      <span v-if="rotatable && previewFile.src" class="rotateBtn overlayBtn" @click="rotate"><i class="icon-rotate">時計回りに90°回転する</i></span>
      <div class="prvImage rotatable" :class="imageRotateClass">
        <div v-if="previewFile.src && previewFile.src == 'deleted'">見つかりません</div>
        <img v-else-if="previewFile.src" class="uploadImagePreview" :src="previewSrc">
        <div v-else><span class="loader icon">Loading...</span></div>
      </div>
      <div class="details" v-if="previewFile.file">
        <span class="name" v-text="previewFile.file.name"></span>
        <span class="size" v-text="Math.round(previewFile.file.size / 1000000 * 10) / 10 + 'MB'"></span>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        // フィールド名
        props: {
          // プレビュー画像情報
          previewFile: {
            type: Object,
            default: null,
            required: false,
          },
          // 回転させることができるかどうか
          rotatable: {
            type: Boolean,
            default: true,
            required: false,
          },
          // 親がマルチプルかシングルか
          multiple: {
            type: Boolean,
            default: false,
            required: false,
          },
          // 上位コンポーネントが表示されているか
          isShow: {
            type: Boolean,
            default: null,
            required: false,
          },
        },
        data: () => ({
          // 現在の画像の回転角度(1増えるごとに90度時計回り)
          imageRotate: 0,
          // 回転初期状態の場合このクラスをつける（そのままだとアニメーションでくるりんぱしてしまう）
          imageRotateClass: 'rotate-init',
          rotateInitTimer: null,
          mordalHeight: null,
        }),
        watch: {
          // 同じエレメント内で画像を変更した場合などに、画像変更前に指定していた回転情報が残ってるのでリセットする
          previewFile(newImg, oldImg) {
            if(!newImg || !oldImg || newImg.src != oldImg.src) {
              this.imageRotate = 0;
              this.imageRotateClass = 'rotate-init';
            }
          },
          isShow: function() {
            if(this.isShow) {
              const target = this.$el.closest('.mordal-content');
              this.mordalHeight = target.clientHeight;
              // window.addEventListener( 'resize', function() {
              //   console.log(target.clientHeight);
              //   this.mordalHeight = target.clientHeight;
              // }, false );
            } else {
              this.mordalHeight = null;
            }
          },
        },
        computed: {
          // 画像回転のみとかで更新した際は画像のファイル名は変わらないので、各種表示時に加工前の画像がキャッシュで残っててそれが表示されてしまうことが多いので現在のタイムスタンプをパラメータでつけて強制更新させる
          previewSrc() {
            var date = new Date();
            var ts = date.getTime();
            return this.previewFile.src + '?' + ts;
          },
          // ラッパーのクラス名を返す（マルチプルかシングルか）
          wrapperClass() {
            return this.multiple ? 'multiplePreview' : 'singlePreview';
          },
          wrapperStyle() {
            if(this.mordalHeight) {
              var previewHeight = this.mordalHeight >= 280 ? this.mordalHeight - 40 : 240;
              if(previewHeight > 500) previewHeight = 500;
              return {maxWidth: previewHeight + 'px'};
            }
          }
        },
        created() {
          // console.log('this.previewFile');
          // console.log(this.previewFile);
        },
        mounted() {
          // console.log(this.$el.closest('.mordal-content'));
          // this.$el.closest('.mordal-content').addEventListener( 'resize', function() {
          //   console.log(this.clientHeight);
          // }, false );
          // this.$el.closest('.mordal-content').onresize = function(){
          //   console.log('15915915915159');
          // }
          // console.log(this.$el.closest('.mordal-content').clientHeight);
        },
        methods: {
          rotate(e) {
            e.preventDefault();
            e.stopPropagation();
            if(this.imageRotate <= 2) {
              // 回転更新300ms後にクラスをリセットするようにしているがその前にさらに回転させた場合（つまり回転ボタンを300ms以内の連打でクリックして1周以上させた場合）もクラスがリセットされてしまうのを防ぐためにリセットをキャンセル
              clearInterval(this.rotateInitTimer);
              // 90°,180°,270°の場合はimageRotateの値をいっこ増やす
              this.imageRotate++;
            } else {
              // 360°（1周まわってもとどおり）の場合はゼロに戻す
              this.imageRotate = 0;
            }
            // 角度に応じたクラス名をつける（cssで回転アニメーション設定してる）
            this.imageRotateClass = 'rotate-' + this.imageRotate;
            // ゼロに戻る回転アニメーション（200ms）が終わったころにクラス名をリセットする（再表示時などにくるりんぱしてしまうのを防ぐ）
            this.rotateInitTimer = setTimeout(() => this.imageRotateClass = 'rotate-init-' + this.imageRotate, 300);
            // 回転した際のプレビュー時（いまのところ本文フィールド）に外枠のサイズ調整をするために比率を計算して戻す。
            var imgWidth = e.target.nextElementSibling.firstElementChild.clientWidth;
            var imgHeight = e.target.nextElementSibling.firstElementChild.clientHeight;
            var ratio = imgWidth / imgHeight;
            // console.log(this.imageRotate);
            // 1階層上のsetRotateイベントを発火（回転情報と比率情報を送る）
            this.$emit('setImageRotate', { rotate: this.imageRotate, ratio: ratio});
          },
        }
    }
</script>
