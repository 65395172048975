<template>
  <form method="POST" :action="actionurl" class="register_form pure-form" enctype="multipart/form-data" @submit.prevent>
    <section id="user_edit">

      <div class="cover">
        <img v-if="!cover_image.wait" :src="cover_image.preview.src" class="coverImage">
        <div v-else style="position:absolute;"><span class="loader icon">Loading...</span></div>
        <div v-show="!cover_image.wait" class="cover_image_edit">
          <span class="btn" v-show="!cover_image.file_name" @click="newCoverImage">カバー画像を新規登録</span>
          <span class="btn" v-show="cover_image.file_name" @click="changeCoverImage">カバー画像を編集</span>
          <span class="btn" v-show="cover_image.file_name" @click="removeCoverImage">カバー画像を削除</span>
        </div>
        <mordal-window
        type="imageUploader"
        slag="cover_image"
        title="カバー画像"
        :enableConfirm="enableConfirmCoverImageFlag"
        :isShow="showCoverImageUploaderFlag"
        @cancel="hideCoverImageUploader"
        @confirm="setCoverImage"
        v-show="showCoverImageUploaderFlag">
          <image-uploader title="カバー画像" name="cover_image" :current="cover_image.file_name" :prvpath="cover_image.prevDir" :isShow="showCoverImageUploaderFlag"
          @setFile="image => uploadedCoverImage(image)"
          @setImageRotate="rotate => setCoverImageRotate(rotate)"
          @setProcessing="setProcessing">
          </image-uploader>
        </mordal-window>
      </div>

      <section class="section user_header">
        <div>
          <div class="basic_profile">
            <div class="user_icon">
              <img v-if="icon_image" :src="icon_image.preview.src" :style="icon_image.preview.rotateStyle" :alt="user.name">
              <img v-else-if="user.icon_image" :src="'/storage/images/icon_image/m/' + user.icon_image" :style="icon_image.preview.rotateStyle" :alt="user.name" width="200">
              <img v-else src="/img/default/icon_image_m.jpg" alt="ゲスト" width="200">
            </div>
            <div class="names">

              <div class="icon_image_edit">
                <span class="btn" v-show="!icon_image.file_name" @click="newIconImage">アイコンを新規登録</span>
                <span class="btn" v-show="icon_image.file_name" @click="changeIconImage">アイコンを編集</span>
                <span class="btn" v-show="icon_image.file_name" @click="removeIconImage">アイコンを削除</span>
              </div>
              <mordal-window
              type="imageUploader"
              slag="icon_image"
              title="アイコン"
              :enableConfirm="enableConfirmIconImageFlag"
              :isShow="showIconImageUploaderFlag"
              @cancel="hideIconImageUploader"
              @confirm="setIconImage"
              v-show="showIconImageUploaderFlag">
                <image-uploader title="アイコン" name="icon_image" :current="icon_image.file_name" :prvpath="icon_image.prevDir" :isShow="showIconImageUploaderFlag"
                @setFile="image => uploadedIconImage(image)"
                @setImageRotate="rotate => setIconImageRotate(rotate)"
                @setProcessing="setProcessing">
                </image-uploader>
              </mordal-window>

            </div>
          </div>

          <div class="invalid-feedback" role="alert" v-html="errors.self_introduction"></div>
                                <!-- <li v-for="(value, key, index) in validationErrors()">@{{ value }}</li> -->

          <fieldset class="">

            <div class="form-group required">
              <div class="form_header">
                <span class="label">表示名</span>
              </div>
              <div class="form_body">
                <div class="form_input">
                  <input id="name" type="text" v-bind:class="{ 'is-invalid':errors.name }" name="name" v-model="user.name" required autocomplete="name" autofocus>
                </div>
                <div class="form_note">
                  <div class="invalid-feedback" role="alert" v-html="errors.name"></div>
                  <div class="note">KYOTOdesign内でのあなたのお名前です。最大30文字まで</div>
                </div>
              </div>
            </div>

            <div class="form-group required">
              <div class="form_header">
                <span class="label">ユーザー名</span>
              </div>
              <div class="form_body">
                <div class="form_input">
                  <input id="user_name" type="text" v-bind:class="{ 'is-invalid':errors.user_name }" name="user_name" v-model="user.user_name" required autocomplete="user_name">
                </div>
                <div class="form_note">
                  <div class="invalid-feedback" role="alert" v-html="errors.user_name"></div>
                  <div class="note">半角英数のみで構成される、KYOTOdesign内でのあなたの識別名です。表示名とあわせて他のユーザにも表示されます。</div>
                  <div class="note">半角英数と"_"(アンダースコア),'.'(ドット)のみ使用できます。最大15文字まで</div>
                </div>
              </div>
            </div>

          </fieldset>

          <fieldset class="">

            <div class="form-group">
              <div class="form_header">
                <span class="label">自己紹介</span>
              </div>
              <div class="form_body">
                <div class="form_input">
                  <!-- <textarea id="self_introduction" v-bind:class="{ 'is-invalid':errors.self_introduction }" name="self_introduction" rows="6" v-model="user.self_introduction"></textarea> -->
                  <textarea-auto-adjust
                    id="self_introduction"
                    :errors="errors.self_introduction"
                    name="self_introduction"
                    v-model="user.self_introduction"
                  ></textarea-auto-adjust>
                </div>
                <div class="form_note">
                  <div class="invalid-feedback" role="alert" v-html="errors.self_introduction"></div>
                  <div class="note">他のユーザに向けた自己紹介文。他のユーザーに表示されます。</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="form_header">
                <span class="label">居住地域</span>
              </div>
              <div class="form_body">
                <div class="form_input_row">
                  <div class="form_input">
                    <select type="text" id="area" name="area" v-bind:class="{ 'is-invalid':errors.area }" v-model="user.area">
                      <option value="">選択してください</option>
                      <option v-for="area_option in area_options" :value="area_option.val" :key="area_option.id">{{ area_option.val }}</option>
                    </select>
                  </div>
                  <div class="form_input">
                    <label for="area_private" class="single_checkbox">
                      <input id="area_private" type="checkbox" class="" name="area_private" value="1" autocomplete="area_private" true-value="1" false-value="0" v-model="user.area_private"> 非公開
                    </label>
                  </div>
                </div>
                <div class="form_note">
                  <div class="invalid-feedback" role="alert" v-html="errors.area"></div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="form_header">
                <span class="label">性別</span>
              </div>
              <div class="form_body">
                <div class="form_input_row">
                  <div class="form_input">
                    <select type="text" id="sex" name="sex" v-bind:class="{ 'is-invalid':errors.sex }" v-model="user.sex">
                      <option value="">選択してください</option>
                      <option v-for="sex_option in sex_options" :value="sex_option.val" :key="sex_option.id">{{ sex_option.val }}</option>
                    </select>
                  </div>
                  <div class="form_input">
                    <label for="sex_private" class="single_checkbox">
                      <input id="sex_private" type="checkbox" class="" name="sex_private" value="1" autocomplete="sex_private" true-value="1" false-value="0" v-model="user.sex_private"> 非公開
                    </label>
                  </div>
                </div>
                <div class="form_note">
                  <div class="invalid-feedback" role="alert" v-html="errors.sex"></div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="form_header">
                <span class="label">生年月日</span>
              </div>
              <div class="form_body">
                <div class="form_input_row">
                  <div class="form_input">

                    <date-field
                    name="birth"
                    v-model="user.birth"
                    classStr=""
                    :errors="errors"
                    minDate="1900-01-01"
                    :maxDate="today"
                    @setDate="value => user.birth = value"
                    >
                    </date-field>
                    <!-- <textarea>{{ user.birth }}</textarea> -->
                  </div>
                  <div class="form_input">
                    <label for="birth_private" class="single_checkbox">
                      <input id="birth_private" type="checkbox" class="" name="birth_private" value="1" autocomplete="birth_private" true-value="1" false-value="0" v-model="user.birth_private"> 非公開
                    </label>
                  </div>
                </div>
                <div class="form_note">
                  <div class="invalid-feedback" role="alert" v-html="errors.birth"></div>
                </div>
              </div>
            </div>

          </fieldset>

          <fieldset class="">

            <div class="form-group required">
              <div class="form_header">
                <span class="label">メールアドレス</span>
              </div>
              <div class="form_body">
                <div class="form_input">
                  <input id="email" type="email" v-bind:class="{ 'is-invalid':errors.email }" name="email" v-model="user.email" required autocomplete="email">
                </div>
                <div class="form_note">
                  <div class="invalid-feedback" role="alert" v-html="errors.email"></div>
                  <div class="note">KYOTOdesignからのお知らせや、パスワードの再発行時など、こちらのアドレスにメールが送信されます。</div>
                  <div class="note">必ず「kyoto-design.jp」からのメールを受信できるようにしてください。</div>
                  <div class="note">メールアドレスに変更がある場合は必ず事前にこちらで変更手続きを行ってください。</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="form_header">
                <span class="label">パスワード</span>
              </div>
              <div class="form_body">
                <div class="form_input">
                  <input id="password" type="password" v-bind:class="{ 'is-invalid':errors.password }" name="password" autocomplete="new-password" v-model="user.password">
                </div>
                <div class="form_note">
                  <div class="invalid-feedback" role="alert" v-html="errors.password"></div>
                  <div class="note">変更したい場合のみ入力してください。変更しない場合は空白にしてください。</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="form_header">
                <span class="label">パスワード再入力</span>
              </div>
              <div class="form_body">
                <div class="form_input">
                  <input id="password-confirm" type="password" class="form-control" name="password_confirmation" autocomplete="new-password" v-model="user.password_confirmation">
                </div>
              </div>
            </div>

          </fieldset>

          <div class="form_buttons">
            <button v-bind:disabled="isProcessing" type="button" class="btn cancelBtn" onclick="location.href=document.referrer;" value="">戻る</button>
            <button v-bind:disabled="isProcessing" type="button" class="btn btn-primary" @click.prevent.stop="onSubmit()">保存</button>
            <button v-if="authuser && authuser.roles && authuser.roles.some(role => role.role <= 20)" v-bind:disabled="isProcessing" type="button" class="btn btn-warning" @click="deleteNode">削除</button>
          </div>


        </div>
      </section>

    </section>

    <div class="dialog dialog-t" v-if="showProgress">
      <div class="inner">
        <div class="dialog-header">
          投稿処理
        </div>
        <div class="dialog-content">
          <div v-if="progress != 100">
            アップロード中...
            <progress ref="progress" value="0" max="100"></progress><br>{{progress}}%
          </div>
          <div v-if="progress == 100">アップロード完了</div>
          <div v-if="progress == 100" class="icon-inline">
            <span class="loader icon">Loading...</span>
            <span>ファイルを処理しています...　処理完了後は自動的にページに移動します。</span>
          </div>
        </div>
      </div>
    </div>

    <div class="dialog dialog-t" v-if="showErrorDialog">
      <div class="inner">
        <div class="dialog-header">
          {{error.status}} {{error.statusText}}
        </div>
        <div class="dialog-content">
          <div>{{error.data.message}}</div>
          <div class="buttons">
            <button type="button" class="btn btn-primary" @click="showErrorDialog = false">OK</button>
          </div>
        </div>
      </div>
    </div>

  </form>

</template>

<script>
    // import ImageUploader from './ImageUploader'
    import ImageUploaderPop from './ImageUploaderPop';
    import ImageUploader from './ImageUploader';
    import MordalWindow from './MordalWindow';
    import TextareaAutoAdjust from './form_element/TextareaAutoAdjust';
    import DateField from './form_element/DateField';
    export default {
        components: {
          // ImageUploader,
          ImageUploaderPop,
          ImageUploader,
          MordalWindow,
          TextareaAutoAdjust,
          DateField,
        },
        props: {
          // ユーザーオブジェクト
            userobj: {
                type: Object,
                default: null,
                required: true,
            },
            // フォームの送信先
            actionurl: {
                type: String,
                default: null,
                required: false,
            },
            authuser: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            // ユーザーオブジェクト
            user: {
              name: '',
              user_name: '',
              self_introduction: null,
              area: null,
              area_private: null,
              sex: null,
              sex_private: null,
              birth: {},
              birth_private: null,
              email: null,
              password: null,
              password_confirmation: null,
            },
            // 今日の日付（生年月日のmaxで使う）
            today: '',
            // 今日の年（生年月日のmaxで使う）
            maxYear: '',
            // 居住地域の選択肢
            area_options: [
                { id: 0, val: '北海道' },
                { id: 1, val: '青森県' },
                { id: 2, val: '岩手県' },
                { id: 3, val: '宮城県' },
                { id: 4, val: '秋田県' },
                { id: 5, val: '山形県' },
                { id: 6, val: '福島県' },
                { id: 7, val: '茨城県' },
                { id: 8, val: '神奈川' },
                { id: 9, val: '東京都' },
                { id: 10, val: '千葉県' },
                { id: 11, val: '埼玉県' },
                { id: 12, val: '群馬県' },
                { id: 13, val: '山梨県' },
                { id: 14, val: '長野県' },
                { id: 15, val: '新潟県' },
                { id: 16, val: '富山県' },
                { id: 17, val: '石川県' },
                { id: 18, val: '福井県' },
                { id: 19, val: '岐阜県' },
                { id: 20, val: '静岡県' },
                { id: 21, val: '愛知県' },
                { id: 22, val: '三重県' },
                { id: 23, val: '滋賀県' },
                { id: 24, val: '京都府' },
                { id: 25, val: '大阪府' },
                { id: 26, val: '兵庫県' },
                { id: 27, val: '奈良県' },
                { id: 28, val: '和歌山県' },
                { id: 29, val: '鳥取県' },
                { id: 30, val: '島根県' },
                { id: 31, val: '岡山県' },
                { id: 32, val: '広島県' },
                { id: 33, val: '山口県' },
                { id: 34, val: '徳島県' },
                { id: 35, val: '香川県' },
                { id: 36, val: '愛媛県' },
                { id: 37, val: '高知県' },
                { id: 38, val: '福岡県' },
                { id: 39, val: '佐賀県' },
                { id: 40, val: '長崎県' },
                { id: 41, val: '熊本県' },
                { id: 42, val: '大分県' },
                { id: 43, val: '宮崎県' },
                { id: 44, val: '鹿児島県' },
                { id: 45, val: '沖縄県' },
                { id: 46, val: '海外' }
            ],
            // 性別の選択肢
            sex_options: [
                { id: 0, val: '男性' },
                { id: 1, val: '女性' },
                { id: 2, val: 'その他' },
            ],
            showIconImageUploaderFlag: false,
            enableConfirmIconImageFlag: false,
            // アップロードされたtmpカバー画像（適用前）
            tmpIconImage: null,
            // カバー画像の登録用画像
            icon_image: {
              file_name: null,
              prevDir: null,
              rotate: 0,
              preview: {
                src: null,
                rotateStyle: {},
              },
            },
            // アイコンのプレビュー用画像
            icon_image_preview: null,

            showCoverImageUploaderFlag: false,
            enableConfirmCoverImageFlag: false,
            // アップロードされたtmpカバー画像（適用前）
            tmpCoverImage: null,
            // カバー画像の登録用画像
            cover_image: {
              file_name: null,
              prevDir: null,
              rotate: 0,
              preview: {
                src: null,
              },
            },
            // カバー画像のプレビュー用画像
            cover_image_preview: null,
            // 元のアイコンを削除したらtrue
            remove_icon_image: false,

            cover_image_preview_url: null,
            // エラーダイアログ表示フラグ
            showErrorDialog: false,
            // 返ってきたerror.responceが入る
            error: {},
            // バリデーションエラーの内容が入る
            errors: {},
            // プログレスダイアログ用の進捗状況（%）
            progress: 0,
            // プログレスダイアログ表示フラグ
            showProgress: false,
            // 処理中はtrueにするとボタンが無効になる
            isProcessing: false,
        }),
        created() {
            // ユーザーオブジェクトをdataにセット
            this.user = this.userobj;
            // // 今日の日付のフォーマットテキストを作る（生年月日フィールドのmaxで使う）
            // this.today = this.formatDate(new Date(), 'YYYY-MM-DD');
            // 今日の日付のフォーマットテキストを作る（生年月日フィールドのmaxで使う）
            const todayDate = new Date();
            this.today = todayDate.getFullYear() + '-' + ('0' + (parseInt(todayDate.getMonth()) + 1)).slice( -2 ) + '-' + todayDate.getDate();
            this.maxYear = this.formatDate(todayDate, 'YYYY');


            if(this.userobj.icon_image) {
              this.icon_image = {
                file_name: this.userobj.icon_image,
                prevDir: '/storage/images/icon_image/l/',
                rotate: 0,
                preview: {
                  src: '/storage/images/icon_image/m/' + this.userobj.icon_image,
                  rotateStyle: {},
                },
              }
            } else {
              this.removeIconImage();
            }

            if(this.userobj.cover_image) {
              this.cover_image = {
                file_name: this.userobj.cover_image,
                prevDir: '/storage/images/cover_image/m/',
                rotate: 0,
                preview: {
                  src: '/storage/images/cover_image/m/' + this.userobj.cover_image,
                },
              }
            } else {
              this.removeCoverImage();
            }

            if(this.userobj.birth) {
              // console.log(this.userobj.birth);
              let date = this.userobj.birth.split('-');
              this.user.birth = {
                date: this.userobj.birth,
                year: date[0],
                month: date[1],
                day: date[2],
              }
            } else {
              this.user.birth = {
                date: null,
                year: null,
                month: null,
                day: null,
              }
            }
            // this.maxYear = this.formatDate(Date(), 'YYYY');
            // this.set_cover_image_preview_style();
        },
        methods: {
            // 日付のフォーマットを作る
            formatDate(date, format) {
              format = format.replace(/YYYY/, date.getFullYear());
              format = format.replace(/MM/, date.getMonth() + 1);
              format = format.replace(/DD/, date.getDate());
              return format;
              // return '2020';
            },
            /**
             * アイコン
             */
            newIconImage() {
              this.showIconImageUploader();
            },
            changeIconImage() {
              this.showIconImageUploader();
            },
            removeIconImage() {
              this.icon_image = {
                file_name: null,
                prevDir: null,
                rotate: 0,
                preview: {
                  src: '/img/default/icon_image_m.jpg',
                },
              }
              this.remove_icon_image = true;
            },
            showIconImageUploader() {
              this.showIconImageUploaderFlag = true;
              // console.log(this.showIconImageUploaderFlag);
            },
            hideIconImageUploader() {
              this.showIconImageUploaderFlag = false;
              // console.log(this.showIconImageUploaderFlag);
            },
            // 上の関数のカバー画像版（処理は同じなのでコメント省略）
            uploadedIconImage(image) {
              this.enableConfirmIconImageFlag = true;
              if(image) {
                this.tmpIconImage = image;
              } else {
                this.tmpIconImage = null;
              }
              // this.remove_icon_image = true;
            },
            setIconImageRotate(rotate) {
              this.enableConfirmIconImageFlag = true;
              this.tmpIconRotate = rotate;
              // console.log('this.tmpIconRotate');
              // console.log(this.tmpIconRotate);
            },
            setIconImage() {
              if(this.tmpIconImage) {
                // console.log('59595959595959595');
                var iconRotate = this.tmpIconRotate ? this.tmpIconRotate.rotate : 0;
                // console.log(iconRotate);
                this.icon_image = {
                  file_name: this.tmpIconImage,
                  prevDir: '/storage/images/tmp/prv/',
                  rotate: iconRotate,
                  preview: {
                    src: '/storage/images/tmp/prv/' + this.tmpIconImage,
                    rotateStyle: {
                      transform: 'rotate(' + parseInt(iconRotate) * 90 + 'deg)',
                    }
                  },
                };
                // console.log(this.icon_image);
              } else if(this.tmpIconRotate) {
                this.icon_image.rotate = this.tmpIconRotate.rotate;
                this.icon_image.preview.rotateStyle = {
                  transform: 'rotate(' + parseInt(this.tmpIconRotate.rotate) * 90 + 'deg)',
                }
              }
                // console.log(this.icon_image);
              this.hideIconImageUploader();
              // this.removeIconImage();
              this.enableConfirmIconImageFlag = false
            },
            /**
             * カバー画像
             */
            newCoverImage() {
              this.showCoverImageUploader();
            },
            changeCoverImage() {
              this.showCoverImageUploader();
            },
            removeCoverImage() {
              this.cover_image = {
                file_name: null,
                prevDir: null,
                rotate: 0,
                preview: {
                  src: '/img/default/cover_image.jpg',
                },
              }
              this.remove_cover_image = true;
            },
            showCoverImageUploader() {
              this.showCoverImageUploaderFlag = true;
              // console.log(this.showCoverImageUploaderFlag);
            },
            hideCoverImageUploader() {
              this.showCoverImageUploaderFlag = false;
              // console.log(this.showCoverImageUploaderFlag);
            },
            // 上の関数のカバー画像版（処理は同じなのでコメント省略）
            uploadedCoverImage(image) {
              this.enableConfirmCoverImageFlag = true;
              if(image) {
                this.tmpCoverImage = image;
              } else {
                this.tmpCoverImage = null;
              }
            },
            setCoverImageRotate(rotate) {
              this.enableConfirmCoverImageFlag = true;
              this.tmpCoverRotate = rotate;
              // console.log(this.tmpCoverRotate);
            },
            setCoverImage() {
              if(this.tmpCoverRotate) { // 回転情報がある場合はtmp画像を作り直す
                if(this.tmpCoverImage) {
                  // console.log('1');
                  // console.log(this.tmpCoverImage);
                  // console.log(this.tmpCoverRotate);
                  // tmpがぞうをもっかい回転させて再保存
                  this.updateTmpCoverImage(this.tmpCoverImage, '/storage/images/tmp/org/', this.tmpCoverRotate, 'cover_image');
                } else if(this.cover_image.file_name) {
                  // console.log('2');
                  // console.log(this.cover_image);
                  // console.log(this.tmpCoverRotate);
                  // 既存画像を回転させて再保存
                  this.updateTmpCoverImage(this.cover_image.file_name, '/storage/images/org/cover_image/', this.tmpCoverRotate, 'cover_image');
                } else {
                }
              } else if(this.tmpCoverImage) {
                this.cover_image = {
                  file_name: this.tmpCoverImage,
                  prevDir: '/storage/images/tmp/prv/',
                  rotate: 0,
                  preview: {
                    src: '/storage/images/tmp/prv/' + this.tmpCoverImage,
                  },
                };
                  // console.log(this.cover_image);
                this.hideCoverImageUploader();
                // this.removeCoverImage();
                this.remove_cover_image = true;
                this.enableConfirmCoverImageFlag = false;
              } else {
                this.removeCoverImage();
                this.hideCoverImageUploader();
              }
            },

            // プレビュー用画像を作り直す
            updateTmpCoverImage(file_name, orgPath, rotate, type) {
                // console.log(file_name);
                // console.log(orgPath);
                // console.log(rotate);
                // console.log(type);
                this.hideCoverImageUploader();
                this.cover_image.wait = true;
                // フォームで送信するFormDataインスタンスつくる
                const formData = new FormData();
                // アップロードするファイルを登録する
                formData.append('file_name', file_name);
                formData.append('file_path', orgPath + file_name);
                // 送信するフィールドのデータを登録する
                formData.append('imageRotate', rotate.rotate);
                formData.append('type', type);
                // アップロード
                axios.post('/tmp-update',formData)
                .then((res)=>{
                  this.tmpCoverImage = res.data.uploadFile.filename;
                  this.tmpCoverRotate = null;
                  // console.log(this.tmpCoverImage);
                  this.setCoverImage();
                })
                .catch((error) => {
                  // console.log(error.response);
                })
            },
            setProcessing(status) {
              this.isProcessing = status;
              console.log(this.isProcessing);
            },

            onUpload(e) {
              if(this.showProgress == true) {
                // console.log(e.loaded +'/'+ e.total);
                // alert(e.loaded +'/'+ e.total);
                // プログレスバーを計算して変更
                this.$refs.progress.value = Math.floor((e.loaded * 100) / e.total);
                this.progress = Math.floor((e.loaded * 100) / e.total);
              }
            },
            deleteNode() {
              this.isProcessing = true;
              this.dialogTitle = "削除処理";
              this.dialogMessage = "削除処理中。このままお待ち下さい。";
              // this.showProgress = true;
              const formData = new FormData();
              // formData.append('_method', 'DELETE');
              // 削除リクエスト投げる
              axios.delete('/user/' + this.user.id)
              .then( response => {
                // リダイレクトURLが返ってきたら（=正常に登録できた）ページ移動する（シングルページにリダイレクト）
                if(response.data.redirect_path) {
                  document.location.href = response.data.redirect_path;
                } else {
                  this.isProcessing = false;
                  // プログレスダイアログを消す
                  this.showProgress = false;
                  // プログレスの値をリセット
                  this.progress = 0;
                  alert('例外が発生しました。Error: PhotoEdit001');
                }
              }).catch(
                (error) => {
                  this.isProcessing = false;
                  // this.showProgress = false;
                  // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                  if(error.response.data.errors) {
                    var errors = {};
                    for(var key in error.response.data.errors) {
                      errors[key] = error.response.data.errors[key].join('<br>');
                    }
                    this.errors = errors;
                    // console.log(error.response.data.errors);

                    var validationErrors = [];
                    for(var key in error.response.data.errors) {
                      validationErrors.push({
                        id: key,
                        messages: error.response.data.errors[key].map((message, key) => {
                            return {
                              id: key,
                              message: message,
                            };
                          }),
                      });
                      this.validationErrors = validationErrors;
                    }
                    // console.log(this.validtionErrors);
                    // this.echoErrors();
                    this.showValidationErrorDialog = true;

                  } else {
                    // バリデーションエラーの場合以外はエラーダイアログを表示
                    // エラーデータをほりこむ
                    this.error = error.response;
                    this.showErrorDialog = true;
                  }
                }
              );
            },
            // サブミット！
            onSubmit() {
                // フォームで送信するFormDataインスタンスつくる
                const formData = new FormData();
                // 送信するフィールドのデータを登録する
                // PUTで送ってるように偽装する（普通にPUTで送れないため）
                formData.append('_method', 'PUT');
                // 元のアイコンを削除した場合にtrueを送る（コントローラーでファイルを消すため）
                if(this.remove_icon_image) formData.append('remove_icon_image', true);
                // console.log(this.icon_image);
                // 登録するアイコン画像
                if(this.icon_image.file_name) formData.append('icon_image', this.icon_image.file_name);
                // console.log('this.icon_image.rotate');
                // console.log(this.icon_image.rotate);

                if(this.icon_image.rotate) formData.append('icon_image_rotate', this.icon_image.rotate);
                // 元のカバー画像を削除した場合にtrueを送る（コントローラーでファイルを消すため）
                if(this.remove_cover_image) formData.append('remove_cover_image', true);
                // 登録するカバー画像
                if(this.cover_image.file_name) formData.append('cover_image', this.cover_image.file_name);
                formData.append('name', this.user.name);
                formData.append('user_name', this.user.user_name);
                formData.append('area', this.user.area);
                formData.append('area_private', this.user.area_private);
                formData.append('sex', this.user.sex);
                formData.append('sex_private', this.user.sex_private);
                if(this.user.birth.year && this.user.birth.month && this.user.birth.day) {
                  formData.append('birth', [this.user.birth.year, this.user.birth.month, this.user.birth.day].join('-'));
                }
                formData.append('birth_year', this.user.birth.year);
                formData.append('birth_month', this.user.birth.month);
                formData.append('birth_day', this.user.birth.day);
                formData.append('birth_private', this.user.birth_private);
                formData.append('self_introduction', this.user.self_introduction);
                formData.append('email', this.user.email);
                if(this.user.password) formData.append('password', this.user.password);
                if(this.user.password_confirmation) formData.append('password_confirmation', this.user.password_confirmation);

                // 写真を追加または変更した場合（=ファイルのアップロードと生成処理が必要=>時間がかかる）プログレスダイアログを表示
                if(this.icon_image || this.cover_image) {
                  this.showProgress = true;
                }
                  // console.log(this.user);
                //axiosでPOST送信
                axios.post('/user/' + this.user.id, formData, { onUploadProgress: this.onUpload })
                // axios.post('/user/' + this.user.id, formData, {
                //   headers: { "X-Requested-With": "XMLHttpRequest" }
                // })
                .then( response => {
                  // プログレスダイアログを消す
                  this.showProgress = false;
                  // プログレスの値をリセット
                  this.progress = 0;
                  // console.log(response);
                  // リダイレクトURLが返ってきたら（=正常に登録できた）ページ移動する（シングルページにリダイレクト）
                  if(response.data.redirect_path) {
                    document.location.href = response.data.redirect_path;
                    // if(confirm(response.data.message + 'ページに移動しますか？')) document.location.href = response.data.redirect_path;
                  } else {
                    // console.log(response);
                    alert('例外が発生しました。Error: UserEdit001');
                  }
                }).catch(
                  (error) => {
                    // プログレスダイアログを消す
                    this.showProgress = false;
                    // プログレスの値をリセット
                    this.progress = 0;
                    // 例外処理
                    // console.log(error);
                    // console.log(error.response.status);
                    // console.log(error.response);
                    // this.success = false;
                    // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                    if(error.response.data.errors) {
                      var errors = {};
                      for(var key in error.response.data.errors) {
                        errors[key] = error.response.data.errors[key].join('<br>');
                      }
                      this.errors = errors;
                    } else {
                      // バリデーションエラーの場合以外はエラーダイアログを表示
                      // エラーデータをほりこむ
                      this.error = error.response;
                      this.showErrorDialog = true;
                    }
                  }
                );
            },
        }
    }
</script>
