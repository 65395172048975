<template>
  <form method="POST" :action="actionurl" class="register_form pure-form pure-form-stacked" enctype="multipart/form-data" @submit.prevent>

        <div class="invalid-feedback" role="alert" v-html="errors.self_introduction"></div>
                              <!-- <li v-for="(value, key, index) in validationErrors()">@{{ value }}</li> -->

        <fieldset class="">

          <div class="form-group required">
            <div class="form_header">
              <span class="label">作品ファイル</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <image-uploader title="作品ファイル" name="pg" :current="node.file_name" :prvpath="'/storage/images/photocontestentory/sample/'"
                @setFile="setFile"
                @setDateTime="setDateTime"
                @setExif="setExif"
                @setProcessing="setProcessing"
                @setImageRotate="setImageRotate">
                </image-uploader>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.file"></div>
                <div class="note">30MBまでのJPEG形式の画像ファイル</div>
              </div>
            </div>
          </div>

          <div class="form-group required">
            <div class="form_header">
              <span class="label">作品のタイトル</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input id="title" type="text" class="pure-input-1" :class="{ 'is-invalid':errors.title }" name="title" v-model="node.title" required autocomplete="title" autofocus>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.title"></div>
                <div class="note">この作品のタイトルを入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group required">
            <div class="form_header">
              <span class="label">撮影場所</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input id="location" type="text" class="pure-input-1" :class="{ 'is-invalid':errors.location }" name="location" v-model="node.location" required autocomplete="location" autofocus>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.location"></div>
                <div class="note">この作品を撮影された場所を入力してください。</div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group required">
            <div class="form_header">
              <span class="label">お名前（ハンドルネーム可）</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input id="author_display_name" type="text" class="pure-input-1" :class="{ 'is-invalid':errors.author_display_name }" name="author_display_name" v-model="node.author_display_name" required autocomplete="author_display_name" autofocus>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.author_display_name"></div>
                <div class="note">一般向けに公開するお名前を入力して下さい。<br />※複数の作品を応募される場合はお名前を統一して下さい。</div>
              </div>
            </div>
          </div>

          <div class="form-group required">
            <div class="form_header">
              <span class="label">連絡用メールアドレス</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input id="author_mail" type="text" class="pure-input-1" :class="{ 'is-invalid':errors.author_mail }" name="author_mail" v-model="node.author_mail" required autocomplete="author_mail" autofocus>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.author_mail"></div>
                <div class="note">メールアドレスは公開されません。<br />入賞された場合、このメールアドレス宛てに通知を行います。 連絡が取れない場合は賞品の発送ができませんので、間違いのないようにお願いいたします。</div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="" v-if="isStaff">
          <div class="form-group">
            <div class="form_header">
              <span class="label">公開状態</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <status-checker :config_status="config_status" :enable_values="enable_status" :currentStatus="node.status ? node.status : 40" @setStatus="setStatus"></status-checker>
              </div>
              <div class="form_note">
                <div class="note">公開状態を選択してください</div>
              </div>
            </div>
          </div>
        </fieldset>

        <!-- {{ user.roles }} -->
        <!-- {{ user.roles.some(role => role.role <= 20) }} -->

        <div class="form_buttons">
          <a v-if="nodeprop" v-bind:disabled="isProcessing" class="btn cancelBtn" v-bind:href="'/photo/' + nodeprop.id">戻る</a>
          <button v-if="nodeprop" v-bind:disabled="isProcessing" type="button" class="btn btn-primary" @click.prevent.stop="onSubmit()">更新</button>
          <button v-else type="button" v-bind:disabled="isProcessing" class="btn btn-primary" @click.prevent.stop="onSubmit()">投稿</button>
          <button v-if="nodeprop && user.roles && user.roles.some(role => role.role <= 20)" v-bind:disabled="isProcessing" type="button" class="btn btn-warning" @click="deleteNode">削除</button>
        </div>

        <div class="dialog dialog-t" v-if="showProgress">
          <div class="inner">
            <div class="dialog-header">
              {{dialogTitle}}
            </div>
            <div class="dialog-content">
              <div class="dialog-loading">
                <span class="loader icon">Loading...</span>
                <span>{{dialogMessage}}</span>
              </div>
              <!--
              <div v-if="progress != 100">
                ファイルをアップロード中... {{progress}}%
                <progress ref="progress" value="0" max="100"></progress>
              </div>
              <div v-if="progress == 100" class="icon-inline">
              </div>
              -->
            </div>
          </div>
        </div>

        <div class="dialog dialog-t" v-if="showValidationErrorDialog">
          <div class="inner">
            <div class="dialog-header">
              入力内容をご確認ください
            </div>
            <div class="dialog-content">
              <ul v-for="error in validationErrors" :key="error.id">
                <li v-for="message in error.messages" :key="message.id">{{ message.message }}</li>
              </ul>
              <div class="buttons">
                <button type="button" class="btn btn-primary" @click="showValidationErrorDialog = false">OK</button>
              </div>
            </div>
          </div>
        </div>

        <div class="dialog dialog-t" v-if="showErrorDialog">
          <div class="inner">
            <div class="dialog-header">
              {{error.status}} {{error.statusText}}
            </div>
            <div class="dialog-content">
              <div>{{error.data.message}}</div>
              <div class="buttons">
                <button type="button" class="btn btn-primary" @click="showErrorDialog = false">OK</button>
              </div>
            </div>
          </div>
        </div>

  </form>

</template>

<script>
    import ImageUploader from './ImageUploader'
    import ImageUploaderPop from './ImageUploaderPop'
    import TagEditor from './TagEditor';
    import CategoryChecker from './CategoryChecker';
    import TextareaAutoAdjust from './form_element/TextareaAutoAdjust';
    import StatusChecker from './form_element/StatusChecker';
    export default {
        components: {
            ImageUploader,
            TagEditor,
            CategoryChecker,
            TextareaAutoAdjust,
            StatusChecker,
        },
        props: {
          // nodeプロパティ
            nodeprop: {
                type: Object,
                default: null,
                required: false,
            },
            // フォームの送信先
            actionurl: {
                type: String,
                required: true,
            },
            user: {
                type: Object,
                default: null,
                required: false,
            },
            config_status: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            isAdmin: false,
            isStaff: false,
            // post
            node: {
              file_name: '',
              title: '',
              location: '',
              exif: null,
              author_display_name: '',
              author_mail: '',
            },
            enable_status: [ // {1: "公開", 5: "限定公開", 10: "予約", 20: "下書き", 40: "承認申請中", 45: "承認却下", 60: "公開制限中", 90: "非公開"}
              // 1, 5, 10, 20, 40, 45, 60, 90,
              1, 40, 45, 60, 90,
            ],
            // 写真の登録用画像
            file: null,
            // 画像の回転情報（0〜3）
            imageRotate: 0,
            // // 写真のプレビュー用画像
            // file_image_preview: null,
            // 元の写真を削除したらtrue
            remove_file: false,
            // バリデーションエラーダイアログ表示フラグ
            showValidationErrorDialog: false,
            // バリデーションエラーダイアログ表示フラグ
            validationErrors: [],
            // エラーダイアログ表示フラグ
            showErrorDialog: false,
            // 返ってきたerror.responceが入る
            error: {},
            // バリデーションエラーの内容が入る
            errors: {},
            // プログレスダイアログ用の進捗状況（%）
            progress: 0,
            // プログレスタイトル
            dialogTitle: '投稿処理',
            // プログレスメッセージ
            dialogMessage: 'データを処理中。完了後は自動的にページに移動します。',
            // プログレスダイアログ表示フラグ
            showProgress: false,
            // プレイスでマッチしたプレイスデータを入れる
            findPlaceResults: [],
            // matchPlaceResults: [],
            // 処理中はtrueにするとボタンが無効になる
            isProcessing: false,
        }),
        created() {
            this.isAdmin = this.user ? this.user.roles.some(user => user.role === 1) : false;
            this.isStaff = this.user ? this.user.roles.some(user => user.role === 1 || user.role === 10 || user.role === 20) : false;
        },
        computed: {
          // プレイスの入力値にマッチするスポットの情報を返す
          matchPlaceResults() {
            if(this.node.places) {
              return this.node.places.map((val, key) => {
                var match = null;
                if(this.findPlaceResults.length >= 1) {
                  match = this.findPlaceResults.find(v => v.place == val.place);
                }
                if(match && match.spots.length >= 1) {
                  return {key: key, place: val.place, spots: match.spots}
                } else {
                  return {key: key, place: val.place, spots: null}
                }
              });
            }
          },
        },
        methods: {
            setStatus(status) {
              this.node.status = status;
              // console.log(this.node.status);
            },
            // ファイルを追加（アップローダーコンポーネントで画像追加したらこれが発火 ※引数にファイルが入ってくる）
            // または ファイルを削除（アップローダーコンポーネントで画像削除したらこれが発火 ※引数はnull）

            setProcessing(status) {
              this.isProcessing = status;
              console.log(this.isProcessing);
            },
            setFile(file) {
              console.log('setFile');
                if(file) { // ファイルが送られてきたら（=画像が追加された）
                  // 登録用画像
                  this.file = file;
                } else { // 引数がnullだったら（=画像が削除された）
                  // 登録用画像を削除
                  this.file = null;
                }
                if(this.node.file_name) {
                  // nodeのfileを削除
                  this.node.file_name = null;
                  // 古いの消したよフラグ立てる
                  this.remove_file = true;
                }
              console.log('setFile2');
            },
            setImageRotate(imageRotate) {
                // console.log(imageRotate);
                this.imageRotate = imageRotate;
            },
            setDateTime(date_time) {
                // console.log(date_time);
                this.node.date_time = date_time;
                // console.log('セット:'+this.node.date_time);
            },
            setExif(exif) {
                // console.log("exexexexexexe");
                // console.log(exif);
                this.node.exif = exif;
            },
            onUpload(e) {
              if(this.showProgress == true) {
                // console.log(e.loaded +'/'+ e.total);
                // alert(e.loaded +'/'+ e.total);
                // プログレスバーを計算して変更
                this.$refs.progress.value = Math.floor((e.loaded * 100) / e.total);
                this.progress = Math.floor((e.loaded * 100) / e.total);
              }
            },
            deleteNode() {
              this.isProcessing = true;
              this.dialogTitle = "削除処理";
              this.dialogMessage = "削除処理中。このままお待ち下さい。";
              this.showProgress = true;
              const formData = new FormData();
              // formData.append('_method', 'DELETE');
              // 削除リクエスト投げる
              axios.delete('/photo/' + this.node.id)
              .then( response => {
                // // プログレスダイアログを消す
                // this.showProgress = false;
                // // プログレスの値をリセット
                // this.progress = 0;
                // console.log('response↓');
                // console.log(response.data);
                // リダイレクトURLが返ってきたら（=正常に登録できた）ページ移動する（シングルページにリダイレクト）
                if(response.data.redirect_path) {
                  document.location.href = response.data.redirect_path;
                  // if(confirm(response.data.message + 'ページに移動しますか？')) document.location.href = response.data.redirect_path;
                } else {
                  this.isProcessing = false;
                  // console.log(response);
                  // console.log('test');
                  // console.log('response');
                  // console.log(response);
                  // console.log('response');
                  // console.log(response.data.redirect_path);
                  // console.log('node↓');
                  // console.log(response.data.node);
                  // プログレスダイアログを消す
                  this.showProgress = false;
                  // プログレスの値をリセット
                  this.progress = 0;
                  alert('例外が発生しました。Error: PhotoEdit001');
                }
              }).catch(
                (error) => {
                  this.isProcessing = false;
                  this.showProgress = false;
                  // 例外処理
                  // console.log(error);
                  // console.log(error.response.status);
                  // console.log(error.response);
                  // this.success = false;
                  // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                  if(error.response.data.errors) {
                    var errors = {};
                    for(var key in error.response.data.errors) {
                      errors[key] = error.response.data.errors[key].join('<br>');
                    }
                    this.errors = errors;
                    // console.log(error.response.data.errors);

                    var validationErrors = [];
                    for(var key in error.response.data.errors) {
                      validationErrors.push({
                        id: key,
                        messages: error.response.data.errors[key].map((message, key) => {
                            return {
                              id: key,
                              message: message,
                            };
                          }),
                      });
                      this.validationErrors = validationErrors;
                    }
                    // console.log(this.validtionErrors);
                    // this.echoErrors();
                    this.showValidationErrorDialog = true;

                  } else {
                    // バリデーションエラーの場合以外はエラーダイアログを表示
                    // エラーデータをほりこむ
                    this.error = error.response;
                    this.showErrorDialog = true;
                  }
                }
              );
            },
            // サブミット！
            onSubmit() {
              // console.log('submit!');
              // alert('送信');
                this.isProcessing = true;
                this.dialogTitle = "投稿処理";
                this.dialogMessage = "データを処理中。完了後は自動的にページに移動します。";
                this.showProgress = true;
                // フォームで送信するFormDataインスタンスつくる
                const formData = new FormData();
                // 送信するフィールドのデータを登録する
                // propで投稿情報が来てたら（updateだったら）
                if(this.nodeprop) {
                  // PUTで送ってるように偽装する（普通にPUTで送れないため）
                  formData.append('_method', 'PUT');
                }
                // 元の写真を削除した場合にtrueを送る（コントローラーでファイルを消すため）
                if(this.remove_file) formData.append('remove_file', true);
                // console.log(this.file);
                // 登録する写真を登録
                if(this.file) {
                  formData.append('file', this.file);
                  formData.append('date_time', (this.node.date_time === "undefined" || this.node.date_time === null) ? "" :this.node.date_time);
                }
                // 写真の回転情報を登録
                if(this.imageRotate.rotate) formData.append('imageRotate', this.imageRotate.rotate);
                // console.log(this.imageRotate.rotate);
                // console.log(this.file);
                // return false;
                // すでに写真があって更新しない場合file_noupd=true をセットする（バリデーションで使うため）
                if(this.node.file_name && !this.file && !this.remove_file) formData.append('file_noupd', true);
                // その他のデータを登録
                formData.append('title', this.node.title);
                formData.append('location', this.node.location);
                formData.append('date_time', this.node.date_time);
                formData.append('exif', JSON.stringify(this.node.exif));
                formData.append('author_display_name', this.node.author_display_name);
                formData.append('author_mail', this.node.author_mail);
                if(this.node.status != null) formData.append('status', this.node.status);
                // 写真を追加または変更した場合（=ファイルのアップロードと生成処理が必要=>時間がかかる）プログレスダイアログを表示
                if(this.file || false) {
                  this.showProgress = true;
                }
                // //config定義（headersを指定）
                // const config = {headers:{
                //   'Content-Type': 'multipart/form-data',
                //   'onUploadProgress': this.onUpload,
                // }}
                // //axiosでPOST送信
                // axios.post(this.actionurl, formData, config)
                //axiosでPOST送信
                axios.post(this.actionurl, formData, {
                  onUploadProgress: this.onUpload,
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then( response => {
                  // alert('submit4');
                  // // プログレスダイアログを消す
                  // this.showProgress = false;
                  // // プログレスの値をリセット
                  // this.progress = 0;
                  // console.log('response↓');
                  // console.log(response.data);
                  // リダイレクトURLが返ってきたら（=正常に登録できた）ページ移動する（シングルページにリダイレクト）
                  if(response.data.redirect_path) {
                    document.location.href = response.data.redirect_path;
                    // if(confirm(response.data.message + 'ページに移動しますか？')) document.location.href = response.data.redirect_path;
                  } else {
                    this.isProcessing = false;
                    // console.log(response);
                    // console.log('test');
                    // console.log('response');
                    // console.log(response);
                    // console.log('response');
                    // console.log(response.data.redirect_path);
                    // console.log('node↓');
                    // console.log(response.data.node);
                    // プログレスダイアログを消す
                    this.showProgress = false;
                    // プログレスの値をリセット
                    this.progress = 0;
                    alert('例外が発生しました。Error: PhotoEdit001');
                  }
                }).catch(
                  (error) => {
                    this.isProcessing = false;
                    this.showProgress = false;
                    // 例外処理
                    // console.log(error);
                    // console.log(error.response.status);
                    // console.log(error.response);
                    // this.success = false;
                    // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                    if(error.response.data.errors) {
                      var errors = {};
                      for(var key in error.response.data.errors) {
                        errors[key] = error.response.data.errors[key].join('<br>');
                      }
                      this.errors = errors;
                      // console.log(error.response.data.errors);

                      var validationErrors = [];
                      for(var key in error.response.data.errors) {
                        validationErrors.push({
                          id: key,
                          messages: error.response.data.errors[key].map((message, key) => {
                              return {
                                id: key,
                                message: message,
                              };
                            }),
                        });
                        this.validationErrors = validationErrors;
                      }
                      // console.log(this.validtionErrors);
                      // this.echoErrors();
                      this.showValidationErrorDialog = true;

                    } else {
                      // バリデーションエラーの場合以外はエラーダイアログを表示
                      // エラーデータをほりこむ
                      this.error = error.response;
                      this.showErrorDialog = true;
                    }
                    this.isProcessing = false;
                  }
                );
            },
        }
    }
</script>
