<template>

  <form method="POST" class="recommendBtn pure-form pure-form-stacked" @submit.prevent @click.stop>
    <div
      class="recommend_btn"
      v-bind:class="['btn_' + status, type == 9 ? 'pr' : '' ]"
      @pointerdown.prevent="startPress"
      @pointerup.prevent="endPress"
      @pointermove="pointerMove"
      @pointercancel="pointerCancel"
    >
      <!-- <img class="recommend_icon" src="/img/icon_recommend.svg" width="35"> -->
      <svg class="recommend_icon" viewBox="0 0 28.35 28.35">
        <path class="star" d="M15.85,3.63l2.89,5.34,5.97,1.1c1.49.28,2.08,2.1,1.04,3.2l-4.18,4.4.8,6.02c.2,1.5-1.35,2.63-2.72,1.98l-5.48-2.62-5.48,2.62c-1.37.65-2.92-.47-2.72-1.98l.8-6.02-4.18-4.4c-1.04-1.1-.45-2.92,1.04-3.2l5.97-1.1,2.89-5.34c.72-1.33,2.64-1.33,3.36,0Z"/>
      </svg>
      <div class="recommend_status">
        <span class="recommend_id">{{id}}:</span>
        <span v-if="status == 'recommended' && ( date_start || date_end )" class="recommend_term">
          <svg class="clock_icon" viewBox="0 0 28.35 28.35">
            <circle class="base" cx="14.17" cy="14.17" r="12.26"/>
            <polyline class="hands" points="14.17 6.71 14.17 14.17 21.64 14.17"/>
          </svg>
        </span>
        <span v-if="status == 'recommended'" class="recommend_weight">{{weight}}</span>
      </div>
    </div>
    <div class="controllers" v-if="open">
      <label class="weight">
        <span class="form_header">ウェイト（1～100）</span>
        <input
          type="number"
          name="weight"
          pattern="\d*"
          class=""
          autocomplete="off"
          autofocus
          maxlength="3"
          :min="1"
          :max="100"
          v-model="weight_reserve"
        >
      </label>
      <label class="term">
        <span class="form_header">有効期間（YYYY-MM-DD）</span>
        <div class="form_input">
          <input type="text" class="pure-input-1" name="date_start" v-model="date_start" placeholder="" autofocus>
          <span>～<input type="text" class="pure-input-1" name="date_end" v-model="date_end" placeholder="" autofocus></span>
        </div>
      </label>
      <label class="type single_checkbox">
        <input type="checkbox" class="" name="type" value="9" autocomplete="type" true-value="9" false-value="0" v-model="type">PR
      </label>
      <div class="btns">
        <button v-bind:disabled="isProcessing" type="button" class="btn" @click="cancel()">Cancel</button>
        <button v-bind:disabled="isProcessing" type="button" class="btn btn-primary" @click="doRecommend()">OK</button>
      </div>
    </div>
  </form>

</template>

<script>
  // import ImageUploader from './ImageUploader'
  // import ImageUploaderPop from './ImageUploaderPop'
  // import TagEditor from './TagEditor';
  // import CategoryChecker from './CategoryChecker';
  // import TextareaAutoAdjust from './form_element/TextareaAutoAdjust';
  // import StatusChecker from './form_element/StatusChecker';
  export default {
    // components: {
    //     ImageUploader,
    //     TagEditor,
    //     CategoryChecker,
    //     TextareaAutoAdjust,
    //     StatusChecker,
    // },
    props: {
      model: {
        type: String,
        required: true,
      },
      id: {
        type: Number,
        required: true,
      },
      recommendobj: {
        type: Object,
        default: null,
        required: false,
      },
    },
    data: () => ({
      delay: 300,
      pressTimer: null,
      isPressing: false,
      status: 'recommend',
      weight: 50,
      weight_reserve: 50,
      type: 0,
      date_start: null,
      date_start_reserve: null,
      date_end: null,
      date_end_reserve: null,
      open: false,
      // 処理中はtrueにするとボタンが無効になる
      isProcessing: false,
    }),
    created() {
      if(this.recommendobj.id) {
        // console.log(this.recommendobj);
        this.status = 'recommended';
        this.weight = this.recommendobj.weight;
        this.weight_reserve = this.weight;
        this.type = this.recommendobj.type;
        this.date_start = this.recommendobj.date_start;
        this.date_start_reserve = this.date_start;
        this.date_end = this.recommendobj.date_end;
        this.date_end_reserve = this.date_end;
      } else {
        this.status = 'recommend';
      }
      // $this.recommend = $this.recommended;
      // $this.recommend = $this.recommended;
    },
    // computed: {
    //   // プレイスの入力値にマッチするスポットの情報を返す
    //   matchPlaceResults() {
    //     if(this.node.places) {
    //       return this.node.places.map((val, key) => {
    //         var match = null;
    //         if(this.findPlaceResults.length >= 1) {
    //           match = this.findPlaceResults.find(v => v.place == val.place);
    //         }
    //         if(match && match.spots.length >= 1) {
    //           return {key: key, place: val.place, spots: match.spots}
    //         } else {
    //           return {key: key, place: val.place, spots: null}
    //         }
    //       });
    //     }
    //   },
    // },
    methods: {
      onClickOutside(e) {
        this.open = false;
      },
      startPress() {
        this.pressTimer = setTimeout(() => {
          this.onLongPress()
          this.isPressing = true;
        }, this.delay)
      },
      endPress() {
        if(!this.pressTimer || !this.isPressing || !this.open) {
          this.togglehRecommend();
        }
        clearTimeout(this.pressTimer)
        this.pressTimer = null
      },
      pointerMove() {
        if(!this.pressTimer) return
        this.isPressing = false;
        clearTimeout(this.pressTimer)
        this.pressTimer = null
      },
      pointerCancel() {
        if(!this.pressTimer) return
        this.isPressing = false;
        clearTimeout(this.pressTimer)
        this.pressTimer = null
      },
      onLongPress() {
        this.open = true;
      },
      doRecommend() {
        this.weight = this.weight_reserve;
        this.status = 'recommended';
        this.open = false;
        this.upd();
      },
      cancel() {
        this.date_start_reserve = this.date_start;
        this.date_end_reserve = this.date_end;
        this.open = false;
      },
      togglehRecommend() {
        // this.isActive = !this.isActive;
        if(this.status == 'recommend') {
          this.status = 'recommended';
        } else if(this.status == 'recommended') {
          this.status = 'recommend';
        } else {
          this.status = 'recommend';
        }
        this.upd();
      },
      upd() {
        // フォームで送信するFormDataインスタンスつくる
        const formData = new FormData();
        // データを登録
        formData.append('status', this.status);
        formData.append('model', this.model);
        formData.append('id', this.id);
        if(this.recommendobj) formData.append('recommend_id', this.recommendobj.id);
        formData.append('weight', this.weight);
        formData.append('type', this.type);
        if(this.date_start) formData.append('date_start', this.date_start);
        if(this.date_end) formData.append('date_end', this.date_end);
        axios.post('/recommend', formData)
        .then( response => {

        }).catch(
          (error) => {

          }
        );
      },
    },
    beforeMount() {
      document.addEventListener("click", this.onClickOutside);
    },
    beforeDestroy() {
      document.removeEventListener("click", this.onClickOutside);
    },
  }
</script>

<style lang="scss" scoped>
  .recommendBtn {
    position: absolute;
    top: .5rem;
    right: .5rem;
    .recommend_btn {
      cursor: pointer;
      position: relative;
      width: 3rem;
      height: 3rem;
      // background-color: #CCCCCC;
        /* 水平・垂直方向の中央揃え */
        display: flex;
        justify-content: center;
        align-items: center;
      .recommend_icon {
        // position: absolute;
        // bottom: .3rem;
        // right: .3rem;
        // width: 3rem;
        transition: .1s;
        width: 3rem;
        height: 3rem;
        // width: 3rem;
        .star {
          stroke-width: .15rem;
          transition: .1s;
          fill: rgba(180, 180, 180, 1);
          stroke: #000;
        }
        // color: rgba(0, 0, 0, 0.5);
        // text-shadow:
        //   1px 1px 0 #000, -1px -1px 0 #000,
        //   -1px 1px 0 #000,  1px -1px 0 #000,
        //   1px 0 0 #000, -1px  0 0 #000,
        //   0 1px 0 #000,  0 -1px 0 #000;
        // font-size: 2.4rem;
      }
      .recommend_status {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        /* 水平・垂直方向の中央揃え */
        display: flex;
        justify-content: center;
        align-items: center;
        .recommend_id {
          display: none;
        }
        .recommend_weight {
          // position: absolute;
          // // bottom: -0.3rem;
          // top: 50%;
          // left: 50%;
          // margin-right: -50%;
          // transform: translate(-50%, -50%);
          font-size: 1.1rem;
          line-height: 1;
          padding-top: .4rem;
          color: rgba(255, 255, 255, 1);
          text-shadow:
            1px 1px 0 #000, -1px -1px 0 #000,
            -1px 1px 0 #000,  1px -1px 0 #000,
            1px 0 0 #000, -1px  0 0 #000,
            0 1px 0 #000,  0 -1px 0 #000;
        }
        .recommend_term {
          // position: absolute;
          // top: -0.2rem;
          // right: -0.2rem;
          padding-top: .2rem;
          margin-left: -0.3rem;
          margin-right: .15rem;
          width: 1.3rem;
          height: 1.3rem;
          line-height: 0;
          .clock_icon {
            width: 1.3rem;
            height: 1.3rem;
            .base, .hands {
              width: 1.3rem;
              height: 1.3rem;
              stroke: #000;
              stroke-linejoin: round;
            }
            .base {
              fill: #fff;
              stroke-linecap: round;
              stroke-width: .3rem;
            }
            .hands {
              fill: #fff;
              stroke-width: .3rem;
            }
          }
        }
      }
    }
    .btn_recommended {
      .recommend_icon {
        // bottom: 0;
        // right: 0;
        // width: 3.6rem;
        transform: scale(1.2);
        .star {
          fill: rgba(255, 170, 0, 1);
          stroke: #000;
        }
        // color: rgba(255, 221, 0, 1);
      }
      &.pr {
        .star {
          fill: rgba(70, 160, 255, 1);
        }
      }
    }
    .controllers {
      position: absolute;
      top: 2.3rem;
      right: 1rem;
      z-index: 90;
      min-width: 260px;
      max-width: 90%;
      background-color: #fff;
      padding: 1rem;
      border: 1px solid #ccc;
      border-radius: 8px;
      box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.4);
      .weight {
        display: flex;
        justify-content: center;
        align-items: center;
        .form_header {
          width: auto;
        }
        input {
          width: 7rem;
        }
      }
      .term {
        margin-top: 1rem;
        .form_header {
          display: block;
          text-align: center;
        }
        .form_input {
          display: flex;
          justify-content: center;
          input {
            width: 11rem;
          }
        }
      }
      .type {
          display: flex;
          justify-content: center;
          margin: 0 auto 1rem;
        input {
          margin-right: .5rem;
        }
      }
      .btns {
        margin-top: .5rem;
        text-align: center;
      }
    }
  }
  // スライダー（カルーセル）で複製されたものの場合はボタン押せないのでボタンを超薄くする
  .swiper-slide-duplicate .recommendBtn {
    opacity: .2;
  }
</style>
