<template>
  <div class="articleEdit">
    <form method="POST" :action="actionurl" class="register_form pure-form pure-form-stacked" enctype="multipart/form-data" @submit.prevent>


        <fieldset class="">

          <div class="form-group required">
            <div class="form_header">
              <span class="label">タイトル</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1" v-bind:class="{ 'is-invalid':errors.title }" name="name" v-model="node.title" required autocomplete="title" autofocus>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.title"></div>
                <div class="note"></div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">写真</span>
            </div>
            <div class="form_body">
              <div class="form_input relative">
                <image-preview-multiple
                :images="articleImages"
                @remove="index => removeArticleImage(index)"
                @onsort="images => sortArticleImage(images)"
                @setImageRotate="(imageRotate, index) => setImageRotate(imageRotate, index)"
                ></image-preview-multiple>
                <image-uploader-with-photo
                name="article_image"
                tmpFormatName="article_image"
                :image-que="articleImageQue"
                :multiple="true"
                @addQue="addQue"
                @setFile="setFile"
                @setProcessing="setProcessing"
                >
                </image-uploader-with-photo>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.image"></div>
              <div class="note">30MBまでのJPEG形式の画像ファイル</div>
              </div>
            </div>
            <!-- <textarea style="max-width:100%;min-height:400px;">{{ articleImages }}</textarea> -->
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group required">
            <div class="form_header">
              <span class="label">記事本文</span>
            </div>
            <div class="form_body">
              <multi-element-editor :body="node.body" @setBody="val => setBody(val)" @removeCurrentElement="id => removeCurrentElement(id)"></multi-element-editor>
            </div>
            <!-- <textarea style="max-width:100%;min-height:200px;">{{ removedCurrentBodyElements }}</textarea> -->
            <!-- <textarea style="max-width:100%;min-height:200px;">{{ node.body }}</textarea> -->
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">プレイス（スポット）</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <tag-editor :tagColumn="'place'" :class="{ 'is-invalid':errors.places }" :currentTags="node.places" @setTags="setPlaces"></tag-editor>
              </div>
              <div v-if="node.places">
                <div v-for="result in matchPlaceResults" :key="result.key">
                  <div v-if="result.spots">
                    <div class="place">「{{ result.place }}」で
                      <a v-for="spot in result.spots" :key="spot.id" :href="'/spot/' + spot.id" target="_blank">{{ spot.name }}</a> と関連付けられます。
                    </div>
                  </div>
                  <div v-else>
                    <div class="place">「{{ result.place }}」でマッチするスポットが今の所登録されていません。</div>
                  </div>
                </div>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.places"></div>
                <div class="note">この記事に関連する場所や施設の名前を全て入力してください。文中に具体的に名前が出ていなくても記事と深く関連性があるなど必要な場合は入れてください。ここに入力された名前は当サイトに掲載のスポットと照合され、自動的に関連づけられます（なるべく一般的と思われる名前を推奨します。）。複数ある場合はカンマ区切りで入力してください。</div>
                <div class="note">例: <br>金閣寺の場合→「金閣寺, 鹿苑寺, 鹿苑寺金閣」<br>金戒光明寺の場合→「金戒光明寺, 黒谷さん, 黒谷, くろ谷さん, くろ谷」など</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">タグ</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <tag-editor :tagColumn="'tag'" :currentTags="node.tags" @setTags="setTags"></tag-editor>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.tags"></div>
                <div class="note">この記事に関連するワードをタグとして入力します。同じタグがついた他のページと関連づけられます。カンマ区切りで入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group required">
            <div class="form_header">
              <span class="label">カテゴリー</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <category-checker :entity="'article'" :currentCategories="node.categories" @setCategories="setCategories"></category-checker>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.categories"></div>
                <div class="note">該当するカテゴリーを全てチェックしてください。最低でも1つチェックが必要です。</div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="">
          <div class="form-group">
            <div class="form_header">
              <span class="label">下書き</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <label><input type="checkbox" class="" v-model="isDraft" @change="setDraft">この記事を下書きとして保存する</label>
              </div>
              <div class="form_note">
                <div class="note">実際の表示を確認したい場合や、編集を一旦中断して後で再開したい場合は、これをチェックしてこの投稿を下書きとして一旦保存します。下書きの状態の記事は審査、掲載されません。</div>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset class="" v-if="isStaff">
          <div class="form-group">
            <div class="form_header">
              <span class="label">公開状態</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <status-checker :config_status="config_status" :enable_values="enable_status" :currentStatus="node.status ? node.status : 40" @setStatus="setStatus"></status-checker>
              </div>
              <div class="form_note">
                <div class="note">公開状態を選択してください</div>
              </div>
            </div>
          </div>
        </fieldset>

        <div class="form_buttons">
          <a v-if="nodeprop" v-bind:disabled="isProcessing" class="btn cancelBtn" v-bind:href="'/report/' + nodeprop.id">戻る</a>
          <button v-if="nodeprop" v-bind:disabled="isProcessing" type="button" class="btn btn-primary" @click.prevent.stop="onSubmit()">更新</button>
          <button v-else type="button" v-bind:disabled="isProcessing" class="btn btn-primary" @click.prevent.stop="onSubmit()">投稿</button>
          <button v-if="nodeprop && user.roles && user.roles.some(role => role.role <= 20)" type="button" v-bind:disabled="isProcessing" class="btn btn-warning" @click="deleteNode">削除</button>
        </div>

        <div class="dialog dialog-t" v-if="showProgress">
          <div class="inner">
            <div class="dialog-header">
              投稿処理
            </div>
            <div class="dialog-content">
              <div v-if="progress > 0 && progress < 100">
                画像ファイルアップロード中...
                <progress ref="progress" value="0" max="100"></progress><br>{{progress}}%
              </div>
              <div v-if="!progress || progress <= 0 && progress >= 100" class="icon-inline">
                <span class="loader icon">Loading...</span>
                <span>ページ生成中...</span>
              </div>
            </div>
          </div>
        </div>

        <div class="dialog dialog-t" v-if="showValidationErrorDialog">
          <div class="inner">
            <div class="dialog-header">
              入力内容をご確認ください
            </div>
            <div class="dialog-content">
              <ul v-for="error in validationErrors" :key="error.id">
                <li v-for="message in error.messages" :key="message.id">{{ message.message }}</li>
              </ul>
              <div class="buttons">
                <button type="button" class="btn btn-primary" @click="showValidationErrorDialog = false">OK</button>
              </div>
            </div>
          </div>
        </div>

        <div class="dialog dialog-t" v-if="showErrorDialog">
          <div class="inner">
            <div class="dialog-header">
              {{error.status}} {{error.statusText}}
            </div>
            <div class="dialog-content">
              <div>{{error.data.message}}</div>
              <div class="buttons">
                <button type="button" class="btn btn-primary" @click="showErrorDialog = false">OK</button>
              </div>
            </div>
          </div>
        </div>

    </form>
  </div>

</template>

<script>
    import ImageUploaderWithPhoto from './form_element/ImageUploaderWithPhoto';
    import MultiElementEditor from './MultiElementEditor';
    import TagEditor from './TagEditor';
    import CategoryChecker from './CategoryChecker';
    import draggable from 'vuedraggable';
    import ImagePreviewMultiple from './ImagePreviewMultiple'
    import TextareaAutoAdjust from './form_element/TextareaAutoAdjust';
    import StatusChecker from './form_element/StatusChecker';
    export default {
        components: {
            ImageUploaderWithPhoto,
            MultiElementEditor,
            TagEditor,
            CategoryChecker,
            draggable,
            ImagePreviewMultiple,
            TextareaAutoAdjust,
            StatusChecker,
        },
        props: {
          // nodeプロパティ
            nodeprop: {
                type: Object,
                default: null,
                required: false,
            },
            // フォームの送信先
            actionurl: {
                type: String,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
            config_status: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            isAdmin: false,
            isStaff: false,
            isDraft: false,
            beforeDraft: null,
            enable_status: [ // {1: "公開", 5: "限定公開", 10: "予約", 20: "下書き", 40: "承認申請中", 45: "承認却下", 60: "公開制限中", 90: "非公開"}
              // 1, 5, 10, 20, 40, 45, 60, 90,
              1, 20, 40, 45, 60, 90,
            ],
            // post
            node: {
              title: '',
              body: [],
              places: [],
            },
            removedCurrentBodyElements: [],
            // 写真のデータ
            articleImages: [],
            articleImageQue: 0,
            removedArticleImages: [],
            // // 画像を追加してプレビューが描画されるまでのキュー数
            // showPreviewQue: 0,
            // 元の写真を削除したらtrue
            remove_file: false,
            // エラーダイアログ表示フラグ
            showErrorDialog: false,
            // 返ってきたerror.responceが入る
            error: {},
            // バリデーションエラーの内容が入る
            errors: {},
            // プログレスダイアログ用の進捗状況（%）
            progress: 0,
            // プログレスダイアログ表示フラグ
            showProgress: false,
            areas: [],
            areaChecked: [],
            // バリデーションエラーダイアログ表示フラグ
            showValidationErrorDialog: false,
            // バリデーションエラーダイアログ表示フラグ
            validationErrors: [],
            // プレイスでマッチしたプレイスデータを入れる
            findPlaceResults: [],
            // 処理中はtrueにするとボタンが無効になる
            isProcessing: false
        }),
        created() {
            // console.log('this.nodeprop');
            // console.log(this.nodeprop);
            this.isAdmin = this.user.roles.some(user => user.role === 1);
            this.isStaff = this.user.roles.some(user => user.role === 1 || user.role === 10 || user.role === 20);
            if(this.nodeprop) {
              // ノードプロパティをdataにセット
              this.node = this.nodeprop;
              if (this.node.status == 20) {
                this.beforeDraft = null;
                this.isDraft = true;
              } else {
                this.beforeDraft = this.node.status;
                this.isDraft = false;
              }
              // console.log('this.isDraft:' + this.isDraft);
              // console.log('this.beforeDraft:' + this.beforeDraft);
              // console.log('this.node.status:' + this.node.status);

              if(this.nodeprop.article_images) {
                this.nodeprop.article_images.forEach((article_image, index) => {
                  if(article_image.file) {
                    if(article_image.file_type == 'App\\Models\\ArticleImageFile') {
                      var current_image = {
                        viewId: index + 1,
                        order: article_image.order,
                        type: 'current_article_image',
                        file_id: article_image.file_id,
                        file_name: article_image.file.file_name,
                        landscape: article_image.file.landscape,
                        preview: {
                          src: '/storage/images/article_image/thumb/' + article_image.file.file_name,
                        },
                        new: false,
                        article_image_id: article_image.id,
                      }
                    } else if(article_image.file_type == 'App\\Models\\Photo') {
                      var pg_file = this.nodeprop.photos.filter(pg => { return pg.id == article_image.file_id } );
                      var current_image = {
                        viewId: index + 1,
                        order: article_image.order,
                        type: 'current_pg',
                        file_id: article_image.file_id,
                        file_name: article_image.file.file_name,
                        landscape: article_image.file.landscape,
                        preview: {
                          src: '/storage/images/article_image/thumb/' + article_image.file.file_name,
                        },
                        new: false,
                        rotatable: true,
                        article_image_id: article_image.id,
                        user_id: article_image.file.user_id,
                        user_name: pg_file[0].user.name,
                      }
                    }
                  } else { // 通常はないけど、もしリレーション先の画像モデルが見つからない（削除されてしまってる）場合の処理。
                    var current_image = {
                      viewId: index + 1,
                      order: article_image.order,
                      type: 'current_deleted',
                      file_id: article_image.file_id,
                      file_name: null,
                      landscape: null,
                      preview: {
                        src: 'deleted',
                      },
                      new: false,
                      article_image_id: article_image.id,
                    }
                  }
                  this.articleImages.push(current_image);
                });
              }
              // console.log('this.articleImages');
              // console.log(this.articleImages);

              if(this.nodeprop.body) {
                // this.node.body = [];
                var current_body = [];
                this.nodeprop.body.forEach(body => {
                  if(body.element_type == 'App\\Models\\ElementHeader') {
                    var element = {
                      elementType: 'header',
                      new: false,
                      article_body_element_id: body.id,
                      type: body.element.type,
                      text: body.element.text,
                      order: body.order,
                      status: 'preview',
                    };
                  } else if(body.element_type == 'App\\Models\\ElementText') {
                    var element = {
                      new: false,
                      article_body_element_id: body.id,
                      type: body.element.type,
                      text: body.element.text,
                      order: body.order,
                      status: 'preview',
                    };
                    if(body.element.type == 'p') {
                      element.elementType = 'paragraph';
                    } else if(body.element.type == 'ul') {
                      element.elementType = 'list';
                    }
                  } else if(body.element_type == 'App\\Models\\ElementImage') {
                    var element = {
                      elementType: 'image',
                      new: false,
                      article_body_element_id: body.id,
                      type: 'uploadImage',
                      image: {
                        uploadFile: null,
                        file_preview_src: '/storage/images/element_image/single/' + body.element.file_name,
                        preview: {
                          src: '/storage/images/element_image/single/' + body.element.file_name,
                        },
                        rotate: 0,
                        prevStyle: {},
                        fileName: body.element.file_name,
                        nodeId: body.element_id,
                      },
                      caption: body.caption,
                      flag: {
                        uploaderShowFlag: false,
                        nowRenderingFlag: false,
                        nowChangeFlag: false,
                        isDragging: false,
                        // ドラッグ中かどうかの判定用
                        dragCount: 0,
                      },
                      order: body.order,
                      status: 'preview',
                    };
                  } else if(body.element_type == 'App\\Models\\Photo') {
                    var element = {
                      elementType: 'image',
                      new: false,
                      article_body_element_id: body.id,
                      type: 'pg',
                      image: {
                        uploadFile: null,
                        file_preview_src: '/storage/images/element_image/single/' + body.element.file_name,
                        preview: {
                          src: '/storage/images/element_image/single/' + body.element.file_name,
                        },
                        fileName: body.element.file_name,
                        nodeId: body.element_id,
                        file: {
                          name: body.element.title,
                        },
                        author: {
                          name: body.element.user_id,
                        },
                      },
                      caption: body.caption,
                      flag: {
                        uploaderShowFlag: false,
                        nowRenderingFlag: false,
                        nowChangeFlag: false,
                        isDragging: false,
                        // ドラッグ中かどうかの判定用
                        dragCount: 0,
                      },
                      order: body.order,
                      status: 'preview',
                    };
                  }
                  current_body.push(element);
                  // this.node.body.push(element);
                });
                this.setBody(current_body);
              }

            }

            if(this.node.weight === undefined) this.node.weight = 0;
            this.executeSetPlaces;
            // console.log(this.node);
            // console.log(this.actionurl);
        },
        // beforeDestroy() {
        //   this.articleImages.foreach((image, index) => this.removeArticleImage(index));
        //   alert('ですとろーい');
        // },
        computed: {
          // プレイスの入力値にマッチするスポットの情報を返す
          matchPlaceResults() {
            if(this.node.places) {
              // console.log('プレイス検索中');
              return this.node.places.map((val, key) => {
                var match = null;
                if(this.findPlaceResults.length >= 1) {
                  match = this.findPlaceResults.find(v => v.place == val.place);
                }
                if(match && match.spots.length >= 1) {
                  return {key: key, place: val.place, spots: match.spots}
                } else {
                  return {key: key, place: val.place, spots: null}
                }
              });
            }
          },
        },
        methods: {
            setDraft(event) {
              // console.log('this.isDraft');
              // console.log(this.isDraft);
              if(this.isDraft === true) {
                this.beforeDraft = this.node.status;
                this.setStatus(20);
                // console.log('1');
              } else {
                this.setStatus(this.beforeDraft);
                this.beforeDraft = null;
                // console.log('2');
              }
              // console.log('!this.isDraft:' + this.isDraft);
              // console.log('!this.beforeDraft:' + this.beforeDraft);
              // console.log('!this.node.status:' + this.node.status);
            },
            setStatus(status) {
              // console.log('status');
              // console.log(status);
              if(status == 20 && this.node.status != 20) {
                this.beforeDraft = this.node.status;
                this.isDraft = true;
              } else if(status != 20 && this.node.status == 20) {
                this.beforeDraft = null;
                this.isDraft = false;
              }
              this.node.status = status;
              // console.log('this.isDraft:' + this.isDraft);
              // console.log('this.beforeDraft:' + this.beforeDraft);
              // console.log('this.node.status:' + this.node.status);
            },
            sortArticleImage(articleImages) {
              // console.log('fire!');
              this.articleImages = articleImages;
            },
            setImageRotate(imageRotate, index) {
                // console.log(imageRotate);
                this.articleImages[index].rotate = imageRotate.rotate;
                // this.imageRotate = imageRotate;
            },
            /**
             * article_imagesの処理
             */
            addQue() {
              this.articleImageQue++;
              this.articleImages.push({
                order: this.articleImages.length + 1,
                type: 'que',
                queIndex: this.articleImageQue,
                preview: {},
              });
            },
            setProcessing(status) {
              this.isProcessing = status;
              // console.log(this.isProcessing);
            },
            // from: ImageUploaderMultipleWithPhoto ->
            // ファイルを追加（アップローダーコンポーネントで画像追加したらこれが発火 ※引数にファイルが入ってくる）
            // または ファイルを削除（アップローダーコンポーネントで画像削除したらこれが発火 ※引数はnull）
            // setFile(file, type, queIndex = null) {
            setFile(e) {
              var file = e.file;
              var type = e.type;
              var queIndex = e.queIndex;
              if(type == 'upload') {
                if(file) { // ファイルが送られてきたら（=画像が追加された）
                  var targetIndex = this.articleImages.findIndex(image => image.queIndex == queIndex);
                  var newValue = {
                    viewId: this.articleImages.length,
                    order: this.articleImages[targetIndex].order,
                    type: 'article_image',
                    file_name: file.tmpFile,
                    preview: {
                      src: file.src,
                      file: file.file,
                    },
                    file: file,
                    new: true,
                    rotatable: true,
                    rotate: 0,
                    imageRotate: 0,
                    imageRotateClass: 'rotate-init',
                  };
                  this.$set(this.articleImages, targetIndex, newValue);
                } else { // 引数がnullだったら（=画像が削除された）
                  // 登録用画像を削除
                  this.file = null;
                }
                if(this.node.file_name) {
                  // nodeのfileを削除
                  this.node.file_name = null;
                  // 古いの消したよフラグ立てる
                  this.remove_file = true;
                }
                this.isProcessing = false;
              } else if(type == 'pg') {
                if(file) { // ファイルが送られてきたら（=画像が追加された）
                  // 画像データを登録
                  this.articleImages.push({
                    order: this.articleImages.length + 1,
                    type: 'pg',
                    file_name: file.file_name,
                    preview: {
                      src: '/storage/images/pg/thumb/' + file.file_name,
                    },
                    node: file,
                    new: true,
                    user_id: file.user.id,
                    user_name: file.user.name,
                  });
                } else { // 引数がnullだったら（=画像が削除された）
                  // 登録用画像を削除
                  this.file = null;
                }
                if(this.node.file_name) {
                  // nodeのfileを削除
                  this.node.file_name = null;
                  // 古いの消したよフラグ立てる
                  this.remove_file = true;
                }
              }
              this.isProcessing = false;
            },
            // from: ImageUploaderMultipleWithPhoto -> メイン画像アップロードコンポーネントから、画像が削除された時の処理
            removeArticleImage(index) {
              this.isProcessing = true;
              // console.log('removeSpotImage');
              // console.log(index);
              if(this.articleImages[index].type == 'article_image' && this.articleImages[index].new === true ) {
                // その編集で追加した画像の場合はtmp画像を消す
                axios.post('/tmp-remove',{
                  field: 'multiple_image',
                  images: [this.articleImages[index].file_name],
                })
                .then((res)=>{
                  // console.log(res);
                  // 指定の画像を消す
                  this.articleImages.splice( index, 1 );
                  // orderを書き換える（key + 1）
                  this.articleImages.forEach((val, index) => val.order = index + 1);
                  this.isProcessing = false;
                })
                .catch((error) => {
                  // console.log(error.response);
                  alert('サーバーエラー: 画像を削除できませんでした');
                  // // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                  // if(error.response.data.errors) {
                  //   var errors = {};
                  //   for(var key in error.response.data.errors) {
                  //     errors[key] = error.response.data.errors[key].join('<br>');
                  //   }
                  //   this.errors = errors;
                  // } else {
                  //   // バリデーションエラーの場合以外はエラーダイアログを表示
                  //   // エラーデータをほりこむ
                  //   this.error = error.response;
                  //   this.showErrorDialog = true;
                  // }
                  this.isProcessing = false;
                })
              } else {
                // もともとある画像の場合は削除フラグをセットしてデータから消す
                this.removedArticleImages.push({
                  article_image_id: this.articleImages[index].article_image_id,
                  file: {
                    type: this.articleImages[index].type,
                    file_id: this.articleImages[index].file_id,
                    file_name: this.articleImages[index].file_name,
                  }
                }),
                // 指定の画像を消す
                this.articleImages.splice( index, 1 );
                // orderを書き換える（key + 1）
                this.articleImages.forEach((val, index) => val.order = index + 1);
                this.isProcessing = false;
              }
            },

            /**
             * bodyの処理
             */
            // from: MultiElementEditor -> 本文コンポーネントから本文のデータをセット
            setBody(val) {
                this.node.body = val;
            },
            // from: MultiElementEditor -> 本文コンポーネントから、カレント（今回の編集で新規に追加したものでない）エレメントを削除した場合に削除リストに入れる
            removeCurrentElement(elm) {
                var removedCurrentBodyElement = {
                  order: elm.order,
                  element_id: elm.article_body_element_id,
                  element_type: elm.elementType,
                };
                if(elm.elementType == 'header' || elm.elementType == 'paragraph' || elm.elementType == 'list') {
                  removedCurrentBodyElement.type = elm.type;
                  removedCurrentBodyElement.text = elm.text;
                } else if(elm.elementType == 'image') {
                  removedCurrentBodyElement.file = {
                    type: elm.type,
                    file_name: elm.image.fileName,
                    file_id: elm.image.nodeId,
                  };
                }
                this.removedCurrentBodyElements.push(removedCurrentBodyElement);
            },
            // タグをセット
            setTags(tagsArr) {
              // console.log(tagsArr);
              var newTags = [];
              tagsArr.forEach(tag =>{
                newTags.push({
                  tag: tag,
                });
              });
              this.node.tags = newTags;
            },
            // プレイスをセット
            setPlaces(placesArr) {
              // その都度やってたら1文字ごとにクエリが飛ぶので、0.5秒待ってから処理。その間に次のやつ来たら前のをキャンセルする。
              if(this.setPlacesQue) {
                clearTimeout(this.setPlacesQue);
              }
              this.setPlacesQue = setTimeout(this.executeSetPlaces, 500, placesArr);
            },
            executeSetPlaces(placesArr) {
              var newPlaces = [];
              placesArr.forEach(place =>{
                if(place) {
                  newPlaces.push({
                    place: place,
                  });
                }
              });
              if(JSON.stringify(this.node.places) != JSON.stringify(newPlaces)) { //前後の変数を比較して異なっていた時だけ処理する
                this.node.places = newPlaces;
                this.findPlaces();
              }
            },
            findPlaces() {
              // プレイス名の配列を作る
              var placeNames = this.node.places.map(v => { return v.place });
              // マッチするスポットを探す
              axios.post('/place/find_spot', { places: placeNames }).then((res)=>{
                if(res.data) {
                  // プレイスがあったらデータに反映
                  this.findPlaceResults = res.data;
                  // console.log('res.data');
                  // console.log(res.data);
                }
                // console.log(this.node.places);
                // console.log(this.matchPlaceResults);
              })
              // ToDo: エラー処理書く
              .catch(error => console.log(error))
            },
            // カテゴリーをセット
            setCategories(categoriesArr) {
              // var newCategories = [];
              var newCategories = categoriesArr.map(category =>{
                return {id: category};
              });
              this.node.categories = newCategories;
              //     console.log('-+++--');
              // console.log(this.node.categories);
            },
            // // エリアをセット
            // setAreas() {
            //   // console.log(e.target);
            //   // var area_id = e.target.value;
            //   // var newCategories = [];
            //   var newAreas = this.areaChecked.map(area =>{
            //     return {id: area};
            //   });
            //   this.node.areas = newAreas;
            //   // console.log('-+++--');
            //   // console.log(this.node.areas);
            // },


            /**
             * 送信処理
             */
            // 画像アップロード中プログレスバーを出す
            onUpload(e) {
              this.isProcessing = true;
              if(this.showProgress == true) {
                // console.log(e.loaded +'/'+ e.total);
                if(this.$refs.progress) {
                  // プログレスバーを計算して変更
                  this.$refs.progress.value = Math.floor((e.loaded * 100) / e.total);
                  this.progress = Math.floor((e.loaded * 100) / e.total);
                }
              }
              this.isProcessing = false;
            },
            deleteNode() {
              this.isProcessing = true;
              const formData = new FormData();
              // formData.append('_method', 'DELETE');
              // 削除リクエスト投げる
              // console.log('/report/' + this.node.id);
              if (confirm('本当に削除してよろしいですか?')) {
                axios.delete('/report/' + this.node.id)
                .then((response) => {
                  // // プログレスダイアログを消す
                  // this.showProgress = false;
                  // // プログレスの値をリセット
                  // this.progress = 0;
                  // console.log(response);
                  // リダイレクトURLが返ってきたら（=正常に削除できた）ページ移動する（一覧ページにリダイレクト）
                  if(response.data.redirect_path) {
                    // console.log(response.data.redirect_path);
                    // alert('例外が発生しました。Error: ArticleEdit001');
                    // alert(response.data.redirect_path);
                    document.location.href = response.data.redirect_path;
                    // if(confirm(response.data.message + 'ページに移動しますか？')) document.location.href = response.data.redirect_path;
                  } else {
                    // console.log(response);
                    alert('例外が発生しました。Error: ArticleEdit001');
                    this.isProcessing = false;
                  }
                }).catch(
                  (error) => {
                    // プログレスダイアログを消す
                    this.showProgress = false;
                    // プログレスの値をリセット
                    this.progress = 0;
                    // 例外処理
                    // console.log(error);
                    // console.log(error.response.status);
                    console.log(error.response);
                    // this.success = false;
                    // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                    if(error.response.data.errors) {
                      var errors = {};
                      for(var key in error.response.data.errors) {
                        errors[key] = error.response.data.errors[key].join('<br>');
                      }
                      this.errors = errors;
                      this.isProcessing = false;
                      // console.log(error.response.data.errors);

                      var validationErrors = [];
                      for(var key in error.response.data.errors) {
                        validationErrors.push({
                          id: key,
                          messages: error.response.data.errors[key].map((message, key) => {
                              return {
                                id: key,
                                message: message,
                              };
                            }),
                        });
                        this.validationErrors = validationErrors;
                      }
                      // console.log(this.validtionErrors);
                      // this.echoErrors();
                      this.showValidationErrorDialog = true;
                      this.isProcessing = false;
                    } else {
                      // バリデーションエラーの場合以外はエラーダイアログを表示
                      // エラーデータをほりこむ
                      this.error = error.response;
                      this.showErrorDialog = true;
                      this.isProcessing = false;
                    }
                  }
                );

              }
            },
            // サブミット！
            onSubmit() {
                this.isProcessing = true;
                // console.log('this.node.michelin_star');
                // console.log(this.node.michelin_star);
                // フォームで送信するFormDataインスタンスつくる
                const formData = new FormData();
                // 送信するフィールドのデータを登録する
                // propで投稿情報が来てたら（updateだったら）
                if(this.nodeprop) {
                  // PUTで送ってるように偽装する（普通にPUTで送れないため）
                  formData.append('_method', 'PUT');
                }
                // 元の写真を削除した場合に消したファイルの情報を送る（コントローラーでレコードとファイルを消すため）
                if(this.removedArticleImages) {
                  this.removedArticleImages.forEach((val, key) => {
                    formData.append('removedArticleImages[article_image_id][]', val.article_image_id);
                    formData.append('removedArticleImages[file][' + key + '][type]', val.file.type);
                    formData.append('removedArticleImages[file][' + key + '][file_id]', val.file.file_id);
                    formData.append('removedArticleImages[file][' + key + '][file_name]', val.file.file_name);
                  });
                }
                  // console.log(this.removedArticleImages);
                // 登録する写真を登録
                if(this.articleImages) {
                  this.articleImages.forEach((data,index) => {
                    // console.log(data);
                    formData.append('image['+data.order+'][type]', data.type);
                    if(data.type == 'article_image') formData.append('image['+data.order+'][file_name]', data.file_name);
                    if(data.type == 'pg') formData.append('image['+data.order+'][node]', data.node.id);
                    if(data.type == 'current_article_image' || data.type == 'current_pg' || data.type == 'current_deleted') formData.append('image['+data.order+'][article_image_id]', data.article_image_id);
                    if(data.rotate && data.type != 'pg') formData.append('image['+data.order+'][rotate]', data.rotate);
                    // if(data.rotate && data.type != 'pg') console.log(data.rotate);
                  });
                }
                // その他のデータを登録
                formData.append('title', this.node.title);
                if(this.node.duration) formData.append('duration', this.node.duration);
                if(this.node.note) formData.append('note', this.node.note);
                // if(this.node.status != null) {
                //   formData.append('status', this.node.status);
                // } else {
                  formData.append('status', this.node.status);
                // }
                // 元のエレメントを削除した場合に消したエレメントのエレメントIDをセット（コントローラーでファイルを消すため）
                if(this.removedCurrentBodyElements) {
                  this.removedCurrentBodyElements.forEach((val, key) => {
                    formData.append('removedCurrentBodyElements[]', val.element_id);
                  });
                }
                // 本文のデータをセット
                if(this.node.body.length >= 1) {
                  this.node.body.forEach((data,index) => {
                    formData.append('body['+data.order+'][order]', data.order);
                    formData.append('body['+data.order+'][element_type]', data.elementType);
                    if(data.article_body_element_id) formData.append('body['+data.order+'][article_body_element_id]', data.article_body_element_id);
                    if(data.elementType == 'header' || data.elementType == 'paragraph' || data.elementType == 'list') {
                      if(data.type) formData.append('body['+data.order+'][type]', data.type);
                      if(data.text) formData.append('body['+data.order+'][text]', data.text);
                    } else if(data.elementType == 'image') {
                      if(data.type) formData.append('body['+data.order+'][type]', data.type);
                      if(data.caption) formData.append('body['+data.order+'][caption]', data.caption);
                      if(data.type == 'uploadImage') {
                        if(data.image.uploadFile) formData.append('body['+data.order+'][file]', data.image.uploadFile);
                        if(data.image.rotate) formData.append('body['+data.order+'][rotate]', data.image.rotate);
                      } else if(data.type == 'pg') {
                        if(data.image) formData.append('body['+data.order+'][nodeId]', data.image.nodeId);
                      }
                    }
                  });
                }
                // タグをセット
                if(this.node.tags) {
                  this.node.tags.forEach(tag => {
                    formData.append('tags[]', tag.tag);
                  });
                }
                // プレイスをセット
                if(this.node.places) {
                  this.node.places.forEach(place => {
                    formData.append('places[]', place.place);
                  });
                }
                // カテゴリーをセット
                if(this.node.categories) {
                  this.node.categories.forEach(category => {
                    formData.append('categories[]', category.id);
                  });
                }

                // 写真を追加または変更した場合（=ファイルのアップロードと生成処理が必要=>時間がかかる）プログレスダイアログを表示
                if(this.articleImages) {
                  this.showProgress = true;
                }


                //axiosでPOST送信
                axios.post(this.actionurl, formData, {
                  onUploadProgress: this.onUpload,
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then( response => {
                    // console.log(response);
                    // // プログレスダイアログを消す
                    // this.showProgress = false;
                    // // プログレスの値をリセット
                    // this.progress = 0;
                  // console.log(response);
                  // リダイレクトURLが返ってきたら（=正常に登録できた）ページ移動する（シングルページにリダイレクト）
                  if(response.data.redirect_path) {
                    // alert('例外が発生しました。Error: ArticleEdit001');
                    document.location.href = response.data.redirect_path;
                    this.isProcessing = false;
                    // if(confirm(response.data.message + 'ページに移動しますか？')) document.location.href = response.data.redirect_path;
                  } else {
                    // console.log(response);
                    // プログレスダイアログを消す
                    this.showProgress = false;
                    // プログレスの値をリセット
                    this.progress = 0;
                    this.isProcessing = false;
                    alert('例外が発生しました。Error: ArticleEdit001');
                  }
                }).catch(
                  (error) => {
                    // プログレスダイアログを消す
                    this.showProgress = false;
                    // プログレスの値をリセット
                    this.progress = 0;
                    // 例外処理
                    // console.log(error);
                    // console.log(error.response.status);
                    // console.log(error.response);
                    // this.success = false;
                    // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                    if(error.response.data.errors) {
                      var errors = {};
                      for(var key in error.response.data.errors) {
                        errors[key] = error.response.data.errors[key].join('<br>');
                      }
                      this.errors = errors;
                      // console.log(error.response.data.errors);

                      var validationErrors = [];
                      for(var key in error.response.data.errors) {
                        validationErrors.push({
                          id: key,
                          messages: error.response.data.errors[key].map((message, key) => {
                              return {
                                id: key,
                                message: message,
                              };
                            }),
                        });
                        this.validationErrors = validationErrors;
                      }
                      // console.log(this.validtionErrors);
                      // this.echoErrors();
                      this.isProcessing = false;
                      this.showValidationErrorDialog = true;
                    } else {
                      // バリデーションエラーの場合以外はエラーダイアログを表示
                      // エラーデータをほりこむ
                      this.error = error.response;
                      this.showErrorDialog = true;
                      this.isProcessing = false;
                    }
                  }
                );
            },

        }
    }
</script>

<style lang="scss" scoped>
    .relative {
      position: relative;
    }
</style>
