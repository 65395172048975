<template>
  <div v-if="className" class="eventStatus" :class="className">
    <span>{{message}}</span>
  </div>
</template>

<script>
  export default {
    props: {
      // スタート日
      start: {
        type: Number,
        required: true,
      },
      // 終了日
      end: {
        type: Number,
        required: false,
      },
    },
    data: () => ({
      s: {
        date: null,
        time: null,
        y: null,
        m: null,
        d: null,
        fmt: '',
      },
      e: {
        date: null,
        time: null,
        y: null,
        m: null,
        d: null,
        fmt: '',
      },
      n: {
        date: null,
        time: null,
        y: null,
        m: null,
        d: null,
        fmt: '',
      },
      nd: {
        date: null,
        time: null,
        y: null,
        m: null,
        d: null,
        fmt: '',
      },
      nw: {
        date: null,
        time: null,
        y: null,
        m: null,
        d: null,
        fmt: '',
      },
      nm: {
        date: null,
        time: null,
        y: null,
        m: null,
        d: null,
        fmt: '',
      },
      // ts: 0,
      // ds: '',
      // e: '',
      // te: 0,
      // de: '',
      // tnow: 0,
      // dnow: '',
      className: '',
      message: '',
    }),
    created() {
      this.s.time = this.start * 1000;
      this.s.date = new Date(this.s.time);
      this.s.y = this.s.date.getFullYear();
      this.s.m = this.s.date.getMonth() + 1;
      this.s.d = this.s.date.getDate();
      this.s.fmt = this.s.y + '-' + ('0' + this.s.m).slice(-2) + '-' + ('0' + this.s.d).slice(-2);
      if(this.end) {
        this.e.time = this.end * 1000;
        this.e.date = new Date(this.e.time);
        this.e.y = this.e.date.getFullYear();
        this.e.m = this.e.date.getMonth() + 1;
        this.e.d = this.e.date.getDate();
        this.e.fmt = this.e.y + '-' + ('0' + this.e.m).slice(-2) + '-' + ('0' + this.e.d).slice(-2);
      } else {
        this.e = this.s;
      }
      this.n.date = new Date();
      this.n.time = this.n.date.getTime();
      this.n.y = this.n.date.getFullYear();
      this.n.m = this.n.date.getMonth() + 1;
      this.n.d = this.n.date.getDate();
      this.n.fmt = this.n.y + '-' + ('0' + this.n.m).slice(-2) + '-' + ('0' + this.n.d).slice(-2);

      const nd = new Date();
      this.nd.date = new Date(this.n.y, this.n.m - 1, this.n.d + 1);
      this.nd.time = this.nd.date.getTime();
      this.nd.y = this.nd.date.getFullYear();
      this.nd.m = this.nd.date.getMonth() + 1;
      this.nd.d = this.nd.date.getDate();
      this.nd.fmt = this.nd.y + '-' + ('0' + this.nd.m).slice(-2) + '-' + ('0' + this.nd.d).slice(-2);

      const nw = new Date();
      this.nw.date = new Date(this.n.y, this.n.m - 1, this.n.d + 7);
      this.nw.time = this.nw.date.getTime();
      this.nw.y = this.nw.date.getFullYear();
      this.nw.m = this.nw.date.getMonth() + 1;
      this.nw.d = this.nw.date.getDate();
      this.nw.fmt = this.nw.y + '-' + ('0' + this.nw.m).slice(-2) + '-' + ('0' + this.nw.d).slice(-2);

      const nm = new Date();
      this.nm.date = new Date(this.n.y, this.n.m, this.n.d);
      this.nm.time = this.nm.date.getTime();
      this.nm.y = this.nm.date.getFullYear();
      this.nm.m = this.nm.date.getMonth() + 1;
      this.nm.d = this.nm.date.getDate();
      this.nm.fmt = this.nm.y + '-' + ('0' + this.nm.m).slice(-2) + '-' + ('0' + this.nm.d).slice(-2);


      // console.log('---');
      // console.log(this.n.fmt);
      // console.log(this.nd.fmt);
      // console.log(this.nw.fmt);
      // console.log(this.nm.fmt);

      if (this.s.fmt === this.n.fmt) {
        if(this.s.fmt === this.e.fmt) {
          this.className = 'd0';
          this.message = '本日開催';
        } else {
          this.className = 'd0';
          this.message = '本日より開催';
        }
      } else if(this.s.fmt === this.nd.fmt) {
        if(this.s.fmt === this.e.fmt) {
          this.className = 'd1';
          this.message = '明日開催';
        } else {
          this.className = 'd1';
          this.message = '明日より開催';
        }
      } else if(this.e.fmt === this.n.fmt) {
          this.className = 'd0';
          this.message = '本日まで';
      } else if(this.s.fmt <= this.n.fmt && this.e.fmt >= this.n.fmt) {
          this.className = 'd0';
          this.message = '開催中';
      } else if(this.s.fmt <= this.nw.fmt && this.s.fmt >= this.n.fmt) {
        const ato = this.compareDate(this.s.time, this.n.time);
        if(this.s.fmt === this.e.fmt) {
          this.className = 'd2';
          this.message = 'あと' + ato + '日で開催';
        } else {
          this.className = 'd2';
          this.message = 'あと' + ato + '日で開始';
        }
      } else if(this.s.fmt <= this.nm.fmt && this.s.fmt >= this.n.fmt) {
        const ato = this.compareDate(this.s.time, this.n.time);
        if(this.s.fmt === this.e.fmt) {
          this.className = 'd3';
          this.message = 'あと' + ato + '日で開催';
        } else {
          this.className = 'd3';
          this.message = 'あと' + ato + '日で開始';
        }
      } else if(this.e.fmt < this.n.fmt) {
          this.className = 'd4';
          this.message = '終了しました';
      }

    },
    methods: {
      compareDate(d1, d2) {
        let diff = d1 - d2;
        let diffDay = diff / 86400000;//1日は86400秒（JSはミリ秒単位なのでさらに1000倍）
        return Math.ceil(diffDay);
      }
    }
  }
</script>

<style lang="scss" scoped>
//
</style>
