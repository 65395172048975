<template>

<div class="creatorRegistration">

  <h3 v-if="type == 310">フォトグラファー登録申請</h3>
  <h3 v-else-if="type == 315">フォトグラファー(販売者)登録申請</h3>
  <h3 v-else-if="type == 320">ライター登録申請</h3>
  <h3 v-else>クリエイター登録申請</h3>

  <form method="POST" :action="actionurl" class="pure-form pure-form-stacked" @submit.prevent="onSubmit">

        <div v-if="type >= '310' && type < '320'" class="form-group">
          <div class="form_header">
            <span class="label">作品の販売</span>
          </div>
          <div class="form_body">
            <div class="form_input">
              <label for="is_seller" class="single_checkbox">
                <input id="is_seller" type="checkbox" class="" name="is_seller" value="1" autocomplete="is_seller" v-model="is_seller">作品の販売を希望する
              </label>
            </div>
            <div class="form_note">
              <div class="note">販売報酬についての詳細は<a href="/payment" target="_blank">こちら</a>をご参照ください。</div>
            </div>
          </div>
        </div>

        <div v-if="is_seller" class="form-group">
          <div class="form_header">
            <span class="label">お電話番号</span>
          </div>
          <div class="form_body">
            <div class="form_input">
              <input type="text" class="pure-input-1" v-bind:class="{ 'is-invalid':errors.tel }" name="website" v-model="tel" autocomplete="tel">
            </div>
            <div class="form_note">
              <div class="invalid-feedback" role="alert" v-html="errors.tel"></div>
              <div class="note">申請に不備があった場合や、その他ご確認事項が生じた際に事務局より連絡させていただくことが可能なお電話番号をご記入ください。</div>
            </div>
          </div>
        </div>

        <div v-if="is_seller" class="form-group">
          <div class="form_header">
            <span class="label">お振込先口座情報</span>
          </div>
          <div class="form_body">
            <div class="form_input">
              <textarea-auto-adjust
                :errors="errors.transferer"
                name="date_note"
                v-model="transferer"
              ></textarea-auto-adjust>
            </div>
            <div class="form_note">
              <div class="invalid-feedback" role="alert" v-html="errors.date_note"></div>
              <div class="note">お振込先の金融機関口座情報を正確に記入してください。</div>
            </div>
          </div>
        </div>

        <div v-if="is_seller" class="form-group">
          <div class="form_header">
            <span class="label">身分証明書</span>
          </div>
          <div class="form_body">
            <div class="form_input relative">
              <input type="file" id="id_img1" @change="onImageUploaded('img1', $event)" name="id_img1">
            </div>
            <div class="form_input relative">
              <input type="file" id="id_img2" @change="onImageUploaded('img2', $event)" name="id_img2">
            </div>
            <div class="form_note">
              <div class="invalid-feedback" role="alert" v-html="errors.id_imgs"></div>
            <div class="note">ご本人確認のため、ご自身の身分証明書（運転免許証、マイナンバーカード、パスポートなど）をスキャンまたはカメラ撮影して添付してください。</div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="form_header">
            <span class="label">備考</span>
          </div>
          <div class="form_body">
            <div class="form_input">
              <!-- <textarea v-bind:class="{ 'is-invalid':errors.access }" name="access" rows="3" v-model="node.access"></textarea> -->
              <textarea-auto-adjust
                :errors="errors.note"
                name="access"
                v-model="note"
              ></textarea-auto-adjust>
            </div>
            <div class="form_note">
              <div class="invalid-feedback" role="alert" v-html="errors.note"></div>
              <div class="note">ご自身の作品が掲載されたページのURLや、その他審査の参考となる情報などがあればこちらに記載してください。</div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="form_header">
            <span class="label">同意事項</span>
          </div>
          <div class="form_body">
            <div class="note">
              <ul>
                <li>ご連絡事項などがある場合、当運営事務局からメール<span v-if="is_seller">またはお電話</span>にてご連絡をさせていただく場合がございます。</li>
                <li v-if="type >= 310 && type < 320">ご申請いただいた時点で<a href="/photographer_agreement" target="_blank">フォトグラファー規約</a>に同意いただいたものとします。</li>
              </ul>
            </div>
            <div class="form_input">
              <label class="single_checkbox">
                <input type="checkbox" class="" name="agreement" value="1" autocomplete="agreement" true-value="1" false-value="" v-model="agreement">すべて同意する
              </label>
            </div>
            <div class="form_note">
              <div class="invalid-feedback" role="alert" v-html="errors.agreement"></div>
              <div class="note"></div>
            </div>
          </div>
        </div>

        <div v-if="type == '310' || type == '320' || (type == '315' && is_seller)" class="form_buttons">
          <button type="submit" class="btn btn-primary" :disabled="!registerd && !agreement && !wait">今すぐ申請する</button>
        </div>

  </form>


        <p v-if="message" class="message">
          {{ message }}
        </p>
      </div>
</template>

<script>
    import TextareaAutoAdjust from './form_element/TextareaAutoAdjust';
    import ImageUploaderWithPhoto from './form_element/ImageUploaderWithPhoto';
    import ImagePreviewMultiple from './ImagePreviewMultiple';
    export default {
        components: {
            TextareaAutoAdjust,
            ImageUploaderWithPhoto,
            ImagePreviewMultiple,
        },
        props: {
          // ユーザーオブジェクト
          userobj: {
              type: Object,
              default: null,
              required: true,
          },
          // タイプ
          type: {
              type: Number,
              default: null,
              required: true,
          },
          // フォームの送信先
          actionurl: {
              type: String,
              default: null,
              required: true,
          }
        },
        data: () => ({
          registerd: false,
          message: null,
          note: null,
          is_seller: false,
          tel: null,
          transferer: null,
          id_imgs: [],
          id_imgs1: null,
          id_imgs2: null,
          agreement: 0,
          idImageQue: 0,
          removedIdImages: [],
          // // 画像を追加してプレビューが描画されるまでのキュー数
          // showPreviewQue: 0,
          // 元の写真を削除したらtrue
          remove_file: false,
          // エラーダイアログ表示フラグ
          showErrorDialog: false,
          // 返ってきたerror.responceが入る
          error: {},
          // バリデーションエラーの内容が入る
          errors: {},
          // プログレスダイアログ用の進捗状況（%）
          progress: 0,
          // プログレスダイアログ表示フラグ
          showProgress: false,
          success: false,
          // バリデーションエラーダイアログ表示フラグ
          showValidationErrorDialog: false,
          // バリデーションエラーダイアログ表示フラグ
          validationErrors: [],
          wait: false,
          // 処理中はtrueにするとボタンが無効になる
          isProcessing: false,
        }),
        created() {
        },
        methods: {
          // 画像ファイルの処理
          onImageUploaded(n, e) {
            // event(=e)から画像データを取得する
            const image = e.target.files[0]
            this.id_imgs[n] = image
            // this.createImage(image, n)
          },
          createImage(image, n) {
            const reader = new FileReader()
            // imageをreaderにDataURLとしてattachする
            reader.readAsDataURL(image)
            // readAdDataURLが完了したあと実行される処理
            reader.onload = () => {
              this.id_imgs[n] = reader.result
            }
          },
          // サブミット！
          onSubmit() {
            this.wait = true;
            // フォームで送信するFormDataインスタンスつくる
            const formData = new FormData();
            // 送信するフィールドのデータを登録する
            // PUTで送ってるように偽装する（普通にPUTで送れないため）
            formData.append('_method', 'PUT');
            formData.append('registerd', this.registerd);
            formData.append('message', this.message);
            if(this.is_seller) {
              formData.append('is_seller', this.is_seller);
              formData.append('tel', this.tel);
              formData.append('transferer', this.transferer);
              formData.append('id_img1', this.id_imgs.img1);
              formData.append('id_img2', this.id_imgs.img2);
              formData.append('type', 315);
            } else {
              formData.append('type', this.type);
            }
            formData.append('note', this.note);
            formData.append('agreement', this.agreement);
            axios.post(this.actionurl, formData, {
              onUploadProgress: this.onUpload,
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
            .then(response => {
              if (response.data.result == 'success') {
                console.log(response.data);
                this.registerd = true;
              }
              this.message = response.data.message;
              this.wait = false;
              location.reload();
            }).catch(
              (error) => {
                  if(error.response.data.errors) {
                    var errors = {};
                    for(var key in error.response.data.errors) {
                      errors[key] = error.response.data.errors[key].join('<br>');
                    }
                    this.errors = errors;
                    // console.log(error.response.data.errors);

                    var validationErrors = [];
                    for(var key in error.response.data.errors) {
                      validationErrors.push({
                        id: key,
                        messages: error.response.data.errors[key].map((message, key) => {
                            return {
                              id: key,
                              message: message,
                            };
                          }),
                      });
                      this.validationErrors = validationErrors;
                    }
                    // console.log(this.validtionErrors);
                    // this.echoErrors();
                    this.showValidationErrorDialog = true;
                  } else {
                    // バリデーションエラーの場合以外はエラーダイアログを表示
                    // エラーデータをほりこむ
                    this.error = error.response;
                    this.showErrorDialog = true;
                  }
                this.wait = false;
              }
            );
          },
        }
    }
</script>
