<docs>
</docs>

<template>
    <div class="category_Checker checkboxes">
      <ul>
        <li v-for="cat in tree" :key="cat.id" :class="cat.children ? 'parent' : ''">
          <span v-if="!cat.children" class="category_row">
            <label><input type="checkbox" v-model="checked" :value="cat.id" @change="onChange"> <span class="category_name">{{ cat.category}}</span></label>
          </span>
          <span v-else class="row"><span class="category_name">{{ cat.category}}</span></span>
          <ul v-if="cat.children">
            <li v-for="childCat in cat.children" :key="childCat.id">
              <span class="row">
                <label><input type="checkbox" v-model="checked" :value="childCat.id" @change="onChange"> <span class="category_name">{{ childCat.category}}</span></label>
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
</template>

<script>
    export default {
        // フィールド名
        props: {
            // カテゴリーの種類
            entity: {
                type: String,
                required: false,
            },
            currentCategories: {
                type: Array,
                default: null,
                required: false,
            },
        },
        data: () => ({
            tree: [],
            checked: [],
        }),
        created() {

          // 選択済みカテゴリーのIDを配列にほりこむ
          if(this.currentCategories) {
            this.checked = this.currentCategories.map(category => {
              return category.id;
            });
          }
          // if(this.currentCategories) {
          //   this.checked = this.currentCategories;
          // }
          // カテゴリーのデータを取ってくる
          axios.post('/category/get_tree/' + this.entity).then((res)=>{
            this.tree = res.data;
          })
        },
        computed: {

        },
        methods: {
          onChange() {
            // console.log(this.checked);
            this.$emit('setCategories', this.checked);
          }
        },
    }
</script>

<style lang="scss" scoped>
</style>
