<template>
  <div class="spotEdit">
    <form method="POST" :action="actionurl" class="register_form pure-form pure-form-stacked" enctype="multipart/form-data" @submit.prevent>

        <div class="invalid-feedback" role="alert" v-html="errors.self_introduction"></div>
                              <!-- <li v-for="(value, key, index) in validationErrors()">@{{ value }}</li> -->

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">写真</span>
            </div>
            <div class="form_body">
              <div class="form_input relative">
                <!-- <div class="pleaseWait" v-show="showPreviewQue">
                  <div class="pleaseWait_inner">
                    <span class="loader icon">Loading...</span><span>ファイル読み込み中。しばらくお待ちください。</span>
                  </div>
                </div> -->
                <!-- <div class="multiple_images_preview">
                  <draggable v-if="spotImages" v-model="spotImages" element="div" class="photo_thumbs_sq"
                  :options="{
                    animation: 200,
                    handle:'.draggable-handle',
                    delay: 30, // time in milliseconds to define when the sorting should start
                    delayOnTouchOnly: true, // only delay if user is using touch
                    touchStartThreshold: 5, // px, how many pixels the point should move before cancelling a delayed drag event
                  }"
                  :move="onmove"
                  @start="onstart"
                  @add="onadd"
                  @remove="onremove"
                  @update="onupdate"
                  @end="onend"
                  @choose="onchoose"
                  @sort="onsort"
                  @filter="onfilter"
                  @clone="onclone"> -->

                <image-preview-multiple
                :images="spotImages"
                @remove="index => removeSpotImage(index)"
                @onsort="images => sortSpotImage(images)"
                @setImageRotate="(imageRotate, index) => setImageRotate(imageRotate, index)"
                ></image-preview-multiple>
                <image-uploader-with-photo
                name="spot_image"
                tmpFormatName="spot_image"
                :image-que="spotImageQue"
                :multiple="true"
                @addQue="addQue"
                @setFile="setFile"
                @setProcessing="setProcessing"
                >
                </image-uploader-with-photo>
                <!-- <image-uploader-multiple-with-photo title="写真ファイル" name="spot_image" :current="node.file_name" :prvpath="'/storage/images/spot/thumb/'" :image-que="spotImageQue" @addQue="addQue" @setFile="setFile"></image-uploader-multiple-with-photo> -->
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.image"></div>
              <div class="note">30MBまでのJPEG形式の画像ファイル</div>
              </div>
            </div>
            <!-- <textarea style="max-width:100%;min-height:200px;">{{ spotImages }}</textarea> -->
          </div>

          <div class="form-group required">
            <div class="form_header">
              <span class="label">スポット名</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1" v-bind:class="{ 'is-invalid':errors.name }" name="name" v-model="node.name" required autocomplete="name" autofocus>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.name"></div>
                <div class="note">このスポットの名前。複数の呼び名がある場合はいちばんポピュラーなものを入れる。</div>
              </div>
            </div>
          </div>

          <div class="form-group required">
            <div class="form_header">
              <span class="label">スポット名のよみ</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1" v-bind:class="{ 'is-invalid':errors.name_yomi }" name="name_yomi" v-model="node.name_yomi" required autocomplete="name_yomi">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.name_yomi"></div>
                <div class="note">上記スポット名のよみがな。※全角ひらがな</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">概要文</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <!-- <textarea v-bind:class="{ 'is-invalid':errors.excerpt }" name="excerpt" rows="2" v-model="node.excerpt"></textarea> -->
                <textarea-auto-adjust
                  :errors="errors.excerpt"
                  name="excerpt"
                  v-model="node.excerpt"
                ></textarea-auto-adjust>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.excerpt"></div>
                <div class="note">一覧表示などで表示する用の100文字程度の概要文。</div>
              </div>
            </div>
          </div>

          <div class="form-group required">
            <div class="form_header">
              <span class="label">本文</span>
            </div>
            <div class="form_body">
              <multi-element-editor :body="node.body" @setBody="val => setBody(val)" @removeCurrentElement="id => removeCurrentElement(id)"></multi-element-editor>
            </div>
            <!-- <textarea style="max-width:100%;min-height:200px;">{{ removedCurrentBodyElements }}</textarea> -->
            <!-- <textarea style="max-width:100%;min-height:200px;">{{ node.body }}</textarea> -->
          </div>

          <div class="form-group required">
            <div class="form_header">
              <span class="label">座標（緯度・経度）</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <span class="inputPre">緯度</span>
                <input type="text" class="pure-input-1-3" v-bind:class="{ 'is-invalid':errors.lat }" name="lat" v-model="node.lat" required autocomplete="lat">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.lat"></div>
              </div>
              <div class="form_input">
                <span class="inputPre">経度</span>
                <input type="text" class="pure-input-1-3" v-bind:class="{ 'is-invalid':errors.lng }" name="lng" v-model="node.lng" required autocomplete="lng">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.lng"></div>
                <div class="note">世界測地系の座標。※半角数字(小数点以下最大7桁まで)</div>
              </div>
            </div>
          </div>

          <div class="form-group required">
            <div class="form_header">
              <span class="label">所在地</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <span class="inputPre">〒</span>
                <input type="text" class="pure-input-1-4" v-bind:class="{ 'is-invalid':errors.postcode }" name="postcode" v-model="node.postcode" autocomplete="postcode">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.postcode"></div>
              </div>
              <div class="form_input">
                <!-- <textarea v-bind:class="{ 'is-invalid':errors.address }" required name="address" rows="2" v-model="node.address"></textarea> -->
                <textarea-auto-adjust
                  :errors="errors.address"
                  name="address"
                  v-model="node.address"
                ></textarea-auto-adjust>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.address"></div>
                <div class="note">郵便番号と所在地（京都市の場合は「京都府」は不要）を入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">TEL・FAX</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <span class="inputPre">TEL</span>
                <input type="text" class="pure-input-1-3" v-bind:class="{ 'is-invalid':errors.tel }" name="tel" v-model="node.tel" autocomplete="tel">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.tel"></div>
              </div>
              <div class="form_input">
                <span class="inputPre">FAX</span>
                <input type="text" class="pure-input-1-3" v-bind:class="{ 'is-invalid':errors.fax }" name="fax" v-model="node.fax" autocomplete="fax">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.fax"></div>
                <div class="note">電話番号・FAX番号情報を入力してください。</div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">正式名</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1-2" v-bind:class="{ 'is-invalid':errors.name_formal }" name="name_formal" v-model="node.name_formal" autocomplete="name_formal">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.name_formal"></div>
                <div class="note">寺/神社の正式名を入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">創建年</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1-2" v-bind:class="{ 'is-invalid':errors.founding_year }" name="founding_year" v-model="node.founding_year" autocomplete="founding_year">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.founding_year"></div>
                <div class="note">寺/神社の創建年情報を入力してください。</div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">山号</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1-2" v-bind:class="{ 'is-invalid':errors.sangou }" name="name" v-model="node.sangou" autocomplete="sangou">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.sangou"></div>
                <div class="note">寺の山号を入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">宗派</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1-2" v-bind:class="{ 'is-invalid':errors.denomination }" name="denomination" v-model="node.denomination" autocomplete="denomination">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.denomination"></div>
                <div class="note">寺の宗派を入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">本尊</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1-2" v-bind:class="{ 'is-invalid':errors.honzon }" name="honzon" v-model="node.honzon" autocomplete="honzon">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.honzon"></div>
                <div class="note">寺の本尊を入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">開基</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1-2" v-bind:class="{ 'is-invalid':errors.kaiki }" name="kaiki" v-model="node.kaiki" autocomplete="kaiki">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.kaiki"></div>
                <div class="note">寺の開基情報を入力してください。</div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">御祭神</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1" v-bind:class="{ 'is-invalid':errors.syusaishin }" name="syusaishin" v-model="node.syusaishin" autocomplete="syusaishin">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.syusaishin"></div>
                <div class="note">神社の御祭神情報を入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">例祭</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1" v-bind:class="{ 'is-invalid':errors.reisai }" name="reisai" v-model="node.reisai" autocomplete="reisai">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.reisai"></div>
                <div class="note">神社の例祭情報を入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">ご利益</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <!-- <textarea v-bind:class="{ 'is-invalid':errors.grace }" name="grace" rows="2" v-model="node.grace"></textarea> -->
                <textarea-auto-adjust
                  :errors="errors.grace"
                  name="grace"
                  v-model="node.grace"
                ></textarea-auto-adjust>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.grace"></div>
                <div class="note">何らかのご利益がある場合はその種類と、簡単な説明があればそれも記入してください。</div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">文化財</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <!-- <textarea v-bind:class="{ 'is-invalid':errors.bunkazai }" name="bunkazai" rows="2" v-model="node.bunkazai"></textarea> -->
                <textarea-auto-adjust
                  :errors="errors.bunkazai"
                  name="bunkazai"
                  v-model="node.bunkazai"
                ></textarea-auto-adjust>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.bunkazai"></div>
                <div class="note">文化財情報を入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">料金（拝観料、入場料、利用料）</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <!-- <textarea v-bind:class="{ 'is-invalid':errors.charge }" name="charge" rows="3" v-model="node.charge"></textarea> -->
                <textarea-auto-adjust
                  :errors="errors.charge"
                  name="charge"
                  v-model="node.charge"
                ></textarea-auto-adjust>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.charge"></div>
                <div class="note">料金情報を入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">時間</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <!-- <textarea v-bind:class="{ 'is-invalid':errors.time }" name="time" rows="3" v-model="node.time"></textarea> -->
                <textarea-auto-adjust
                  :class="{ 'is-invalid':errors.time }"
                  name="time"
                  v-model="node.time"
                ></textarea-auto-adjust>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.time"></div>
                <div class="note">時間情報を入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">駐車場</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <!-- <textarea v-bind:class="{ 'is-invalid':errors.parking }" name="parking" rows="2" v-model="node.parking"></textarea> -->
                <textarea-auto-adjust
                  :errors="errors.parking"
                  name="parking"
                  v-model="node.parking"
                ></textarea-auto-adjust>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.parking"></div>
                <div class="note">駐車場情報を入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">交通アクセス</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <!-- <textarea v-bind:class="{ 'is-invalid':errors.access }" name="access" rows="3" v-model="node.access"></textarea> -->
                <textarea-auto-adjust
                  :errors="errors.access"
                  name="access"
                  v-model="node.access"
                ></textarea-auto-adjust>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.access"></div>
                <div class="note">交通アクセス情報を入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">公式Webサイト</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1" v-bind:class="{ 'is-invalid':errors.website }" name="website" v-model="node.website" autocomplete="website">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.website"></div>
                <div class="note">公式WebサイトのURL（フルパス）を入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">備考</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <!-- <textarea v-bind:class="{ 'is-invalid':errors.note }" name="note" rows="4" v-model="node.note"></textarea> -->
                <textarea-auto-adjust
                  :errors="errors.note"
                  name="note"
                  v-model="node.note"
                ></textarea-auto-adjust>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.note"></div>
                <div class="note">備考を入力してください。</div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group required">
            <div class="form_header">
              <span class="label">プレイス（スポット名情報）</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <tag-editor :tagColumn="'place'" :currentTags="node.places" @setTags="setPlaces"></tag-editor>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.places"></div>
                <div class="note">通称なども含めたこのスポットの名前を設定してください。写真やイベントなどで「場所」として入力された単語と完全一致した場合、このスポットと自動的に関連づけられます。複数の呼び名がある場合は、カンマ区切りで入力してください。</div>
                <div class="note">例: <br>金閣寺の場合→「金閣寺, 鹿苑寺, 鹿苑寺金閣」<br>金戒光明寺の場合→「金戒光明寺, 黒谷さん, 黒谷, くろ谷さん, くろ谷」など</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">タグ</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <tag-editor :tagColumn="'tag'" :currentTags="node.tags" @setTags="setTags"></tag-editor>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.tags"></div>
                <div class="note">このスポットに関連するワードをタグとして入力します。同じタグがついたスポットやその他のページと関連づけられます。カンマ区切りで入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group required">
            <div class="form_header">
              <span class="label">カテゴリー</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <category-checker :entity="'spot'" :currentCategories="node.categories" @setCategories="setCategories"></category-checker>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.categories"></div>
                <div class="note">このスポットが該当するカテゴリーを全てチェックしてください。最低でも1つチェックが必要です。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">エリア</span>
            </div>
            <div class="form_body">
              <div class="form_input checkboxes">
                <ul>
                  <li v-for="area in areas" :key="area.id">
                    <span class="row">
                      <input type="checkbox" :id="'area_' + area.id" v-model="areaChecked" :value="area.id" @change="setAreas">
                      <label :for="'area_' + area.id"><span class="area_name">{{ area.area_name}}</span></label>
                    </span>
                  </li>
                </ul>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.areas"></div>
                <div class="note">このスポットが該当するエリアを全てチェックしてください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">ミシュラン星</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <div class="form_inputs">
                  <select type="text" id="michelin_star" name="michelin_star" v-bind:class="{ 'is-invalid':errors.michelin_star }" v-model="node.michelin_star">
                    <option v-for="michelin_star_option in michelin_star_options" :value="michelin_star_option.id" :key="michelin_star_option.id">{{ michelin_star_option.label }}</option>
                  </select>
                </div>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.michelin_star"></div>
                <div class="note">ミシュランの星を獲得している場合は選択してください。</div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="" v-if="isStaff">
          <div class="form-group">
            <div class="form_header">
              <span class="label">公開状態</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <status-checker :config_status="config_status" :enable_values="enable_status" :currentStatus="node.status ? node.status : 40" @setStatus="setStatus"></status-checker>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.categories"></div>
                <div class="note">公開状態を選択してください</div>
              </div>
            </div>
          </div>
        </fieldset>

        <div class="form_buttons">
          <a v-if="nodeprop" class="btn cancelBtn" v-bind:href="'/spot/' + nodeprop.id">戻る</a>
          <button v-if="nodeprop" v-bind:disabled="isProcessing" type="button" class="btn btn-primary" @click.prevent.stop="onSubmit()">更新</button>
          <button v-else type="button" v-bind:disabled="isProcessing" class="btn btn-primary" @click.prevent.stop="onSubmit()">投稿</button>
          <button v-if="nodeprop && user.roles && user.roles.some(role => role.role <= 20)" type="button" class="btn btn-warning" @click="deleteNode">削除</button>
        </div>

        <div class="dialog dialog-t" v-if="showProgress">
          <div class="inner">
            <div class="dialog-header">
              投稿処理
            </div>
            <div class="dialog-content">
              <div v-if="progress > 0 && progress < 100">
                画像ファイルアップロード中...
                <progress ref="progress" value="0" max="100"></progress><br>{{progress}}%
              </div>
              <div v-if="!progress || progress <= 0 && progress >= 100" class="icon-inline">
                <span class="loader icon">Loading...</span>
                <span>ページ生成中...　処理完了後は自動的にページに移動します。</span>
              </div>
            </div>
          </div>
        </div>

        <div class="dialog dialog-t" v-if="showValidationErrorDialog">
          <div class="inner">
            <div class="dialog-header">
              入力内容をご確認ください
            </div>
            <div class="dialog-content">
              <ul v-for="error in validationErrors" :key="error.id">
                <li v-for="message in error.messages" :key="message.id">{{ message.message }}</li>
              </ul>
              <div class="buttons">
                <button type="button" class="btn btn-primary" @click="showValidationErrorDialog = false">OK</button>
              </div>
            </div>
          </div>
        </div>

        <div class="dialog dialog-t" v-if="showErrorDialog">
          <div class="inner">
            <div class="dialog-header">
              {{error.status}} {{error.statusText}}
            </div>
            <div class="dialog-content">
              <div>{{error.data.message}}</div>
              <div class="buttons">
                <button type="button" class="btn btn-primary" @click="showErrorDialog = false">OK</button>
              </div>
            </div>
          </div>
        </div>

    </form>
  </div>

</template>

<script>
    import ImageUploaderWithPhoto from './form_element/ImageUploaderWithPhoto';
    import MultiElementEditor from './MultiElementEditor';
    import TagEditor from './TagEditor';
    import CategoryChecker from './CategoryChecker';
    import draggable from 'vuedraggable';
    import ImagePreviewMultiple from './ImagePreviewMultiple'
    import TextareaAutoAdjust from './form_element/TextareaAutoAdjust';
    import StatusChecker from './form_element/StatusChecker';
    export default {
        components: {
            ImageUploaderWithPhoto,
            MultiElementEditor,
            TagEditor,
            CategoryChecker,
            draggable,
            ImagePreviewMultiple,
            TextareaAutoAdjust,
            StatusChecker,
        },
        props: {
          // nodeプロパティ
            nodeprop: {
                type: Object,
                default: null,
                required: false,
            },
            // フォームの送信先
            actionurl: {
                type: String,
                required: true,
            },
            user: {
                type: Object,
                required: true,
            },
            config_status: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            isAdmin: false,
            isStaff: false,
            enable_status: [ // {1: "公開", 5: "限定公開", 10: "予約", 20: "下書き", 40: "承認申請中", 45: "承認却下", 60: "公開制限中", 90: "非公開"}
              // 1, 5, 10, 20, 40, 45, 60, 90,
              1, 90,
            ],
            // post
            node: {
              name: '',
              body: [],
            },
            removedCurrentBodyElements: [],
            // 写真のデータ
            spotImages: [],
            spotImageQue: 0,
            removedSpotImages: [],
            // // 画像を追加してプレビューが描画されるまでのキュー数
            // showPreviewQue: 0,
            // 元の写真を削除したらtrue
            remove_file: false,
            // エラーダイアログ表示フラグ
            showErrorDialog: false,
            // 返ってきたerror.responceが入る
            error: {},
            // バリデーションエラーの内容が入る
            errors: {},
            // プログレスダイアログ用の進捗状況（%）
            progress: 0,
            // プログレスダイアログ表示フラグ
            showProgress: false,
            // ミシュラン星の選択肢
            michelin_star_options: [
              { id: 0, label: 'なし' },
              { id: 1, label: '★ ミシュラングリーンガイド 一ツ星' },
              { id: 2, label: '★★ ミシュラングリーンガイド 二ツ星' },
              { id: 3, label: '★★★ ミシュラングリーンガイド 三ツ星' },
            ],
            areas: [],
            areaChecked: [],
            // バリデーションエラーダイアログ表示フラグ
            showValidationErrorDialog: false,
            // バリデーションエラーダイアログ表示フラグ
            validationErrors: [],
            // 処理中はtrueにするとボタンが無効になる
            isProcessing: false,
        }),
        created() {
            this.isAdmin = this.user.roles.some(user => user.role === 1);
            this.isStaff = this.user.roles.some(user => user.role === 1 || user.role === 10 || user.role === 20);
            // console.log('this.nodeprop');
            // console.log(this.nodeprop);
            if(this.nodeprop) {
              // console.log('this.nodeprop1');
              // console.log(this.nodeprop);
              // ノードプロパティをdataにセット
              this.node = this.nodeprop;
              if(this.nodeprop.spot_images) {
                // console.log('this.nodeprop.spot_images');
                // console.log(this.nodeprop.spot_images);
                this.nodeprop.spot_images.forEach((spot_image, index) => {
                  // console.log('spot_image');
                  // console.log(spot_image);
                  if(spot_image.file) {
                    if(spot_image.file_type == 'App\\Models\\SpotImageFile') {
                      var current_image = {
                        viewId: index + 1,
                        order: spot_image.order,
                        type: 'current_spot_image',
                        file_id: spot_image.file_id,
                        file_name: spot_image.file.file_name,
                        landscape: spot_image.file.landscape,
                        preview: {
                          src: '/storage/images/spot_image/thumb/' + spot_image.file.file_name,
                        },
                        new: false,
                        spot_image_id: spot_image.id,
                      }
                    } else if(spot_image.file_type == 'App\\Models\\Photo') {
                      var pg_file = this.nodeprop.photos.filter(pg => { return pg.id == spot_image.file_id } );
                      var current_image = {
                        viewId: index + 1,
                        order: spot_image.order,
                        type: 'current_pg',
                        file_id: spot_image.file_id,
                        file_name: spot_image.file.file_name,
                        landscape: spot_image.file.landscape,
                        preview: {
                          src: '/storage/images/spot_image/thumb/' + spot_image.file.file_name,
                        },
                        new: false,
                        rotatable: true,
                        spot_image_id: spot_image.id,
                        user_id: spot_image.file.user_id,
                        user_name: pg_file[0].user.name,
                      }
                    }
                  } else { // 通常はないけど、もしリレーション先の画像モデルが見つからない（削除されてしまってる）場合の処理。
                    var current_image = {
                      viewId: index + 1,
                      order: spot_image.order,
                      type: 'current_deleted',
                      file_id: spot_image.file_id,
                      file_name: null,
                      landscape: null,
                      preview: {
                        src: 'deleted',
                      },
                      new: false,
                      spot_image_id: spot_image.id,
                    }
                  }
                  this.spotImages.push(current_image);
                  // console.log('this.spotImages');
                  // console.log(this.spotImages);
                });
              }
              // console.log('this.spotImages');
              // console.log(this.spotImages);
              if(this.nodeprop.body) {
                // this.node.body = [];
                var current_body = [];
                this.nodeprop.body.forEach(body => {
                  if(body.element_type == 'App\\Models\\ElementHeader') {
                    var element = {
                      elementType: 'header',
                      new: false,
                      spot_body_element_id: body.id,
                      type: body.element.type,
                      text: body.element.text,
                      order: body.order,
                      status: 'preview',
                    };
                  } else if(body.element_type == 'App\\Models\\ElementText') {
                    var element = {
                      new: false,
                      spot_body_element_id: body.id,
                      type: body.element.type,
                      text: body.element.text,
                      order: body.order,
                      status: 'preview',
                    };
                    if(body.element.type == 'p') {
                      element.elementType = 'paragraph';
                    } else if(body.element.type == 'ul') {
                      element.elementType = 'list';
                    }
                  } else if(body.element_type == 'App\\Models\\ElementImage') {
                    var element = {
                      elementType: 'image',
                      new: false,
                      spot_body_element_id: body.id,
                      type: 'uploadImage',
                      image: {
                        uploadFile: null,
                        file_preview_src: '/storage/images/element_image/single/' + body.element.file_name,
                        preview: {
                          src: '/storage/images/element_image/single/' + body.element.file_name,
                        },
                        rotate: 0,
                        prevStyle: {},
                        fileName: body.element.file_name,
                        nodeId: body.element_id,
                      },
                      caption: body.caption,
                      flag: {
                        uploaderShowFlag: false,
                        nowRenderingFlag: false,
                        nowChangeFlag: false,
                        isDragging: false,
                        // ドラッグ中かどうかの判定用
                        dragCount: 0,
                      },
                      order: body.order,
                      status: 'preview',
                    };
                  } else if(body.element_type == 'App\\Models\\Photo') {
                    var element = {
                      elementType: 'image',
                      new: false,
                      spot_body_element_id: body.id,
                      type: 'pg',
                      image: {
                        uploadFile: null,
                        file_preview_src: '/storage/images/element_image/single/' + body.element.file_name,
                        preview: {
                          src: '/storage/images/element_image/single/' + body.element.file_name,
                        },
                        fileName: body.element.file_name,
                        nodeId: body.element_id,
                        file: {
                          name: body.element.title,
                        },
                        author: {
                          name: body.element.user_id,
                        },
                      },
                      caption: body.caption,
                      flag: {
                        uploaderShowFlag: false,
                        nowRenderingFlag: false,
                        nowChangeFlag: false,
                        isDragging: false,
                        // ドラッグ中かどうかの判定用
                        dragCount: 0,
                      },
                      order: body.order,
                      status: 'preview',
                    };
                  }
                  current_body.push(element);
                  // this.node.body.push(element);
                });
                this.setBody(current_body);
              }
            }

            if(this.node.michelin_star === undefined) this.node.michelin_star = 0;

            // エリアのデータを取ってくる
            axios.post('/spot/get_areas').then((res)=>{
              this.areas = res.data;
              // console.log('areas');
              // console.log(this.areas);
            }).catch((error)=> {
              console.log(error);
            });

            if(this.nodeprop) {

              // 選択済みエリアのIDを配列にほりこむ
              if(this.nodeprop.areas) {
                // console.log(this.nodeprop.areas);

                this.areaChecked = this.nodeprop.areas.map(area => {
                  return area.id;
                });
              }
              // if(this.currentCategories) {
              //   this.checked = this.currentCategories;
              // }

            }

        },
        methods: {
            setStatus(status) {
              this.node.status = status;
              // console.log(this.node.status);
            },
            sortSpotImage(spotImages) {
              // console.log('fire!');
              this.spotImages = spotImages;
            },
            setImageRotate(imageRotate, index) {
                // console.log('imageRotate');
                // console.log(imageRotate);
                this.spotImages[index].rotate = imageRotate.rotate;
                // this.imageRotate = imageRotate;
            },
            /**
             * spot_imagesの処理
             */
            addQue() {
              this.spotImageQue++;
              this.spotImages.push({
                order: this.spotImages.length + 1,
                type: 'que',
                queIndex: this.spotImageQue,
                preview: {},
              });
            },
            setProcessing(status) {
              this.isProcessing = status;
              console.log(this.isProcessing);
            },
            // from: ImageUploaderMultipleWithPhoto ->
            // ファイルを追加（アップローダーコンポーネントで画像追加したらこれが発火 ※引数にファイルが入ってくる）
            // または ファイルを削除（アップローダーコンポーネントで画像削除したらこれが発火 ※引数はnull）
            // setFile(file, type, queIndex) {
            setFile(e) {
              var file = e.file;
              var type = e.type;
              var queIndex = e.queIndex;
                // console.log('setFile');
                // console.log(queIndex);
                if(type == 'upload') {
                  // console.log(files);
                    if(file) { // ファイルが送られてきたら（=画像が追加された）
                      var targetIndex = this.spotImages.findIndex(image => image.queIndex == queIndex);
                      var newValue = {
                        viewId: this.spotImages.length,
                        order: this.spotImages[targetIndex].order,
                        type: 'spot_image',
                        file_name: file.tmpFile,
                        preview: {
                          src: file.src,
                          file: file.file,
                        },
                        file: file,
                        new: true,
                        rotatable: true,
                        rotate: 0,
                        imageRotate: 0,
                        imageRotateClass: 'rotate-init',
                      };
                      // console.log('newValue');
                      // console.log(newValue);
                      this.$set(this.spotImages, targetIndex, newValue);
                    } else { // 引数がnullだったら（=画像が削除された）
                      // 登録用画像を削除
                      this.file = null;
                    }
                    if(this.node.file_name) {
                      // nodeのfileを削除
                      this.node.file_name = null;
                      // 古いの消したよフラグ立てる
                      this.remove_file = true;
                    }
                } else if(type == 'pg') {
                    if(file) { // ファイルが送られてきたら（=画像が追加された）
                      // console.log(file);
                      // 画像データを登録
                      this.spotImages.push({
                        order: this.spotImages.length + 1,
                        type: 'pg',
                        file_name: file.file_name,
                        preview: {
                          src: '/storage/images/pg/thumb/' + file.file_name,
                        },
                        node: file,
                        new: true,
                        user_id: file.user.id,
                        user_name: file.user.name,
                      });
                    } else { // 引数がnullだったら（=画像が削除された）
                      // 登録用画像を削除
                      this.file = null;
                    }
                    if(this.node.file_name) {
                      // nodeのfileを削除
                      this.node.file_name = null;
                      // 古いの消したよフラグ立てる
                      this.remove_file = true;
                    }
                }
                this.isProcessing = false;
            },
            // from: ImageUploaderMultipleWithPhoto -> メイン画像アップロードコンポーネントから、画像が削除された時の処理
            removeSpotImage(index) {
              // console.log('removeSpotImage');
              // console.log(index);
              if(this.spotImages[index].type == 'spot_image' && this.spotImages[index].new === true ) {

                        axios.post('/tmp-remove',{
                          field: 'multiple_image',
                          images: [this.spotImages[index].file_name],
                        })
                        .then((res)=>{
                          // console.log(res);
                          // 指定の画像を消す
                          this.spotImages.splice( index, 1 );
                          // orderを書き換える（key + 1）
                          this.spotImages.forEach((val, index) => val.order = index + 1);
                        })
                        .catch((error) => {
                          // console.log(error.response);
                          alert('サーバーエラー: 画像を削除できませんでした');
                          // // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                          // if(error.response.data.errors) {
                          //   var errors = {};
                          //   for(var key in error.response.data.errors) {
                          //     errors[key] = error.response.data.errors[key].join('<br>');
                          //   }
                          //   this.errors = errors;
                          // } else {
                          //   // バリデーションエラーの場合以外はエラーダイアログを表示
                          //   // エラーデータをほりこむ
                          //   this.error = error.response;
                          //   this.showErrorDialog = true;
                          // }
                        })
              } else {
                this.removedSpotImages.push({
                  spot_image_id: this.spotImages[index].spot_image_id,
                  file: {
                    type: this.spotImages[index].type,
                    file_id: this.spotImages[index].file_id,
                    file_name: this.spotImages[index].file_name,
                  }
                }),
                // 指定の画像を消す
                this.spotImages.splice( index, 1 );
                // orderを書き換える（key + 1）
                this.spotImages.forEach((val, index) => val.order = index + 1);
              }
              // console.log('this.removedSpotImages');
              // console.log(this.removedSpotImages);
              // console.log('this.spotImages');
              // console.log(this.spotImages);
            },

            /**
             * bodyの処理
             */
            // from: MultiElementEditor -> 本文コンポーネントから本文のデータをセット
            setBody(val) {
                this.node.body = val;
            },
            // from: MultiElementEditor -> 本文コンポーネントから、カレント（今回の編集で新規に追加したものでない）エレメントを削除した場合に削除リストに入れる
            removeCurrentElement(elm) {
                var removedCurrentBodyElement = {
                  order: elm.order,
                  element_id: elm.spot_body_element_id,
                  element_type: elm.elementType,
                };
                if(elm.elementType == 'header' || elm.elementType == 'paragraph' || elm.elementType == 'list') {
                  removedCurrentBodyElement.type = elm.type;
                  removedCurrentBodyElement.text = elm.text;
                } else if(elm.elementType == 'image') {
                  removedCurrentBodyElement.file = {
                    type: elm.type,
                    file_name: elm.image.fileName,
                    file_id: elm.image.nodeId,
                  };
                }
                this.removedCurrentBodyElements.push(removedCurrentBodyElement);
            },
            // タグをセット
            setTags(tagsArr) {
              // console.log(tagsArr);
              var newTags = [];
              tagsArr.forEach(tag =>{
                newTags.push({
                  tag: tag,
                });
              });
              this.node.tags = newTags;
            },
            // プレイスをセット
            setPlaces(placesArr) {
              // console.log(placesArr);
              var newPlaces = [];
              placesArr.forEach(place =>{
                newPlaces.push({
                  place: place,
                });
              });
              this.node.places = newPlaces;
              // console.log(this.node.places);
            },
            // カテゴリーをセット
            setCategories(categoriesArr) {
              // var newCategories = [];
              var newCategories = categoriesArr.map(category =>{
                return {id: category};
              });
              this.node.categories = newCategories;
              //     console.log('-+++--');
              // console.log(this.node.categories);
            },
            // エリアをセット
            setAreas() {
              // console.log(e.target);
              // var area_id = e.target.value;
              // var newCategories = [];
              var newAreas = this.areaChecked.map(area =>{
                return {id: area};
              });
              this.node.areas = newAreas;
              // console.log('-+++--');
              // console.log(this.node.areas);
            },


            /**
             * 送信処理
             */
            // 画像アップロード中プログレスバーを出す
            onUpload(e) {
              if(this.showProgress == true) {
                // console.log(e.loaded +'/'+ e.total);
                if(this.$refs.progress) {
                  // プログレスバーを計算して変更
                  this.$refs.progress.value = Math.floor((e.loaded * 100) / e.total);
                  this.progress = Math.floor((e.loaded * 100) / e.total);
                }
              }
            },
            deleteNode() {
              this.isProcessing = true;
              this.dialogTitle = "削除処理";
              this.dialogMessage = "削除処理中。このままお待ち下さい。";
              this.showProgress = true;
              const formData = new FormData();
              // formData.append('_method', 'DELETE');
              // 削除リクエスト投げる
              // console.log('/report/' + this.node.id);
              if (confirm('本当に削除してよろしいですか?')) {
                axios.delete('/spot/' + this.node.id)
                .then((response) => {
                  // // プログレスダイアログを消す
                  // this.showProgress = false;
                  // // プログレスの値をリセット
                  // this.progress = 0;
                  // console.log(response);
                  // リダイレクトURLが返ってきたら（=正常に削除できた）ページ移動する（一覧ページにリダイレクト）
                  if(response.data.redirect_path) {
                    // console.log(response.data.redirect_path);
                    // alert('例外が発生しました。Error: ArticleEdit001');
                    // alert(response.data.redirect_path);
                    document.location.href = response.data.redirect_path;
                    // if(confirm(response.data.message + 'ページに移動しますか？')) document.location.href = response.data.redirect_path;
                  } else {
                    // console.log(response);
                    alert('例外が発生しました。Error: ArticleEdit001');
                  }
                }).catch(
                  (error) => {
                    // プログレスダイアログを消す
                    this.showProgress = false;
                    // プログレスの値をリセット
                    this.progress = 0;
                    // 例外処理
                    // console.log(error);
                    // console.log(error.response.status);
                    // console.log(error.response);
                    // this.success = false;
                    // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                    if(error.response.data.errors) {
                      var errors = {};
                      for(var key in error.response.data.errors) {
                        errors[key] = error.response.data.errors[key].join('<br>');
                      }
                      this.errors = errors;
                      // console.log(error.response.data.errors);

                      var validationErrors = [];
                      for(var key in error.response.data.errors) {
                        validationErrors.push({
                          id: key,
                          messages: error.response.data.errors[key].map((message, key) => {
                              return {
                                id: key,
                                message: message,
                              };
                            }),
                        });
                        this.validationErrors = validationErrors;
                      }
                      // console.log(this.validtionErrors);
                      // this.echoErrors();
                      this.showValidationErrorDialog = true;
                    } else {
                      // バリデーションエラーの場合以外はエラーダイアログを表示
                      // エラーデータをほりこむ
                      this.error = error.response;
                      this.showErrorDialog = true;
                    }
                  }
                );

              }
            },
            // サブミット！
            onSubmit() {
                this.isProcessing = true;
                // console.log('this.node.michelin_star');
                // console.log(this.node.michelin_star);
                // フォームで送信するFormDataインスタンスつくる
                const formData = new FormData();
                // 送信するフィールドのデータを登録する
                // propで投稿情報が来てたら（updateだったら）
                if(this.nodeprop) {
                  // PUTで送ってるように偽装する（普通にPUTで送れないため）
                  formData.append('_method', 'PUT');
                }
                // 元の写真を削除した場合に消したファイルの情報を送る（コントローラーでレコードとファイルを消すため）
                if(this.removedSpotImages) {
                  this.removedSpotImages.forEach((val, key) => {
                    formData.append('removedSpotImages[spot_image_id][]', val.spot_image_id);
                    formData.append('removedSpotImages[file][' + key + '][type]', val.file.type);
                    formData.append('removedSpotImages[file][' + key + '][file_id]', val.file.file_id);
                    formData.append('removedSpotImages[file][' + key + '][file_name]', val.file.file_name);
                  });
                }
                // 登録する写真を登録
                if(this.spotImages) {
                  this.spotImages.forEach((data,index) => {
                    // console.log(data);
                    formData.append('image['+data.order+'][type]', data.type);
                    if(data.type == 'spot_image') formData.append('image['+data.order+'][file_name]', data.file_name);
                    if(data.type == 'pg') formData.append('image['+data.order+'][node]', data.node.id);
                    if(data.type == 'current_spot_image' || data.type == 'current_pg' || data.type == 'current_deleted') formData.append('image['+data.order+'][spot_image_id]', data.spot_image_id);
                    if(data.rotate && data.type != 'pg') formData.append('image['+data.order+'][rotate]', data.rotate);
                    // if(data.rotate && data.type != 'pg') console.log(data.rotate);
                  });
                }
                // その他のデータを登録
                formData.append('name', this.node.name);
                formData.append('name_yomi', this.node.name_yomi);
                if(this.node.excerpt) formData.append('excerpt', this.node.excerpt);
                formData.append('lat', this.node.lat);
                formData.append('lng', this.node.lng);
                if(this.node.postcode) formData.append('postcode', this.node.postcode);
                formData.append('address', this.node.address);
                if(this.node.tel) formData.append('tel', this.node.tel);
                if(this.node.fax) formData.append('fax', this.node.fax);
                if(this.node.name_formal) formData.append('name_formal', this.node.name_formal);
                if(this.node.founding_year) formData.append('founding_year', this.node.founding_year);
                if(this.node.sangou) formData.append('sangou', this.node.sangou);
                if(this.node.denomination) formData.append('denomination', this.node.denomination);
                if(this.node.honzon) formData.append('honzon', this.node.honzon);
                if(this.node.kaiki) formData.append('kaiki', this.node.kaiki);
                if(this.node.syusaishin) formData.append('syusaishin', this.node.syusaishin);
                if(this.node.reisai) formData.append('reisai', this.node.reisai);
                if(this.node.grace) formData.append('grace', this.node.grace);
                if(this.node.bunkazai) formData.append('bunkazai', this.node.bunkazai);
                if(this.node.charge) formData.append('charge', this.node.charge);
                if(this.node.time) formData.append('time', this.node.time);
                if(this.node.parking) formData.append('parking', this.node.parking);
                if(this.node.access) formData.append('access', this.node.access);
                if(this.node.website) formData.append('website', this.node.website);
                if(this.node.note) formData.append('note', this.node.note);
                if(this.node.michelin_star) {
                  formData.append('michelin_star', this.node.michelin_star);
                } else {
                  formData.append('michelin_star', 0);
                }
                if(this.node.status != null) formData.append('status', this.node.status);
                // 元のエレメントを削除した場合に消したエレメントのエレメントIDをセット（コントローラーでファイルを消すため）
                if(this.removedCurrentBodyElements) {
                  this.removedCurrentBodyElements.forEach((val, key) => {
                    formData.append('removedCurrentBodyElements[]', val.element_id);
                  });
                }
                // 本文のデータをセット
                if(this.node.body.length >= 1) {
                  this.node.body.forEach((data,index) => {
                    formData.append('body['+data.order+'][order]', data.order);
                    formData.append('body['+data.order+'][element_type]', data.elementType);
                    if(data.spot_body_element_id) formData.append('body['+data.order+'][spot_body_element_id]', data.spot_body_element_id);
                    if(data.elementType == 'header' || data.elementType == 'paragraph' || data.elementType == 'list') {
                      if(data.type) formData.append('body['+data.order+'][type]', data.type);
                      if(data.text) formData.append('body['+data.order+'][text]', data.text);
                    } else if(data.elementType == 'image') {
                      if(data.type) formData.append('body['+data.order+'][type]', data.type);
                      if(data.caption) formData.append('body['+data.order+'][caption]', data.caption);
                      if(data.type == 'uploadImage') {
                        if(data.image.uploadFile) formData.append('body['+data.order+'][file]', data.image.uploadFile);
                        if(data.image.rotate) formData.append('body['+data.order+'][rotate]', data.image.rotate);
                      } else if(data.type == 'pg') {
                        if(data.image) formData.append('body['+data.order+'][nodeId]', data.image.nodeId);
                      }
                    }
                  });
                }
                // タグをセット
                if(this.node.tags) {
                  this.node.tags.forEach(tag => {
                    formData.append('tags[]', tag.tag);
                  });
                }
                // プレイスをセット
                if(this.node.places) {
                  this.node.places.forEach(place => {
                    formData.append('places[]', place.place);
                  });
                }
                // カテゴリーをセット
                if(this.node.categories) {
                  this.node.categories.forEach(category => {
                    formData.append('categories[]', category.id);
                  });
                }
                // エリアをセット
                if(this.node.areas) {
                  this.node.areas.forEach(area => {
                    formData.append('areas[]', area.id);
                  });
                }

                // 写真を追加または変更した場合（=ファイルのアップロードと生成処理が必要=>時間がかかる）プログレスダイアログを表示
                if(this.spotImages) {
                  this.showProgress = true;
                }
                //axiosでPOST送信
                axios.post(this.actionurl, formData, {
                  onUploadProgress: this.onUpload,
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then( response => {
                  // プログレスダイアログを消す
                  this.showProgress = false;
                  // プログレスの値をリセット
                  this.progress = 0;
                  // console.log(response);
                  // リダイレクトURLが返ってきたら（=正常に登録できた）ページ移動する（シングルページにリダイレクト）
                  if(response.data.redirect_path) {
                    document.location.href = response.data.redirect_path;
                    // if(confirm(response.data.message + 'ページに移動しますか？')) document.location.href = response.data.redirect_path;
                  } else {
                    // console.log("response");
                    // console.log(response.data);
                    alert('例外が発生しました。Error: SpotEdit001');
                  }
                  this.isProcessing = false;
                }).catch(
                  (error) => {
                    // プログレスダイアログを消す
                    this.showProgress = false;
                    // プログレスの値をリセット
                    this.progress = 0;
                    // 例外処理
                    // console.log(error);
                    // console.log(error.response.status);
                    // console.log(error.response);
                    // this.success = false;
                    // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                    if(error.response.data.errors) {
                      var errors = {};
                      for(var key in error.response.data.errors) {
                        errors[key] = error.response.data.errors[key].join('<br>');
                      }
                      this.errors = errors;
                      // console.log(error.response.data.errors);

                      var validationErrors = [];
                      for(var key in error.response.data.errors) {
                        validationErrors.push({
                          id: key,
                          messages: error.response.data.errors[key].map((message, key) => {
                              return {
                                id: key,
                                message: message,
                              };
                            }),
                        });
                        this.validationErrors = validationErrors;
                      }
                      // console.log(this.validtionErrors);
                      // this.echoErrors();
                      this.showValidationErrorDialog = true;
                    } else {
                      // バリデーションエラーの場合以外はエラーダイアログを表示
                      // エラーデータをほりこむ
                      this.error = error.response;
                      this.showErrorDialog = true;
                    }
                    this.isProcessing = false;
                  }
                  
                );
            },

        }
    }
</script>

<style lang="scss" scoped>
    .relative {
      position: relative;
    }
</style>
