<template>
  <div class="planEdit">
    <form method="POST" :action="actionurl" class="register_form pure-form pure-form-stacked" enctype="multipart/form-data" @submit.prevent>


        <fieldset class="">

          <div class="form-group required">
            <div class="form_header">
              <span class="label">タイトル</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1" v-bind:class="{ 'is-invalid':errors.title }" name="name" v-model="node.title" required autocomplete="title" autofocus>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.title"></div>
                <div class="note">モデルコースのタイトル。</div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">写真</span>
            </div>
            <div class="form_body">
              <div class="form_input relative">
                <image-preview-multiple
                :images="planImages"
                @remove="index => removePlanImage(index)"
                @onsort="images => sortPlanImage(images)"
                @setImageRotate="(imageRotate, index) => setImageRotate(imageRotate, index)"
                ></image-preview-multiple>
                <image-uploader-with-photo
                name="plan_image"
                tmpFormatName="plan_image"
                :image-que="planImageQue"
                :multiple="true"
                @addQue="addQue"
                @setFile="setFile"
                @setProcessing="setProcessing"
                >
                </image-uploader-with-photo>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.image"></div>
              <div class="note">30MBまでのJPEG形式の画像ファイル</div>
              </div>
            </div>
            <!-- <textarea style="max-width:100%;min-height:400px;">{{ planImages }}</textarea> -->
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">説明</span>
            </div>
            <div class="form_body">
              <multi-element-editor :body="node.body" @setBody="val => setBody(val)" @removeCurrentElement="id => removeCurrentElement(id)"></multi-element-editor>
            </div>
            <!-- <textarea style="max-width:100%;min-height:200px;">{{ removedCurrentBodyElements }}</textarea> -->
            <!-- <textarea style="max-width:100%;min-height:200px;">{{ node.body }}</textarea> -->
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">行程</span>
            </div>
            <div class="form_body">
              <itinerary-editor
                :itineraries="itineraries"
                :itinerary_traffic_types="itinerary_traffic_types"
                @setIteneraries="setIteneraries"
                @removeCurrentItinerary="removeCurrentItinerary"
                @removeItineraryImage="removeItineraryImage"
              ></itinerary-editor>
            </div>
            <!-- <textarea style="max-width:100%;min-height:200px;">{{ itinerary_traffic_types }}</textarea> -->
            <!-- <textarea style="max-width:100%;min-height:200px;">{{ itineraries }}</textarea> -->
            <!-- <textarea style="max-width:100%;min-height:200px;">{{ node.body }}</textarea> -->
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group required">
            <div class="form_header">
              <span class="label">所要時間</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <input type="text" class="pure-input-1" v-bind:class="{ 'is-invalid':errors.duration }" name="duration" v-model="node.duration" required autocomplete="duration">
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.duration"></div>
                <div class="note">書式は 日.時間（いずれも1桁の整数のみ）<br />例:0.5→5時間、2.0→2日<br />10時間以上は1日としてください。</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">備考</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <!-- <textarea v-bind:class="{ 'is-invalid':errors.note }" name="note" rows="2" v-model="node.note"></textarea> -->
                <textarea-auto-adjust
                  :errors="errors.note"
                  name="note"
                  v-model="node.note"
                ></textarea-auto-adjust>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.note"></div>
                <div class="note">このモデルコースに関する備考があればこちらに入力してください。</div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="">

          <div class="form-group">
            <div class="form_header">
              <span class="label">プレイス（スポット）</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <tag-editor :tagColumn="'place'" :class="{ 'is-invalid':errors.places }" :currentTags="node.places" @setTags="setPlaces"></tag-editor>
              </div>
              <div v-if="node.places">
                <div v-for="result in matchPlaceResults" :key="result.key">
                  <div v-if="result.spots">
                    <div class="place">「{{ result.place }}」で
                      <a v-for="spot in result.spots" :key="spot.id" :href="'/spot/' + spot.id" target="_blank">{{ spot.name }}</a> と関連付けられます。
                    </div>
                  </div>
                  <div v-else>
                    <div class="place">「{{ result.place }}」でマッチするスポットが今の所登録されていません。</div>
                  </div>
                </div>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.places"></div>
                <div class="note">訪問する場所や施設の名前を全て入力してください。行程には入っていなくても必要な場合は入れてください。ここに入力された名前は当サイトに掲載のスポットと照合され、自動的に関連づけられます（なるべく一般的と思われる名前を推奨します。）。複数ある場合はカンマ区切りで入力してください。</div>
                <div class="note">例: <br>金閣寺の場合→「金閣寺, 鹿苑寺, 鹿苑寺金閣」<br>金戒光明寺の場合→「金戒光明寺, 黒谷さん, 黒谷, くろ谷さん, くろ谷」など</div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="form_header">
              <span class="label">タグ</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <tag-editor :tagColumn="'tag'" :currentTags="node.tags" @setTags="setTags"></tag-editor>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.tags"></div>
                <div class="note">このモデルコースに関連するワードをタグとして入力します。同じタグがついたスポットやその他のページと関連づけられます。カンマ区切りで入力してください。</div>
              </div>
            </div>
          </div>

          <div class="form-group required">
            <div class="form_header">
              <span class="label">カテゴリー</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <category-checker :entity="'plan'" :currentCategories="node.categories" @setCategories="setCategories"></category-checker>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.categories"></div>
                <div class="note">該当するカテゴリーを全てチェックしてください。最低でも1つチェックが必要です。</div>
              </div>
            </div>
          </div>

        </fieldset>

        <fieldset class="" v-if="isStaff">
          <div class="form-group">
            <div class="form_header">
              <span class="label">公開状態</span>
            </div>
            <div class="form_body">
              <div class="form_input">
                <status-checker :config_status="config_status" :enable_values="enable_status" :currentStatus="node.status ? node.status : 1" @setStatus="setStatus"></status-checker>
              </div>
              <div class="form_note">
                <div class="invalid-feedback" role="alert" v-html="errors.categories"></div>
                <div class="note">公開状態を選択してください</div>
              </div>
            </div>
          </div>
        </fieldset>

        <div class="form_buttons">
          <a v-if="nodeprop" class="btn cancelBtn" v-bind:href="'/plan/' + nodeprop.id">戻る</a>
          <button v-if="nodeprop" v-bind:disabled="isProcessing" type="button" class="btn btn-primary" @click.prevent.stop="onSubmit()">更新</button>
          <button v-else type="button" v-bind:disabled="isProcessing" class="btn btn-primary" @click.prevent.stop="onSubmit()">投稿</button>
          <button v-if="nodeprop && user.roles && user.roles.some(role => role.role <= 20)" type="button" class="btn btn-warning" @click="deleteNode">削除</button>
        </div>

        <div class="dialog dialog-t" v-if="showProgress">
          <div class="inner">
            <div class="dialog-header">
              投稿処理
            </div>
            <div class="dialog-content">
              <div v-if="progress > 0 && progress < 100">
                画像ファイルアップロード中...
                <progress ref="progress" value="0" max="100"></progress><br>{{progress}}%
              </div>
              <div v-if="!progress || progress <= 0 && progress >= 100" class="icon-inline">
                <span class="loader icon">Loading...</span>
                <span>ページ生成中...　処理完了後は自動的にページに移動します。</span>
              </div>
            </div>
          </div>
        </div>

        <div class="dialog dialog-t" v-if="showValidationErrorDialog">
          <div class="inner">
            <div class="dialog-header">
              入力内容をご確認ください
            </div>
            <div class="dialog-content">
              <ul v-for="error in validationErrors" :key="error.id">
                <li v-for="message in error.messages" :key="message.id">{{ message.message }}</li>
              </ul>
              <div class="buttons">
                <button type="button" class="btn btn-primary" @click="showValidationErrorDialog = false">OK</button>
              </div>
            </div>
          </div>
        </div>

        <div class="dialog dialog-t" v-if="showErrorDialog">
          <div class="inner">
            <div class="dialog-header">
              {{error.status}} {{error.statusText}}
            </div>
            <div class="dialog-content">
              <div>{{error.data.message}}</div>
              <div class="buttons">
                <button type="button" class="btn btn-primary" @click="showErrorDialog = false">OK</button>
              </div>
            </div>
          </div>
        </div>

    </form>
  </div>

</template>

<script>
    import ImageUploaderWithPhoto from './form_element/ImageUploaderWithPhoto';
    import MultiElementEditor from './MultiElementEditor';
    import ItineraryEditor from './form_element/ItineraryEditor';
    import TagEditor from './TagEditor';
    import CategoryChecker from './CategoryChecker';
    import draggable from 'vuedraggable';
    import ImagePreviewMultiple from './ImagePreviewMultiple'
    import TextareaAutoAdjust from './form_element/TextareaAutoAdjust';
    import StatusChecker from './form_element/StatusChecker';
    export default {
        components: {
            ImageUploaderWithPhoto,
            MultiElementEditor,
            ItineraryEditor,
            TagEditor,
            CategoryChecker,
            draggable,
            ImagePreviewMultiple,
            TextareaAutoAdjust,
            StatusChecker,
        },
        props: {
          // nodeプロパティ
          nodeprop: {
            type: Object,
            default: null,
            required: false,
          },
          // フォームの送信先
          actionurl: {
            type: String,
            required: true,
          },
          user: {
              type: Object,
              required: true,
          },
          config_status: {
              type: Object,
              required: true,
          },
          // 移動手段タイプの設定
          itinerary_traffic_types: {
            type: Array,
            required: true,
          },
            user: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            isAdmin: false,
            isStaff: false,
            enable_status: [ // {1: "公開", 5: "限定公開", 10: "予約", 20: "下書き", 40: "承認申請中", 45: "承認却下", 60: "公開制限中", 90: "非公開"}
              // 1, 5, 10, 20, 40, 45, 60, 90,
              1, 90,
            ],
            // post
            node: {
              title: '',
              body: [],
              itineraries: [],
              places: [],
            },
            itineraries: [
              // {
              //     new: true,
              //     spot_id: null,
              //     name: null,
              //     image: {
              //       file_name: null,
              //       preview: {
              //         src: null,
              //       },
              //       flag: {
              //         uploaderShowFlag: true,
              //         nowRenderingFlag: false,
              //         nowChangeFlag: false,
              //         isDragging: false,
              //         // ドラッグ中かどうかの判定用
              //         dragCount: 0,
              //       },
              //     },
              //     photo_id: null,
              //     description: null,
              //     traffic_type: null,
              //     order: 1,
              // }
            ],
            removedItineraries: [],
            removedItineraryImages: [],
            removedCurrentBodyElements: [],
            // 写真のデータ
            planImages: [],
            planImageQue: 0,
            removedPlanImages: [],
            // // 画像を追加してプレビューが描画されるまでのキュー数
            // showPreviewQue: 0,
            // 元の写真を削除したらtrue
            remove_file: false,
            // エラーダイアログ表示フラグ
            showErrorDialog: false,
            // 返ってきたerror.responceが入る
            error: {},
            // バリデーションエラーの内容が入る
            errors: {},
            // プログレスダイアログ用の進捗状況（%）
            progress: 0,
            // プログレスダイアログ表示フラグ
            showProgress: false,
            areas: [],
            areaChecked: [],
            // バリデーションエラーダイアログ表示フラグ
            showValidationErrorDialog: false,
            // バリデーションエラーダイアログ表示フラグ
            validationErrors: [],
            // プレイスでマッチしたプレイスデータを入れる
            findPlaceResults: [],
            // 処理中はtrueにするとボタンが無効になる
            isProcessing: false,
        }),
        created() {
            this.isAdmin = this.user.roles.some(user => user.role === 1);
            this.isStaff = this.user.roles.some(user => user.role === 1 || user.role === 10 || user.role === 20);
            // console.log('this.nodeprop');
            // console.log('-------------this.nodeprop-----------------');
            // console.log(this.nodeprop);
            if(this.nodeprop) {
              // ノードプロパティをdataにセット
              this.node = this.nodeprop;
              if(this.nodeprop.plan_images) {
                this.nodeprop.plan_images.forEach((plan_image, index) => {
                  if(plan_image.file) {
                    if(plan_image.file_type == 'App\\Models\\PlanImageFile') {
                      var current_image = {
                        viewId: index + 1,
                        order: plan_image.order,
                        type: 'current_plan_image',
                        file_id: plan_image.file_id,
                        file_name: plan_image.file.file_name,
                        landscape: plan_image.file.landscape,
                        preview: {
                          src: '/storage/images/plan_image/thumb/' + plan_image.file.file_name,
                        },
                        new: false,
                        plan_image_id: plan_image.id,
                      }
                    } else if(plan_image.file_type == 'App\\Models\\Photo') {
                      var pg_file = this.nodeprop.photos.filter(pg => { return pg.id == plan_image.file_id } );
                      var current_image = {
                        viewId: index + 1,
                        order: plan_image.order,
                        type: 'current_pg',
                        file_id: plan_image.file_id,
                        file_name: plan_image.file.file_name,
                        landscape: plan_image.file.landscape,
                        preview: {
                          src: '/storage/images/plan_image/thumb/' + plan_image.file.file_name,
                        },
                        new: false,
                        rotatable: true,
                        plan_image_id: plan_image.id,
                        user_id: plan_image.file.user_id,
                        user_name: pg_file[0].user.name,
                      }
                    }
                  } else { // 通常はないけど、もしリレーション先の画像モデルが見つからない（削除されてしまってる）場合の処理。
                    var current_image = {
                      viewId: index + 1,
                      order: plan_image.order,
                      type: 'current_deleted',
                      file_id: plan_image.file_id,
                      file_name: null,
                      landscape: null,
                      preview: {
                        src: 'deleted',
                      },
                      new: false,
                      plan_image_id: plan_image.id,
                    }
                  }
                  this.planImages.push(current_image);
                });
              }
              // console.log('this.nodeprop.itineraries');
              // console.log(this.nodeprop.itineraries);
              // console.log('this.planImages');
              // console.log(this.planImages);


                // console.log('this.nodeprop.itineraries');
                // console.log(this.nodeprop.itineraries);

              if(this.nodeprop.itineraries) {
                this.iteneraries = [];
                // console.log('+++++++++++++++++this.nodeprop.itineraries++++++++++++++++++');
                // console.log(this.nodeprop.itineraries);
                this.nodeprop.itineraries.forEach((itinerary, index) => {
                  let currentItinerary = {};
                  currentItinerary = {
                    new: false,
                    itinerary_id: itinerary.id,
                    spot_id: itinerary.spot_id ? itinerary.spot_id : null,
                    image: {},
                    name: itinerary.name,
                    description: itinerary.description ? itinerary.description : null,
                    order: index + 1,
                    traffics: itinerary.traffics
                  };
                  if(itinerary.image !== null && itinerary.image !== '') {
                    currentItinerary.image = {
                      new: false,
                      type: 'current_upload',
                      file_name: itinerary.image,
                      rotatable: true,
                      rotate: 0,
                      imageRotateClass: "rotate-init",
                      preview: {
                        src: '/storage/images/itinerary_image/single/'+itinerary.image,
                      },
                      file: {},
                      flag: {
                        uploaderShowFlag: false,
                        nowRenderingFlag: false,
                        nowChangeFlag: false,
                        isDragging: false,
                        dragCount: 0,
                      },
                    }
                  } else if(itinerary.photo_id !== null && itinerary.photo_id !== '') {
                    // console.log('itinerary.photo');
                    // console.log(itinerary.photo);
                    currentItinerary.image = {
                      new: false,
                      type: 'current_pg',
                      file_name: itinerary.photo.file_name,
                      rotatable: false,
                      rotate: null,
                      imageRotateClass: '',
                      photo_id: itinerary.photo.id,
                      preview: {
                        src: '/storage/images/itinerary_image/single/'+itinerary.photo.file_name_nc,
                      },
                      file: {},
                      flag: {
                        uploaderShowFlag: false,
                        nowRenderingFlag: false,
                        nowChangeFlag: false,
                        isDragging: false,
                        dragCount: 0,
                      },
                    }
                  } else {
                    currentItinerary.image = {
                      file_name: null,
                      preview: {
                        src: null,
                      },
                      flag: {
                        uploaderShowFlag: true,
                        nowRenderingFlag: false,
                        nowChangeFlag: false,
                        isDragging: false,
                        dragCount: 0,
                      },
                    };
                  }
                  // console.log('currentItinerary');
                  // console.log(currentItinerary);
                  this.itineraries.push(currentItinerary);
                });
              }
              // console.log(this.itineraries);

              if(this.nodeprop.body) {
                // this.node.body = [];
                var current_body = [];
                this.nodeprop.body.forEach(body => {
                  if(body.element_type == 'App\\Models\\ElementHeader') {
                    var element = {
                      elementType: 'header',
                      new: false,
                      plan_body_element_id: body.id,
                      type: body.element.type,
                      text: body.element.text,
                      order: body.order,
                      status: 'preview',
                    };
                  } else if(body.element_type == 'App\\Models\\ElementText') {
                    var element = {
                      new: false,
                      plan_body_element_id: body.id,
                      type: body.element.type,
                      text: body.element.text,
                      order: body.order,
                      status: 'preview',
                    };
                    if(body.element.type == 'p') {
                      element.elementType = 'paragraph';
                    } else if(body.element.type == 'ul') {
                      element.elementType = 'list';
                    }
                  } else if(body.element_type == 'App\\Models\\ElementImage') {
                    var element = {
                      elementType: 'image',
                      new: false,
                      plan_body_element_id: body.id,
                      type: 'uploadImage',
                      image: {
                        uploadFile: null,
                        file_preview_src: '/storage/images/element_image/single/' + body.element.file_name,
                        preview: {
                          src: '/storage/images/element_image/single/' + body.element.file_name,
                        },
                        rotate: 0,
                        prevStyle: {},
                        fileName: body.element.file_name,
                        nodeId: body.element_id,
                      },
                      caption: body.caption,
                      flag: {
                        uploaderShowFlag: false,
                        nowRenderingFlag: false,
                        nowChangeFlag: false,
                        isDragging: false,
                        // ドラッグ中かどうかの判定用
                        dragCount: 0,
                      },
                      order: body.order,
                      status: 'preview',
                    };
                  } else if(body.element_type == 'App\\Models\\Photo') {
                    var element = {
                      elementType: 'image',
                      new: false,
                      plan_body_element_id: body.id,
                      type: 'pg',
                      image: {
                        uploadFile: null,
                        file_preview_src: '/storage/images/element_image/single/' + body.element.file_name,
                        preview: {
                          src: '/storage/images/element_image/single/' + body.element.file_name,
                        },
                        fileName: body.element.file_name,
                        nodeId: body.element_id,
                        file: {
                          name: body.element.title,
                        },
                        author: {
                          name: body.element.user_id,
                        },
                      },
                      caption: body.caption,
                      flag: {
                        uploaderShowFlag: false,
                        nowRenderingFlag: false,
                        nowChangeFlag: false,
                        isDragging: false,
                        // ドラッグ中かどうかの判定用
                        dragCount: 0,
                      },
                      order: body.order,
                      status: 'preview',
                    };
                  }
                  current_body.push(element);
                  // this.node.body.push(element);
                });
                this.setBody(current_body);
              }

            }
                this.node.itineraries = [
                  {
                    plan_id: null,
                    name: null,
                    spot_id: null,
                    image: null,
                    photo_id: null,
                    description: null,
                    traffic_type: null,
                    traffic_text: null,
                    order: null,
                  }
                ];

            if(this.nodeprop.date_start) {
              let split_date = nodeprop.date_start.split('-');
              this.node.date_start = {
                date: nodeprop.date_start,
                year: split_date[0],
                month: split_date[1],
                day: split_date[2],
              }
            }
            if(this.nodeprop.date_end) {
              let split_date = nodeprop.date_end.split('-');
              this.node.date_end = {
                date: nodeprop.date_end,
                year: split_date[0],
                month: split_date[1],
                day: split_date[2],
              }
            }

            if(this.node.weight === undefined) this.node.weight = 0;
            this.executeSetPlaces;
            // console.log(this.node);
            // console.log(this.actionurl);
        },
        // beforeDestroy() {
        //   this.planImages.foreach((image, index) => this.removePlanImage(index));
        //   alert('ですとろーい');
        // },
        computed: {
          // プレイスの入力値にマッチするスポットの情報を返す
          matchPlaceResults() {
            if(this.node.places) {
              // console.log('プレイス検索中');
              return this.node.places.map((val, key) => {
                var match = null;
                if(this.findPlaceResults.length >= 1) {
                  match = this.findPlaceResults.find(v => v.place == val.place);
                }
                if(match && match.spots.length >= 1) {
                  return {key: key, place: val.place, spots: match.spots}
                } else {
                  return {key: key, place: val.place, spots: null}
                }
              });
            }
          },
        },
        methods: {
          setStatus(status) {
            // alert(status);
            // alert(this.node.status);
            this.node.status = status;
            // console.log(this.node.status);
          },
          setIteneraries(iti) {
            this.itineraries = iti;
            // console.log(this.itineraries);
          },
          removeCurrentItinerary(itinerary) {
            this.removedItineraries.push(itinerary);
          },
          removeItineraryImage(images) {
            this.removedItineraryImages = images;
          },
          // test(e) {
          //   console.log(e.target.value);
          //   // node.date_start.day = e.target.value ? ( '00' + e.target.value ).slice( -2 ) : ''
          // },
            sortPlanImage(planImages) {
              // console.log('fire!');
              this.planImages = planImages;
            },
            setImageRotate(imageRotate, index) {
                // console.log(imageRotate);
                this.planImages[index].rotate = imageRotate.rotate;
                // this.imageRotate = imageRotate;
            },
            /**
             * plan_imagesの処理
             */
            addQue() {
              this.planImageQue++;
              this.planImages.push({
                order: this.planImages.length + 1,
                type: 'que',
                queIndex: this.planImageQue,
                preview: {},
              });
            },
            setProcessing(status) {
              this.isProcessing = status;
              console.log(this.isProcessing);
            },
            // from: ImageUploaderMultipleWithPhoto ->
            // ファイルを追加（アップローダーコンポーネントで画像追加したらこれが発火 ※引数にファイルが入ってくる）
            // または ファイルを削除（アップローダーコンポーネントで画像削除したらこれが発火 ※引数はnull）
            // setFile(file, type, queIndex = null) {
            setFile(e) {
              var file = e.file;
              var type = e.type;
              var queIndex = e.queIndex;
              // console.log('++++++++++++++++++++++++');
              // console.log(file);
              // console.log(type);
              // console.log(queIndex);
              if(type == 'upload') {
                if(file) { // ファイルが送られてきたら（=画像が追加された）
                  var targetIndex = this.planImages.findIndex(image => image.queIndex == queIndex);
                  var newValue = {
                    viewId: this.planImages.length,
                    order: this.planImages[targetIndex].order,
                    type: 'plan_image',
                    file_name: file.tmpFile,
                    preview: {
                      src: file.src,
                      file: file.file,
                    },
                    file: file,
                    new: true,
                    rotatable: true,
                    rotate: 0,
                    imageRotate: 0,
                    imageRotateClass: 'rotate-init',
                  };
                  this.$set(this.planImages, targetIndex, newValue);
                } else { // 引数がnullだったら（=画像が削除された）
                  // 登録用画像を削除
                  this.file = null;
                }
                if(this.node.file_name) {
                  // nodeのfileを削除
                  this.node.file_name = null;
                  // 古いの消したよフラグ立てる
                  this.remove_file = true;
                }
              } else if(type == 'pg') {
                if(file) { // ファイルが送られてきたら（=画像が追加された）
                  // 画像データを登録
                  this.planImages.push({
                    order: this.planImages.length + 1,
                    type: 'pg',
                    file_name: file.file_name,
                    preview: {
                      src: '/storage/images/pg/thumb/' + file.file_name,
                    },
                    node: file,
                    new: true,
                    user_id: file.user.id,
                    user_name: file.user.name,
                  });
                } else { // 引数がnullだったら（=画像が削除された）
                  // 登録用画像を削除
                  this.file = null;
                }
                if(this.node.file_name) {
                  // nodeのfileを削除
                  this.node.file_name = null;
                  // 古いの消したよフラグ立てる
                  this.remove_file = true;
                }
              }
              this.isProcessing = false;
            },
            // from: ImageUploaderMultipleWithPhoto -> メイン画像アップロードコンポーネントから、画像が削除された時の処理
            removePlanImage(index) {
              // console.log('removeSpotImage');
              // console.log(index);
              if(this.planImages[index].type == 'plan_image' && this.planImages[index].new === true ) {
                // その編集で追加した画像の場合はtmp画像を消す
                axios.post('/tmp-remove',{
                  field: 'multiple_image',
                  images: [this.planImages[index].file_name],
                })
                .then((res)=>{
                  // console.log(res);
                  // 指定の画像を消す
                  this.planImages.splice( index, 1 );
                  // orderを書き換える（key + 1）
                  this.planImages.forEach((val, index) => val.order = index + 1);
                })
                .catch((error) => {
                  // console.log(error.response);
                  alert('サーバーエラー: 画像を削除できませんでした');
                  // // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                  // if(error.response.data.errors) {
                  //   var errors = {};
                  //   for(var key in error.response.data.errors) {
                  //     errors[key] = error.response.data.errors[key].join('<br>');
                  //   }
                  //   this.errors = errors;
                  // } else {
                  //   // バリデーションエラーの場合以外はエラーダイアログを表示
                  //   // エラーデータをほりこむ
                  //   this.error = error.response;
                  //   this.showErrorDialog = true;
                  // }
                })
              } else {
                // もともとある画像の場合は削除フラグをセットしてデータから消す
                this.removedPlanImages.push({
                  plan_image_id: this.planImages[index].plan_image_id,
                  file: {
                    type: this.planImages[index].type,
                    file_id: this.planImages[index].file_id,
                    file_name: this.planImages[index].file_name,
                  }
                }),
                // 指定の画像を消す
                this.planImages.splice( index, 1 );
                // orderを書き換える（key + 1）
                this.planImages.forEach((val, index) => val.order = index + 1);
              }
            },

            /**
             * bodyの処理
             */
            // from: MultiElementEditor -> 本文コンポーネントから本文のデータをセット
            setBody(val) {
                this.node.body = val;
            },
            // from: MultiElementEditor -> 本文コンポーネントから、カレント（今回の編集で新規に追加したものでない）エレメントを削除した場合に削除リストに入れる
            removeCurrentElement(elm) {
                var removedCurrentBodyElement = {
                  order: elm.order,
                  element_id: elm.plan_body_element_id,
                  element_type: elm.elementType,
                };
                if(elm.elementType == 'header' || elm.elementType == 'paragraph' || elm.elementType == 'list') {
                  removedCurrentBodyElement.type = elm.type;
                  removedCurrentBodyElement.text = elm.text;
                } else if(elm.elementType == 'image') {
                  removedCurrentBodyElement.file = {
                    type: elm.type,
                    file_name: elm.image.fileName,
                    file_id: elm.image.nodeId,
                  };
                }
                this.removedCurrentBodyElements.push(removedCurrentBodyElement);
            },
            // タグをセット
            setTags(tagsArr) {
              // console.log(tagsArr);
              var newTags = [];
              tagsArr.forEach(tag =>{
                newTags.push({
                  tag: tag,
                });
              });
              this.node.tags = newTags;
            },
            // プレイスをセット
            setPlaces(placesArr) {
              // その都度やってたら1文字ごとにクエリが飛ぶので、0.5秒待ってから処理。その間に次のやつ来たら前のをキャンセルする。
              if(this.setPlacesQue) {
                clearTimeout(this.setPlacesQue);
              }
              this.setPlacesQue = setTimeout(this.executeSetPlaces, 500, placesArr);
            },
            executeSetPlaces(placesArr) {
              var newPlaces = [];
              placesArr.forEach(place =>{
                if(place) {
                  newPlaces.push({
                    place: place,
                  });
                }
              });
              if(JSON.stringify(this.node.places) != JSON.stringify(newPlaces)) { //前後の変数を比較して異なっていた時だけ処理する
                this.node.places = newPlaces;
                this.findPlaces();
              }
            },
            findPlaces() {
              // プレイス名の配列を作る
              var placeNames = this.node.places.map(v => { return v.place });
              // マッチするスポットを探す
              axios.post('/place/find_spot', { places: placeNames }).then((res)=>{
                if(res.data) {
                  // プレイスがあったらデータに反映
                  this.findPlaceResults = res.data;
                  // console.log('res.data');
                  // console.log(res.data);
                }
                // console.log(this.node.places);
                // console.log(this.matchPlaceResults);
              })
              // ToDo: エラー処理書く
              .catch(error => console.log(error))
            },
            // カテゴリーをセット
            setCategories(categoriesArr) {
              // var newCategories = [];
              var newCategories = categoriesArr.map(category =>{
                return {id: category};
              });
              this.node.categories = newCategories;
              //     console.log('-+++--');
              // console.log(this.node.categories);
            },
            // // エリアをセット
            // setAreas() {
            //   // console.log(e.target);
            //   // var area_id = e.target.value;
            //   // var newCategories = [];
            //   var newAreas = this.areaChecked.map(area =>{
            //     return {id: area};
            //   });
            //   this.node.areas = newAreas;
            //   // console.log('-+++--');
            //   // console.log(this.node.areas);
            // },


            /**
             * 送信処理
             */
            // 画像アップロード中プログレスバーを出す
            onUpload(e) {
              if(this.showProgress == true) {
                // console.log(e.loaded +'/'+ e.total);
                if(this.$refs.progress) {
                  // プログレスバーを計算して変更
                  this.$refs.progress.value = Math.floor((e.loaded * 100) / e.total);
                  this.progress = Math.floor((e.loaded * 100) / e.total);
                }
              }
            },
            deleteNode() {
              this.isProcessing = true;
              this.dialogTitle = "削除処理";
              this.dialogMessage = "削除処理中。このままお待ち下さい。";
              this.showProgress = true;
              const formData = new FormData();
              // formData.append('_method', 'DELETE');
              // 削除リクエスト投げる
              // console.log('/report/' + this.node.id);
              if (confirm('本当に削除してよろしいですか?')) {
                axios.delete('/plan/' + this.node.id)
                .then((response) => {
                  // // プログレスダイアログを消す
                  // this.showProgress = false;
                  // // プログレスの値をリセット
                  // this.progress = 0;
                  // console.log(response);
                  // リダイレクトURLが返ってきたら（=正常に削除できた）ページ移動する（一覧ページにリダイレクト）
                  if(response.data.redirect_path) {
                    // console.log(response.data.redirect_path);
                    // alert('例外が発生しました。Error: ArticleEdit001');
                    // alert(response.data.redirect_path);
                    document.location.href = response.data.redirect_path;
                    // if(confirm(response.data.message + 'ページに移動しますか？')) document.location.href = response.data.redirect_path;
                  } else {
                    // console.log(response);
                    alert('例外が発生しました。Error: ArticleEdit001');
                  }
                }).catch(
                  (error) => {
                    // プログレスダイアログを消す
                    this.showProgress = false;
                    // プログレスの値をリセット
                    this.progress = 0;
                    // 例外処理
                    // console.log(error);
                    // console.log(error.response.status);
                    // console.log(error.response);
                    // this.success = false;
                    // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                    if(error.response.data.errors) {
                      var errors = {};
                      for(var key in error.response.data.errors) {
                        errors[key] = error.response.data.errors[key].join('<br>');
                      }
                      this.errors = errors;
                      // console.log(error.response.data.errors);

                      var validationErrors = [];
                      for(var key in error.response.data.errors) {
                        validationErrors.push({
                          id: key,
                          messages: error.response.data.errors[key].map((message, key) => {
                              return {
                                id: key,
                                message: message,
                              };
                            }),
                        });
                        this.validationErrors = validationErrors;
                      }
                      // console.log(this.validtionErrors);
                      // this.echoErrors();
                      this.showValidationErrorDialog = true;
                    } else {
                      // バリデーションエラーの場合以外はエラーダイアログを表示
                      // エラーデータをほりこむ
                      this.error = error.response;
                      this.showErrorDialog = true;
                    }
                  }
                );

              }
            },
            // サブミット！
            onSubmit() {
                // フォームで送信するFormDataインスタンスつくる
                const formData = new FormData();
                // 送信するフィールドのデータを登録する
                // propで投稿情報が来てたら（updateだったら）
                if(this.nodeprop) {
                  // PUTで送ってるように偽装する（普通にPUTで送れないため）
                  formData.append('_method', 'PUT');
                }
                // 元の写真を削除した場合に消したファイルの情報を送る（コントローラーでレコードとファイルを消すため）
                if(this.removedPlanImages) {
                  this.removedPlanImages.forEach((val, key) => {
                    formData.append('removedPlanImages[plan_image_id][]', val.plan_image_id);
                    formData.append('removedPlanImages[file][' + key + '][type]', val.file.type);
                    formData.append('removedPlanImages[file][' + key + '][file_id]', val.file.file_id);
                    formData.append('removedPlanImages[file][' + key + '][file_name]', val.file.file_name);
                  });
                }
                // 登録する写真を登録
                if(this.planImages) {
                  this.planImages.forEach((data,index) => {
                    // console.log(data);
                    formData.append('image['+data.order+'][type]', data.type);
                    if(data.type == 'plan_image') formData.append('image['+data.order+'][file_name]', data.file_name);
                    if(data.type == 'pg') formData.append('image['+data.order+'][node]', data.node.id);
                    if(data.type == 'current_plan_image' || data.type == 'current_pg' || data.type == 'current_deleted') formData.append('image['+data.order+'][plan_image_id]', data.plan_image_id);
                    if(data.rotate && data.type != 'pg') formData.append('image['+data.order+'][rotate]', data.rotate);
                    // if(data.rotate && data.type != 'pg') console.log(data.rotate);
                  });
                }
                // その他のデータを登録
                formData.append('title', this.node.title);
                if(this.node.duration) formData.append('duration', this.node.duration);
                if(this.node.note) formData.append('note', this.node.note);
                if(this.node.status != null) formData.append('status', this.node.status);
                // 元のエレメントを削除した場合に消したエレメントのエレメントIDをセット（コントローラーでファイルを消すため）
                if(this.removedCurrentBodyElements) {
                  this.removedCurrentBodyElements.forEach((val, key) => {
                    formData.append('removedCurrentBodyElements[]', val.element_id);
                  });
                }
                // 行程写真を削除した場合に消したファイルの情報を送る（コントローラーでレコードとファイルを消すため）
                if(this.removedItineraryImages) {
                  this.removedItineraryImages.forEach((val, key) => {
                    formData.append('removedItineraryImages[' + key + '][type]', val.type);
                    formData.append('removedItineraryImages[' + key + '][file_name]', val.file_name);
                    formData.append('removedItineraryImages[' + key + '][photo_id]', val.photo_id);
                  });
                }
                // 行程を削除した場合に消した行程の情報を送る（コントローラーでレコードとファイルを消すため）
                if(this.removedItineraries) {
                  this.removedItineraries.forEach((val, key) => {
                    formData.append('removedItineraries[' + key + ']', val);
                  });
                }
                // console.log('--1--');
                // 行程のデータをセット
                if(this.itineraries.length >= 1) {
                  this.itineraries.forEach((data,index) => {
                    formData.append('itineraries['+data.order+'][order]', data.order);
                    formData.append('itineraries['+data.order+'][new]', data.new);
                    formData.append('itineraries['+data.order+'][itinerary_id]', data.itinerary_id ?  data.itinerary_id : '');
                    formData.append('itineraries['+data.order+'][name]', data.name);
                    formData.append('itineraries['+data.order+'][spot_id]', data.spot_id ? data.spot_id : '');
                    if(data.image.file_name) {
                      formData.append('itineraries['+data.order+'][image][type]', data.image.type);
                      if(data.image.type === 'upload' || data.image.type === 'current_upload') {
                        formData.append('itineraries['+data.order+'][image][file_name]', data.image.file_name);
                        formData.append('itineraries['+data.order+'][image][rotate]', data.image.rotate);

                      } else if(data.image.type === 'pg' || data.image.type === 'current_pg') {
                        formData.append('itineraries['+data.order+'][image][photo_id]', data.image.photo_id);
                      }
                    }
                    formData.append('itineraries['+data.order+'][description]', data.description ? data.description : '');
                // console.log('--2--');
                    if(data.traffics.length >= 1) {
                      data.traffics.forEach((traffic, index) => {
                        formData.append('itineraries['+data.order+'][traffics]['+index+'][id]', traffic.id ? traffic.id : '');
                        formData.append('itineraries['+data.order+'][traffics]['+index+'][plan_itinerary_id]', traffic.plan_itinerary_id ? traffic.plan_itinerary_id : '');
                        formData.append('itineraries['+data.order+'][traffics]['+index+'][order]', traffic.order ? traffic.order : '');
                        formData.append('itineraries['+data.order+'][traffics]['+index+'][type]', traffic.type ? traffic.type : '');
                        formData.append('itineraries['+data.order+'][traffics]['+index+'][title]', traffic.title ? traffic.title : '');
                        formData.append('itineraries['+data.order+'][traffics]['+index+'][text]', traffic.text ? traffic.text : '');
                      });
                    }
                // console.log('--3--');
                  });
                }
                // 本文のデータをセット
                if(this.node.body.length >= 1) {
                  this.node.body.forEach((data,index) => {
                    formData.append('body['+data.order+'][order]', data.order);
                    formData.append('body['+data.order+'][element_type]', data.elementType);
                    if(data.plan_body_element_id) formData.append('body['+data.order+'][plan_body_element_id]', data.plan_body_element_id);
                    if(data.elementType == 'header' || data.elementType == 'paragraph' || data.elementType == 'list') {
                      if(data.type) formData.append('body['+data.order+'][type]', data.type);
                      if(data.text) formData.append('body['+data.order+'][text]', data.text);
                    } else if(data.elementType == 'image') {
                      if(data.type) formData.append('body['+data.order+'][type]', data.type);
                      if(data.caption) formData.append('body['+data.order+'][caption]', data.caption);
                      if(data.type == 'uploadImage') {
                        if(data.image.uploadFile) formData.append('body['+data.order+'][file]', data.image.uploadFile);
                        if(data.image.rotate) formData.append('body['+data.order+'][rotate]', data.image.rotate);
                      } else if(data.type == 'pg') {
                        if(data.image) formData.append('body['+data.order+'][nodeId]', data.image.nodeId);
                      }
                    }
                  });
                }
                // タグをセット
                if(this.node.tags) {
                  this.node.tags.forEach(tag => {
                    formData.append('tags[]', tag.tag);
                  });
                }
                // プレイスをセット
                if(this.node.places) {
                  this.node.places.forEach(place => {
                    formData.append('places[]', place.place);
                  });
                }
                // カテゴリーをセット
                if(this.node.categories) {
                  this.node.categories.forEach(category => {
                    formData.append('categories[]', category.id);
                  });
                }

                // 写真を追加または変更した場合（=ファイルのアップロードと生成処理が必要=>時間がかかる）プログレスダイアログを表示
                if(this.planImages) {
                  this.showProgress = true;
                }
                //axiosでPOST送信
                axios.post(this.actionurl, formData, {
                  onUploadProgress: this.onUpload,
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then( response => {
                  // プログレスダイアログを消す
                  this.showProgress = false;
                  // プログレスの値をリセット
                  this.progress = 0;
                  // console.log(response);
                  // リダイレクトURLが返ってきたら（=正常に登録できた）ページ移動する（シングルページにリダイレクト）
                  if(response.data.redirect_path) {
                    document.location.href = response.data.redirect_path;
                    // if(confirm(response.data.message + 'ページに移動しますか？')) document.location.href = response.data.redirect_path;
                  } else {
                    // console.log(response);
                    alert('例外が発生しました。Error: PlanEdit001');
                  }
                }).catch(
                  (error) => {
                    // プログレスダイアログを消す
                    this.showProgress = false;
                    // プログレスの値をリセット
                    this.progress = 0;
                    // 例外処理
                    // console.log(error);
                    // console.log(error.response.status);
                    // console.log(error.response);
                    // this.success = false;
                    // バリデーションエラーの場合、this.errorsにエラー内容をほりこむ（=エラー表示される）
                    if(error.response.data.errors) {
                      var errors = {};
                      for(var key in error.response.data.errors) {
                        errors[key] = error.response.data.errors[key].join('<br>');
                      }
                      this.errors = errors;
                      // console.log(error.response.data.errors);

                      var validationErrors = [];
                      for(var key in error.response.data.errors) {
                        validationErrors.push({
                          id: key,
                          messages: error.response.data.errors[key].map((message, key) => {
                              return {
                                id: key,
                                message: message,
                              };
                            }),
                        });
                        this.validationErrors = validationErrors;
                      }
                      // console.log(this.validtionErrors);
                      // this.echoErrors();
                      this.showValidationErrorDialog = true;
                    } else {
                      // バリデーションエラーの場合以外はエラーダイアログを表示
                      // エラーデータをほりこむ
                      this.error = error.response;
                      this.showErrorDialog = true;
                    }
                  }
                );
            },

        }
    }
</script>

<style lang="scss" scoped>
    .relative {
      position: relative;
    }
</style>
