<template>
  <textarea
    :name="name"
    :id="id"
    :class="{ 'is-invalid':errors }"
    :ref="refVal"
    :value="value"
    :rows="rows"
    :cols="cols"
    :required="required"
    :autocomplete="autocomplete"
    :autofocus="autofocus"
    :placeholder="placeholder"
    @input="updateValue"
    @change="textAreaHeightSet($event.target)"
    @focus="textAreaHeightSet($event.target)"
    @keydown.enter="updateTextareaElmWithEnterKey($event,elm)"
  >
  </textarea>
</template>

<script>
    export default {
        props: {
          name: {
            type: String,
            required: true,
          },
          isShow: {
            type: Boolean,
            default: true,
            required: false,
          },
          id: {
            type: String,
            required: false,
          },
          errors: {
            type: Object,
            required: false,
          },
          refVal: {
            type: String,
            // default: new Date().getTime().toString(16)  + Math.floor(1000*Math.random()).toString(16),
            default: 'textarea',
            required: false,
          },
          value: {
            type: String,
            default: '',
            required: false,
          },
          required: {
            type: Boolean,
            default: false,
            required: false,
          },
          autocomplete: {
            type: String,
            default: '',
            required: false,
          },
          rows: {
            type: Number,
            default: 2,
            required: false,
          },
          cols: {
            type: Number,
            required: false,
          },
          autofocus: {
            type: String,
            required: false,
          },
          placeholder: {
            type: String,
            required: false,
          },
// autofocus属性
// 自動フォーカスを指定する（autofocus）
// cols属性（初期値は20）
// 一行当たりの最大文字数の目安を指定する
// disabled属性
// 操作を無効にする（disabled）
// form属性
// どのフォームと関連付けるかを<form>のid名で指定
// maxlength属性
// 入力可能な最大文字数を指定
// name属性
// 入力フィールドの名前を指定
// placeholder属性
// ユーザー入力に関するヒントを短めのテキストで指定
// readonly属性
// ユーザーによるテキスト編集を不可にして読み取り専用にする（readonly）
// required属性
// 入力必須を指定
// rows属性（初期値は2）
// 表示行数を指定する
// wrap属性
// 入力テキストの改行ルールを指定
        },
        // data: () => ({
        // }),
        // created() {
        // },
        mounted () {
              // this.$refs[this.refValue].focus();
              // console.log('this.refVal');
              // console.log(this.refVal);
            this.textAreaHeightSet(this.$refs[this.refVal]);
        },
        // computed: {
        //   classVal() {
        //     console.log('this.class_str');
        //     console.log(this.class_str);
        //     return this.class_str;
        //   }
        // },
        watch: {
          isShow() {
            // console.log('this.isShow');
            // console.log(this.isShow);
            // console.log('this.$refs[this.refVal]');
            // console.log(this.$refs[this.refVal]);
            if(this.isShow) {
              this.$refs[this.refVal].focus();
            } else {
              this.$refs[this.refVal].blur();
            }
          }
        },
        methods: {
          updateValue(e) {
            this.textAreaHeightSet(e.target);
            this.$emit('input', e.target.value);
          },
            // 変換確定時のEnterでもEnter押下が発火してしまうのでEnterのkeypressイベント（変換中は発火しない）で一旦フラグを立ててkeyupで発火させるようにフラグを立てる
            setCanEnter(e) {
                this.canEnter = true;
            },
            // テキストエリアフォーカス中にctrl+enterで確定
            updateTextareaElmWithEnterKey(e) {
                if(e.ctrlKey || e.metaKey) {
                  e.preventDefault(); // デフォルトの動作をキャンセル
                  e.stopPropagation(); // 現在のイベントの親要素への伝播
                  // this.updateElm(elm);
                  this.$emit('onEnter');
                }
            },
            // テキストエリアの高さセット
            textAreaHeightSet: function(target){
                // var target = e.target
                // 一旦テキストエリアを小さくしてスクロールバー（縦の長さ)を取得
                target.style.height = "1.4em";
                var wSclollHeight = parseInt(target.scrollHeight);
                // 最低50pxにする
                if(wSclollHeight < 50){
                  wSclollHeight = 50;
                }
                // テキストエリアの高さを設定する
                target.style.height = wSclollHeight + 2 + "px";
            },
        }
    }
</script>
